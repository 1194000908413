import { LITE_TYPE_OF_REPORT_EXCLUDED } from "constants/databaseConstants";
import { userIsLite } from "helpers/litehelper";

export const initialCertificateFilterDataState = () => {
    const typeOfReportArray = ['country', 'entity', 'law', 'department', 'auditor'];

    return {
        typeOfReport: typeOfReportArray.filter((element)=>{
          if(userIsLite()){
            return !LITE_TYPE_OF_REPORT_EXCLUDED.includes(element)
          }else{
              return element
          }
        }),
        roleId: [],
        companyGroupId: '',
        userId: [],
        countryId: [],
        stateId: [],
        cityId: [],
        departmentId: [],
        entityId: [],
        lawId: [],
        taskStatusId: [],
        complianceTypeId: [],
        complianceCategoryId: [],
        complianceRiskRatingId: [],
        fromDate: '',
        toDate: ''
    };
};

export const listColumns = [
    {
        label: 'Entity Name',
        field: 'entityName',
        sort: 'disabled',
    },
    {
        label: 'Country',
        field: 'country',
        sort: 'disabled',
    },
    {
        label: 'State',
        field: 'state',
        sort: 'disabled',
    },
    {
        label: 'Department',
        field: 'department',
        sort: 'disabled',
    },
    {
        label: 'Short Law Name',
        field: 'lawShortName',
        sort: 'disabled',
    },
    {
        label: 'Compliance Title',
        field: 'complianceTitle',
        sort: 'disabled',
    },
    {
        label: 'Compliance Class',
        field: 'complianceType',
        sort: 'disabled',
    },
    {
        label: 'System Live Date',
        field: 'systemLiveDate',
        sort: 'disabled',
    },
    {
        label: 'Compliance Due Date',
        field: 'dueDate',
        sort: 'disabled',
    },
    {
        label: 'Actual Compliance Performance Date',
        field: 'compliancePerformanceDate',
        sort: 'disabled',
    },
    {
        label: 'System Reporting Date',
        field: 'systemReportingDate',
        sort: 'disabled',
    },
    {
        label: 'Delay in System Reporting',
        field: 'noDelayDays',
        sort: 'disabled',
    },
    {
        label: 'Status',
        field: 'statusName',
        sort: 'disabled',
    },
    {
        label: 'User Role',
        field: 'roleName',
        sort: 'disabled',
    }
];