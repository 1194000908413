import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isGTROLE } from 'helpers/projectUtils';
import MainIndex from './MainIndex';
import NewsIndex from './Index';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { Col } from 'reactstrap';
import { userIsLite } from 'helpers/litehelper';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import NoAccess from 'components/Application/NoAccess';

class CommonIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGtRole: isGTROLE(),
        };
    }



    render() {
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        return (
            <React.Fragment>
                {
                    userIsLite() && !isLite.litePlan.isLegalWatchdogEnabled ?  <NoAccess isLite /> :<>
                     <Col lg="12">
                    <CommonBreadcrum type={'legal-watch-dog'} />
                </Col>
                {this.state.isGtRole === true ? <MainIndex /> : <NewsIndex defaultDate={(this.props.match.params?.defaultDate) ? this.props.match.params?.defaultDate : ''} />}
                    </>
                }
               
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({});
const withConnect = connect(mapStatetoProps, {});
export default compose(withConnect, withRouter)(CommonIndex);
