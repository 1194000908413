import React, { Component } from 'react';
import { FormGroup, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import __t from 'i18n/translator';
import { initialTaskCommentState } from './CommonFunctions';
import { customDateValidator } from 'helpers/dateTimeHelper';
import 'react-datepicker/dist/react-datepicker.css';
import {
    addFundTaskCommentRequest,
    getFundTaskActivityRequest,
} from 'store/actions';
import { toastrMessage } from 'helpers/messageHelper';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';

class TaskCommentForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            commentFormData: initialTaskCommentState(),
        };
    }

    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.addFundTaskCommentRequest(this.props.taskId, values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.fundTaskCommentApiResponse &&
            this.props.fundTaskCommentApiResponse !==
            nextprops.fundTaskCommentApiResponse
        ) {
            toastrMessage(
                nextprops.fundTaskCommentApiResponse.message,
                nextprops.fundTaskCommentApiResponse.responseType
            );
            if (this.props.taskId) {
                this.props.getFundTaskActivityRequest(this.props.taskId);
            }
            this.onModalDismiss();
        }
    }
    onModalDismiss = () => {
        this.validator.hideMessages();
        this.setState(
            {
                commentFormData: '',
            },
            () => {
                this.setState({
                    commentFormData: initialTaskCommentState(),
                });
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <div>
                    <Formik
                        initialValues={this.state.commentFormData}
                        enableReinitialize={true}
                        onSubmit={this.formSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>

                                <div className="inner-comment-chat-box">
                                    <FormGroup>
                                        <Field
                                            name="remarks"
                                            className="form-control"
                                            value={values.remarks}
                                            placeholder={__t(
                                                'form_labels.fund_task.response'
                                            )}
                                        />
                                        {this.validator.message(
                                            __t(
                                                'form_labels.fund_task.comment'
                                            ),
                                            values?.remarks,
                                            'required',
                                            {
                                                messages: {
                                                    required: __t(
                                                        'validations.fund_task.title_required'
                                                    ),
                                                },
                                            }
                                        )}
                                    </FormGroup>

                                    <Button
                                        type="submit"
                                        color="link"
                                        className="send-btn"
                                    >
                                        <svg
                                            width="34"
                                            height="33"
                                            viewBox="0 0 34 33"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.17157 1.17157C0 2.34315 0 4.22876 0 8V17V25.5V31.5858C0 32.4767 1.07714 32.9229 1.70711 32.2929L8.35355 25.6464C8.42581 25.5742 8.46194 25.5381 8.50788 25.519C8.55383 25.5 8.60492 25.5 8.70711 25.5H26C29.7712 25.5 31.6569 25.5 32.8284 24.3284C34 23.1569 34 21.2712 34 17.5V8C34 4.22876 34 2.34315 32.8284 1.17157C31.6569 0 29.7712 0 26 0H8C4.22876 0 2.34315 0 1.17157 1.17157Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M9.5625 9.5625L24.4375 9.5625"
                                                stroke="white"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M9.5625 15.9375L20.1875 15.9375"
                                                stroke="white"
                                                strokeWidth="3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fundTaskCommentApiResponse: makeFundsSelectField(
        'fundTaskCommentApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addFundTaskCommentRequest,
    getFundTaskActivityRequest,
});

export default compose(withConnect, withRouter)(TaskCommentForm);
