import React, { Component } from 'react';
import { Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logoLight from '../../images/logo-light.webp';
import logoDark from '../../images/logo-dark.webp';
import { activateAccountTokenRequest, activateAccountRequest } from 'store/actions';
import reducer from 'store/Auth/ActivateAccount/reducer';
import saga from 'store/Auth/ActivateAccount/saga';
import { makeSelectField } from 'store/Auth/ActivateAccount/selector';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { toastrMessage } from 'helpers/messageHelper';
import { Field, Form, Formik } from 'formik';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { customValidatorForStrongPassword } from 'helpers/customValidators';
import __t from 'i18n/translator';
import { isDarkMode } from 'helpers/generalUtils';
import Login_slider from './LoginSlider';
import LoginPageSlider from 'components/Application/LoginPageSlider';

const key = 'activateAccount';
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });
class ActivateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,
            showActivateAccountForm: false
        }
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidatorForStrongPassword()
            ),
        });
    }
    componentDidMount() {
        this.props.activateAccountTokenRequest(this.state.token);
    }
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.setState({
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword
            });
            this.props.activateAccountRequest(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.activateAccountTokenApiResponse &&
            this.props.activateAccountTokenApiResponse !== nextprops.activateAccountTokenApiResponse
        ) {
            this.setState({
                showActivateAccountForm: (nextprops.activateAccountTokenApiResponse.responseType === DANGER) ? false : true
            });
            if (nextprops.activateAccountTokenApiResponse.responseType === DANGER) {
                toastrMessage(nextprops.activateAccountTokenApiResponse.message, nextprops.activateAccountTokenApiResponse.responseType);
                window.location.replace('/logout');
            }
        }
        if (
            nextprops.activateAccountApiResponse &&
            this.props.activateAccountApiResponse !== nextprops.activateAccountApiResponse
        ) {
            if (nextprops.activateAccountApiResponse.responseType === SUCCESS) {
                this.setState({
                    newPassword: '',
                    confirmPassword: ''
                });
                toastrMessage(nextprops.activateAccountApiResponse.message, nextprops.activateAccountApiResponse.responseType);
                window.location.replace('/logout');
            } else {
                toastrMessage(nextprops.activateAccountApiResponse.message, nextprops.activateAccountApiResponse.responseType);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="login-wrapper">
                    <LoginPageSlider />
                    <div className="wrapper-page">
                        <Row className='h-100 d-flex justify-content-center align-items-center login_form_row'>
                            <Col md="7" className="login-form">
                                <Card className="login_sub_slider">
                                    <h2 className='login_sub_heading'>Welcome to <span>GCMS Pro</span></h2>
                                    <div className='login_inner_slider'>
                                        <Login_slider />
                                    </div>
                                </Card>
                                <Card className="account-card">
                                    <div className='login-main'>
                                        <div className='loging-head'>
                                            <div className="logo logo-login">
                                                {isDarkMode() ? (
                                                    <img src={logoLight} alt="logo" />
                                                ) : (
                                                    <img src={logoLight} alt="logo" />
                                                )}
                                                {/* <h3>
                                                    {__t(
                                                        'common.app_name'
                                                    )}
                                                </h3> */}
                                            </div>
                                        </div>

                                        <Formik
                                            initialValues={this.state}
                                            enableReinitialize={true}
                                            onSubmit={this.formSubmit}
                                        >
                                            {({ values }) => (
                                                <Form className="form-horizontal form-login">
                                                    <h3>{__t('page_headers.activate_account')}</h3>
                                                    <Input type='hidden' value={values.token} />
                                                    {this.state.showActivateAccountForm && (
                                                        <React.Fragment>
                                                            <FormGroup className="col-12">
                                                                <Field
                                                                    name="newPassword"
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder={__t('form_labels.reset_password.enter_new_password')}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t('form_labels.reset_password.new_password')}
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t('form_labels.reset_password.new_password'),
                                                                    values.newPassword,
                                                                    'required|min:16|max:24|strongPassword', {
                                                                    messages: {
                                                                        required: __t('validations.reset_password.new_password_required'),
                                                                        min: __t('validations.reset_password.new_password_min_length', { min: 16 }),
                                                                        max: __t('validations.reset_password.new_password_max_length', { max: 24 })
                                                                    }
                                                                }
                                                                )}
                                                            </FormGroup>
                                                            <FormGroup className="col-12">
                                                                <Field
                                                                    name="confirmPassword"
                                                                    type="password"
                                                                    className="form-control"
                                                                    placeholder={__t('form_labels.reset_password.enter_confirm_password')}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t('form_labels.reset_password.confirm_password')}
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t('form_labels.reset_password.confirm_password'),
                                                                    values.confirmPassword,
                                                                    `required|min:16|in:${values.newPassword}`, {
                                                                    messages: {
                                                                        required: __t('validations.reset_password.confirm_password_required'),
                                                                        min: __t('validations.reset_password.confirm_password_min_length', { min: 16 }),
                                                                        in: __t('validations.reset_password.confirm_password_match')
                                                                    }
                                                                }
                                                                )}
                                                            </FormGroup>
                                                            <Col lg="12">
                                                                <Button
                                                                    color="primary"
                                                                    className="w-100 waves-effect waves-light"
                                                                    type="submit"
                                                                >{__t('form_labels.reset_password.activate_account_btn')}</Button>
                                                                <Link to='/login' className='mt-3 d-flex align-items-center justify-content-center forgot_password'>{__t('form_labels.reset_password.back_to_login')}</Link>
                                                            </Col>
                                                        </React.Fragment>
                                                    )}
                                                </Form>
                                            )}
                                        </Formik>
                                        <div className="text-center copy-right w-100">
                                            <p>© {new Date().getFullYear()} {__t('common.footer_text')} </p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    activateAccountTokenApiResponse: makeSelectField('activateAccountTokenApiResponse'),
    activateAccountApiResponse: makeSelectField('activateAccountApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    activateAccountTokenRequest,
    activateAccountRequest
});
export default compose(withConnect, withReducer, withSaga, withRouter)(ActivateAccount);
