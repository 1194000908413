import React, { Component } from 'react';
import { Row, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CLEAR, SEARCH, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import __t from 'i18n/translator';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import { userAllocationMappedList } from 'constants/dropdowns';
import { getEntitySetupListRequest } from 'store/Lite/actions';
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { decryptData } from 'helpers/encryptor';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({ key: subIndustryKey, saga: subIndustrySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({ key: lawCategoryKey, saga: lawCategorySaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
const registeredAccountKey = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: registeredAccountKey,
    reducer: RegisteredAccount,
});
const withLiteCountriesListSaga = injectSaga({ key: registeredAccountKey, saga: LiteRegistrationSaga });
class ComplianceFilter extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
            sortedEntityListDropdown: [],
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedEntitySetupListDropdown:[]
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        const IsLitePlan = getValueByKey(authConstants.LITE_PLAN) && getValueByKey(authConstants.LITE_PLAN) !== "null" ? JSON.parse(decryptData(getValueByKey(authConstants.LITE_PLAN))) : null;
        this.props.getEntitySetupListRequest({ filter: { companyGroupId :[IsLitePlan?.liteClientAccount?.companyGroupId] }})    
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetForm = () => {
        this.setState({
            sortedEntityListDropdown: [],
            sortedStateListDropdown: [],
            sortedCityListDropdown: []
        })
        this.props.onFormReset();
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.sortedEntityListDropdown &&
            this.props.sortedEntityListDropdown !==
            nextprops.sortedEntityListDropdown
        ) {
            this.setState({
                sortedEntityListDropdown: nextprops.sortedEntityListDropdown,
            });
        }
        if (
            nextprops.sortedStateListDropdown &&
            this.props.sortedStateListDropdown !==
            nextprops.sortedStateListDropdown
        ) {
            this.setState({
                sortedStateListDropdown: nextprops.sortedStateListDropdown,
            });
        }
        if (
            nextprops.sortedCityListDropdown &&
            this.props.sortedCityListDropdown !==
            nextprops.sortedCityListDropdown
        ) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown,
            });
        }
        if(nextprops.liteEntitySetupLocationListApiResponse && nextprops.liteEntitySetupLocationListApiResponse !== this.props.liteEntitySetupLocationListApiResponse){
            const list = nextprops.liteEntitySetupLocationListApiResponse?.data && nextprops.liteEntitySetupLocationListApiResponse?.data?.list?.length > 0 && nextprops.liteEntitySetupLocationListApiResponse?.data?.list?.map((element)=>{
                return  element.liteEntitySetupList && element.liteEntitySetupList.length > 0 && element.liteEntitySetupList.sort((a,b)=>a.id-b.id).map((ele,index)=>{
                    return {
                        label:`${element.entityName} - Location ${index + 1}`,
                        value: ele.id
                    }
                })
            })
            this.setState({
                sortedEntitySetupListDropdown: list && list.length > 0 ? list.flat(1):[]
            })
        }
    }
    render() {
        const splitWithQuestion = this.props.location.search ? this.props.location.search.split("?")[1]:null;
        const splitWithEqual = splitWithQuestion ? splitWithQuestion.split("=")[1]:null;
        const findFilter = splitWithEqual ? JSON.parse(decryptData(splitWithEqual)) : null;
        return (
            <React.Fragment>
                <Formik
                    initialValues={findFilter ? {...this.props.allocationFilterData,entitySetUpId:[parseInt(findFilter?.entitySetupId)]} :this.props.allocationFilterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="entitySetUpId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedEntitySetupListDropdown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'entitySetUpId',
                                                (opt) ? opt.map(x => x.value) : []
                                            );
                                        }}
                                        value={this.state.sortedEntitySetupListDropdown?.filter(
                                            option =>
                                                values.entitySetUpId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.entity')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={Select}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedCountryListDropdown}
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={opt => {
                                            let countryIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'countryId',
                                                countryIds
                                            );
                                            setFieldValue(
                                                'stateId',
                                                []
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            if (countryIds.length > 0) {
                                                this.getStateListByCountry(countryIds);
                                                this.getCityListByState([]);
                                            } else {
                                                this.setState({
                                                    sortedStateListDropdown: [],
                                                    sortedCityListDropdown: []
                                                })
                                            }
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            option =>
                                                values.countryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.country')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedStateListDropdown
                                        }
                                        onChange={opt => {
                                            let stateId = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'stateId',
                                                stateId
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            if (stateId.length > 0) {
                                                this.getCityListByState(stateId);
                                            } else {
                                                this.setState({
                                                    sortedCityListDropdown: []
                                                })
                                            }
                                        }}
                                        value={this.state.sortedStateListDropdown?.filter(
                                            option =>
                                                values.stateId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.state')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedCityListDropdown
                                        }
                                        onChange={opt => {
                                            let cityId = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'cityId',
                                                cityId
                                            );
                                        }}
                                        value={this.state.sortedCityListDropdown?.filter(
                                            option => values.cityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.city')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-4'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawCategoryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedLawCategoryListDropdown
                                        }
                                        onChange={opt => {
                                            let lawCategoryId = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'lawCategoryId',
                                                lawCategoryId
                                            );
                                        }}
                                        value={this.props.sortedLawCategoryListDropdown?.filter(
                                            option =>
                                                values.lawCategoryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.law_category')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-2'>
                                    <Field
                                        component={Select}
                                        className="form-control selectbox"
                                        name="type"
                                        options={userAllocationMappedList}
                                        onChange={opt => {
                                            setFieldValue(
                                                'type',
                                                opt.value
                                            );
                                        }}
                                        value={userAllocationMappedList?.filter(
                                            option =>
                                                values.type === option.value
                                        )}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.type')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-2 d-flex justify-content-end">
                                    <div className="d-inline-block">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="mr-1"
                                        >
                                            {SEARCH}
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >{CLEAR}</Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    liteEntitySetupLocationListApiResponse:makeLiteClientRegistrationSelectField("liteEntitySetupLocationListApiResponse")
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getEntitySetupListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withRouter
)(ComplianceFilter);