import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ADD_NEW,
    ENABLE_DELETE,
    SUBMIT,
    UPDATE,
} from 'constants/commonConstants';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllEntityLocationListRequest,
    updateEntityLocationStatusByEntityLocationIdRequest,
    addEntityLocationRequest,
    updateEntityLocationRequest,
    deleteEntityLocationByEntityLocationIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import entityLocationReducer from 'store/Masters/EntityLocation/reducer';
import entityLocationSaga from 'store/Masters/EntityLocation/saga';
import { makeEntityLocationSelectField } from 'store/Masters/EntityLocation/selector';
import { initialEntityLocationDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import AddEntityLocationForm from './AddEntityLocationForm';
import __t from 'i18n/translator';
import { formatDateForApiSystem } from 'helpers/dateTimeHelper';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_ENTITY_LOCATION,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import { userIsLite } from 'helpers/litehelper';
const entityLocationKey = 'entityLocation';
const withEntityLocationReducer = injectReducer({
    key: entityLocationKey,
    reducer: entityLocationReducer,
});
const withEntityLocationSaga = injectSaga({
    key: entityLocationKey,
    saga: entityLocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entityLocationList: [],
            exportEntityLocationList: [],
            entityLocationData: initialEntityLocationDataState(),
            operationType: SUBMIT,
            isFormOpen: false,
            isAuditModalOpen: false,
            auditId: '',
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve EntityLocation list
        this.props.getAllEntityLocationListRequest(userIsLite() ? {isLite:1}:null);
    }

    onStatusChange = async (checked, e, id) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            checked
                ? __t('alerts.active_entity_location')
                : __t('alerts.deactivate_entity_location')
        );
        if (response) {
            this.props.updateEntityLocationStatusByEntityLocationIdRequest(
                id,
                checked
            );
        }
    };
    viewEntityLocation(entityLocationData) {
        this.setState(
            {
                isFormOpen: true,
                entityLocationData: initialEntityLocationDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({ entityLocationData: entityLocationData });
            }
        );
    }
    addEntityLocation() {
        this.setState({
            isFormOpen: true,
        });
    }
    deleteEntityLocation = async (entityLocationId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_location_delete')
        );
        if (response) {
            this.props.deleteEntityLocationByEntityLocationIdRequest(
                entityLocationId
            );
        }
    };
    formSubmit = (values) => {
        let formValues = {};
        formValues = {
            ...values,
            registrationDate:
                values.registrationDate
                    ? formatDateForApiSystem(values.registrationDate)
                    : ''
        };
        this.setState({ entityLocationData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateEntityLocationRequest(values.id, formValues);
        } else {
            this.props.addEntityLocationRequest(formValues);
        }
    };
    resetForm = () => {
        this.setState({
            entityLocationData: initialEntityLocationDataState(),
            operationType: SUBMIT,
            isFormOpen: false,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let entityLocationRecords = [];
        entityLocationRecords = records.map((entityLocationData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(
                        MODULE_ENTITY_LOCATION,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewEntityLocation({
                                        ...entityLocationData,
                                        companyGroupId:
                                            entityLocationData?.entity?.companyGroup
                                                ?.id,
                                    });
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('entityLocation') &&
                        checkModulePermission(
                            MODULE_ENTITY_LOCATION,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteEntityLocation(
                                        entityLocationData.id
                                    );
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(entityLocationData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(entityLocationData.id)}
                        checked={entityLocationData.isActive ? true : false}
                        disabled={!checkModulePermission(MODULE_ENTITY_LOCATION, PERMISSION_EDIT)}
                    />
                    &nbsp;
                </div>
            );
            return {
                companyGroupName:
                    entityLocationData.entity?.companyGroup?.companyGroupName,
                entityName: entityLocationData.entity?.entityName,
                countryName: entityLocationData.country?.countryName,
                stateName: entityLocationData.state?.stateName,
                cityName: entityLocationData.city?.cityName,
                pincode:
                    entityLocationData.pincode != null
                        ? entityLocationData.pincode
                        : '',
                registrationNumber: (entityLocationData.registrationNumber != null) ? entityLocationData.registrationNumber : '',
                registrationDate: entityLocationData.registrationDate != null ? entityLocationData.registrationDate : '',
                isActive: isActive,
                actions: actions,
            };
        });
        return entityLocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.entityLocationList &&
            this.props.entityLocationList !== nextprops.entityLocationList
        ) {
            this.setState({
                exportEntityLocationList: nextprops.entityLocationList,
                entityLocationList: this.generateTable(
                    nextprops.entityLocationList
                ),
            });
        }
        if (nextprops.entityLocationStatusApiResponse && this.props.entityLocationStatusApiResponse !== nextprops.entityLocationStatusApiResponse) {
            toastrMessage(nextprops.entityLocationStatusApiResponse.message, nextprops.entityLocationStatusApiResponse.responseType);
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.entityLocationList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        {this.state.isFormOpen && (
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4 className="with-back-btn">
                                                <a
                                                    href={() => false}
                                                    className="dripicons-arrow-thin-left"
                                                    onClick={() => {
                                                        this.resetForm();
                                                    }}
                                                >
                                                    { }
                                                </a>{' '}
                                                &nbsp;
                                                {this.state.operationType ===
                                                    SUBMIT
                                                    ? __t(
                                                        'form_labels.entity.add_location'
                                                    )
                                                    : __t(
                                                        'form_labels.entity.edit_location'
                                                    )}{' '}
                                            </h4>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <AddEntityLocationForm
                                            operationType={
                                                this.state.operationType
                                            }
                                            entityLocationData={
                                                this.state.entityLocationData
                                            }
                                            operationResponse={
                                                this.props
                                                    .entityLocationApiResponse
                                            }
                                            onFormValid={this.formSubmit}
                                            onFormReset={this.resetForm}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {!this.state.isFormOpen && (
                            <Col lg="12">
                                <Card>
                                    <CardHeader>
                                        <div className="page-header">
                                            <h4>
                                                {__t(
                                                    'page_headers.entity_location_list'
                                                )}
                                            </h4>
                                        </div>
                                        <div className="page-action">
                                            {(checkModulePermission(
                                                MODULE_ENTITY_LOCATION,
                                                PERMISSION_ADD
                                            ) === true) && (
                                                    <a
                                                        href={() => false}
                                                        className="btn btn-primary waves-effect waves-light float-right ml-2"
                                                        onClick={() => {
                                                            this.addEntityLocation();
                                                        }}
                                                    >
                                                        <i className="fas fa-plus">
                                                            {' '}
                                                        </i>{' '}
                                                        {ADD_NEW}
                                                    </a>
                                                )}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomisedDatatable
                                            tableRecords={data}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'entityLocation'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    entityLocationList: makeEntityLocationSelectField('entityLocationList'),
    entityLocationApiResponse: makeEntityLocationSelectField(
        'entityLocationApiResponse'
    ),
    entityLocationStatusApiResponse: makeEntityLocationSelectField('entityLocationStatusApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    getAllEntityLocationListRequest,
    updateEntityLocationStatusByEntityLocationIdRequest,
    addEntityLocationRequest,
    updateEntityLocationRequest,
    deleteEntityLocationByEntityLocationIdRequest,
});
export default compose(
    withConnect,
    withEntityLocationReducer,
    withEntityLocationSaga,
    withRouter
)(Index);
