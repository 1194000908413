import { ACTIVE, AUTH_MODE_LC } from 'constants/commonConstants';
import { setEmptyToNull } from 'helpers/generalUtils';
export const listColumns = [
    {
        label: 'Company Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Full Name',
        field: 'fullName',
    },
    {
        label: 'User Name',
        field: 'userName',
    },
    {
        label: 'Email ID',
        field: 'email',
    },

    {
        label: 'Contact No',
        field: 'contactNo',
    },
    {
        label: 'Authentication Mode',
        field: 'authenticationMode',
    },
    {
        label: 'Active',
        attributes: {
            className: 'active_switch',
        },
        field: 'isActive',
        sort: 'disabled',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];
export const initialUserDataState = () => {
    return {
        fullName: '',
        email: '',
        contactNo: '',
        authenticationMode: AUTH_MODE_LC,
        isActive: ACTIVE,
        userName: '',
        companyGroupId: '',
        entityId: '',
        isGt: false
    };
};

export const initialUserSearchDataState = () => {
    return {
        companyGroupId: [],
        entityId: [],
        typeId: '',
        isLite: 0
    };
};

export const intFields = [];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
