import React, { Component } from 'react';
import data from './menuList';
import MenuItem from './MenuItem';
import MenuItemWithChildren from './MenuItemWithChildren';
import MenuWithMega from './MenuWithMega';
import { checkDataWithLocalStorage, getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { decryptData } from 'helpers/encryptor';
import { Button } from 'reactstrap';
import SupportIcon from '../../images/support.png';
import { LITE_PLAN_BASIC, ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants';
import { PopupBasic, userIsLite } from 'helpers/litehelper';
import PremiumIcon from 'app/Lite/components/PremiumIcon';

class TopMenuNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modulePermissions: JSON.parse(decryptData(getValueByKey(authConstants.PERMISSIONS)))?.map((module) => {
                // unique permission ids
                let permissionsArray = [
                    ...new Set(
                        module.permissions
                            .split(',')
                            ?.map((opt) => parseInt(opt)) || []
                    ),
                ];
                return {
                    ...module,
                    permissions: permissionsArray,
                };
            }) || [],
            roleId:JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))
        }
    }

    render() {
        checkDataWithLocalStorage();
        const loginUserModules = this.state.modulePermissions?.map((opt) => { return opt.moduleId }) || [];
        const isLite = getValueByKey(authConstants.LITE_PLAN) && getValueByKey(authConstants.LITE_PLAN) !== "null" ? JSON.parse(decryptData(getValueByKey(authConstants.LITE_PLAN))) : null;
        const isLiteUser = getValueByKey(authConstants.IS_LITE) && getValueByKey(authConstants.IS_LITE) !== "null" ? JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE))) : null;
        let menuData = data;
        menuData = menuData.map((ele)=>{
            if(ele.isLiteModule){
                if(isLiteUser?.isLite && this.state.roleId[0]?.roleId == ROLE_COMPANY_GROUP_ADMIN){
                    return ele
                }else{
                    const filter = ele.children && ele.children.length > 0 && ele.children.filter((child)=>!child.isChildrenLite);
                    return {
                        ...ele,
                        children:filter
                    }
                }
            }else{
                return ele
            }
        })
        return (
            <React.Fragment>
                <div id="topbar-menu">
                    <ul className="metismenu m-0" id="menu">
                        {menuData.map((item, i) => {
                            if (item.is_mega) {
                                return <MenuWithMega item={item} key={i} />
                            }
                            else {
                                if (item.children) {
                                    if (item.modules && loginUserModules.some(moduleAccess => item.modules?.includes(moduleAccess))) {
                                        return <MenuItemWithChildren isLiteUser={isLiteUser} isLite={isLite} item={item} key={i} loginUserModules={loginUserModules} />
                                    }
                                } else {
                                    if (item.modules && loginUserModules.some(moduleAccess => item.modules?.includes(moduleAccess))) {
                                        return <li key={i} className={item.class_name}>
                                            <MenuItem isLite={isLite} isLiteUser={isLiteUser} item={item} /></li>
                                    }
                                }
                            }
                        })}
                    </ul>
                    <Button color="primary" className="support_btn" title='Support Ticket' onClick={async()=>{
                            if(userIsLite() && !isLite?.litePlan?.isSupportTicketEnabled){
                                await PopupBasic();
                            }else{
                                window.location.replace(
                                   '/support-tickets'
                               );
                            }
                    }}>
                        {userIsLite() && !isLite?.litePlan?.isSupportTicketEnabled ? <PremiumIcon isSmall/>:null}
                        Need Support
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 5.75C9.37868 5.75 8.875 6.25368 8.875 6.875C8.875 7.28921 8.53921 7.625 8.125 7.625C7.71079 7.625 7.375 7.28921 7.375 6.875C7.375 5.42525 8.55025 4.25 10 4.25C11.4497 4.25 12.625 5.42525 12.625 6.875C12.625 7.58584 12.3415 8.23198 11.883 8.70396C11.7907 8.79894 11.7027 8.88687 11.6187 8.97076C11.4029 9.18641 11.2138 9.37533 11.0479 9.5885C10.8289 9.86992 10.75 10.0768 10.75 10.25V11C10.75 11.4142 10.4142 11.75 10 11.75C9.58579 11.75 9.25 11.4142 9.25 11V10.25C9.25 9.59481 9.55501 9.06444 9.86417 8.66721C10.0929 8.37329 10.3804 8.08634 10.6138 7.85346C10.6842 7.78321 10.7496 7.71789 10.807 7.65877C11.0046 7.45543 11.125 7.18004 11.125 6.875C11.125 6.25368 10.6213 5.75 10 5.75ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z" fill="currentColor"/></svg>
                        </span>
                    </Button>
                </div>

            </React.Fragment>
        );
    }
}


export default TopMenuNav;