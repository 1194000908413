export const liteEntityFormInitialValue = (countriesId) => {
    return {
        industryId: "",
        subIndustryId: [],
        entityId: "",
        countryId: parseInt(countriesId) ?? "",
        financialYearStart: "",
        financialYearEnd: "",
        stateId: [],
        cityId: [],
        entityTypeId: "",
        managementUser: "",
        entityNewValue: false,
        managementNewValue: false,
        entityName: "",
        facilityTypeId: [],
        entityLocations: [],
    }
}