import React, { Component, createRef } from 'react';

class CommonOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.overlayRef = createRef(); // Reference to the overlay div
        this.observer = null; // Store MutationObserver
        this.parentNode = null; // Store parent node of the overlay
    }

    componentDidMount() {
        // Store the parent node of the overlay
        if (this.overlayRef.current) {
            this.parentNode = this.overlayRef.current.parentNode;
        }
        this.observeOverlay();
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect(); // Stop observing on unmount
        }
    }

    observeOverlay = () => {
        this.observer = new MutationObserver(() => {
            // Check if the overlay still exists in the DOM
            if (this.overlayRef.current && !document.body.contains(this.overlayRef.current)) {
                console.warn("Overlay removed! Re-adding at the first position...");
                this.reAddOverlay(); // Restore at the correct location
            }
        });

        this.observer.observe(document.body, { childList: true, subtree: true });
    };

    reAddOverlay = () => {
        if (this.parentNode && !this.parentNode.contains(this.overlayRef.current)) {
            console.warn("Re-inserting overlay at the first position...");
            this.parentNode.insertBefore(this.overlayRef.current, this.parentNode.firstChild); // Insert at the beginning
        }
    };

    render() {

        return (
            <>
                    <div
                    ref={this.overlayRef} // Store reference
                        className={
                            this.props.isTile
                                ? 'overlay-tile-blur'
                                : 'overlay-blur'
                        }
                    >
                        <span className="icon">
                            <svg
                                width="21"
                                height="16"
                                viewBox="0 0 21 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.54901 1.80237L6.35401 5.15037L9.74401 0.976366C9.83894 0.859676 9.95896 0.765905 10.0952 0.702037C10.2313 0.638168 10.3802 0.605847 10.5306 0.607481C10.681 0.609115 10.8291 0.644661 10.9639 0.711474C11.0987 0.778286 11.2166 0.874642 11.309 0.993366L14.544 5.14937L18.472 1.75337C18.6263 1.62028 18.8175 1.53748 19.0201 1.51605C19.2227 1.49462 19.427 1.53559 19.6057 1.63346C19.7844 1.73134 19.9289 1.88146 20.0199 2.06373C20.1109 2.246 20.1441 2.45172 20.115 2.65337L18.615 13.0004H2.42201L0.899011 2.70037C0.868758 2.4976 0.901555 2.29044 0.992961 2.10694C1.08437 1.92343 1.22996 1.77246 1.41003 1.67445C1.5901 1.57645 1.79594 1.53617 1.99966 1.55904C2.20339 1.58192 2.39517 1.66687 2.54901 1.80237ZM2.50001 14.0004H18.5V15.0004C18.5 15.2656 18.3947 15.5199 18.2071 15.7075C18.0196 15.895 17.7652 16.0004 17.5 16.0004H3.50001C3.23479 16.0004 2.98044 15.895 2.7929 15.7075C2.60537 15.5199 2.50001 15.2656 2.50001 15.0004V14.0004Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                        <h3 className='text-center'>{this.props.isLite ? "Subscribe to Premium Plan" :"Subscribe to GCMS Pro"}</h3>
                        <p>
                            Unlock limitless possibilities and access advanced
                            analytics with a premium upgrade. Go beyond your
                            limits with detailed reports and exclusive features.
                        </p>
                    </div>
            </>
        );
    }
}

export default CommonOverlay;
