import React, { Component } from 'react';
import { Button, Col, FormGroup, Label, Row, Card } from 'reactstrap';
import __t from 'i18n/translator';
import { ACTIVE, APPROVE_REVIEW, AUDIT_BTN, DATE_FORMAT_DATEPICKER, DATE_FORMAT_PLACEHOLDER, DISCREPANCY_AUDIT_BTN, NO, RESET, SEND_BACK, SUBMIT, YES } from 'constants/commonConstants';
import { AUDITED_AND_CLOSED, CLOSED, COMPLIANCE_CATEGORY_ONE_TIME, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_TYPE_MONETARY, DISCREPANCY_TYPE_NON_MONETARY, DISCREPANCY_TYPE_OTHERS, EXPENSE_TYPE_NONE, EXPENSE_TYPE_PENALTY, EXPENSE_TYPE_STATUTORY_PAYMENT, PENDING_FOR_AUDIT, PENDING_FOR_COMPLETION, PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, REOPENED, RISK_RATING_CRITICAL, RISK_RATING_HIGH, RISK_RATING_LOW, RISK_RATING_MODERATE, SEND_BACK_BY_AUDITOR_TO_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER2, SEND_BACK_BY_AUDITOR_TO_SUBMITTER, SEND_BACK_TO_APPROVER, SEND_BACK_TO_SUBMITTER, SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS } from 'constants/databaseConstants';
import { Field, FieldArray, Form, Formik } from 'formik';
import { FloatComponent } from 'components/Application/NumberFormat';
import { animatedComponents, getExpenseTypeNameById, MultiValue, Option } from 'helpers/projectUtils';
import { completionStatusList, discrepancyTypeList, expenseTypeList } from 'constants/dropdowns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { customDateValidator, formatDateForApi, getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import Dropzone from 'react-dropzone';
import { customProofFileValidator } from 'helpers/customValidators';
import { getValueByKey } from 'helpers/authUtils';
import { commonConfirmBoxWithRemarks } from 'helpers/messageHelper';
import * as authConstants from 'constants/authConstants';
import SimpleReactValidator from 'simple-react-validator';
import moment from "moment";
import { userIsLite } from 'helpers/litehelper';

class ReportingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            selectedFile: []
        };
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customProofFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.proof_file'));
                return false;
            }
        });
        let label =
            (this.props.taskDetails.parentTaskId > 0 || this.props.taskDetails.auditorId === this.props.loginUserId)
                ? __t('form_labels.reporting.document')
                : __t('form_labels.reporting.proof_file');
        if (errorsArray.length > 0) {
            this.setState({
                errors: label + ' ' + __t('validations.custom.proof_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFile: files });
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    removeFile = (file) => {
        var array = [...this.state.selectedFile]; // make a separate copy of the array
        var index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ selectedFile: array }, () => {
                if (this.state.selectedFile.length === 0) {
                    this.setState({
                        errors: '',
                    });
                }
            });
        }
    };
    getRiskRatingClass = (riskRating) => {
        let riskRatingClassName = '';
        switch (riskRating?.id) {
            case RISK_RATING_CRITICAL:
                riskRatingClassName = 'bg-danger';
                break;
            case RISK_RATING_HIGH:
                riskRatingClassName = 'bg-orange';
                break;
            case RISK_RATING_MODERATE:
                riskRatingClassName = 'bg-warning';
                break;
            case RISK_RATING_LOW:
                riskRatingClassName = 'bg-primary';
                break;
            default:
                break;
        }
        return riskRatingClassName;
    };

    formSubmitForReporting = async (values) => {
        let continueSubmitForm = false;
        if (
            this.props.taskDetails.submitterId == getValueByKey(authConstants.USER_ID) &&
            (this.props.taskDetails.taskStatusId == PENDING_FOR_COMPLETION ||
                this.props.taskDetails.taskStatusId == REOPENED)
        ) {
            if (
                this.state.selectedFile.length == 0 &&
                this.props.complianceData?.isProofRequired === true
            ) {
                let validationError =
                    this.props.taskDetails.parentTaskId > 0
                        ? __t('validations.reporting.document_required')
                        : __t('validations.reporting.proof_files_required');
                this.setState({
                    errors: validationError
                });
                if (!this.validator.allValid()) {
                    this.validator.showMessages();
                    this.forceUpdate();
                }
                return false;
            }
        }
        if (this.validator.allValid()) {
            const formData = new FormData();
            if (
                this.props.taskDetails.pendingSubTasks == true &&
                this.props.taskDetails.taskStatusId ===
                PENDING_WITH_SECOND_APPROVER &&
                values.activityType == 'closed'
            ) {
                const response = await commonConfirmBoxWithRemarks(
                    __t('alerts.task_close'),
                    __t('alerts.task_confirmation_if_subtask_pending'),
                    __t('form_labels.reporting.remarks_placeholder')
                );
                if (response.result) {
                    formData.append(
                        'subtask_close_remarks',
                        response.textarea.value
                    );
                    continueSubmitForm = true;
                }
            } else {
                continueSubmitForm = true;
            }
            if (continueSubmitForm === true) {
                for (let [formKey, formVal] of Object.entries(values)) {
                    let formattedValue = formVal;
                    if (formKey === 'systemReportingDate' || formKey === 'compliancePerformanceDate' || formKey === 'documentProcurementDate') {
                        formattedValue = formVal ? formatDateForApi(formVal) : '';
                    }
                    if(formKey === 'discrepancyDepartmentAmountData'){
                        formattedValue = formVal ? JSON.stringify(formVal) : '';
                    }
                    formData.append(formKey, formattedValue);
                }
                if (this.state.selectedFile?.length > 0) {
                    this.state.selectedFile.forEach((file) => {
                        formData.append('files', file);
                    });
                }
                if (this.props.taskDetails.submitterId == getValueByKey(authConstants.USER_ID) &&
                    (this.props.taskDetails.taskStatusId == PENDING_FOR_COMPLETION ||
                        this.props.taskDetails.taskStatusId == REOPENED ||
                        this.props.taskDetails.taskStatusId == SEND_BACK_TO_SUBMITTER ||
                        this.props.taskDetails.taskStatusId == SEND_BACK_BY_AUDITOR_TO_SUBMITTER)
                ) {
                    if(userIsLite() &&(this.props.taskDetails.firstApproverUserId == "" || this.props.taskDetails.firstApproverUserId==null) ){
                        formData.append('status', CLOSED);
                    }else{
                        formData.append('status', PENDING_WITH_FIRST_APPROVER);
                    }
                }
                if (this.props.taskDetails.taskStatusId === PENDING_WITH_FIRST_APPROVER ||
                    this.props.taskDetails.taskStatusId === SEND_BACK_TO_APPROVER ||
                    this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_APPROVER) {
                    let status = this.props.taskDetails.secondApproverUserId != '' && this.props.taskDetails.secondApproverUserId !== null ? PENDING_WITH_SECOND_APPROVER : CLOSED;
                    if (values.activityType == 'sent_back_to_submitter') {
                        status = SEND_BACK_TO_SUBMITTER;
                    }
                    formData.append('status', status);
                }
                if (this.props.taskDetails.taskStatusId === PENDING_WITH_SECOND_APPROVER ||
                    this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_APPROVER2) {
                    let status = values.activityType == 'sent_back_to_first_approver' ? SEND_BACK_TO_APPROVER : CLOSED;
                    formData.append('status', status);
                }
                if (this.props.taskDetails.taskStatusId === PENDING_FOR_AUDIT) {
                    if (values.activityType == 'sent_back_to_submitter') {
                        formData.append('status', SEND_BACK_BY_AUDITOR_TO_SUBMITTER);
                    }
                    if (values.activityType == 'sent_back_to_first_approver') {
                        formData.append('status', SEND_BACK_BY_AUDITOR_TO_APPROVER);
                    }
                    if (values.activityType == 'sent_back_to_second_approver') {
                        formData.append('status', SEND_BACK_BY_AUDITOR_TO_APPROVER2);
                    }
                    if (values.activityType == 'audited_and_closed') {
                        formData.append('status', AUDITED_AND_CLOSED);
                    }
                    if (values.activityType == 'discrepancy_pending') {
                        formData.append('status', DISCREPANCY_PENDING_RESOLUTION);
                    }
                }
                this.props.reportingTaskByTaskIdRequest(this.props.taskId, formData);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
    }
    render() {
        let completionStatusListDropdown = completionStatusList;
        if (!this.props.taskDetails?.complianceAllocationLawComplianceStatus) {
            completionStatusListDropdown = completionStatusList.filter((c) => c.value === 3);
        }
        return (
            <Formik
                initialValues={this.props.formData}
                enableReinitialize={true}
                onSubmit={this.formSubmitForReporting}
            >
                {({ values, setFieldValue, handleSubmit }) => (
                    <Card className=" task_details">
                        <Form>
                            <Row>
                                <Col lg={(this.props.taskDetails.auditorId !== this.props.loginUserId) ? 6 : 4} className="mb-3">
                                    <div className="form_date_level">
                                        <label>Due Date</label>
                                        <p>{this.props.taskDetails.dueDate}</p>
                                    </div>
                                </Col>

                                <Col lg={(this.props.taskDetails.auditorId !== this.props.loginUserId) ? 6 : 4} className="mb-3">
                                    {(this
                                        .props
                                        .complianceData
                                        ?.riskRating || (this.props.oldVersion && this
                                            .props
                                            .complianceVersion
                                            ?.riskRating)) && (
                                            <>
                                                <div className="form_date_level risk-level-box">
                                                    <label>
                                                        {__t('form_labels.reporting.risk_level')}
                                                    </label>
                                                    <span
                                                        className={this.getRiskRatingClass((this.props.oldVersion) ? this.props.complianceVersion.riskRating : this.props.complianceData.riskRating)}
                                                    >
                                                        <svg
                                                            color="currentColor"
                                                            width="16"
                                                            height="20"
                                                            viewBox="0 0 16 20"
                                                            fill="currentColor"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                stroke="currentColor"
                                                                strokeOpacity="0.5"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                            ></path>
                                                        </svg>
                                                        {(this.props.oldVersion) ? this.props.complianceVersion.riskRating.riskRatingName : this.props.complianceData.riskRating.riskRatingName}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                </Col>
                                {(this.props.taskDetails.taskStatusId === PENDING_FOR_COMPLETION ||
                                    this.props.taskDetails.taskStatusId === REOPENED ||
                                    this.props.taskDetails.taskStatusId === SEND_BACK_TO_SUBMITTER ||
                                    this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_SUBMITTER) &&
                                    this.props.taskDetails.submitterId == this.props.loginUserId && (
                                        <React.Fragment>
                                            <FormGroup className="col-lg-4 w-100 sticky_label">
                                                <Field
                                                    component={
                                                        DatePicker
                                                    }
                                                    isClearable={true}
                                                    className="form-control"
                                                    name="compliancePerformanceDate"
                                                    selected={
                                                        values.compliancePerformanceDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.compliancePerformanceDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={DATE_FORMAT_DATEPICKER}
                                                    onChange={(
                                                        date
                                                    ) => {
                                                        setFieldValue('compliancePerformanceDate', date);
                                                    }}
                                                    minDate={moment(this.props.taskDetails.effectiveDate,"DD-MM-YYYY").toDate()}
                                                    showMonthDropdown={true}
                                                    showYearDropdown={true}
                                                    dropdownMode="select"
                                                    placeholderText={DATE_FORMAT_PLACEHOLDER}
                                                    onFocus={(e) =>
                                                        e.target.blur()
                                                    }
                                                    maxDate={new Date()}
                                                    popperPlacement="top-end"
                                                    popperModifiers={[
                                                      {
                                                        name: "myModifier",
                                                        fn(state) {
                                                          // Do something with the state
                                                          return state;
                                                        },
                                                      },
                                                    ]}
                                                ></Field>
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.compliance_performance_date'
                                                    )}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.reporting.compliance_performance_date'
                                                    ),
                                                    values.compliancePerformanceDate
                                                        ? getDateObjectWithValueFromMoment(
                                                            values.compliancePerformanceDate
                                                        )
                                                        : '',
                                                    'required|customDate',
                                                    {
                                                        messages:
                                                        {
                                                            required:
                                                                __t(
                                                                    'validations.reporting.performance_date_required'
                                                                ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            {/*  Document Procurement Date */}
                                            <FormGroup className="col-lg-4 w-100 sticky_label">
                                                <Field
                                                    component={
                                                        DatePicker
                                                    }
                                                    isClearable={true}
                                                    className="form-control"
                                                    name="documentProcurementDate"
                                                    selected={
                                                        values.documentProcurementDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.documentProcurementDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(
                                                        date
                                                    ) => {
                                                        setFieldValue(
                                                            'documentProcurementDate',
                                                            date
                                                        );
                                                    }}
                                                    minDate={moment(this.props.taskDetails.effectiveDate,"DD-MM-YYYY").toDate()}
                                                    showMonthDropdown={
                                                        true
                                                    }
                                                    showYearDropdown={
                                                        true
                                                    }
                                                    dropdownMode="select"
                                                    placeholderText={
                                                        DATE_FORMAT_PLACEHOLDER
                                                    }
                                                    onFocus={(
                                                        e
                                                    ) =>
                                                        e.target.blur()
                                                    }
                                                    maxDate={
                                                        new Date()
                                                    }
                                                    popperPlacement="top-end"
                                                    popperModifiers={[
                                                      {
                                                        name: "myModifier",
                                                        fn(state) {
                                                          // Do something with the state
                                                          return state;
                                                        },
                                                      },
                                                    ]}
                                                ></Field>
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.reporting.document_procurement_date'
                                                    )}
                                                    {this
                                                        .props
                                                        .complianceData
                                                        .complianceCategoryId ===
                                                        COMPLIANCE_CATEGORY_ONE_TIME && (
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        )}
                                                </Label>
                                                {this
                                                    .props
                                                    .complianceData
                                                    .complianceCategoryId ===
                                                    COMPLIANCE_CATEGORY_ONE_TIME &&
                                                    this.validator.message(
                                                        __t(
                                                            'form_labels.reporting.document_procurement_date'
                                                        ),
                                                        values.documentProcurementDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.documentProcurementDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages:
                                                            {
                                                                required:
                                                                    __t(
                                                                        'validations.reporting.document_procurement_date_required'
                                                                    ),
                                                            },
                                                        }
                                                    )}
                                            </FormGroup>
                                            {/* System Reporting Date is hide */}
                                            <FormGroup className="col-lg-4 w-100 sticky_label d-none">
                                                <Field
                                                    component={
                                                        DatePicker
                                                    }
                                                    className="form-control"
                                                    name="systemReportingDate"
                                                    selected={
                                                        values.systemReportingDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.systemReportingDate
                                                            )
                                                            : null
                                                    }
                                                    dateFormat={
                                                        DATE_FORMAT_DATEPICKER
                                                    }
                                                    onChange={(
                                                        date
                                                    ) => {
                                                        setFieldValue('systemReportingDate', date);
                                                    }}
                                                    showMonthDropdown={true
                                                    }
                                                    showYearDropdown={true
                                                    }
                                                    dropdownMode="select"
                                                    placeholderText={__t('form_labels.reporting.system_reporting_date'
                                                    )}
                                                    onFocus={(e
                                                    ) => e.target.blur()
                                                    }
                                                    maxDate={new Date()
                                                    }
                                                    disabled={true
                                                    }
                                                ></Field>
                                                <Label className="form-label">
                                                    {__t('form_labels.reporting.system_reporting_date'
                                                    )}
                                                    <span className="text-danger required"> *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.reporting.system_reporting_date'
                                                    ),
                                                    values.systemReportingDate ? getDateObjectWithValueFromMoment(values.systemReportingDate) : '',
                                                    'required|customDate',
                                                    {
                                                        messages: { required: __t('validations.reporting.system_reporting_date_required'), },
                                                    }
                                                )}
                                            </FormGroup>
                                            {/* System Reporting Date is hide */}
                                            <FormGroup className="col-lg-4">
                                                <Field
                                                    component={Select
                                                    }
                                                    name="completionStatus"
                                                    className="form-control selectbox"
                                                    options={completionStatusListDropdown}
                                                    onChange={(opt
                                                    ) => {
                                                        setFieldValue('completionStatus', opt.value);
                                                    }}
                                                    value={completionStatusListDropdown.filter((option) => option.value === values.completionStatus
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t('form_labels.reporting.completion_status'
                                                    )}{' '}
                                                    <span className="text-danger required"> *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t('form_labels.reporting.completion_status'
                                                    ),
                                                    values.completionStatus,
                                                    'required',
                                                    {
                                                        messages: { required: __t('validations.reporting.completion_status_required'), },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col-lg-4">
                                                <div className="incurred_dropdown">
                                                    <Field component={Select} name="expenseType[]" className="form-control selectbox"
                                                        options={expenseTypeList}
                                                        onChange={(opt) => {
                                                            let selectedValues = opt ? opt.map((x) => x.value) : [];
                                                            if (selectedValues.includes(EXPENSE_TYPE_STATUTORY_PAYMENT) || selectedValues.includes(EXPENSE_TYPE_PENALTY)) {
                                                                setFieldValue('expenseType', selectedValues.filter((ex) => ex != EXPENSE_TYPE_NONE));
                                                            } else {
                                                                setFieldValue('expenseType', selectedValues);
                                                            }
                                                            if (selectedValues.includes(EXPENSE_TYPE_STATUTORY_PAYMENT) || selectedValues.includes(EXPENSE_TYPE_PENALTY)) {
                                                                this.props.getCountryListByStatusRequest(ACTIVE);
                                                            }
                                                            this.validator.purgeFields();
                                                        }}
                                                        value={expenseTypeList.filter((option) => values.expenseType.includes(option.value))}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                    />
                                                    <Label className="form-label"> {__t('form_labels.reporting.expense_type_incurred')}{' '} <span className="text-danger required">     * </span>
                                                    </Label>
                                                    {this.validator.message(__t('form_labels.reporting.expense_type_incurred'), values.expenseType, 'required', { messages: { required: __t('validations.reporting.expense_type_incurred_required'), }, }
                                                    )}
                                                    <Row className="incurred_sub_field">
                                                        {values.expenseType.includes(EXPENSE_TYPE_STATUTORY_PAYMENT) && (
                                                            <React.Fragment>
                                                                <p>
                                                                    {getExpenseTypeNameById(EXPENSE_TYPE_STATUTORY_PAYMENT)}
                                                                </p>
                                                                <FormGroup className="col-lg-6">
                                                                    <Field component={Select} name="currency1" className="form-control selectbox" options={this.props.sortedCurrencyListDropDown}
                                                                        onChange={(opt) => { setFieldValue('currency1', opt ? opt.value : ''); }}
                                                                        value={this.props.sortedCurrencyListDropDown.filter((option) => option.value === values.currency1)}
                                                                        isClearable={true} />
                                                                    <Label className="form-label">
                                                                        {__t('form_labels.reporting.currency')}{' '}
                                                                        <span className="text-danger required">*</span>
                                                                    </Label>
                                                                    {this.validator.message(__t('form_labels.reporting.currency'),
                                                                        values.currency1, 'required', { messages: { required: __t('validations.reporting.currency_required'), }, })}
                                                                </FormGroup>
                                                                <FormGroup className="col-lg-6">
                                                                    <Field
                                                                        as={FloatComponent}
                                                                        name="amount1"
                                                                        className="form-control"
                                                                        precision={10}
                                                                        scale={2}
                                                                        placeholder={__t('form_labels.reporting.amount')}
                                                                    />
                                                                    <Label className="form-label">
                                                                        {__t('form_labels.reporting.amount')}                 <span className="text-danger required">*</span>
                                                                    </Label>
                                                                    {this.validator.message(__t('form_labels.reporting.amount'), values.amount1, 'required|numeric|min:0,num', { messages: { required: __t('validations.reporting.amount_required'), }, })}
                                                                </FormGroup>
                                                            </React.Fragment>
                                                        )}
                                                        {values.expenseType.includes(EXPENSE_TYPE_PENALTY) && (
                                                            <React.Fragment>
                                                                <p>{getExpenseTypeNameById(EXPENSE_TYPE_PENALTY)}</p>
                                                                <FormGroup className="col-lg-6">
                                                                    <Field
                                                                        component={Select}
                                                                        name="currency2"
                                                                        className="form-control selectbox"
                                                                        options={this.props.sortedCurrencyListDropDown}
                                                                        onChange={(opt) => {
                                                                            setFieldValue('currency2', opt ? opt.value : '');
                                                                        }}
                                                                        value={this.props.sortedCurrencyListDropDown.filter((option) =>
                                                                            option.value === values.currency2
                                                                        )}
                                                                        isClearable={true}
                                                                    />
                                                                    <Label className="form-label">
                                                                        {__t('form_labels.reporting.currency')}{' '}
                                                                        <span className="text-danger required">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    {this.validator.message(
                                                                        __t('form_labels.reporting.currency'),
                                                                        values.currency2,
                                                                        'required',
                                                                        {
                                                                            messages:
                                                                            {
                                                                                required:
                                                                                    __t('validations.reporting.currency_required'),
                                                                            },
                                                                        }
                                                                    )}
                                                                </FormGroup>
                                                                <FormGroup className="col-lg-6">
                                                                    <Field
                                                                        as={FloatComponent}
                                                                        name="amount2"
                                                                        className="form-control"
                                                                        precision={10}
                                                                        scale={2}
                                                                        placeholder={__t('form_labels.reporting.amount')}
                                                                    />
                                                                    <Label className="form-label">
                                                                        {__t(
                                                                            'form_labels.reporting.amount'
                                                                        )}
                                                                        <span className="text-danger required">
                                                                            *
                                                                        </span>
                                                                    </Label>
                                                                    {this.validator.message(
                                                                        __t('form_labels.reporting.amount'),
                                                                        values.amount2,
                                                                        'required|numeric|min:0,num',
                                                                        {
                                                                            messages:
                                                                            {
                                                                                required:
                                                                                    __t('validations.reporting.amount_required'),
                                                                            },
                                                                        }
                                                                    )}
                                                                </FormGroup>
                                                            </React.Fragment>
                                                        )}
                                                    </Row>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="col-lg-4 sticky_label radio_btn litigation_radio_btn mb-0">
                                                <div className="litigation_radio_bg">
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.reporting.matter_in_litigation'
                                                        )}{' '}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="matterInLitigation"
                                                            id="matterInLitigation1"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue('matterInLitigation', 1);
                                                                } else {
                                                                    setFieldValue('matterInLitigation', 0);
                                                                }
                                                            }}
                                                            value={1}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="matterInLitigation1"
                                                        >
                                                            {YES}
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="matterInLitigation"
                                                            id="matterInLitigation2"
                                                            onChange={(opt) => {
                                                                if (opt) {
                                                                    setFieldValue('matterInLitigation', 0);
                                                                } else {
                                                                    setFieldValue('matterInLitigation', 1);
                                                                }
                                                            }}
                                                            value={0}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            for="matterInLitigation2"
                                                        >
                                                            {NO}
                                                        </Label>
                                                    </div>
                                                    {values.matterInLitigation === 1 && (
                                                        <Row>
                                                            <FormGroup className="col-lg-12">
                                                                <Label className="form-label">
                                                                    {__t('form_labels.reporting.description')}
                                                                </Label>
                                                                <Field
                                                                    component="textarea"
                                                                    name="description"
                                                                    className="form-control"
                                                                    placeholder={__t('form_labels.reporting.description')}
                                                                    value={values.description}
                                                                    maxLength={1000}
                                                                    minLength={3}
                                                                    rows="5"
                                                                />
                                                                {this.validator.message(
                                                                    __t('form_labels.reporting.description'),
                                                                    values.description,
                                                                    'max:1000|min:3',
                                                                    {
                                                                        messages:
                                                                        {
                                                                            min: __t('validations.reporting.description_min', { min: 3 }),
                                                                            max: __t('validations.reporting.description_max', { max: 1000 }),
                                                                        },
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                        </Row>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        </React.Fragment>
                                    )}
                                {this.props.taskDetails.auditorId === this.props.loginUserId && (
                                    // departmentDataForInput
                                    <Col lg="4" className="mb-3">
                                        <div className="form_date_level">
                                            <label>Department</label>
                                            <p>{this.props.taskDetails?.departmentDataForInput?.map((d) => d.label).join(', ')}</p>
                                        </div>
                                    </Col>
                                )}
                                {this.props.taskDetails.auditorId !== this.props.loginUserId && (
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="departmentId"
                                            className="form-control selectbox"
                                            options={this.props.taskDetails?.departmentDataForInput}
                                            onChange={(opt) => {
                                                setFieldValue('departmentId', opt ? opt.value : []);
                                            }}
                                            value={this.props.taskDetails?.departmentDataForInput?.filter((option) =>
                                                values.departmentId === option.value)}
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.reporting.department')}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                        {this.validator.message(
                                            __t('form_labels.reporting.department'),
                                            values.departmentId,
                                            'required',
                                            {
                                                messages:
                                                {
                                                    required:
                                                        __t('validations.reporting.department_required'),
                                                },
                                            }
                                        )}
                                    </FormGroup>
                                )}

                                <FormGroup className="col-lg-4">
                                    <Dropzone
                                        onDrop={(acceptedFiles) =>
                                            this.handleAcceptedFiles(acceptedFiles)
                                        }
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div className="dz-message needsclick" {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <h3>
                                                        {(this.props.taskDetails.parentTaskId > 0 || this.props.taskDetails.auditorId === this.props.loginUserId) ? __t('form_labels.upload.document')
                                                            : __t('form_labels.upload.proof_of_compliance')}
                                                        {this.props.isProofRequired === true && this.props.taskDetails.submitterId == this.props.loginUserId && (
                                                            <span className="text-danger required">*</span>
                                                        )}
                                                    </h3>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {this.state.errors != '' && (
                                        <div className="srv-validation-message">
                                            {this.state.errors}
                                        </div>
                                    )}
                                    <div
                                        className="dropzone-previews mt-2"
                                        id="file-previews"
                                    >
                                        {this.state.selectedFile.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={
                                                        i +
                                                        '-file'
                                                    }
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            <Col>
                                                                <Link
                                                                    to="#"
                                                                    className="upload-document-title font-weight-bold"
                                                                >
                                                                    {
                                                                        f.name
                                                                    }
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>
                                                                        {
                                                                            f.formattedSize
                                                                        }
                                                                    </strong>
                                                                </p>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    className="btn btn-danger btn-sm"
                                                                    title="Delete file"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        this.removeFile(
                                                                            f
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        width="15"
                                                                        height="18"
                                                                        viewBox="0 0 15 18"
                                                                        fill="transparent"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z"
                                                                            fill="CurrentColor"
                                                                        />
                                                                    </svg>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </FormGroup>
                                <FormGroup className={(this.props.taskDetails.auditorId !== this.props.loginUserId) ? "col sticky_label mb-1" : "col-lg-4 sticky_label mb-1"}>
                                    <Field
                                        type="text"
                                        component="textarea"
                                        name="remarks"
                                        rows={3}
                                        className="form-control"
                                        placeholder={__t('form_labels.reporting.remarks')}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reporting.remarks')}
                                        {((values?.completionStatus &&
                                            values.completionStatus ==
                                            SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS) ||
                                            (values?.expenseType &&
                                                values.expenseType.includes(EXPENSE_TYPE_PENALTY)) || (
                                                values.activityType === 'sent_back_to_submitter' ||
                                                values.activityType === 'sent_back_to_first_approver' ||
                                                values.activityType === 'sent_back_to_second_approver'
                                            )) || ((moment(values.compliancePerformanceDate, "DD-MM-YYYY").isBefore(moment(this.props.taskDetails.enforcementDate, "DD-MM-YYYY")) || moment(values.documentProcurementDate, "DD-MM-YYYY").isBefore(moment(this.props.taskDetails.enforcementDate, "DD-MM-YYYY")))) ? (
                                                <span className="text-danger required">
                                                    *
                                                </span>
                                            ):null}
                                    </Label>
                                    {(values?.completionStatus && values.completionStatus == SUBMITTER_COMPLETION_WITH_EXCEPTION_STATUS) ||
                                        (values?.expenseType && values.expenseType.includes(EXPENSE_TYPE_PENALTY)) ||
                                        (values.activityType === 'sent_back_to_submitter' ||
                                            values.activityType === 'sent_back_to_first_approver' ||
                                            values.activityType === 'sent_back_to_second_approver') || moment(values.compliancePerformanceDate, "DD-MM-YYYY").isBefore(moment(this.props.taskDetails.enforcementDate, "DD-MM-YYYY")) || moment(values.documentProcurementDate, "DD-MM-YYYY").isBefore(moment(this.props.taskDetails.enforcementDate, "DD-MM-YYYY"))
                                        ? this.validator.message(
                                            __t('form_labels.reporting.remarks'),
                                            values.remarks,
                                            'required|min:3|max:1000',
                                            {
                                                messages: {
                                                    required: __t('validations.reporting.remarks_required'),
                                                    min: __t('validations.reporting.remarks_min', { min: 3, }),
                                                    max: __t('validations.reporting.remarks_max', { max: 1000, }),
                                                }
                                            }
                                        )
                                        : ''}
                                </FormGroup>
                                {this.props.taskDetails.auditorId === this.props.loginUserId && (
                                    <>
                                        <FormGroup className="col-lg-4 sticky_label mb-1">
                                            <Field
                                                type="text"
                                                component="textarea"
                                                name="recommendations"
                                                rows={3}
                                                className="form-control"
                                                placeholder={__t('form_labels.reporting.recommendation')}
                                            />
                                            <Label className="form-label">
                                                {__t('form_labels.reporting.recommendation')}
                                                {/* <span className="text-danger required">
                                            *
                                        </span> */}
                                            </Label>
                                            {/* {this.validator.message(
                                            __t('form_labels.reporting.recommendation'),
                                            values.recommendations,
                                            'required|min:3|max:1000',
                                            {
                                                messages: {
                                                    required: __t('validations.reporting.recommendation_required'),
                                                    min: __t('validations.reporting.recommendation_min', { min: 3, }),
                                                    max: __t('validations.reporting.recommendation_max', { max: 1000, }),
                                                }
                                            }
                                        )} */}
                                        </FormGroup>
                                        <Col lg="12" className='discrepancy-form-fields'>
                                            <Row>
                                                <Col lg={values.discrepancyType.length > 0 ? "6" : "4"}>
                                                    <Row>
                                                        <FormGroup className="col-lg-12 mt-2">
                                                            <Field
                                                                component={Select}
                                                                name="discrepancyType[]"
                                                                className="form-control selectbox"
                                                                options={discrepancyTypeList}
                                                                onChange={(opt) => {
                                                                    let selectedValue = opt ? opt.map((x) => x.value) : [];
                                                                    if (selectedValue.includes(DISCREPANCY_TYPE_MONETARY) || selectedValue.includes(DISCREPANCY_TYPE_NON_MONETARY)) {
                                                                        this.props.getCountryListByStatusRequest(ACTIVE);
                                                                    } else {
                                                                        setFieldValue('discrepancyDepartmentAmountData', [
                                                                            { departmentId: '', currency: {}, amount: 0.00 }
                                                                        ]);
                                                                    }
                                                                    setFieldValue('departmentId', '');
                                                                    setFieldValue('discrepancyNonMonetaryDescription', '');
                                                                    setFieldValue('discrepancyOthersDescription', '');
                                                                    setFieldValue('discrepancyType', selectedValue);
                                                                    this.validator.purgeFields();
                                                                }}
                                                                value={discrepancyTypeList.filter((option) =>
                                                                    values.discrepancyType.includes(option.value)
                                                                )}
                                                                isMulti={true}
                                                                closeMenuOnSelect={true}
                                                            />
                                                            <Label className="form-label">
                                                                {__t('form_labels.reporting.discrepancy_type')}{' '}
                                                            </Label>
                                                        </FormGroup>
                                                    </Row>
                                                    {values.discrepancyType.length > 0 && (
                                                        <Row>
                                                            <Col lg="12">
                                                                <FieldArray
                                                                    name="discrepancyDepartmentAmountData"
                                                                    render={arrayHelpers => (
                                                                        <div className='discrepancy'>
                                                                            {values.discrepancyDepartmentAmountData?.length > 0 &&
                                                                                values.discrepancyDepartmentAmountData.map(
                                                                                    (item, idx) => {
                                                                                        return (
                                                                                            <Row key={idx} className='mt-2 mb-3'>
                                                                                                <FormGroup className="col pr-0 mb-0">
                                                                                                    <Field
                                                                                                        component={Select}
                                                                                                        name={`discrepancyDepartmentAmountData.${idx}.departmentId`}
                                                                                                        className="form-control selectbox"
                                                                                                        options={this.props.taskDetails?.departmentDataForInput.filter(
                                                                                                            (option) => {
                                                                                                                let departmentArr = values.discrepancyDepartmentAmountData?.map(
                                                                                                                    ({ departmentId, ...rest }) => departmentId);
                                                                                                                return !departmentArr?.includes(option.value);
                                                                                                            }
                                                                                                        )}
                                                                                                        onChange={(opt) => {
                                                                                                            setFieldValue(`discrepancyDepartmentAmountData.${idx}.departmentId`, opt ? opt.value : '');
                                                                                                        }}
                                                                                                        value={this.props.taskDetails?.departmentDataForInput?.filter((option) =>
                                                                                                            item.departmentId === option.value
                                                                                                        )}
                                                                                                    />
                                                                                                    <Label className="form-label">
                                                                                                        {__t('form_labels.reporting.department')}
                                                                                                        <span className="text-danger required">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </Label>
                                                                                                    {this.validator.message(
                                                                                                        __t('form_labels.reporting.department'),
                                                                                                        item.departmentId,
                                                                                                        'required',
                                                                                                        {
                                                                                                            messages: {
                                                                                                                required: __t('validations.reporting.department_required'),
                                                                                                            },
                                                                                                        }
                                                                                                    )}
                                                                                                </FormGroup>
                                                                                                <FormGroup className="col pr-0 mb-0">
                                                                                                    <Field
                                                                                                        component={Select}
                                                                                                        name={`discrepancyDepartmentAmountData.${idx}.currency`}
                                                                                                        className="form-control selectbox"
                                                                                                        options={this.props.sortedCurrencyListDropDown}
                                                                                                        onChange={(opt) => {
                                                                                                            let currencyVal = '';
                                                                                                            if (opt) {
                                                                                                                currencyVal = {
                                                                                                                    countryId: opt.value,
                                                                                                                    countryName: opt.countryName,
                                                                                                                    currency: opt.currency
                                                                                                                }
                                                                                                            }
                                                                                                            setFieldValue(`discrepancyDepartmentAmountData.${idx}.currency`, currencyVal);
                                                                                                        }}
                                                                                                        value={this.props.sortedCurrencyListDropDown.filter((option) =>
                                                                                                            option.value === item.currency.countryId
                                                                                                        )}
                                                                                                        isClearable={true}
                                                                                                    />
                                                                                                    <Label className="form-label">
                                                                                                        {__t('form_labels.reporting.currency')}{' '}
                                                                                                        <span className="text-danger required">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </Label>
                                                                                                    {this.validator.message(
                                                                                                        __t('form_labels.reporting.currency'),
                                                                                                        item.currency,
                                                                                                        'required',
                                                                                                        {
                                                                                                            messages:
                                                                                                            {
                                                                                                                required: __t('validations.reporting.currency_required')
                                                                                                            },
                                                                                                        }
                                                                                                    )}
                                                                                                </FormGroup>
                                                                                                <FormGroup className="col pr-0 mb-0">
                                                                                                    <Field
                                                                                                        as={FloatComponent}
                                                                                                        name={`discrepancyDepartmentAmountData.${idx}.amount`}
                                                                                                        className="form-control"
                                                                                                        precision={10}
                                                                                                        scale={2}
                                                                                                        placeholder={__t('form_labels.reporting.amount')}
                                                                                                    // onChange={(opt) => {
                                                                                                    //     setFieldValue(`discrepancyDepartmentAmountData.${idx}.amount`, opt.value)
                                                                                                    // }}
                                                                                                    />
                                                                                                    <Label className="form-label">
                                                                                                        {__t('form_labels.reporting.amount')}
                                                                                                        <span className="text-danger required">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </Label>
                                                                                                    {this.validator.message(
                                                                                                        __t('form_labels.reporting.amount'),
                                                                                                        item.amount,
                                                                                                        'required|numeric|min:0,num',
                                                                                                        {
                                                                                                            messages:
                                                                                                            {
                                                                                                                required: __t('validations.reporting.amount_required')
                                                                                                            }
                                                                                                        }
                                                                                                    )}
                                                                                                </FormGroup>
                                                                                                <div className='d-flex align-items-start ml-2'>
                                                                                                    {values.discrepancyDepartmentAmountData.length > 1 ? (
                                                                                                        <Button
                                                                                                            type="button"
                                                                                                            className="btn btn-danger mr-2 btn-sm h-100"
                                                                                                            onClick={() => {
                                                                                                                arrayHelpers.remove(idx);
                                                                                                            }} // remove a row from the list
                                                                                                        >
                                                                                                            <i className="fas fa-minus m-0"></i>
                                                                                                        </Button>
                                                                                                    ) : null}
                                                                                                    {(values.discrepancyDepartmentAmountData.length === idx + 1) ? (
                                                                                                        <Button
                                                                                                            type="button"
                                                                                                            className='btn btn-primary btn-sm h-100'
                                                                                                            onClick={() => {
                                                                                                                arrayHelpers.insert(idx + 1, {
                                                                                                                    departmentId: '',
                                                                                                                    currency: {},
                                                                                                                    amount: 0.00
                                                                                                                });
                                                                                                                this.validator.purgeFields();
                                                                                                                this.validator.hideMessages();
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-plus m-0"></i>
                                                                                                        </Button>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            </Row>
                                                                                        )
                                                                                    })
                                                                            }
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Col>
                                                <Col lg="6">
                                                    <Row>
                                                        {values.discrepancyType.includes(DISCREPANCY_TYPE_NON_MONETARY) && (
                                                            <FormGroup className="col-lg-12 sticky_label mt-2 mb-1">
                                                                <Field
                                                                    type="text"
                                                                    component="textarea"
                                                                    name="discrepancyNonMonetaryDescription"
                                                                    className="form-control"
                                                                    placeholder={__t('form_labels.reporting.discrepancy_non_monetary_description')}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t('form_labels.reporting.discrepancy_non_monetary_description')}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t('form_labels.reporting.discrepancy_non_monetary_description'),
                                                                    values.discrepancyNonMonetaryDescription,
                                                                    'required|min:3|max:1000',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.reporting.discrepancy_non_monetary_description_required'),
                                                                            min: __t('validations.reporting.discrepancy_non_monetary_description_min', { min: 3, }),
                                                                            max: __t('validations.reporting.discrepancy_non_monetary_description_max', { max: 1000, }),
                                                                        }
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                        )}
                                                        {values.discrepancyType.includes(DISCREPANCY_TYPE_OTHERS) && (
                                                            <FormGroup className="col-lg-12 sticky_label mt-2 mb-1">
                                                                <Field
                                                                    type="text"
                                                                    component="textarea"
                                                                    name="discrepancyOthersDescription"
                                                                    className="form-control"
                                                                    placeholder={__t('form_labels.reporting.discrepancy_others_description')}
                                                                />
                                                                <Label className="form-label">
                                                                    {__t('form_labels.reporting.discrepancy_others_description')}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                                {this.validator.message(
                                                                    __t('form_labels.reporting.discrepancy_others_description'),
                                                                    values.discrepancyOthersDescription,
                                                                    'required|min:3|max:1000',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.reporting.discrepancy_others_description_required'),
                                                                            min: __t('validations.reporting.discrepancy_others_description_min', { min: 3, }),
                                                                            max: __t('validations.reporting.discrepancy_others_description_max', { max: 1000, }),
                                                                        }
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col className='col-12'>
                                    <FormGroup className="mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            {(this.props.taskDetails.taskStatusId === PENDING_FOR_COMPLETION ||
                                                this.props.taskDetails.taskStatusId === REOPENED ||
                                                this.props.taskDetails.taskStatusId === SEND_BACK_TO_SUBMITTER ||
                                                this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_SUBMITTER) &&
                                                this.props.taskDetails.submitterId == this.props.loginUserId && (
                                                    <React.Fragment>
                                                        <Button
                                                            type="submit"
                                                            name="activityType"
                                                            color="primary"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                setFieldValue('activityType', 'submitter');
                                                            }}
                                                        >
                                                            {SUBMIT}
                                                        </Button>
                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                        >
                                                            {RESET}
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            {(((this.props.taskDetails.taskStatusId === PENDING_WITH_FIRST_APPROVER ||
                                                this.props.taskDetails.taskStatusId === SEND_BACK_TO_APPROVER ||
                                                this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_APPROVER) &&
                                                this.props.taskDetails.firstApproverUserId == this.props.loginUserId) ||
                                                ((this.props.taskDetails.taskStatusId === PENDING_WITH_SECOND_APPROVER ||
                                                    this.props.taskDetails.taskStatusId === SEND_BACK_BY_AUDITOR_TO_APPROVER2) &&
                                                    this.props.taskDetails.secondApproverUserId == this.props.loginUserId)) && (
                                                    <React.Fragment>
                                                        <Button
                                                            type="submit"
                                                            name="activityType"
                                                            color="primary"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                setFieldValue('activityType', this.props.taskDetails.secondApproverUserId == this.props.loginUserId ? 'closed' : 'approved_by_first_approver');
                                                            }}
                                                        >
                                                            {APPROVE_REVIEW}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            name="activityType"
                                                            color="secondary"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                setFieldValue('activityType', this.props.taskDetails.secondApproverUserId == this.props.loginUserId ? 'sent_back_to_first_approver' : 'sent_back_to_submitter');
                                                            }}
                                                        >
                                                            {SEND_BACK}
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            {/* Auditor Buttons */}
                                            {this.props.taskDetails.taskStatusId === PENDING_FOR_AUDIT &&
                                                this.props.taskDetails.auditorId == this.props.loginUserId && (
                                                    <React.Fragment>
                                                        {/* <UncontrolledDropdown className="float-end" direction="left">
                                                        <DropdownToggle caret color="warning">
                                                            {this.getCurrentSendBackBtnForAuditor(values.activityType)}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem header>
                                                                {SEND_BACK_TO}
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                setFieldValue('activityType', 'sent_back_to_submitter');
                                                                handleSubmit();
                                                            }}>
                                                                {SEND_BACK_TO_SUBMITTER_BTN}
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                setFieldValue('activityType', 'sent_back_to_first_approver');
                                                                handleSubmit();
                                                            }}>
                                                                {SEND_BACK_TO_APPROVER_BTN}
                                                            </DropdownItem>
                                                            {this.props.taskDetails.secondApproverUserId != '' && (
                                                                <DropdownItem onClick={() => {
                                                                    setFieldValue('activityType', 'sent_back_to_second_approver');
                                                                    handleSubmit();
                                                                }}>
                                                                    {SEND_BACK_TO_APPROVER2_BTN}
                                                                </DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> */}
                                                        <Button
                                                            type="submit"
                                                            name="activityType"
                                                            color="primary"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                setFieldValue('activityType', 'audited_and_closed');
                                                            }}
                                                            disabled={(this.props.taskDetails.pendingObservation == 1 || values.discrepancyType.length > 0)}
                                                        >
                                                            {AUDIT_BTN}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            name="activityType"
                                                            color="danger"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                setFieldValue('activityType', 'discrepancy_pending');
                                                            }}
                                                            disabled={this.props.taskDetails.pendingObservation == 1 || values.discrepancyType.length === 0}
                                                        >
                                                            {DISCREPANCY_AUDIT_BTN}
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                )}
            </Formik>
        );
    }
}
export default ReportingForm;
