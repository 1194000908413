import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import { getAssignedEventsListRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { initialAssignedFilterDataState, listColumns } from './CommonFunctions';
import __t from 'i18n/translator';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import AssignedEventFilterForm from './AssignedEventFilterForm';
import {
    MODULE_ASSIGNED_EVENTS,
    PERMISSION_ADD,
} from 'constants/databaseConstants';
import { checkModulePermission } from 'helpers/projectUtils';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import NoAccess from 'components/Application/NoAccess';
import { userIsLite } from 'helpers/litehelper';
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            assignedEventList: [],
            searchFormData: initialAssignedFilterDataState(),
        };
    }
    componentDidMount() {
        if(!userIsLite()){
            this.getAssignedEventsSearchList({});
        }
    }
    getAssignedEventsSearchList = (values = {}) => {
        let apiData = { filter: values };
        this.props.getAssignedEventsListRequest(apiData);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getAssignedEventsSearchList(this.state.searchFormData);
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialAssignedFilterDataState(),
            },
            () => {
                this.getAssignedEventsSearchList({});
            }
        );
    };

    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            window.open(
                                '/event-compliances/' + allocationData?.id,
                                '_blank'
                            );
                        }}
                    >
                        <i className="fas fa-sitemap"></i>
                    </a>
                    {checkModulePermission(
                        MODULE_ASSIGNED_EVENTS,
                        PERMISSION_ADD
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.plan_event')}
                                className="btn btn-link text-primary tbl-action"
                                onClick={() => {
                                    this.props.history.push(
                                        '/plan-event/' +
                                        allocationData?.countryId +
                                        '/' +
                                        allocationData?.id
                                    );
                                }}
                            >
                                <i className="fas fa-plus"></i>
                            </a>
                        )}
                </div>
            );
            return {
                countryName: allocationData.country?.countryName,
                stateName: allocationData.state?.stateName,
                cityName: allocationData.city?.cityName,
                eventName: allocationData.eventName,
                eventApplicability: allocationData.eventApplicability
                    ? allocationData.eventApplicability
                    : '',
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            this.setState({
                assignedEventList: this.generateTable(
                    nextprops.allocationApiResponse.data.complianceList
                ),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.assignedEventList,
        };
        return (
            <React.Fragment>
                {
                    userIsLite() ? <NoAccess />:<Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'assigned-event'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={true}
                            dialogName={__t('page_headers.assigned_events')}

                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <AssignedEventFilterForm
                                    allocationFilterData={
                                        this.state.searchFormData
                                    }
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable tableRecords={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
              
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getAssignedEventsListRequest,
});
export default compose(
    withConnect,
    withAllocationReducer,
    withAllocationSaga,
    withRouter
)(Index);
