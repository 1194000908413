import { ADD_ENTITY_LOCATION_REQUEST, ADD_ENTITY_LOCATION_RESPONSE, DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST, DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE, GET_ALL_ENTITY_LOCATION_LIST_REQUEST, GET_ALL_ENTITY_LOCATION_LIST_RESPONSE, GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST, GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE, GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_REQUEST, GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_RESPONSE, UPDATE_ENTITY_LOCATION_REQUEST, UPDATE_ENTITY_LOCATION_RESPONSE, UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_REQUEST, UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_RESPONSE } from "./actionTypes";
export const addEntityLocationRequest = data => {
    return {
        type: ADD_ENTITY_LOCATION_REQUEST,
        payload: { data },
    };
};
export const addEntityLocationResponse = (message, responseType) => {
    return {
        type: ADD_ENTITY_LOCATION_RESPONSE,
        payload: { message, responseType },
    };
};
export const getAllEntityLocationListRequest = (isLite) => {
    return {
        type: GET_ALL_ENTITY_LOCATION_LIST_REQUEST,
        payload: {isLite}
    };
};
export const getAllEntityLocationListResponse = entityLocationList => {
    return {
        type: GET_ALL_ENTITY_LOCATION_LIST_RESPONSE,
        payload: { entityLocationList },
    };
};
export const getEntityLocationByEntityLocationIdRequest = (entityLocationId) => {
    return {
        type: GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST,
        payload: { entityLocationId: entityLocationId },
    };
};
export const getEntityLocationByEntityLocationIdResponse = (message, responseType, data) => {
    return {
        type: GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE,
        payload: { message, responseType, data },
    };
};
export const updateEntityLocationRequest = (entityLocationId, data) => {
    return {
        type: UPDATE_ENTITY_LOCATION_REQUEST,
        payload: { entityLocationId, data },
    };
};
export const updateEntityLocationResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_LOCATION_RESPONSE,
        payload: { message, responseType },
    };
};
export const updateEntityLocationStatusByEntityLocationIdRequest = (entityLocationId, status) => {
    return {
        type: UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_REQUEST,
        payload: { entityLocationId, status },
    };
};
export const updateEntityLocationStatusByEntityLocationIdResponse = (message, responseType) => {
    return {
        type: UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const deleteEntityLocationByEntityLocationIdRequest = (entityLocationId) => {
    return {
        type: DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST,
        payload: { entityLocationId },
    };
};
export const deleteEntityLocationByEntityLocationIdResponse = (message, responseType) => {
    return {
        type: DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_RESPONSE,
        payload: { message, responseType },
    };
};
export const getEntityLocationListByEntityIdRequest = (entityId) => {
    return {
        type: GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_REQUEST,
        payload: { entityId }
    };
};
export const getEntityLocationListByEntityIdResponse = entityLocationList => {
    return {
        type: GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_RESPONSE,
        payload: { entityLocationList },
    };
};