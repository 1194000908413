import produce from 'immer';
import { LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE, LITE_ACCOUNT_DETAILS_RESPONSE, LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE, LITE_ACCOUNT_UPDATE_RESPONSE, LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE, LITE_EDIT_LITE_ACCOUNT_RESPONSE, LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE, LITE_ENTITY_STATE_LIST_RESPONSE, LITE_ENTITY_UPDATE_BY_ID_RESPONSE, LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE, LITE_SUSPEND_ACCOUNT_RESPONSE } from './actionTypes';
import { DANGER, SUCCESS } from 'constants/commonConstants';

export const initialState = {
    liteAccountDetails: [],
    accountDetailsApiResponse: null,
    countryListWiseEntityApiResponse: null,
    liteEntityUpdateNameApiResponse:null,
    liteEntityStateApiResponse:null,
    liteAccountUpdateApiResponse:null,
    entityDetailsByIdApiResponse:null,
    updateEntityDetailsByIdApiResponse:null,
    entityWiseQuestionListApiResponse:null,
    liteSuspendAccountApiResponse:null,
    liteEditAccount:null
}


const LiteAccountDetailsReducer = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case LITE_ACCOUNT_DETAILS_RESPONSE:
                draft.liteAccountDetails = action.payload.data;
                break;
            case LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE:
                draft.accountDetailsApiResponse = action.payload.data;
                break;
            case LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE:
                draft.countryListWiseEntityApiResponse = action.payload.data;
                break;
            case LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE:
                draft.liteEntityUpdateNameApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_ENTITY_STATE_LIST_RESPONSE:
                draft.liteEntityStateApiResponse= action.payload.data;
                break;
            case LITE_ACCOUNT_UPDATE_RESPONSE:
                draft.liteAccountUpdateApiResponse ={
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE:
                draft.entityDetailsByIdApiResponse= action.payload;
                break;
            case LITE_ENTITY_UPDATE_BY_ID_RESPONSE:
                draft.updateEntityDetailsByIdApiResponse={
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE:
                draft.entityWiseQuestionListApiResponse = action.payload.data;
                break;
            case LITE_SUSPEND_ACCOUNT_RESPONSE:
                draft.liteSuspendAccountApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_EDIT_LITE_ACCOUNT_RESPONSE:
                draft.liteEditAccount={
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
            default:
                state = { ...state };
                break;
        }
    });
export default LiteAccountDetailsReducer;