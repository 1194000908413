import React, { Component } from 'react';
import { Row, Label, FormGroup, Button, CustomInput } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    CLEAR,
    SEARCH,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
} from 'constants/commonConstants';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawListRequest,
    getEventListByFilterForAllocationRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    getUserEntityListRequest
} from 'store/actions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import { compareString, setEmptyToNull } from 'helpers/generalUtils';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents, isGTROLE } from 'helpers/projectUtils';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
import __t from 'i18n/translator';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { selectSortedEventList } from 'store/Masters/Event/selector';
import DatePicker from 'react-datepicker';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { statusvalues } from 'constants/dropdowns';
import { selectSortedEntityList } from 'store/Entity/selector';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from "constants/authConstants"
import { ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
import { userIsLite } from 'helpers/litehelper';

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});

const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
export const intFields = ['country', 'state', 'city'];
export const nullableFields = [];
export const formatValuesForApi = (values) => {
    var data = {};
    data = setEmptyToNull(values, nullableFields, intFields, []);
    return data;
};
class ComplianceSearchForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            searchCompliancesFormData: this.props.searchCompliancesFormData,
            allCompliancesCategoriesList: [],
            allCompliancesTypesList: [],
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedSubIndustryListDropdown: [],
            sortedLawListDropdown: [],
            sortedEventListDropdown: [],
        };
    }
    componentDidMount() {
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
        this.props.getUserEntityListRequest();
    }
    getStateListByCountry(countryId) {
        this.props.getStateListByCountryRequest(countryId);
    }
    getCityListByState(stateId) {
        this.props.getCityListByStateRequest(stateId);
    }
    getSubIndustryListByIndustry(industryId) {
        this.props.getSubIndustryListByIndustryRequest(industryId);
    }
    getEventListByFilterForAllocation(values) {
        this.props.getEventListByFilterForAllocationRequest(values);
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState(
                {
                    searchCompliancesFormData: values,
                },
                () => {
                    this.props.onFormSubmit(formatValuesForApi(values));
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.setState(
            {
                sortedStateListDropdown: [],
                sortedCityListDropdown: [],
                sortedSubIndustryListDropdown: [],
                sortedLawListDropdown: [],
                sortedEventListDropdown: [],
            },
            () => {
                this.props.onResetForm();
            }
        );
    };
    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: values.countryId ? values.countryId : [],
                stateId: values.stateId ? values.stateId : [],
                cityId: values.cityId ? values.cityId : [],
                industryId: values.industryId ? values.industryId : [],
                subIndustryId: values.subIndustryId ? values.subIndustryId : [],
                lawCategoryId: values.lawCategoryId ? values.lawCategoryId : [],
                type: this.props.pageType,
            },
            orderBy: {},
        };
        this.props.getLawListRequest(apiData, false);
    }
    getEventsBySearch(values) {
        this.props.getEventListBySearchRequest(values);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !==
            nextprops.allCompliancesTypesList
        ) {
            this.setState({
                allCompliancesTypesList: nextprops.allCompliancesTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.complianceTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.sortedStateListDropdown &&
            this.props.sortedStateListDropdown !==
            nextprops.sortedStateListDropdown
        ) {
            this.setState({
                sortedStateListDropdown: nextprops.sortedStateListDropdown,
            });
        }
        if (
            nextprops.sortedCityListDropdown &&
            this.props.sortedCityListDropdown !==
            nextprops.sortedCityListDropdown
        ) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown,
            });
        }
        if (
            nextprops.sortedSubIndustryListDropdown &&
            this.props.sortedSubIndustryListDropdown !==
            nextprops.sortedSubIndustryListDropdown
        ) {
            this.setState({
                sortedSubIndustryListDropdown:
                    nextprops.sortedSubIndustryListDropdown,
            });
        }
        if (
            nextprops.sortedLawListDropdown &&
            this.props.sortedLawListDropdown !== nextprops.sortedLawListDropdown
        ) {
            this.setState({
                sortedLawListDropdown: nextprops.sortedLawListDropdown,
            });
        }
        if (
            nextprops.sortedEventListDropdown &&
            this.props.sortedEventListDropdown !==
            nextprops.sortedEventListDropdown
        ) {
            this.setState({
                sortedEventListDropdown: nextprops.sortedEventListDropdown,
            });
        }
    }
    render() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)));
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        const getComplianceCategory = isLite.litePlan?.complianceCategories ? JSON.parse(isLite.litePlan.complianceCategories):null;
        const getComplianceCategoryId = getComplianceCategory && getComplianceCategory.length > 0 ? getComplianceCategory.map((ele)=>ele.id):null;
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.searchCompliancesFormData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row className="filter-form">
                                <React.Fragment>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="industryId"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedIndustryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let industry = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'industryId',
                                                    industry
                                                );
                                                setFieldValue(
                                                    'subIndustryId',
                                                    []
                                                );
                                                setFieldValue('lawId', []);
                                                if (industry.length > 0) {
                                                    this.props.getSubIndustryListByIndustryRequest(
                                                        industry
                                                    );
                                                } else {
                                                    this.setState({
                                                        sortedSubIndustryListDropdown:
                                                            [],
                                                    });
                                                }
                                                this.getLawsByFilters({
                                                    ...values,
                                                    industryId: industry,
                                                });
                                            }}
                                            value={this.props.sortedIndustryListDropdown?.filter(
                                                (option) =>
                                                    values.industryId.includes(
                                                        option.value
                                                    )
                                            )}
                                            isClearable={true}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.statutory.industry'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="subIndustryId"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .sortedSubIndustryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let subIndustry = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'subIndustryId',
                                                    subIndustry
                                                );
                                                setFieldValue('lawId', []);
                                                this.getLawsByFilters({
                                                    ...values,
                                                    subIndustryId: subIndustry,
                                                });
                                            }}
                                            value={this.state.sortedSubIndustryListDropdown?.filter(
                                                (option) =>
                                                    values.subIndustryId.includes(
                                                        option.value
                                                    )
                                            )}
                                            isClearable={true}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.statutory.sub_industry'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={Select}
                                            name="countryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedCountryListDropdown
                                            }
                                            styles={{
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'countryId',
                                                    selectedValues
                                                );
                                                setFieldValue('stateId', []);
                                                setFieldValue('cityId', []);
                                                setFieldValue('lawId', []);
                                                this.getStateListByCountry(
                                                    selectedValues
                                                );
                                                this.getLawsByFilters({
                                                    ...values,
                                                    countryId: selectedValues,
                                                });
                                                this.getEventListByFilterForAllocation(
                                                    {
                                                        ...values,
                                                        lawId: selectedValues,
                                                    }
                                                );
                                            }}
                                            value={this.props.sortedCountryListDropdown?.filter(
                                                (option) =>
                                                    values.countryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.country'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="stateId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .sortedStateListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'stateId',
                                                    selectedValues
                                                );
                                                setFieldValue('cityId', []);
                                                setFieldValue('lawId', []);
                                                if (selectedValues.length > 0) {
                                                    this.getCityListByState(
                                                        selectedValues
                                                    );
                                                } else {
                                                    this.setState({
                                                        sortedCityListDropdown:
                                                            [],
                                                    });
                                                }
                                                this.getLawsByFilters({
                                                    ...values,
                                                    stateId: selectedValues,
                                                });
                                            }}
                                            value={this.state.sortedStateListDropdown?.filter(
                                                (option) =>
                                                    values.stateId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="stateId"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.state'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="cityId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .sortedCityListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'cityId',
                                                    selectedValues
                                                );
                                                setFieldValue('lawId', []);
                                                this.getLawsByFilters({
                                                    ...values,
                                                    cityId: selectedValues,
                                                });
                                            }}
                                            value={this.state.sortedCityListDropdown?.filter(
                                                (option) =>
                                                    values.cityId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="cityId"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.city')}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="lawCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedLawCategoryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'lawCategoryId',
                                                    selectedValues
                                                );
                                                setFieldValue('lawId', []);
                                                this.getLawsByFilters({
                                                    ...values,
                                                    lawCategoryId:
                                                        selectedValues,
                                                });
                                            }}
                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                (option) =>
                                                    values.lawCategoryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="lawCategoryId"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.law_category'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>

                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            className="form-control selectbox"
                                            name="lawId[]"
                                            options={
                                                this.state.sortedLawListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'lawId',
                                                    selectedValues
                                                );
                                                this.getEventListByFilterForAllocation(
                                                    {
                                                        ...values,
                                                        lawId: selectedValues,
                                                    }
                                                );
                                            }}
                                            value={this.state.sortedLawListDropdown?.filter(
                                                (option) =>
                                                    values.lawId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="laws"
                                        />
                                        <Label className="form-label">
                                            {__t('form_labels.allocation.law')}{' '}
                                        </Label>
                                    </FormGroup>

                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="eventId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .sortedEventListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'eventId',
                                                    selectedValues
                                                );
                                            }}
                                            value={this.state.sortedEventListDropdown?.filter(
                                                (option) =>
                                                    values.eventId.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="event"
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.events'
                                            )}{' '}
                                        </Label>
                                    </FormGroup>

                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="complianceTypeId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .allCompliancesTypesList
                                            }
                                            onChange={(opt) => {
                                                let complianceTypeId = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'complianceTypeId',
                                                    complianceTypeId
                                                );
                                            }}
                                            value={this.state.allCompliancesTypesList?.filter(
                                                (option) =>
                                                    values.complianceTypeId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.reports.compliance_class'
                                            )}
                                        </Label>
                                    </FormGroup>

                                    <FormGroup className="col-lg-4">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="complianceCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.state
                                                    .allCompliancesCategoriesList.filter((element)=>{
                                                        if(userIsLite()){
                                                            return getComplianceCategoryId.includes(element.value)
                                                        }else{
                                                            return element
                                                        }
                                                    })
                                            }
                                            onChange={(opt) => {
                                                let complianceCategoryId = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'complianceCategoryId',
                                                    complianceCategoryId
                                                );
                                            }}
                                            value={this.state.allCompliancesCategoriesList?.filter(
                                                (option) =>
                                                    values.complianceCategoryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.compliance.compliance_classification'
                                            )}
                                        </Label>
                                    </FormGroup>

                                    {!isGTROLE() && (
                                        <FormGroup className="col-lg-4">
                                            <Field
                                                component={Select}
                                                name="entityId[]"
                                                className="form-control selectbox"
                                                options={
                                                    this.props
                                                        .sortedEntityListDropdown
                                                }
                                                onChange={(opt) => {
                                                    let entityId = opt
                                                        ? opt.map((x) => x.value)
                                                        : [];
                                                    setFieldValue(
                                                        'entityId',
                                                        entityId
                                                    );
                                                }}
                                                value={this.props.sortedEntityListDropdown?.filter(
                                                    (option) =>
                                                        values.entityId?.includes(
                                                            option.value
                                                        )
                                                )}
                                                isMulti
                                                closeMenuOnSelect={false}
                                            />
                                            <Label className="form-label">
                                                {__t(
                                                    'form_labels.task_filters.entity'
                                                )}
                                            </Label>
                                        </FormGroup>
                                    )}
                                    <FormGroup className="col-lg-2 sticky_label">
                                        <Field
                                            component={DatePicker}
                                            isClearable={true}
                                            className="form-control"
                                            name="fromDate"
                                            selected={
                                                values.fromDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.fromDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={(date) => {
                                                setFieldValue('fromDate', date);
                                                let dateCheck =
                                                    getDateObjectWithValueFromMoment(
                                                        values.toDate
                                                    ) <
                                                        getDateObjectWithValueFromMoment(
                                                            date
                                                        )
                                                        ? true
                                                        : false;
                                                if (dateCheck) {
                                                    setFieldValue('toDate', '');
                                                }
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={
                                                DATE_FORMAT_PLACEHOLDER
                                            }
                                            onFocus={(e) => e.target.blur()}
                                        ></Field>
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.from_period'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-2 sticky_label">
                                        <Field
                                            component={DatePicker}
                                            isClearable={true}
                                            className="form-control"
                                            name="toDate"
                                            selected={
                                                values.toDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values.toDate
                                                    )
                                                    : null
                                            }
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={(date) => {
                                                setFieldValue('toDate', date);
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={
                                                DATE_FORMAT_PLACEHOLDER
                                            }
                                            onFocus={(e) => e.target.blur()}
                                            minDate={
                                                values?.fromDate
                                                    ? getDateObjectWithValueFromMoment(
                                                        values?.fromDate
                                                    )
                                                    : ''
                                            }
                                        ></Field>
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.archive_records.to_period'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className={(!isGTROLE()) ? "col-lg-4" : "col-lg-2"}>
                                        <Field
                                            component={Select}
                                            name="status"
                                            className="form-control selectbox"
                                            options={
                                                statusvalues
                                            }
                                            onChange={(opt) => {
                                                let statusValue =
                                                    opt
                                                        ? opt.value
                                                        : '';
                                                setFieldValue(
                                                    'status',
                                                    statusValue
                                                );
                                            }}
                                            value={statusvalues?.filter(
                                                (option) =>
                                                    option.value ===
                                                    values.status
                                            )}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.compliance.status'
                                            )}
                                        </Label>
                                    </FormGroup>
                                    {
                                        currentRole[0].roleId == ROLE_SUPER_ADMIN ? <FormGroup className="col-lg-2 d-flex justify-content-end align-items-center">
                                        <Field
                                            component={CustomInput} 
                                            type="checkbox"
                                            id="isLite"
                                            name="isLite"
                                            className='new_addition_checks'
                                            onChange={(e) => {
                                                setFieldValue("isLite",e.target.checked)
                                            }}
                                            checked={values.isLite}
                                            label={"Lite Compliances"} 
                                        />
                                    </FormGroup>:null
                                    }
                                    
                                    <FormGroup className="col mb-0 d-flex justify-content-end">
                                        <div className="d-inline-block">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mr-1"
                                            >
                                                {SEARCH}
                                            </Button>
                                            <Button
                                                type="reset"
                                                color="secondary"
                                                onClick={this.resetForm}
                                            >
                                                {CLEAR}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </React.Fragment>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedLawListDropdown: selectSortedLawSearchList(),
    sortedEventListDropdown: selectSortedEventList(),
    allCompliancesCategoriesList: makeLawComplianceSelectField(
        'allCompliancesCategoriesList'
    ),
    allCompliancesTypesList: makeLawComplianceSelectField(
        'allCompliancesTypesList'
    ),
    sortedEntityListDropdown: selectSortedEntityList(),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getLawListRequest,
    getEventListByFilterForAllocationRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    getUserEntityListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withEventReducer,
    withEventSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(ComplianceSearchForm);
