import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import dashboardReducer from 'store/Dashboard/reducer';
import DashboardSaga from 'store/Dashboard/saga';
import {
    getOverallTaskListRequest, getTaskUserDetailsByTaskIdRequest, clearOverallTaskApiResponse
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { makeTaskSelectField } from 'store/Task/selector';
import { actionPendingIcon, getAuditStatusById, getTaskStatusById, isManagementRole } from 'helpers/projectUtils';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { AUDITED_AND_CLOSED, CLOSED, PENDING_FOR_AUDIT, PENDING_FOR_COMPLETION, PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, REOPENED, ROLE_MANAGEMENT_TEAM, SEND_BACK_BY_AUDITOR_TO_APPROVER, SEND_BACK_TO_APPROVER, SEND_BACK_TO_SUBMITTER } from 'constants/databaseConstants';
import ReactTooltip from 'react-tooltip';
import { PENDING_TO_TRIGGERED, SUCCESS } from 'constants/commonConstants';
import ViewTaskUser from 'components/Application/ViewTaskUser';
import { makeDashboardSelectField } from 'store/Dashboard/selector';
import { getDateFrom } from 'helpers/dateTimeHelper';
import { compareString, getFirstCapitalize, redirectUrl } from 'helpers/generalUtils';
import ViewEventTasksModal from 'app/Events/ViewEventTasksModal';
import eventAllocationReducer from 'store/EventAllocation/reducer';
import eventAllocationSaga from 'store/EventAllocation/saga';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { userIsLite } from 'helpers/litehelper';
import { decryptData } from 'helpers/encryptor';
const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Status',
        field: 'taskStatusId'
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];
const listEventColumns = [
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Event Name',
        field: 'eventName',
    },
    {
        label: 'Country',
        field: 'countryName',
    },
    {
        label: 'Type',
        field: 'type',
    },
    {
        label: 'Event Date',
        field: 'eventOccurrenceDate',
    },
    {
        label: 'Status',
        field: 'status',
    },
    {
        label: 'Action',
        field: 'actions',
        attributes: {
            className: 'action_col',
        },
    }
];

const listColumnsClosedTask = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Task Status',
        field: 'taskStatusId'
    },
    {
        label: 'Audit Status',
        field: 'auditStatus'
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];

const eventAllocationKey = 'eventAllocation';
const withEventAllocationReducer = injectReducer({
    key: eventAllocationKey,
    reducer: eventAllocationReducer,
});
const withEventAllocationSaga = injectSaga({
    key: eventAllocationKey,
    saga: eventAllocationSaga,
});
const dashboardKey = 'dashboard';
const withDashboardReducer = injectReducer({
    key: dashboardKey,
    reducer: dashboardReducer,
});
const withDashboardSaga = injectSaga({
    key: dashboardKey,
    saga: DashboardSaga,
});
const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class OverallTaskIndex extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskList: [],
            eventList: [],
            isEventData: false,
            userDepartments: [],
            userDepartmentEntities: [],
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
            isViewEventTaskModalOpen: false,
            eventData: {},
            compliancesTypesList: [],
            compliancesCategoriesList: [],
            riskRatingsList: [],
            currentRole:JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))
        };
    }
    componentDidMount() {
        this.getOverallTaskList(this.props.overallFilterValues);
    }
    componentWillUnmount() {
        this.setState({
            taskList: [],
            eventList: []
        }, () => {
            this.props.clearOverallTaskApiResponse();
        })
    }
    getOverallTaskList = (values = {}) => {
        let apiData = {
            filter: {
                ...values,
                countryId: (values.countryId) ? values.countryId[0] : '',
                stateId: (values.stateId) ? values.stateId[0] : '',
                cityId: (values.cityId) ? values.cityId[0] : '',
                isManagementUser: isManagementRole()
            },
        };
        this.props.getOverallTaskListRequest(apiData);
    };
    openViewTaskUserModal = (data) => {
        this.setState({
            isViewTaskUserModalOpen: true,
            viewTaskUserData: data,
        });
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds = (taskData.complianceAllocationDepartmentId != '' && taskData.complianceAllocationDepartmentId != null) ? taskData.complianceAllocationDepartmentId.split(',') : [];
        const collaboratorUserIds = (taskData.collaboratorUserId != '' && taskData.collaboratorUserId != null) ? taskData.collaboratorUserId.split(',').map(Number) : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (departmentIds.some((department) => {
            let departmentHod = this.state.userDepartments?.find((departmentEntity) => departmentEntity.entityId == taskData.complianceAllocationEntityId && departmentEntity.departmentId == department);
            if (departmentHod) {
                return true;
            }
        })) {
            if(this.state.currentRole[0].roleId == ROLE_MANAGEMENT_TEAM){
                roles.push("-")
            }else{
                roles.push('HOD');
            }
        } else if (taskData.collaboratorUserId != '' && collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    generateTable = (records) => {
        let taskRecords = [];
        const locationList = this.props.locationList
        taskRecords = records.map((taskData, i) => {
            let roleNames = this.getRoleName(taskData);
            let type = 'report';
            if ([PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(taskData.taskStatusId)) {
                type = 'audit';
            }
            if ([PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, SEND_BACK_TO_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER].includes(taskData.taskStatusId)) {
                type = 'review';
            }
            let iconName = actionPendingIcon(this.props.loginUserId, taskData.taskStatusId, taskData.submitterId, taskData.firstApproverUserId, taskData.secondApproverUserId, taskData.auditorId);
            let undoIcon = ((taskData.taskStatusId === REOPENED || taskData.taskStatusId === SEND_BACK_TO_SUBMITTER) && taskData.submitterId === this.props.loginUserId) ? true : false;
            let actions = (<div className="tbl_action">
                {(iconName === 'view') && (
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            redirectUrl(
                                this.props.history,
                                '/compliances/' + type + '/' + taskData.id
                            );
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                )}
                {iconName === 'action' && (
                    <a
                        href={() => false}
                        title={(undoIcon) ? __t('tables.tasks.rework') : __t('tables.tasks.reporting')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            redirectUrl(
                                this.props.history,
                                '/compliances/' +
                                type +
                                '/' +
                                taskData.id
                            );
                        }}
                    >
                        {(undoIcon) ? (
                            <i className="fas fa-undo"></i>
                        ) : (
                            <i className="fas fa-file-import"></i>
                        )}
                    </a>
                )}
            </div>);
            let taskCodeVal = (
                <span searchvalue={taskData.taskCode} className="code_td">{taskData.taskCode}</span>
            );

            const findTaskStatusName = this.props.taskStatusList && this.props.taskStatusList.length > 0 ? this.props.taskStatusList.find((element) => element.value == taskData.taskStatusId)?.label ?? "" : "";
            let taskStatusInfo = getTaskStatusById(taskData.taskStatusId, findTaskStatusName);
            let auditStatusInfo = getAuditStatusById(taskData.taskStatusId, findTaskStatusName);

            let infoVal = (
                <div className='status_info' searchvalue={this.props?.overallFilterValues?.clickTypeValue == "closed" ? auditStatusInfo : findTaskStatusName}>
                    <span>{this.props?.overallFilterValues?.clickTypeValue == "closed" ? auditStatusInfo : findTaskStatusName}</span>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.props.getTaskUserDetailsByTaskIdRequest(taskData.id)
                        }} className="text-primary"><i className="fas fa-info-circle"></i></a>
                </div>
            );
            let complianceAction = (
                <a searchvalue={taskData?.complianceAllocationLawComplianceCode}
                    href={() => false}
                    title="View Details"
                    className='tbl-link'
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocationLawComplianceId,
                            '_blank'
                        )
                    }
                >
                    {taskData?.complianceAllocationLawComplianceCode}{' '}
                </a>
            )
            const cityName = locationList?.filter((data) => data.id === taskData?.complianceAllocationEntityLocationCityId)[0]?.cityName
            // let complianceTypeId = (taskData.complianceVersionLawComplianceTypeId == null) ? taskData.complianceAllocationLawComplianceTypeId : taskData.complianceVersionLawComplianceTypeId;
            // let complianceTypeName = this.state.compliancesTypesList.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = (taskData.complianceVersionLawComplianceCategoryId == null) ? taskData.complianceAllocationLawComplianceCategoryId : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.id === complianceCategoryId)?.complianceCategoryName || '';

            let complianceRiskRatingId = (taskData.complianceVersionLawComplianceRiskRatingId == null) ? taskData.complianceAllocationLawComplianceRiskRatingId : taskData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.riskRatingsList.find((c) => c.id === complianceRiskRatingId)?.riskRatingName || '';

            return {
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.ComplianceAllocationEntityCompanyGroupName,
                entityName: taskData.complianceAllocationEntityName,
                complianceCode:
                    complianceAction,
                // countryName:
                //     taskData.complianceAllocationEntityLocationCountryName,
                // stateName: taskData.complianceAllocationEntityLocationStateName,
                cityName: cityName ? cityName : "",
                complianceTitle:
                    taskData.parentTaskId === null
                        ? ((taskData.complianceVersionLawComplianceTitle == null) ? taskData.complianceAllocationLawComplianceTitle : taskData.complianceVersionLawComplianceTitle)
                        : taskData.title,
                complianceClass: complianceCategoryName,
                // complianceType: complianceTypeName,
                dueDate: (taskData.dueDate) ? <Label searchvalue={taskData.dueDate}>{getDateFrom(taskData.dueDate)}</Label> : <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>,
                riskLevel: complianceRiskRatingName,
                taskStatusId: this.props?.overallFilterValues?.clickTypeValue == "closed" ? taskStatusInfo : infoVal,
                auditStatus: infoVal,
                parentTaskId: taskData.parentTaskId === null ? 'Statutory' : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions: actions,
            };
        });
        return taskRecords;
    };
    openViewEventTaskModal = (eventData) => {
        this.setState({
            isViewEventTaskModalOpen: true,
            eventData: eventData,
        });
    };
    closeViewEventTaskModal = () => {
        this.setState({
            isViewEventTaskModalOpen: false,
            eventData: {},
        });
    };
    generateEventTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((eventData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            this.openViewEventTaskModal(eventData);
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                </div>
            );
            return {
                entityName: eventData.entity?.entityName,
                countryName: eventData.event?.country?.countryName,
                eventName: eventData.event?.eventName,
                type: (eventData?.eventType) ? getFirstCapitalize(eventData?.eventType) : '',
                eventOccurrenceDate: eventData.eventOccurrenceDate,
                status: eventData.status,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            this.state.eventList.length === 0 &&
            this.state.taskList.length === 0 &&
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
        ) {
            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            this.state.eventList.length === 0 &&
            this.state.taskList.length === 0 &&
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !== nextprops.allCompliancesTypesList
        ) {
            this.setState({
                compliancesTypesList: nextprops.allCompliancesTypesList,
            });
        }

        if (
            this.state.eventList.length === 0 &&
            this.state.taskList.length === 0 &&
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (nextprops.overallFilterValues !== this.props.overallFilterValues) {
            this.getOverallTaskList(nextprops.overallFilterValues)
        }
        if (
            nextprops.overallTaskApiResponse &&
            nextprops.overallTaskApiResponse !== this.props.overallTaskApiResponse &&
            this.state.eventList.length === 0 &&
            this.state.taskList.length === 0
        ) {
            if (nextprops.overallTaskApiResponse.data?.tasks?.length > 0) {
                if (nextprops.overallTaskApiResponse.data?.allocatedEvents == true) {
                    this.setState({
                        eventList: [],
                        taskList: [],
                        compliancesTypesList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceTypesData,
                        compliancesCategoriesList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceCategoriesData,
                        riskRatingsList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceRiskRatingsData
                    }, () => {
                        this.setState({
                            isEventData: true,
                            eventList: this.generateEventTable(
                                nextprops.overallTaskApiResponse.data?.tasks
                            )
                        })
                    });
                } else {
                    this.setState({
                        userDepartments:
                            nextprops.overallTaskApiResponse.data
                                .userDepartments,
                        taskList: [],
                        eventList: [],
                        compliancesTypesList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceTypesData,
                        compliancesCategoriesList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceCategoriesData,
                        riskRatingsList: nextprops.overallTaskApiResponse.data?.masterData?.masterComplianceRiskRatingsData
                    }, () => {
                        this.setState({
                            isViewTaskUserModalOpen: false,
                            viewTaskUserData: {},
                            taskList: this.generateTable(nextprops.overallTaskApiResponse.data?.tasks)
                        });
                    });
                }
            }
        }
        if (nextprops.taskUserDetailsApiResponse && nextprops.taskUserDetailsApiResponse !== this.props.taskUserDetailsApiResponse) {
            if (nextprops.taskUserDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    isViewTaskUserModalOpen: true,
                    viewTaskUserData: nextprops.taskUserDetailsApiResponse.data?.taskUserData
                })
            }
        }
    }
    render() {
        const data = {
            columns: this.props?.overallFilterValues?.clickTypeValue == "closed" ? listColumnsClosedTask.filter((element)=>{
                if(userIsLite()){
                    if(element.field== "auditStatus"){
                        return null
                    }else{
                        return element
                    }
                }else{
                    return element;
                }

            }) : (!this.state.isEventData) ? listColumns : listEventColumns,
            rows: (!this.state.isEventData) ? this.state.taskList : this.state.eventList,
        };
        let sortRows = [];
        if (!this.state.isEventData) {
            sortRows = ['taskCode', 'complianceCode', 'dueDate'];
        }
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link to="#" className="btn btn-primary mb-2 dashboard_back_btn" onClick={() => {
                            this.props.closeOverallTask()
                        }}>  <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable tableRecords={data}
                                    sortRows={sortRows}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewTaskUser
                    modalName={__t('form_labels.reporting.view_task_user')}
                    isModalOpen={this.state.isViewTaskUserModalOpen}
                    taskUserData={this.state.viewTaskUserData}
                    onModalDismiss={() => this.closeViewTaskUserModal()}
                />
                <ViewEventTasksModal
                    modalName={__t('form_labels.events.tasks')}
                    isModalOpen={this.state.isViewEventTaskModalOpen}
                    eventData={this.state.eventData}
                    onModalDismiss={() => this.closeViewEventTaskModal()}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    overallTaskApiResponse: makeDashboardSelectField('overallTaskApiResponse'),
    taskUserDetailsApiResponse: makeTaskSelectField('taskUserDetailsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getOverallTaskListRequest,
    getTaskUserDetailsByTaskIdRequest,
    clearOverallTaskApiResponse
});
export default compose(
    withConnect,
    withDashboardReducer,
    withDashboardSaga,
    withTaskReducer,
    withTaskSaga,
    withEventAllocationReducer,
    withEventAllocationSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(OverallTaskIndex);
