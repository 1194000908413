import React, { Component } from 'react';
import { Row, Col, CardBody, Card, CardHeader, Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ENABLE_DELETE, NONE, SUBMIT, UPDATE } from 'constants/commonConstants';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import {
    getAllCompanyGroupListRequest,
    updateCompanyGroupStatusByCompanyGroupIdRequest,
    addCompanyGroupRequest,
    updateCompanyGroupRequest,
    deleteCompanyGroupByCompanyGroupIdRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import { makeCompanyGroupSelectField } from 'store/Masters/CompanyGroup/selector';
import { initialCompanyGroupDataState, listColumns } from './CommonFunctions';
import Switch from 'react-switch';
import AddCompanyGroupForm from './AddCompanyGroupForm';
import { commonConfirmBox } from 'helpers/messageHelper';
import {
    MODULE_COMPANY_GROUP,
    PERMISSION_ADD,
    PERMISSION_DELETE,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import __t from 'i18n/translator';
import AuditLogModal from 'components/Application/AuditLogModal';
import { checkModulePermission } from 'helpers/projectUtils';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedMDBDatatablePage from 'components/Application/CustomisedMDBDatatablePage';
import { userIsLite } from 'helpers/litehelper';
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyGroupList: [],
            exportCompanyGroupList: [],
            companyGroupData: initialCompanyGroupDataState(),
            operationType: SUBMIT,
            isAuditModalOpen: false,
            auditId: '',
            activePage: 1,
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        //Retrieve Company Group list
        let isLite=null;
        if(userIsLite()){
            isLite =true
        }
        this.props.getAllCompanyGroupListRequest({isLite});
    }
    onStatusChange(checked, e, id) {
        this.props.updateCompanyGroupStatusByCompanyGroupIdRequest(id, checked);
    }
    viewCompanyGroup(companyGroupData) {
        this.setState(
            {
                companyGroupData: initialCompanyGroupDataState(),
                operationType: UPDATE,
            },
            () => {
                this.setState({
                    companyGroupData: {
                        ...companyGroupData,
                        companyGroupAdmin:
                            companyGroupData.companyGroupAdmin.map(
                                (opt) => opt.userId
                            ),
                    },
                });
            }
        );
    }
    deleteCompanyGroup = async (companyGroupId) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.company_group_delete')
        );
        if (response) {
            this.props.deleteCompanyGroupByCompanyGroupIdRequest(
                companyGroupId
            );
        }
    };
    formSubmit = (values) => {
        this.setState({ companyGroupData: values });
        if (this.state.operationType === UPDATE) {
            this.props.updateCompanyGroupRequest(values.id, values);
        } else {
            this.props.addCompanyGroupRequest(values);
        }
    };
    resetForm = () => {
        this.setState({
            companyGroupData: initialCompanyGroupDataState(),
            operationType: SUBMIT,
        });
    };
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    generateTable = (records) => {
        let companyGroupRecords = [];
        companyGroupRecords = records.map((companyGroupData, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(
                        MODULE_COMPANY_GROUP,
                        PERMISSION_EDIT
                    ) === true && companyGroupData.id != NONE && (
                            <a
                                href={() => false}
                                title={__t('common.edit')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.viewCompanyGroup(companyGroupData);
                                }}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </a>
                        )}
                    {ENABLE_DELETE.includes('companyGroup') &&
                        checkModulePermission(
                            MODULE_COMPANY_GROUP,
                            PERMISSION_DELETE
                        ) === true && (
                            <a
                                href={() => false}
                                title={__t('common.delete')}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {
                                    this.deleteCompanyGroup(
                                        companyGroupData.id
                                    );
                                }}
                            >
                                <i className="fas fa-trash-alt"></i>
                            </a>
                        )}
                    <a
                        href={() => false}
                        title={__t('common.audit_logs')}
                        className="btn btn-link text-primary tbl-action"
                        onClick={() => {
                            this.openAuditLogModal(companyGroupData.id);
                        }}
                    >
                        <i className="fas fa-history"></i>
                    </a>
                </div>
            );
            let isActive = (
                <div className="d-flex">
                    <Switch
                        className="switch-sm"
                        onColor="#9BD732"
                        onChange={this.onStatusChange}
                        id={String(companyGroupData.id)}
                        checked={companyGroupData.isActive ? true : false}
                        disabled={!checkModulePermission(MODULE_COMPANY_GROUP, PERMISSION_EDIT) || companyGroupData.id == NONE}
                    />
                    &nbsp;
                </div>
            );
            let companyGroupAdmins = [];
            companyGroupData.companyGroupAdmin.map((opt) => {
                if (opt.user) {
                    companyGroupAdmins.push(opt.user.fullName + ' - ' + opt.user.email);
                }
            })
            return {
                companyGroupName: (companyGroupData.companyGroupName) ? companyGroupData.companyGroupName : '',
                tenantName: companyGroupData.tenant ? companyGroupData.tenant.name : '',
                companyGroupAdmin: companyGroupAdmins.join(),
                isActive: isActive,
                actions: actions,
            };
        });
        return companyGroupRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.companyGroupList &&
            this.props.companyGroupList !== nextprops.companyGroupList
        ) {
            this.setState({
                exportCompanyGroupList: nextprops.companyGroupList.map(
                    (opt) => ({
                        ...opt,
                        companyGroupAdmin: opt.companyGroupAdmin?.map(
                            (x) =>
                                x.user?.fullName + ' - ' + x.user?.email
                        ),
                    })
                ),
                companyGroupList: this.generateTable(
                    nextprops.companyGroupList
                ),
            });
        }
    }
    handlePageChange = (newPage) => {
        this.setState({
            activePage: (newPage?.page) ? newPage?.page : 1
        })
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.companyGroupList,
        };
        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'company-group'} />
                        </Col>
                        <Col
                            lg={
                                checkModulePermission(
                                    MODULE_COMPANY_GROUP,
                                    PERMISSION_ADD
                                ) === true ||
                                    checkModulePermission(
                                        MODULE_COMPANY_GROUP,
                                        PERMISSION_EDIT
                                    ) === true
                                    ? 7
                                    : 12
                            }
                        >
                            <Card>
                                <CardHeader>
                                    <div className="page-header">
                                        <h4>
                                            {__t(
                                                'page_headers.company_group_list'
                                            )}
                                        </h4>
                                    </div>

                                </CardHeader>
                                <CardBody>
                                    <CustomisedMDBDatatablePage

                                        activepage={this.state.activePage}
                                        onHandlePageChange={this.handlePageChange}
                                        tableRecords={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {(checkModulePermission(
                            MODULE_COMPANY_GROUP,
                            PERMISSION_ADD
                        ) === true ||
                            checkModulePermission(
                                MODULE_COMPANY_GROUP,
                                PERMISSION_EDIT
                            ) === true) && (
                                <Col lg="5">
                                    <Card>
                                        <CardHeader>
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'page_headers.company_group_form'
                                                    )}
                                                </h4>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <AddCompanyGroupForm
                                                operationType={
                                                    this.state.operationType
                                                }
                                                companyGroupData={
                                                    this.state.companyGroupData
                                                }
                                                operationResponse={
                                                    this.props
                                                        .companyGroupApiResponse
                                                }
                                                onFormValid={this.formSubmit}
                                                onFormReset={this.resetForm}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                    </Row>
                    <AuditLogModal
                    exportBtn={true}
                        isModalOpen={this.state.isAuditModalOpen}
                        modalName={__t('page_headers.audit_trails')}
                        auditType={'companyGroup'}
                        auditId={this.state.auditId}
                        onModalDismiss={() => this.closeAuditLogModal()}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    companyGroupList: makeCompanyGroupSelectField('companyGroupList'),
    companyGroupApiResponse: makeCompanyGroupSelectField(
        'companyGroupApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getAllCompanyGroupListRequest,
    updateCompanyGroupStatusByCompanyGroupIdRequest,
    addCompanyGroupRequest,
    updateCompanyGroupRequest,
    deleteCompanyGroupByCompanyGroupIdRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(Index);
