import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { compareString } from 'helpers/generalUtils';
const reducerState = state => state.user || initialState;
const makeUserSelectField = field =>
    createSelector(reducerState, userState => userState[field]);
const selectSortedUserList = () =>
    createSelector(makeUserSelectField('userList'), userList =>
        userList?.map(opt => {
            let label = opt.email;
            if(opt.fullName){
                label = opt.fullName + ' - ' + opt.email
            }
            return {
                value: opt.id,
                label: label,
                fullName: opt.fullName,
                email: opt.email,
            }
        })
            .sort((item1, item2) => compareString(item1.label, item2.label))
    );
export {
    reducerState,
    makeUserSelectField,
    selectSortedUserList
};
