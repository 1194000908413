import { getValueByKey } from "./authUtils";
import { decryptData } from "./encryptor";
import * as authConstants from 'constants/authConstants';
import { alertBoxIconPlan } from "./messageHelper";
import __t from "i18n/translator";

export const userIsLite =()=>{
    const isLite = getValueByKey(authConstants.IS_LITE) ? JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE))):null;
    if (isLite?.isLite == 1) {
        return true;
    }else{
        return false;
    }
}

export const PopupPremium =async()=>{
    const response = await alertBoxIconPlan(
        __t('lite.alert_plan_access'),
        __t('lite.alert_plan_message_premium'),
        "info"
    );
}

export const PopupBasic =async(additionalMessage)=>{
    const response = await alertBoxIconPlan(
        __t('lite.alert_plan_access'),
        additionalMessage ? additionalMessage :__t('lite.alert_plan_message_basic'),
        "info"
    );
}

export const extractDomain = (str) => {
        // eslint-disable-next-line
        if (str && str !== "" && str.includes('@')) {
            return str.split('@')[1].toLowerCase();
        }
        if (str) {
            const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/\s]+)/i;
            const match = str.match(regex);
            return match ? match[1] : null;
        }
        return null
};