import CommonBreadcrum from 'components/Application/CommonBreadcrum'
import { Field, Formik } from 'formik';
import __t from 'i18n/translator'
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Select from "react-select";
import classnames from "classnames";
import { EditLiteAccountRequest, getCountryListWithEntityRequest, getLiteAccountDetailsRequest, liteAccountEntityNameUpdateRequest, LiteAccountUpdateRequest, liteEntityStateListRequest, suspendLiteAccountRequest } from 'store/Lite/AccountDetails/actions';
import { withRouter } from 'react-router-dom';
import { makeLiteAccountSelectField } from 'store/Lite/AccountDetails/selector';
import LiteAccountDetailsReducer from 'store/Lite/AccountDetails/reducer';
import LiteAccountDetailsSaga from 'store/Lite/AccountDetails/saga';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import StateAccordion from './components/StateAccordian';
import AccountEntityAccordion from './components/AccountEntityAccordian';
import SeeMore from './components/SeeMore';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import CustomisedModal from 'components/Application/CustomisedModal';
import { getIndustryListByStatusRequest, getSubIndustryListByIndustryRequest, getSystemConfigurationUsersListRequest } from 'store/actions';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import { makeRoleSelectField } from 'store/Masters/Role/selector';
import { liteStatusOfEL, PaymentStatus, PlanList, TrailExtensionPeriodDuration } from 'constants/dropdowns';
import moment from "moment"
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import * as Yup from "yup";
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import { ACTIVE, ClientRegistrationValidationMessage, NO, restrictedDomains, SUCCESS, YES } from 'constants/commonConstants';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { LITE_PLAN_BASIC, LITE_PLAN_PREMIUM, MODULE_LITE_CLIENT_ACCOUNT_DETAILS, PERMISSION_EDIT, PERMISSION_VIEW, PLAN_TYPE, ROLE_SUPER_ADMIN, SUSPEND_TYPE } from 'constants/databaseConstants';
import { animatedComponents, checkModulePermission, MultiValue, Option } from 'helpers/projectUtils';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import { extractDomain } from 'helpers/litehelper';
import { element } from 'prop-types';

const accountDetailsKey = 'LiteAccountDetails';
const withAccountDetailsReducer = injectReducer({
    key: accountDetailsKey,
    reducer: LiteAccountDetailsReducer,
});
const withAccountDetailsSaga = injectSaga({ key: accountDetailsKey, saga: LiteAccountDetailsSaga });
const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const EntityDetails = [
    {
        Header: 'Financial Year Start',
        accessor: 'startMonth',
        disableSortBy: true,
    },
    {
        Header: 'Financial Year End',
        accessor: 'endMonth',
        disableSortBy: true,
    },

    {
        Header: 'Entity Type',
        accessor: 'entityTypeName',
        disableSortBy: true,
    },
    {
        Header: 'Facility Type',
        accessor: 'facilityTypeName',
        disableSortBy: true,
    },
    {
        Header: 'Industry',
        accessor: 'industryName',
        disableSortBy: true,
    },
    {
        Header: 'Sub Industry',
        accessor: 'subIndustryName',
        disableSortBy: true,
    },
    {
        Header: 'Management User',
        accessor: 'email',
        disableSortBy: true,
    },
    {
        Header: 'State/Province',
        accessor: 'stateName',
        disableSortBy: true,
    },
    {
        Header: 'Actions',
        // TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

export const initialValueEditAccountDetails= {
    userFullName:"",
    accountName:"",
    emailId:"",
    mobileNo:"",
    companyFullName:"",
    companyWebsite:"",
    industryId:[],
    subIndustryId:[]
}
class LiteAccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            currentPage: 1,
            pageCount: 10,
            totalRecords: 0,
            accountDetailsData: null,
            countryWiseEntity: null,
            planHistoryToggle:false,
            countryWiseEntityTable: null,
            showStateEntityWise:null,
            showState:false,
            exportSystemConfigurationUserList:[],
            editAccountDetailsFormikInitialValue:initialValueEditAccountDetails,
            selectedFile:null,
            paymentSelectedFile:null,
            addUserPopup:null,
            accountAttachmentFile:null,
            accountUserFileName:null,
            showTerminationModal:false,
            updateAccountLoader:false,
            planHistory:[],
            companyGroupId:null,
            downloadFileName: '',
            downloadFileUrl: '',
            isEditModeOn:false,
            formikInitialValue:null,
            initialValues:{
                email:""
            },
            roleId: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))),
            isRmUser:JSON.parse(decryptData(getValueByKey(authConstants.IS_RM_USER)))
        }
        this.fileInputRef = React.createRef(); // Create a ref for the file input
        this.accountFileInputRef = React.createRef(); // Create a ref for the file input
        this.handleButtonClick = this.handleButtonClick.bind(this)
    }
    componentDidMount() {
        this.props.getLiteAccountDetailsRequest({
            accountId: this.props?.match?.params?.accountId
        });
        if(this.state.roleId[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser){
            this.props.getSystemConfigurationUsersListRequest();
        }
    }

    generateTable = (records) => {
        let complianceRecords = [];
        complianceRecords = records && records.length > 0 ? records.map((complianceData, i) => {
            return {
                entityName: complianceData.entityName,
                entityId: complianceData.id,
                columns: EntityDetails,
                rows: complianceData.liteEntitySetupList && complianceData.liteEntitySetupList.length > 0 && complianceData.liteEntitySetupList.map((loc) => {
                    const facilityJoin = loc.liteEntityPropertyList && loc.liteEntityPropertyList.length > 0 && loc.liteEntityPropertyList.filter((ele) => ele.type == 1).map((fac) => fac.facilityType.facilityTypeName);
                    const subIndustryJoin = loc.liteEntityPropertyList && loc.liteEntityPropertyList.length > 0 && loc.liteEntityPropertyList.filter((ele) => ele.type == 2).map((fac) => fac.subIndustry?.subIndustryName);
                    const managementUser = complianceData.userRoles && complianceData.userRoles.length > 0 && complianceData.userRoles.map((ele)=>ele?.user?.email);
                    return {
                        countryId: this.state.countryId,
                        stateName: <Button type="button" color='primary' onClick={()=>{
                            this.setState({
                                showState:true
                            },()=>{
                                this.props.liteEntityStateListRequest({entitySetupId:loc.id})
                            })
                        }}>Show State</Button>,
                        startMonth: complianceData.entityFinancialYears && complianceData.entityFinancialYears.length > 0 && complianceData.entityFinancialYears[0]?.startMonth,
                        endMonth: complianceData.entityFinancialYears && complianceData.entityFinancialYears.length > 0 && complianceData.entityFinancialYears[0]?.endMonth,
                        email: <SeeMore value={managementUser} modalName="Management user" />,
                        industryName: loc.industry.industryName,
                        entityTypeName: loc.entityType.entityTypeName,
                        facilityTypeName:<SeeMore value={facilityJoin} length={3} modalName="Facility Type"/>  ,
                        subIndustryName: <SeeMore value={subIndustryJoin} length={3} modalName="Sub Industry"/>,
                        actions: <div className='d-flex'>
                                            <Button color='link' type='button' className='text-primary p-1' title='View'>
                                                <Link to={`/lite/account-details/${this.props?.match?.params?.accountId}/entity/${complianceData.id}/configured-entity/${loc.id}`}>
                                                    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="11.4997" cy="8.50261" r="3.84314" stroke="currentColor" strokeWidth="1.64706" /><path d="M21.341 7.61396C21.657 8.01392 21.8151 8.2139 21.8151 8.5C21.8151 8.7861 21.657 8.98608 21.341 9.38604C19.8529 11.2694 16.0082 15.5 11.5 15.5C6.99178 15.5 3.14715 11.2694 1.65899 9.38604C1.34295 8.98608 1.18493 8.7861 1.18493 8.5C1.18493 8.2139 1.34295 8.01392 1.65899 7.61396C3.14715 5.73062 6.99178 1.5 11.5 1.5C16.0082 1.5 19.8529 5.73062 21.341 7.61396Z" stroke="currentColor" strokeWidth="1.64706" /></svg>
                                                </Link>
                                            </Button>
                                </div>
                    }
                })
            }
        }):[];
        return complianceRecords;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.accountDetailsApiResponse && nextProps.accountDetailsApiResponse !== this.props.accountDetailsApiResponse) {
            if(nextProps.accountDetailsApiResponse.planCountryList && nextProps.accountDetailsApiResponse.planCountryList.length <= 0){
                if(this.state.roleId[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser){
                    // window.location.href= `/lite?tab=account_details`
                }else{
                    window.location.href=`/home`
                    return false;
                }
            }
            this.setState({
                accountDetailsData: nextProps.accountDetailsApiResponse.clientAccount,
                planHistory:nextProps.accountDetailsApiResponse.clientAccountHistories,
                countryList: nextProps.accountDetailsApiResponse.planCountryList,
                countryId:nextProps.accountDetailsApiResponse.planCountryList[0]?.id,
                companyGroupId:nextProps.accountDetailsApiResponse.clientAccount.companyGroupId,
                selectedFile:nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList && nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList.length > 0 && nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList[0]?.fileName ? {name:nextProps.accountDetailsApiResponse.clientAccount?.liteClientPlanList[0]?.fileName}:null,
                paymentSelectedFile:nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList && nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList.length > 0 && nextProps.accountDetailsApiResponse?.clientAccount?.liteClientPlanList[0]?.fileName ? {fileName:nextProps.accountDetailsApiResponse.clientAccount?.liteClientPlanList[0]?.fileName,systemFileName:nextProps.accountDetailsApiResponse.clientAccount?.liteClientPlanList[0]?.systemFileName}:null,
                accountAttachmentFile:nextProps.accountDetailsApiResponse.clientAccount?.fileName ? {name:nextProps.accountDetailsApiResponse.clientAccount?.fileName}:null,
                accountUserFileName:nextProps.accountDetailsApiResponse.clientAccount?.fileName ? {fileName:nextProps.accountDetailsApiResponse.clientAccount?.fileName,systemFileName:nextProps.accountDetailsApiResponse.clientAccount?.systemFileName}:null,
                activeTab: `${nextProps.accountDetailsApiResponse?.planCountryList[0]?.id}`,
            })
            this.props.getIndustryListByStatusRequest(ACTIVE)
            this.props.getSubIndustryListByIndustryRequest(nextProps.accountDetailsApiResponse.clientAccount?.industryId)
            this.props.getCountryListWithEntityRequest({
                countryId: nextProps.accountDetailsApiResponse.planCountryList[0]?.id,
                companyGroupId: nextProps.accountDetailsApiResponse.clientAccount.companyGroupId,
            })
        }
        if (nextProps.countryListWiseEntityApiResponse && nextProps.countryListWiseEntityApiResponse !== this.props.countryListWiseEntityApiResponse) {
            this.setState({
                countryWiseEntity: nextProps.countryListWiseEntityApiResponse.list,
                countryWiseEntityTable: this.generateTable(nextProps.countryListWiseEntityApiResponse.list)
            })
        }
        if(nextProps.liteEntityStateApiResponse && nextProps.liteEntityStateApiResponse !== this.props.liteEntityStateApiResponse){
            this.setState({
                showStateEntityWise:nextProps.liteEntityStateApiResponse.stateList
            })
        }

        if (
            nextProps.systemConfigurationUserList &&
            this.props.systemConfigurationUserList !==
            nextProps.systemConfigurationUserList
        ) {
            this.setState({
                exportSystemConfigurationUserList: nextProps.systemConfigurationUserList
                .filter(opt => opt.user !== null)
                .map(opt => ({
                    label:opt.user?.email,
                    value:opt.user?.id,
                })),
            })
        }
        if(nextProps.liteAccountUpdateApiResponse && nextProps.liteAccountUpdateApiResponse !== this.props.liteAccountUpdateApiResponse){
            toastrMessage(nextProps.liteAccountUpdateApiResponse.message, nextProps.liteAccountUpdateApiResponse.responseType)
            if(nextProps.liteAccountUpdateApiResponse.responseType == SUCCESS){
                this.props.getLiteAccountDetailsRequest({
                    accountId: this.props?.match?.params?.accountId
                });
            }
            this.setState({
                updateAccountLoader:false
            })
        }
        if(nextProps.liteSuspendAccountApiResponse && nextProps.liteSuspendAccountApiResponse !== this.props.liteSuspendAccountApiResponse){
            if(nextProps.liteSuspendAccountApiResponse.responseType == SUCCESS){
                this.showTerminationModalDismiss()
                this.props.getLiteAccountDetailsRequest({
                    accountId: this.props?.match?.params?.accountId
                });
            }
            toastrMessage(nextProps.liteSuspendAccountApiResponse.message, nextProps.liteSuspendAccountApiResponse.responseType)
        }
        if(nextProps.liteEntityUpdateNameApiResponse && nextProps.liteEntityUpdateNameApiResponse !== this.props.liteEntityUpdateNameApiResponse){
            if(nextProps.liteEntityUpdateNameApiResponse.responseType == SUCCESS){
                this.setState({
                    initialValues:{
                        email:""
                    }
                })
                this.onModalDismiss();
            }
            toastrMessage(nextProps.liteEntityUpdateNameApiResponse.message, nextProps.liteEntityUpdateNameApiResponse.responseType)
        }
        if(nextProps.liteEditAccount && nextProps.liteEditAccount !== this.props.liteEditAccount){
            if(nextProps.liteEditAccount.responseType == SUCCESS){
                this.setState({
                    isEditModeOn:false,
                    editAccountDetailsFormikInitialValue:initialValueEditAccountDetails
                },()=>{
                    this.props.getLiteAccountDetailsRequest({
                        accountId: this.props?.match?.params?.accountId
                    });
                })
            }
            toastrMessage(nextProps.liteEditAccount.message, nextProps.liteEditAccount.responseType)
        }
    }
    toggle(tab,countryId) {
        if (this.state.activeTab !== tab) {
            this.setState({
                    activeTab: tab,
                    countryId:countryId,
                },()=>{
                    this.props.getCountryListWithEntityRequest({
                        countryId: countryId,
                        companyGroupId: this.state.companyGroupId,
                    })
                });
        }
    }
    showStateModal(){
            this.setState({
                showState: false,
                showStateEntityWise:null
            })
    }
    togglePlanHistory(){
        this.setState((prevState) => ({
            planHistoryToggle: !prevState.planHistoryToggle,
        }));
    }

    formSubmit(values,state){
        state.setState({
            updateAccountLoader:true
        })
        const formData = new FormData();
        values ={
            ...values,
            liteClientId:this.props?.match?.params?.accountId,
        }
        const newValuesForRemarks = values.remarks;
        delete values.remarks;
        for (const property in values) {
            if(values[property] !== ""){
                values[property] = values[property] !== "" ? parseInt(values[property]):""
            }else{
                delete values[property];
            }
        }
        values ={
            ...values,
            remarks:newValuesForRemarks,
            paymentAttachmentFile:this.state.selectedFile,
            accountAttachmentFile:this.state.accountAttachmentFile
        }
        for(const field in values){
            if(field == "paymentAttachmentFile" || field == "accountAttachmentFile"){
                formData.append(field,values[field])
            }else if(field == "remarks"){
                formData.append("remarks",values[field])
            }else{
                formData.append(field,JSON.stringify(values[field]))
            }
        }
        this.props.LiteAccountUpdateRequest({apiData:formData,liteClientId:this.props?.match?.params?.accountId})
    }

    handleButtonClick = (e,type) => {
        if(type == "paymentAttachment"){
            this.fileInputRef.current.click(); // Trigger the file input click
        }else{
            this.accountFileInputRef.current.click()
        }
    };

    handleFileChange = (event,type) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            file.formattedSize = this.formatBytes(file.size)
            if(type == "paymentAttachment"){
                this.setState({
                    selectedFile:file
                })
            }else{
                this.setState({
                    accountAttachmentFile:file
                })
            }
        }
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };

    showTerminationModalDismiss(){
        this.setState({
            showTerminationModal: false,
        })
    }
    removeFiles(type){
        if(type == "paymentAttachment"){
            this.setState({
                selectedFile: this.state.accountDetailsData?.liteClientPlanList[0]?.fileName ?? null
            })
        }else{
            this.setState({
                accountAttachmentFile: this.state.accountDetailsData?.fileName ?? null
            })
        }
    }

    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.systemFileName
            })
        });
    };
    onModalDismiss = () => {
        this.setState({
            addUserPopup: null,
        });
    };
    EditAccountForm=(values)=>{
        values={
            ...values,
            mobileNo:values.mobileNo?.toString()
        }
        this.props.EditLiteAccountRequest(values)
    }
    suspendAccountForm=(values)=>{
        if(this.state?.accountDetailsData?.isAccountSuspend){
            this.props.suspendLiteAccountRequest({...values,isDataDeletion:1,accountId:this.state?.accountDetailsData?.id})
        }else{
            this.props.suspendLiteAccountRequest({...values,accountId:this.state?.accountDetailsData?.id})
        }
    }
    render() {
        const data2 = {
            columns: EntityDetails,
            rows: [],
        }
        const isEditMode = this.props?.match.url.includes("edit");
        const findRelationShipManager = this.props.exportSystemConfigurationUserList && this.props.exportSystemConfigurationUserList.length > 0 && this.props.exportSystemConfigurationUserList.find((ele)=>ele.value == this.state.accountDetailsData.relationshipManagerUserId)
        const designatedGTCGa = this.props.exportSystemConfigurationUserList && this.props.exportSystemConfigurationUserList.length > 0 && this.props.exportSystemConfigurationUserList.find((ele)=>ele.value == this.state.accountDetailsData.designatedGtCgaId)
        const selectedPlan  = this.state?.accountDetailsData?.liteClientPlanList && this.state?.accountDetailsData?.liteClientPlanList.length > 0 && this.state?.accountDetailsData?.liteClientPlanList[0]?.planId == LITE_PLAN_BASIC ? "Basic": this.state?.accountDetailsData?.liteClientPlanList?.length > 0 && this.state?.accountDetailsData?.liteClientPlanList[0]?.planId == LITE_PLAN_PREMIUM ? "Premium": "NA";
        const planStatus = this.state?.accountDetailsData?.liteClientPlanList && this.state?.accountDetailsData?.liteClientPlanList.length > 0 && this.state?.accountDetailsData?.liteClientPlanList[0]?.planStatus == 1 ? "Trail": this.state?.accountDetailsData?.liteClientPlanList && this.state?.accountDetailsData?.liteClientPlanList.length > 0 && this.state?.accountDetailsData?.liteClientPlanList[0]?.planStatus == 2 ? "Active": this.state?.accountDetailsData?.liteClientPlanList && this.state?.accountDetailsData?.liteClientPlanList.length > 0 && this.state?.accountDetailsData?.liteClientPlanList[0]?.planStatus == 3 ? "Expired":"NA";
        const statusOfEl = this.state?.accountDetailsData && this.state?.accountDetailsData?.paperworkStatus == 1 ? "Pending" : this.state?.accountDetailsData?.paperworkStatus == 2 ? "To do" : this.state?.accountDetailsData?.paperworkStatus == 3 ? "Executed" :"NA";
        const remarks = this.state.accountDetailsData?.remarks;
        const paymentAmount = this.state.accountDetailsData?.liteClientPlanList && this.state.accountDetailsData?.liteClientPlanList.length > 0 && this.state.accountDetailsData?.liteClientPlanList[0]?.amount;
        const trialExtentionDays = this.state.accountDetailsData?.liteClientPlanList  && this.state.accountDetailsData?.liteClientPlanList.length > 0 && this.state.accountDetailsData?.liteClientPlanList[0]?.trialExtentionDays;
        const paymentStatus = this.state.accountDetailsData?.liteClientPlanList && this.state.accountDetailsData?.liteClientPlanList.length > 0 && this.state.accountDetailsData?.liteClientPlanList[0]?.paymentStatus == 0 ? "Pending" :this.state.accountDetailsData?.liteClientPlanList && this.state.accountDetailsData?.liteClientPlanList.length > 0 && this.state.accountDetailsData?.liteClientPlanList[0]?.paymentStatus == 1 ? "Completed" :"NA";
        return (
            <Container fluid>
                <Row>
                    <Col lg="12">
                    {
                        this.state.roleId[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser ? <CommonBreadcrum type={'account-details'} accountName={this.state?.accountDetailsData?.companyGroupAdmin.userName ?? "-"} />: <CommonBreadcrum type={'account-details-lite-cga'} />
                    }
                        
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="page-header">
                            <h4 className="with-back-btn">
                                <Link
                                    to={`${this.state.roleId[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser ? "/lite?tab=account_details":"/home"}`}
                                    className="dripicons-arrow-thin-left"
                                // onClick={() => {
                                //     // this.props.hideComplianceList();
                                //     window.location.replace();
                                // }}
                                >
                                    { }
                                </Link>
                                <span>
                                    {this.state.roleId[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser ? __t("lite.account_details"):"Account Details"}
                                </span>
                            </h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader className='filter_section'>
                                <div className='page-header'>
                                    <h4>{__t("lite.basic_details")}</h4>
                                </div>
                                <div className='d-flex'>
                                    {
                                       (this.state.roleId[0].roleId == ROLE_SUPER_ADMIN  || this.state.isRmUser)&& !this.state?.accountDetailsData?.dataDeletionOnSuspension && !this.state.isEditModeOn ? <Button type='button' color="secondary" className='mr-2 text-nowrap ' outline onClick={()=>{
                                            this.setState((prev)=>{
                                                return {
                                                    isEditModeOn:!prev.isEditModeOn,
                                                    formikInitialValue:{
                                                        liteClientId:this.state.accountDetailsData.id,
                                                        userFullName:this.state?.accountDetailsData?.companyGroupAdmin?.fullName,
                                                        accountName:this.state?.accountDetailsData?.accountName,
                                                        emailId:this.state?.accountDetailsData?.companyGroupAdmin.email,
                                                        mobileNo:this.state?.accountDetailsData?.companyGroupAdmin.contactNo,
                                                        companyFullName:this.state?.accountDetailsData?.companyGroup.companyGroupName,
                                                        companyWebsite:this.state?.accountDetailsData?.companyWebsite,
                                                        industryId:this.state.accountDetailsData?.industryId,
                                                        subIndustryId:this.state.accountDetailsData.subIndustryId
                                                    }
                                                }
                                            },()=>{
                                            //    this.props.getIndustryListByStatusRequest(ACTIVE)
                                            //    this.props.getSubIndustryListByIndustryRequest(this.state.accountDetailsData?.industryId)
                                            })
                                        }}>
                                            {__t("lite.account_details_module.edit_approved_account")}
                                        </Button>:null
                                    }
                                    
                                  {this.state.planHistory && this.state.planHistory.length > 0 ?  <Button type='button' color="primary" className='text-nowrap '   outline onClick={()=>{
                                        this.setState({
                                            planHistoryToggle: true
                                        })
                                    }}>
                                        {__t("lite.account_details_module.plan_history")}
                                    </Button>:null}
                                   
                                    <CustomisedSideModal
                                        modalSize={
                                            'modal-lg modal-dialog-centered modal-dialog-scrollable side_slider plan-history-modal'
                                        }
                                        modalName={'Account History'}
                                        isModalOpen={this.state.planHistoryToggle}
                                        onModalDismiss={() => this.togglePlanHistory()}
                                    >
                                        <div className='plan-history-main'>
                                            {
                                                this.state.planHistory && this.state.planHistory.length > 0 && this.state.planHistory.map((element,index)=>{
                                                    return   <div className='planh-item' key={index}>
                                                                <div className='planh-header d-flex justify-content-between'>
                                                                    {
                                                                        element.type == SUSPEND_TYPE  ? <h4 className='m-0'>{element.isAccountSuspend && element.dataDeletionOnSuspension  ? "Account is Terminated" :element.isAccountSuspend ? "Account is Suspended": !element.isAccountSuspend && !element.dataDeletionOnSuspension ? "Account is Activated":"" }</h4> :<h4 className='m-0'>{element?.liteClientPlanDetails?.trialExtentionDays ? `Extended With Trail Period  ${element?.liteClientPlanDetails?.litePlan?.planName}` :element?.liteClientPlanDetails?.isTrialPlan ? `Start With Trail Period ${element?.liteClientPlanDetails?.litePlan?.planName}`:`Start With ${element?.liteClientPlanDetails?.litePlan?.planName}`}</h4>
                                                                    }
                                                                    
                                                                    {
                                                                        checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) && element?.liteClientPlanDetails?.fileName ? <Button type='button' className='btn btn-primary' onClick={()=>{
                                                                                this.saveFile(element?.liteClientPlanDetails);
                                                                        }}> 
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                          <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                                          <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                      </Button>:null
                                                                    }
                                                                </div>
                                                                <div className='d-flex'>
                                                                    {
                                                                        element.type == SUSPEND_TYPE ? <p className='mr-4'>{element.isAccountSuspend && element.dataDeletionOnSuspension ? "Terminated Date:":element.isAccountSuspend ? "Suspended Date:" :!element.isAccountSuspend && !element.dataDeletionOnSuspension ? "Activated Date:" :""}<span>{moment(element.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}</span></p> :<p className='mr-4'>{__t("lite.account_details_module.start_date")}:<span>{moment(element?.liteClientPlanDetails?.planStartDate, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}</span></p>
                                                                    }
                                                                    {
                                                                        element.type == PLAN_TYPE ?  <p>{__t("lite.account_details_module.end_date")}:<span>{moment(element?.liteClientPlanDetails?.planEndDate, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}</span></p>:null
                                                                    }
                                                                   
                                                                   {element.type == PLAN_TYPE && element?.liteClientPlanDetails?.trialExtentionDays ? <p className='ml-auto'>{__t("lite.account_details_module.extended_period")} :<span>{element?.liteClientPlanDetails?.trialExtentionDays} Days</span></p> :null }
                                                                </div>
                                                                {
                                                                    element.type == SUSPEND_TYPE && element.suspensionRemarks ?  <p className='remark'>Remark:<span>{element.suspensionRemarks}</span></p>:null
                                                                }
                                                                {
                                                                    element.type == PLAN_TYPE ? <p className='amount'>{__t("lite.account_details_module.amount")}:<Badge>$ {element?.liteClientPlanDetails?.amount}</Badge></p>:null
                                                                }
                                                                
                                                    </div>
                                                })
                                            }
                                          
                                             {/* <div className='planh-item'>
                                                <div className='planh-header d-flex justify-content-between'>
                                                    <h4 className='m-0'>Extend with this Basic Plan</h4>
                                                    <Button type='button' className='btn btn-primary'> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                    <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    </Button>                                                  
                                                    
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='mr-4'>Start Date:<span>01/01/2022</span></p>
                                                    <p>End Date:<span>01/01/2023</span></p>
                                                </div>
                                                <p className='remark'>Remark:<span>Lorem Ipsum is that it has a more-or-less normal distribution of letters.</span></p>
                                                <p className='amount'>Amount:<Badge>$ 40.00</Badge></p>
                                            </div>
                                            <div className='planh-item'>
                                                <div className='planh-header d-flex justify-content-between'>
                                                    <h4 className='m-0'>Start with this Basic Plan</h4>
                                                    <Button type='button' className='btn btn-primary'> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                    <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    </Button>    
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='mr-4'>Start Date:<span>01/01/2022</span></p>
                                                    <p>End Date:<span>01/01/2023</span></p>
                                                    <p className='ml-auto'>Trail Period:<span>45 Days</span></p>
                                                </div>
                                                <p className='remark'>Remark:<span>Lorem Ipsum is that it has a more-or-less normal distribution of letters.</span></p>
                                                <p className='amount'>Amount:<Badge>$ 40.00</Badge></p>
                                            </div>  */}
                                        </div>
                                    </CustomisedSideModal>
                                </div>
                            </CardHeader>
                            {
                                this.state.isEditModeOn ? <CardBody>
                                        <Formik
                                            initialValues={this.state.isEditModeOn ? this.state.formikInitialValue : this.state.editAccountDetailsFormikInitialValue}
                                            enableReinitialize={true}
                                            validationSchema={
                                                Yup.object({
                                                userFullName: Yup.string()
                                                    .matches(/^[a-zA-Z\s]+$/, ClientRegistrationValidationMessage.USER_FULL_NAME.MATCH_MESSAGE)
                                                    .max(25, ClientRegistrationValidationMessage.USER_FULL_NAME.MAX_MESSAGE)
                                                    .required(ClientRegistrationValidationMessage.USER_FULL_NAME.REQUIRED_MESSAGE),
                                                accountName: Yup.string()
                                                    .matches(/^[a-zA-Z0-9\s]+$/, ClientRegistrationValidationMessage.ACCOUNT_NAME.MATCH_MESSAGE)
                                                    .max(25, ClientRegistrationValidationMessage.ACCOUNT_NAME.MAX_MESSAGE)
                                                    .required(ClientRegistrationValidationMessage.ACCOUNT_NAME.REQUIRED_MESSAGE),
                                                emailId: Yup.string()
                                                    .email(ClientRegistrationValidationMessage.EMAIL_ID.EMAIL_FORMAT_MESSAGE)
                                                    .matches(/@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/, ClientRegistrationValidationMessage.EMAIL_ID.MATCH_MESSAGE)
                                                    .test('not-restricted-domain', ClientRegistrationValidationMessage.EMAIL_ID.RESTRICTED_DOMAIN_MESSAGE, function (value) {
                                                        if (!value) return true; // Skip this validation if value is empty
                                                        const domain = value?.split('@')[1]?.toLowerCase(); // Extract the domain and make it lowercase
                                                        const splitWithDot = domain?.split(".") && domain?.split(".").length > 0 ? domain?.split(".")[0] : domain;
                                                        return !restrictedDomains?.includes(splitWithDot); // Check if domain is not in the restricted list
                                                    })
                                                    .required(ClientRegistrationValidationMessage.EMAIL_ID.REQUIRED_MESSAGE),
                                                mobileNo: Yup.string()
                                                    .matches(/^[0-9]{7,14}$/, ClientRegistrationValidationMessage.MOBILE_NUMBER.MATCH_MESSAGE)
                                                    .required(ClientRegistrationValidationMessage.MOBILE_NUMBER.REQUIRED_MESSAGE),
                                                companyFullName: Yup.string()
                                                    .matches(/^[a-zA-Z0-9\s]+$/, ClientRegistrationValidationMessage.COMPANY_FULL_NAME.MATCH_MESSAGE)
                                                    .max(25, ClientRegistrationValidationMessage.COMPANY_FULL_NAME.MAX_MESSAGE)
                                                    .required(ClientRegistrationValidationMessage.COMPANY_FULL_NAME.REQUIRED_MESSAGE),
                                                companyWebsite: Yup.string()
                                                    .url(ClientRegistrationValidationMessage.COMPANY_WEBSITE.URL_MESSAGE)
                                                    .matches(/^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/, ClientRegistrationValidationMessage.COMPANY_WEBSITE.MATCH_MESSAGE)
                                                    .required(ClientRegistrationValidationMessage.COMPANY_WEBSITE.REQUIRED_MESSAGE)
                                                    // matching the domain enter in company_website and email_id it should be same.
                                                    .test('match-email-domain', ClientRegistrationValidationMessage.COMPANY_WEBSITE.TEST_MESSAGE, function (value) {
                                                        const { emailId } = this.parent;
                                                        const emailDomain = extractDomain(emailId);
                                                        const websiteDomain = extractDomain(value);
                                                        return emailDomain && websiteDomain && emailDomain === websiteDomain;
                                                    }),
                                                industryId: Yup.array()
                                                    .min(1, "Industry is required")
                                                    .required("Industry is required"),
                                                subIndustryId: Yup.array()
                                                .min(1, "Sub Industry is required")
                                                .required("Sub Industry is required"),
                                                })
                                            }
                                            validateOnChange= {true}
                                            validateOnBlur={true}
                                            onSubmit={this.EditAccountForm}
                                        >
                                            {({ values, setFieldValue, errors, handleBlur,handleSubmit, touched, setTouched, isSubmitting, setFieldError, setFieldTouched, validateForm }) => {
                                                return <Form className="w-100">
                                                    <Row className='filter-form'>
                                                       <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="userFullName"
                                                                className="form-control"
                                                                value={values.userFullName}
                                                                placeholder="Enter User Full Name"
                                                            />
                                                            <Label className="form-label">
                                                                User Full Name
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.userFullName && <small className='error'>{errors.userFullName}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="accountName"
                                                                className="form-control"
                                                                value={values.accountName}
                                                                placeholder="Enter Account Name"
                                                            />
                                                            <Label className="form-label">
                                                                Account Name
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.accountName && <small className='error'>{errors.accountName}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="emailId"
                                                                className="form-control"
                                                                value={values.emailId}
                                                                type="email"
                                                                placeholder="Enter Email Id"
                                                            />
                                                            <Label className="form-label">
                                                                Email Id
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.emailId && <small className='error'>{errors.emailId}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="mobileNo"
                                                                type="number"
                                                                className="form-control"
                                                                value={values.mobileNo}
                                                                placeholder="Enter Mobile No"
                                                            />
                                                            <Label className="form-label">
                                                                Mobile No
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.mobileNo && <small className='error'>{errors.mobileNo}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="companyFullName"
                                                                className="form-control"
                                                                value={values.companyFullName}
                                                                placeholder="Enter Company Full Name"
                                                            />
                                                            <Label className="form-label">
                                                                Company Full Name
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.companyFullName && <small className='error'>{errors.companyFullName}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                name="companyWebsite"
                                                                className="form-control"
                                                                value={values.companyWebsite}
                                                                placeholder="Enter Company Website"
                                                            />
                                                            <Label className="form-label">
                                                                Company Website
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {errors.companyWebsite && <small className='error'>{errors.companyWebsite}</small>}
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                component={Select}
                                                                name="industryId[]"
                                                                className="form-control selectbox"
                                                                options={
                                                                    this.props.sortedIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                                                }
                                                                onChange={opt => {
                                                                    let industryId = opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                                    setFieldValue(
                                                                        'industryId',
                                                                        industryId
                                                                    );
                                                                    if (industryId != '') {
                                                                        this.props.getSubIndustryListByIndustryRequest([...industryId])
                                                                    } 
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={this.props.sortedIndustryListDropdown?.filter(
                                                                    option => values?.industryId.includes(option.value)
                                                                )}
                                                                components={{ Option, MultiValue, animatedComponents }}
                                                                isMulti
                                                                closeMenuOnSelect={true}
                                                                hideSelectedOptions={false}
                                                                key="industryId"
                                                            />
                                                            {
                                                                errors.industryId && touched.industryId && <small className='error'>{errors.industryId}</small>
                                                            }

                                                            <Label className="form-label">
                                                                {__t('form_labels.lite_entity.entity_setup.industry')}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup className='col-lg-3'>
                                                            <Field
                                                                component={CustomMultiSelect}
                                                                name="subIndustryId[]"
                                                                className="form-control selectbox"
                                                                options={
                                                                    this.props.sortedSubIndustryListDropdown?.filter((opt) => opt.value !== -1)
                                                                }
                                                                onChange={opt => {
                                                                    let subIndustry = opt
                                                                        ? opt.map(
                                                                            (x) =>
                                                                                x.value
                                                                        )
                                                                        : [];
                                                                    setFieldValue(
                                                                        'subIndustryId',
                                                                        subIndustry
                                                                    );
                                                                }}
                                                                value={this.props.sortedSubIndustryListDropdown?.filter(
                                                                    option => values?.subIndustryId?.includes(option.value)
                                                                )}
                                                                isMulti
                                                                onBlur={handleBlur}
                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                allowSelectAll={true}
                                                                components={{ Option, MultiValue, animatedComponents }}
                                                                key="subIndustryId"
                                                            />
                                                            {
                                                                errors.subIndustryId && touched.subIndustryId && <small className='error'>{errors.subIndustryId}</small>
                                                            }
                                                            <Label className="form-label">
                                                                {__t('form_labels.lite_entity.entity_setup.sub_industry')}
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup className="col-12 d-flex justify-content-end align-items-baseline mb-0">
                                                           <Button
                                                                type="reset"
                                                                color="secondary"
                                                                className='mr-3'
                                                                onClick={() => {
                                                                   this.setState({
                                                                     isEditModeOn:false,
                                                                   },()=>{
                                                                    this.props.getIndustryListByStatusRequest(ACTIVE)
                                                                    this.props.getSubIndustryListByIndustryRequest(this.state.accountDetailsData?.industryId)
                                                                   })
                                                                }}
                                                            >
                                                                {__t("common.cancel")}
                                                            </Button>
                                                            <Button
                                                                type="button"
                                                                onClick={(e)=>handleSubmit(e)} 
                                                                color='primary'
                                                                className='d-block'>
                                                                {__t("form_labels.lite_entity.update")}
                                                            </Button>
                                                        </FormGroup>
                                                    </Row>
                                                </Form>
                                            }}
                                        </Formik>
                                </CardBody> : <CardBody>
                                <div className='compliance-basic-details'>
                                    <div className='compliance-card border-0 p-0 m-0'>
                                        <div className='compliance-details'>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.user_full_name")}
                                                        </b>
                                                        <p>
                                                            {this.state?.accountDetailsData?.companyGroupAdmin?.fullName}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.account_name")}
                                                        </b>
                                                        <p>
                                                            {this.state?.accountDetailsData?.accountName}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("form_labels.login.email")}
                                                        </b>
                                                        <p>
                                                            {this.state?.accountDetailsData?.companyGroupAdmin.email}
                                                        </p>
                                                    </div>
                                                </li>

                                            </ul>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.mobile_number")}
                                                        </b>
                                                        <p>
                                                            {this.state?.accountDetailsData?.companyGroupAdmin.contactNo}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('lite.company_name')}
                                                        </b>
                                                        <p>
                                                            {this.state?.accountDetailsData?.companyGroup.companyGroupName}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.website")}
                                                        </b>
                                                        <p>
                                                            <a href={this.state?.accountDetailsData?.companyWebsite} className='text-decoration-underline' target='_blank'>
                                                                {this.state?.accountDetailsData?.companyWebsite}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </li>

                                            </ul>
                                            <ul className="basic-details">
                                               <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('lite.selectedPlan')}
                                                        </b>
                                                        <p>
                                                            {selectedPlan}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('lite.statusPlan')}
                                                        </b>
                                                        <p>
                                                            {planStatus}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('lite.statusEl')}
                                                        </b>
                                                        <p>
                                                            {statusOfEl}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="basic-details">
                                               <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('form_labels.laws.industry')}
                                                        </b>
                                                        <p>
                                                            {this.props.sortedIndustryListDropdown && this.props.sortedIndustryListDropdown.length > 0 ? this.props.sortedIndustryListDropdown.filter((ele)=> this.state.accountDetailsData?.industryId?.includes(ele.value)).map((ind)=>ind?.label).join(", "):"NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t('form_labels.laws.sub_industry')}
                                                        </b>
                                                        <p>
                                                            {this.props.sortedSubIndustryListDropdown  && this.props.sortedSubIndustryListDropdown.length > 0 ? this.props.sortedSubIndustryListDropdown.filter((ele)=>this.state.accountDetailsData?.subIndustryId?.includes(ele.value)).map((subInd)=>subInd?.label).join(", "):"NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                            }
                            
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                    {
                        this.state.countryList && this.state.countryList.length > 0 ?  <Card>
                        <CardHeader className='filter_section'>
                        <div className='page-header'>
                            <h4>{__t("page_headers.lite_entity_details")}</h4>
                        </div>
                        </CardHeader>
                        <CardBody>
                            <Row id={"TabelView"}>
                                <Col lg={12} className="entitydetail_tabs tabs-main fix-header-table">
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        {
                                            this.state.countryList && this.state.countryList.length > 0 && this.state.countryList.map((element, index) => {
                                                return <NavItem key={index}>
                                                    <NavLink
                                                        className={classnames({
                                                            active:
                                                                this.state.activeTab === `${element.id}`,
                                                        })}
                                                        onClick={() => {
                                                            this.setState(() => {
                                                                this.toggle(`${element.id}`,element.id);
                                                            });
                                                        }}
                                                    >
                                                        {element?.countryName}
                                                    </NavLink>
                                                </NavItem>
                                            })
                                        }
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        {
                                            this.state.countryWiseEntityTable && this.state.countryWiseEntityTable.length > 0 ? this.state.countryWiseEntityTable.map((country,index) => {
                                                return <TabPane key={index} className="laws_tab custom_table table-responsive" tabId={`${country.rows[0].countryId}`}>
                                                    <AccountEntityAccordion
                                                        className="accordion cursor-pointer"
                                                        viewDialog={false}
                                                        OpenAddUserModel={()=>{
                                                            this.setState((prevState)=>{
                                                                return {
                                                                    ...prevState,
                                                                    addUserPopup: country.entityId
                                                                }
                                                            })
                                                        }}
                                                        isAccountTerminated={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                        initialValues={this.state.initialValues}
                                                        entityName={country.entityName}
                                                        liteAccountEntityNameUpdateRequest={this.props.liteAccountEntityNameUpdateRequest}
                                                        entityValue={{entityName:country.entityName}}
                                                        customizeModel={
                                                                <CustomisedModal
                                                                modalName={'Add User'}
                                                                isModalOpen={this.state.addUserPopup == country.entityId}
                                                                size
                                                                isSmall
                                                                onModalDismiss={() => this.onModalDismiss()}
                                                            >
                                                                <Formik
                                                                    initialValues={this.state.initialValues}
                                                                    onSubmit={async(values)=>{
                                                                        const response = await commonConfirmBox(
                                                                            __t('common.are_you_sure'),
                                                                            `${values?.email} will get onboarding email once assign any role.`
                                                                        );
                                                                        if(response){
                                                                            this.props.liteAccountEntityNameUpdateRequest({...values,entityId:country.entityId});
                                                                        }
                                                                    }}
                                                                    enableReinitialize={true}
                                                                    validationSchema={Yup.object({
                                                                        email: Yup.string().email(__t("lite.entity.emailId")).required("Email Id Is Required")
                                                                    })}
                                                                >
                                                                    {({ values,errors ,handleSubmit}) => {
                                                                        return <Form id="a">
                                                                                <FormGroup className="col-lg-12">
                                                                                            <Field
                                                                                                name={`email`}
                                                                                                type="email"
                                                                                                id="email"
                                                                                                className="form-control"
                                                                                                placeholder="Enter User Email..."
                                                                                            />
                                                                                            <Label className="form-label">
                                                                                                Enter Email
                                                                                            </Label>
                                                                                            {
                                                                                                errors.email && <small className='error'>{errors.email}</small>
                                                                                            }
                                                                                </FormGroup>
                                                                                <FormGroup className='justify-content-end d-flex'>
                                                                                        <Button color='primary' onClick={(e)=>handleSubmit(e)} className="ml-1">Add User</Button>
                                                                                </FormGroup>
                                                                        </Form>
                                                                    }}
                                                                </Formik>
                                                            </CustomisedModal>
                                                        }
                                                    >
                                                        <CustomisedReactTableWithPagination
                                                            className="table table-bordered table-striped"
                                                            displayEntries={
                                                                false
                                                            }
                                                            searching={false}
                                                            currentPage={
                                                                this.state.currentPage
                                                            }
                                                            isPaginated={false}
                                                            pageCount={
                                                                this.state.pageCount
                                                            }
                                                            tableData={country ?? data2}
                                                            totalRecords={this.state.totalRecords}
                                                        />
                                                    </AccountEntityAccordion>
                                                </TabPane>
                                            }):<p>No Entity Found</p>
                                        }


                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>:null
                    }
                      
                    </Col>
                </Row>
                {
                   this.state.isRmUser || checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) ? isEditMode ?   <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader className='filter_section'>
                                    <div className='page-header'>
                                        <h4>{__t("lite.edit_details")}</h4>
                                    </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={{
                                        relationshipManagerUserId: this.state.accountDetailsData?.relationshipManagerUserId ?? "",
                                        designatedGtCgaId: this.state.accountDetailsData?.designatedGtCgaId ?? "",
                                        paperworkStatus: this.state.accountDetailsData?.paperworkStatus ?? "",
                                        trialExtentionDays: this.state.accountDetailsData?.liteClientPlanList[0]?.trialExtentionDays ?? "",
                                        paymentStatus:this.state.accountDetailsData?.liteClientPlanList[0]?.paymentStatus ?? "",
                                        amount: this.state.accountDetailsData?.liteClientPlanList[0]?.amount ?? "",
                                        planId: this.state.accountDetailsData?.liteClientPlanList[0]?.planId ?? "",
                                        newPlanId:"",
                                        remarks:this.state.accountDetailsData?.remarks ?? "",
                                    }}
                                    validationSchema={Yup.object({
                                        relationshipManagerUserId:Yup.string().required(__t("lite.relationshipManagerUserId")),
                                        amount: Yup.string().required(__t("lite.amountRequired")),
                                    })}
                                    enableReinitialize={true}
                                    onSubmit={(e)=>this.formSubmit(e,this)}
                                >
                                    {({ values, setFieldValue, handleChange,errors,handleSubmit }) => {
                                       return <Form>
                                            <Row className="filter-form">
                                                {
                                                    !this.state.isRmUser || this.state.roleId[0].roleId == ROLE_SUPER_ADMIN ?  <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="relationshipManagerUserId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state.exportSystemConfigurationUserList.filter(option =>
                                                                option.value !== values.designatedGtCgaId
                                                            )
                                                        }
                                                        styles={{
                                                            menuPortal: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                        onChange={(opt)=>{
                                                            let relationshipManagerUserId =
                                                                opt
                                                                    ? opt?.value
                                                                    : "";
                                                            setFieldValue(
                                                                'relationshipManagerUserId',
                                                                relationshipManagerUserId
                                                            );
                                                        }}
                                                        value={this.state.exportSystemConfigurationUserList?.filter(
                                                            (option) =>
                                                                values.relationshipManagerUserId == option.value
                                                        )}
                                                        closeMenuOnSelect={true}
                                                    />
                                                    <Label className="form-label">
                                                        Relationship Manager<span className='text-danger required'>*</span>
                                                    </Label>
                                                    {
                                                        errors.relationshipManagerUserId ? <small className='error'>{errors.relationshipManagerUserId}</small>:null
                                                    }
                                                </FormGroup>:null
                                                }
                                               
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="designatedGtCgaId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.state.exportSystemConfigurationUserList.filter(option =>
                                                                option.value !== values.relationshipManagerUserId
                                                            )
                                                        }
                                                        styles={{
                                                            menuPortal: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                        onChange={(opt)=>{
                                                            let designatedGtCgaId =
                                                                opt
                                                                    ? opt?.value
                                                                    : "";
                                                            setFieldValue(
                                                                'designatedGtCgaId',
                                                                designatedGtCgaId
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        value={this.state.exportSystemConfigurationUserList?.filter(
                                                            (option) =>
                                                                values.designatedGtCgaId == option.value
                                                        )}
                                                        closeMenuOnSelect={true}
                                                    />
                                                    <Label className="form-label">
                                                        Designated GT CGA
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="paperworkStatus"
                                                        className="form-control selectbox"
                                                        options={
                                                            liteStatusOfEL
                                                        }
                                                        isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                        styles={{
                                                            menuPortal: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        onChange={(opt)=>{
                                                            let paperworkStatus =
                                                                opt
                                                                    ? opt?.value
                                                                    : "";
                                                            setFieldValue(
                                                                'paperworkStatus',
                                                                paperworkStatus
                                                            );
                                                        }}
                                                        value={liteStatusOfEL?.filter(
                                                            (option) =>
                                                                values.paperworkStatus == option.value
                                                        )}
                                                        closeMenuOnSelect={true}
                                                    />
                                                    <Label className="form-label">
                                                        Status of El
                                                    </Label>
                                                </FormGroup>
                                               
                                                
                                                <FormGroup className="col-lg-4">
                                                    <Field
                                                        component={Select}
                                                        name="newPlanId"
                                                        className="form-control selectbox"
                                                        options={
                                                            PlanList.filter((element)=>{
                                                                if(element.value == this.state?.accountDetailsData?.liteClientPlanList[0]?.planId){
                                                                    return false;
                                                                }else if((element.value == LITE_PLAN_PREMIUM || element.value == LITE_PLAN_BASIC) && (moment(this.state.accountDetailsData?.liteClientPlanList[0]?.planEndDate,"DD-MM-YYYY hh:mm:ss").format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD"))){
                                                                    return element
                                                                }else if((element.value == LITE_PLAN_PREMIUM || element.value == LITE_PLAN_BASIC) && (moment(this.state.accountDetailsData?.liteClientPlanList[0]?.planUnlockDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD"))){
                                                                    return false
                                                                }else{
                                                                    return element
                                                                }
                                                            })
                                                        }
                                                        styles={{
                                                            menuPortal: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        onChange={(opt)=>{
                                                            let plan =
                                                                opt
                                                                    ? opt?.value
                                                                    : "";
                                                            setFieldValue(
                                                                'newPlanId',
                                                                plan
                                                            );
                                                            if(opt?.value){
                                                                setFieldValue("paymentStatus","")
                                                                setFieldValue("amount","")
                                                                setFieldValue("trialExtentionDays","")
                                                            }else{
                                                                setFieldValue("paymentStatus",this.state.accountDetailsData?.liteClientPlanList[0]?.paymentStatus)
                                                                setFieldValue("amount",this.state.accountDetailsData?.liteClientPlanList[0]?.amount)
                                                                if(this.state.accountDetailsData?.liteClientPlanList[0]?.isTrialPlan){
                                                                    setFieldValue("trialExtentionDays",this.state.accountDetailsData?.liteClientPlanList[0]?.amount)
                                                                }
                                                            }
                                                            this.setState({
                                                                selectedFile:null
                                                            })
                                                        }}
                                                        isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension ? true : this.state.accountDetailsData?.liteClientPlanList[0]?.isTrialPlan ?  !(moment(this.state.accountDetailsData?.liteClientPlanList[0]?.planTrialExpireDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) : !(moment(this.state.accountDetailsData?.liteClientPlanList[0]?.planUnlockDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD"))}
                                                        value={PlanList?.filter(
                                                            (option) =>
                                                                values.newPlanId == option.value
                                                        )}
                                                        isClearable={true}
                                                        closeMenuOnSelect={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t("lite.account_details_module.new_plan")}
                                                    </Label>
                                                </FormGroup>
                                               
                                                {
                                                    !values.newPlanId && moment(this.state.accountDetailsData?.liteClientPlanList[0]?.planTrialExpireDate).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") && this.state.accountDetailsData?.liteClientPlanList[0].isTrialPlan ?  <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="trialExtentionDays"
                                                            className="form-control selectbox"
                                                            options={
                                                                TrailExtensionPeriodDuration
                                                            }
                                                            styles={{
                                                                menuPortal: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                            onChange={(opt)=>{
                                                                let trialExtentionDays =
                                                                    opt
                                                                        ? opt?.value
                                                                        : "";
                                                                setFieldValue(
                                                                    'trialExtentionDays',
                                                                    trialExtentionDays
                                                                );
                                                            }}
                                                            value={TrailExtensionPeriodDuration?.filter(
                                                                (option) =>
                                                                    values.trialExtentionDays == option.value
                                                            )}
                                                            closeMenuOnSelect={true}
                                                        />
                                                        <Label className="form-label">
                                                            Trial Extension
                                                        </Label>
                                                  </FormGroup>: null
                                                }
                                                <FormGroup className="col-lg-4">
                                                        <div className="d-flex gap-2">
                                                                <input
                                                                    name={`accountAttachmentFile`}
                                                                    type="file"
                                                                    className='d-none'
                                                                    ref={this.accountFileInputRef}
                                                                    disabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                                    onChange={(e)=>this.handleFileChange(e,"accountAttachment")}
                                                                />
                                                                <div className='d-flex align-items-center mr-2 fileinput-btn'>
                                                                    <Button color="primary" className=" d-flex align-items-center" type='button' onClick={(e)=>this.handleButtonClick(e,"accountAttachment")} >
                                                                                <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                                                    <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                </svg>
                                                                            {__t("lite.account_details_module.file_attachment")}
                                                                    </Button>

                                                                </div>
                                                                <div className="dropzone-previews" id="file-previews">
                                                                    {this.state.accountAttachmentFile ?   
                                                                        <Card className="shadow-none border dz-processing dz-image-preview dz-success dz-complete mb-0">
                                                                                <div className="px-2 py-1">
                                                                                    <div className="align-items-center d-flex">
                                                                                       
                                                                                            <p className="upload-document-title font-weight-bold m-0" style={{cursor:"pointer"}} onClick={()=>{
                                                                                                this.saveFile(this.state.accountUserFileName);
                                                                                            }}>{this.state.accountAttachmentFile.name}</p>
                                                                                            {this.state.accountAttachmentFile.formattedSize ? <p className="mb-0 mx-2"><strong>{this.state.accountAttachmentFile.formattedSize}</strong></p>:null}
                                                                                            {
                                                                                                this.state.accountAttachmentFile.formattedSize ? <Button
                                                                                                className="btn btn-danger btn-sm"
                                                                                                title="Delete file"
                                                                                                type='button'
                                                                                                onClick={() =>
                                                                                                this.removeFiles("accountAttachment")
                                                                                                }
                                                                                            >
                                                                                                <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                                </svg>
                                                                                            </Button>:null
                                                                                            }  
                                                                                        
                                                                                      
                                                                                    </div>
                                                                                </div>
                                                                        </Card>:null 
                                                                    }               
                                                            </div>
                                                        </div>
                                                </FormGroup>
                                                <FormGroup className="col-lg-12">
                                                                <Field
                                                                    component="textarea"
                                                                    name="remarks"
                                                                    disabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                                    className="form-control"
                                                                    placeholder={__t('lite.remarks')}
                                                                    value={values.remarks}
                                                                    maxLength={1000}
                                                                    minLength={3}
                                                                    rows="3"
                                                                />
                                                                <Label className="form-label">
                                                                    {__t('lite.remarks')}
                                                                </Label>
                                                </FormGroup>
                                            </Row>
                                            <div className='payment-status'>
                                                <div className='card'>
                                                    <CardHeader className='filter_section'>
                                                            <div className='page-header mb-2'>
                                                                <h4>{__t("lite.edit_plan_status")}</h4>
                                                            </div>
                                                    </CardHeader>
                                                    <Row className='mx-2 mb-3'>
                                                        <FormGroup className='col-6'>
                                                            <Field
                                                                component={Select}
                                                                name="paymentStatus"
                                                                className="form-control selectbox"
                                                                options={
                                                                    PaymentStatus
                                                                }
                                                                isDisabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                                styles={{
                                                                    menuPortal: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999,
                                                                    }),
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 9999,
                                                                    }),
                                                                }}
                                                                onChange={(opt)=>{
                                                                    let payment_status =
                                                                        opt
                                                                            ? opt?.value
                                                                            : "";
                                                                    setFieldValue(
                                                                        'paymentStatus',
                                                                        payment_status
                                                                    );
                                                                }}
                                                                value={PaymentStatus?.filter(
                                                                    (option) =>
                                                                        values.paymentStatus !== "" ? values.paymentStatus == option.value:null
                                                                )}
                                                                isClearable={true}
                                                                closeMenuOnSelect={true}
                                                            />
                                                            <Label className="form-label form-label-highlight">
                                                                {__t("lite.account_details_module.payment_status")}
                                                            </Label>
                                                            {
                                                                errors.paymentStatus && <small className='error'>{errors.paymentStatus}</small>
                                                            }
                                                        </FormGroup>
                                                        <FormGroup className='col-6'>
                                                            <Field
                                                                name={`amount`}
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                type="number"
                                                                disabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                                onChange={handleChange}
                                                                value={values.amount}
                                                            />
                                                            <Label className="form-label form-label-highlight">
                                                                {__t("lite.account_details_module.amount")}<span className='text-danger required'>*</span>
                                                            </Label>
                                                            {
                                                                errors.amount && <small className='error'>{errors.amount}</small>
                                                            }
                                                        </FormGroup>
                                                        <Col>
                                                            <input
                                                                name={`fileAttachment`}
                                                                type="file"
                                                                className='d-none'
                                                                ref={this.fileInputRef}
                                                                disabled={this.state?.accountDetailsData?.dataDeletionOnSuspension}
                                                                onChange={(e)=>this.handleFileChange(e,"paymentAttachment")}
                                                            />
                                                            <div className='d-flex align-items-center fileinput-btn'>
                                                                <Button color="primary" type='button' onClick={(e)=>this.handleButtonClick(e,"paymentAttachment")} className='d-flex align-items-center gap-1'>
                                                                    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                                                        <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                    {__t("lite.account_details_module.file_attachment")}
                                                                </Button>

                                                                <div className="dropzone-previews m-0" id="file-previews">
                                                                    {this.state.selectedFile ?   
                                                                        <Card className="shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                                                <div className="px-2 py-1 d-flex align-items-center">             
                                                                                        <p style={{margin:"0px",cursor:"pointer"}} onClick={()=>{
                                                                                                this.saveFile(this.state.paymentSelectedFile);
                                                                                            }} className="upload-document-title font-weight-bold">{this.state.selectedFile.name}</p>
                                                                                        {
                                                                                            this.state.selectedFile.formattedSize ? <p className="mb-0 mx-2"><strong>{this.state.selectedFile.formattedSize}</strong></p>:null
                                                                                        }
                                                                                    
                                                                                        {
                                                                                            this.state.selectedFile.formattedSize ?  <Button
                                                                                            className="btn btn-danger btn-sm"
                                                                                            title="Delete file"
                                                                                            type='button'
                                                                                            onClick={() =>
                                                                                            this.removeFiles("paymentAttachment")
                                                                                            }
                                                                                        >
                                                                                            <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                                            </svg>
                                                                                        </Button>:null
                                                                                        }
                                                                                </div>
                                                                        </Card>:null 
                                                                    }               
                                                                </div>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>
                                          
                                          
                                            <Row className='d-flex justify-content-between'>
                                                <Col lg="6">
                                                {
                                                    !this.state?.accountDetailsData?.dataDeletionOnSuspension ? <>
                                                    <Button color={`${this.state?.accountDetailsData?.isAccountSuspend ? "success" :"danger"}`} type='button' onClick={async()=>{
                                                         const response = await commonConfirmBox(
                                                            __t('common.are_you_sure'),
                                                            this.state?.accountDetailsData?.isAccountSuspend ?__t('lite.account_details_module.activate_account_message') :__t('lite.account_details_module.suspended_account_message')
                                                        );
                                                        if(this.state?.accountDetailsData?.isAccountSuspend){
                                                            if(response){
                                                                this.props.suspendLiteAccountRequest({
                                                                    isDataDeletion:0,
                                                                    isAccountSuspend:0,
                                                                    suspensionRemarks:"",
                                                                    accountId:this.state?.accountDetailsData?.id
                                                            })
                                                            }else{
                                                                this.setState({
                                                                    showTerminationModal:true
                                                                })
                                                            }
                                                        }else{
                                                            if(response){
                                                                this.setState({
                                                                    showTerminationModal:true
                                                                })
                                                            }
                                                        }
                                                    }}><svg className='mr-1' width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="8" cy="5" r="4" stroke="currentColor" stroke-width="1.5"/>
                                                        <circle cx="13" cy="17" r="4" stroke="currentColor" stroke-width="1.5"/>
                                                        <path d="M16 14L10 20" stroke="currentColor" stroke-width="1.5"/>
                                                        <path d="M10 19.8344C9.36629 19.9421 8.69497 20 8 20C4.13401 20 1 18.2091 1 16C1 13.7909 4.13401 12 8 12C9.71352 12 11.2832 12.3518 12.5 12.9359" stroke="currentColor" stroke-width="1.5"/>
                                                        </svg> 
                                                        {this.state?.accountDetailsData?.isAccountSuspend ? __t("lite.account_details_module.active_terminate_account") :__t("lite.account_details_module.suspension_account")}</Button>
                                                    <CustomisedModal
                                                        modalName={this.state?.accountDetailsData?.isAccountSuspend ? __t("lite.account_details_module.only_termination_account") : __t("lite.account_details_module.suspension_account")}
                                                        isModalOpen={this.state.showTerminationModal}
                                                        classNameHeader={"entity-state-modal"}
                                                        onModalDismiss={() =>
                                                            this.showTerminationModalDismiss()
                                                        }
                                                    >
                                                        <div>
                                                            <Formik 
                                                              initialValues={{
                                                                isDataDeletion:0,
                                                                isAccountSuspend:1,
                                                                suspensionRemarks:""
                                                              }}
                                                              validationSchema={Yup.object({
                                                                suspensionRemarks: Yup.string().required("Remark Is Required")
                                                            })}
                                                              onSubmit={this.suspendAccountForm}
                                                            >
                                                                {({values,setFieldValue,handleSubmit,errors})=>{
                                                                    return <Form>
                                                                        {
                                                                            !this.state?.accountDetailsData?.isAccountSuspend ? <FormGroup className="col-lg-12">
                                                                            <div>
                                                                                <p>{__t("lite.account_details_module.account_deletion_message")}</p>
                                                                                <div className='d-flex'>
                                                                                    <div className="form-check form-check-inline pr-2">    
                                                                                       <Field
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="isDataDeletion"
                                                                                            id="isDataDeletion1"
                                                                                            onChange={(opt) => {
                                                                                                opt.stopPropagation();
                                                                                                setFieldValue('isDataDeletion', 1);
                                                                                                setFieldValue('isAccountSuspend', 1);
                                                                                            }}
                                                                                            checked={values.isDataDeletion == 1 ? true : false}
                                                                                            // value={values.isDataDeletion}
                                                                                        />
                                                                                        <Label
                                                                                            className="form-check-label"
                                                                                            for="isDataDeletion1"
                                                                                        >
                                                                                            {YES}
                                                                                        </Label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-inline">    
                                                                                        <Field
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="isAccountSuspend"
                                                                                            id="isAccountSuspend"
                                                                                            onChange={(opt) => {
                                                                                                    setFieldValue('isAccountSuspend', 1);
                                                                                                    setFieldValue('isDataDeletion', 0);
                                                                                            }}
                                                                                            checked={values.isAccountSuspend ==1 && values.isDataDeletion == 0  ? true : false}
                                                                                            // value={values.isDataDeletion}
                                                                                        />
                                                                                        <Label
                                                                                            className="form-check-label"
                                                                                            for="isAccountSuspend"
                                                                                        >
                                                                                            {NO}
                                                                                        </Label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </FormGroup>:null
                                                                        }
                                                                            
                                                                            <FormGroup className="col-lg-12">
                                                                                <Field
                                                                                    component="textarea"
                                                                                    name="suspensionRemarks"
                                                                                    className="form-control"
                                                                                    placeholder={__t('lite.remarks')}
                                                                                    value={values.suspensionRemarks}
                                                                                    maxLength={1000}
                                                                                    minLength={3}
                                                                                    rows="3"
                                                                                />
                                                                                <Label className="form-label">
                                                                                    {__t('lite.remarks')}
                                                                                    <span className="text-danger required">
                                                                                        *
                                                                                    </span>
                                                                                </Label>
                                                                                {
                                                                                  errors.suspensionRemarks && <small className='error'>{errors.suspensionRemarks}</small>
                                                                                }
                                                                            </FormGroup>
                                                                            <FormGroup className="col-lg-12 d-flex justify-content-end">
                                                                                    <Button className='mx-3' type='button' onClick={(e)=>{
                                                                                        e.stopPropagation();
                                                                                        handleSubmit(e)
                                                                                    }} color="primary">Submit</Button>
                                                                                    <Button type="reset" onClick={()=>{
                                                                                        this.showTerminationModalDismiss()
                                                                                    }}>Cancel</Button>
                                                                            </FormGroup>
                                                                    </Form>
                                                                }}
                                                            </Formik>
                                                        </div>
                                                    </CustomisedModal>
                                                    </>:<Button type='button' isDisabled={true} color='danger'>{__t("lite.account_details_module.only_termination_account")}</Button>
                                                }
                                                    
                                                </Col>
                                                {
                                                    !this.state?.accountDetailsData?.dataDeletionOnSuspension ? <Col lg="6" className='d-flex justify-content-end'>
                                                    <Button type="button">Cancel</Button>
                                                    <Button className='mx-3' disabled={this.state.updateAccountLoader} onClick={(e)=>handleSubmit(e)} type='submit' color="primary">Update</Button>
                                                </Col>:null
                                                }
                                                    
                                            </Row>
                                        </Form>
                                    }}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>:   
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader className='filter_section'>
                                <div className='page-header'>
                                    <h4>{__t("lite.view_details")}</h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='compliance-basic-details'>
                                    <div className='compliance-card border-0 p-0 m-0'>
                                        <div className='compliance-details'>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.relationship_manger")}
                                                        </b>
                                                        <p>
                                                            {findRelationShipManager?.label ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.designated_gt_cga")}
                                                        </b>
                                                        <p>
                                                            {designatedGTCGa?.label ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.status_of_el")}
                                                        </b>
                                                        <p>
                                                            {statusOfEl ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>

                                            </ul>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.plan_name")}
                                                        </b>
                                                        <p>
                                                            {selectedPlan ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.trail_extension")}
                                                        </b>
                                                        <p>
                                                            {trialExtentionDays ?? ""} {trialExtentionDays ? "Days":"NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                </li>
                                            </ul>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.remark")}
                                                        </b>
                                                        <p>
                                                            {remarks ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.payment_status")}
                                                        </b>
                                                        <p>
                                                            {paymentStatus ?? "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("lite.amount")}
                                                        </b>
                                                        <p>
                                                            {paymentAmount ? paymentAmount : "NA"}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>:null
                }
                <CustomisedModal
                    modalName={"State/Province"}
                    isModalOpen={this.state.showState}
                    classNameHeader={"entity-state-modal"}
                    onModalDismiss={() =>
                        this.showStateModal()
                    }
                >
                     <p>
                        {this.state.showStateEntityWise && this.state.showStateEntityWise.length > 0 && this.state.showStateEntityWise.map((element, index) => {
                            return <span key={index}>{element?.stateName}</span>;
                        })}
                    </p>
                </CustomisedModal>
                 <DownloadFileByModel
                    modelType='plan'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                 />
            </Container>
        )
    }
}

const mapStatetoProps = createStructuredSelector({
    accountDetailsApiResponse: makeLiteAccountSelectField("accountDetailsApiResponse"),
    countryListWiseEntityApiResponse: makeLiteAccountSelectField("countryListWiseEntityApiResponse"),
    liteEntityUpdateNameApiResponse: makeLiteAccountSelectField("liteEntityUpdateNameApiResponse"),
    liteEntityStateApiResponse:makeLiteAccountSelectField("liteEntityStateApiResponse"),
    systemConfigurationUserList: makeRoleSelectField(
        'systemConfigurationUserList'
    ),
    liteAccountUpdateApiResponse:makeLiteAccountSelectField("liteAccountUpdateApiResponse"),
    liteSuspendAccountApiResponse:makeLiteAccountSelectField("liteSuspendAccountApiResponse"),
    liteEditAccount:makeLiteAccountSelectField("liteEditAccount"),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
});
const withConnect = connect(mapStatetoProps, {
    getLiteAccountDetailsRequest,
    getCountryListWithEntityRequest,
    liteAccountEntityNameUpdateRequest,
    EditLiteAccountRequest,
    liteEntityStateListRequest,
    getSystemConfigurationUsersListRequest,
    LiteAccountUpdateRequest,
    suspendLiteAccountRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
});

export default compose(
    withConnect,
    withAccountDetailsReducer,
    withAccountDetailsSaga,
    withRoleReducer,
    withRoleSaga,
    withIndustrySaga,
    withIndustryReducer,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withRouter
)(LiteAccountDetails);
