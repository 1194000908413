import React, { Component } from 'react';
import { Button, FormGroup, Label, Col, Row, Card } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    loginRequest,
    logoutRequest,
    sendCodeRequest,
    verifyTokenRequest,
    verifyCodeRequest
} from 'store/actions';
import { getValueByKey, isUserAuthenticated, setUserDetails, removeLoggedInUserDetails } from 'helpers/authUtils';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import reducer from 'store/Auth/Login/reducer';
import saga from 'store/Auth/Login/saga';
import { makeSelectField } from 'store/Auth/Login/selector';
import { createStructuredSelector } from 'reselect';
import { SUCCESS } from 'constants/commonConstants';
import logoLight from '../../images/logo-light.webp';
import logoDark from '../../images/logo-dark.webp';
import { toastrMessage } from 'helpers/messageHelper';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import __t from 'i18n/translator';
import * as authConstants from 'constants/authConstants';
import {
    CodeComponent,
} from 'components/Application/NumberFormat';
import OtpTimer from 'otp-timer';
import { compareString, isDarkMode } from 'helpers/generalUtils';
import Select from 'react-select';
import Login_slider from './LoginSlider';
import { MsalContext } from "@azure/msal-react";
import SSOWrappedComponent from './SSOWrappedComponent';
import { ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants';
import { decryptData } from 'helpers/encryptor';
import Axios from 'axios';
import LoginPageSlider from 'components/Application/LoginPageSlider';

const key = 'Login';
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

class Login extends Component {
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        if (props.location.query && props.location.query.isLogout) {
            // call logout API
            removeLoggedInUserDetails();
            this.props.logoutRequest();
        } else {
            if (isUserAuthenticated() === true) {
                if (getValueByKey(authConstants.SET_NEW_PASSWORD) === true) {
                    if (getValueByKey(authConstants.CURRENT_ROLE) == '') {
                        this.props.history.push('/coming-soon');
                    } else {
                        this.props.history.push('/home');
                    }
                } else {
                    this.props.history.push('/set-new-password');
                }
            }
        }
        this.state = {
            profile: null,
            isButtonDisabled: false,
            showOtpForm: false,
            showTenantForm: false,
            loginFormData: {
                email: '',
                userName: '',
                password: '',
                code: '',
                tenantId: ''
            },
            vToken: '',
            isSSO: false,
            sortedTenantListDropdown: []
        };
    }

    // fetchProfile = () => {
    //     const { instance, accounts } = this.context;
    //     const request = {
    //         ...loginRequest,
    //         account: accounts[0],
    //     };

    //     instance
    //         .acquireTokenSilent(request)
    //         .then((response) => {
    //             this.callMsGraph(response.accessToken).then((profile) => this.setState({ profile }));
    //         })
    //         .catch((error) => {
    //             if (error instanceof InteractionRequiredAuthError) {
    //                 instance.acquireTokenPopup(request).then((response) => {
    //                     this.callMsGraph(response.accessToken).then((profile) => this.setState({ profile }));
    //                 });
    //             }
    //         });
    // };

    // callMsGraph = async (accessToken) => {
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //     };

    //     const response = await Axios.get('https://graph.microsoft.com/v1.0/me', { headers });
    //     return response.data;
    // };

    // componentDidMount() {
    //     if (this.context.accounts.length > 0) {
    //         this.fetchProfile();
    //     }
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.context.accounts.length > 0 && !this.state.profile) {
    //         this.fetchProfile();
    //     }
    // }

    setLocalStorage = (token) => {
        localStorage.setItem(authConstants.LOGIN_TYPE, 'SSO');
        localStorage.setItem(authConstants.TOKEN, token);
        localStorage.setItem(authConstants.SET_NEW_PASSWORD, 'false');
        this.props.verifyTokenRequest('default', '');
    };

    sendOtpSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                isButtonDisabled: true,
                loginFormData: values,
            }, () => {
                this.props.sendCodeRequest(values.email, values.password);
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resendCode = () => {
        this.props.sendCodeRequest(
            this.state.loginFormData.email,
            this.state.loginFormData.password
        );
    };
    verifyCode = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                isButtonDisabled: true,
                loginFormData: { ...this.state.loginFormData, code: values.code }
            }, () => {
                this.props.verifyCodeRequest(
                    this.state.loginFormData.email,
                    this.state.loginFormData.password,
                    values.code
                );
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            if (this.state.isSSO) {
                this.props.verifyTokenRequest(
                    'tenant',
                    values.tenantId
                );
            } else {
                this.setState({
                    isButtonDisabled: true
                }, () => {
                    this.props.loginRequest(
                        this.state.loginFormData.email,
                        this.state.loginFormData.password,
                        this.state.loginFormData.code,
                        values.tenantId,
                        this.state.vToken
                    );
                })
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {

        if (
            nextprops.sendCodeApiResponse &&
            this.props.sendCodeApiResponse !== nextprops.sendCodeApiResponse
        ) {
            if (nextprops.sendCodeApiResponse.responseType === SUCCESS) {
                this.setState({
                    isButtonDisabled: false,
                    codeBtn: false,
                });
            } else {
                this.setState({
                    isButtonDisabled: false
                })
            }
        }
        if (
            nextprops.verifyCodeApiResponse &&
            this.props.verifyCodeApiResponse !== nextprops.verifyCodeApiResponse
        ) {
            if (nextprops.verifyCodeApiResponse.responseType === SUCCESS) {
                let tenantListDropdown = [];
                if (nextprops.verifyCodeApiResponse.responseData?.user?.hasGTRole) {
                    tenantListDropdown.push({
                        id: 0,
                        name: "Admin Module"
                    });
                }
                tenantListDropdown = [...tenantListDropdown, ...nextprops.verifyCodeApiResponse.responseData?.user?.tenantList]?.map((opt) => ({
                    value: opt.id,
                    label: opt.isLite ? `Lite - ${opt.name}` : opt.name
                })).sort((item1, item2) => compareString(item1.label, item2.label))

                if (tenantListDropdown.length == 1) {
                    this.props.loginRequest(
                        this.state.loginFormData.email,
                        this.state.loginFormData.password,
                        this.state.loginFormData.code,
                        tenantListDropdown[0].value,
                        nextprops.verifyCodeApiResponse.responseData.vToken
                    )
                } else {
                    this.setState({
                        vToken: nextprops.verifyCodeApiResponse.responseData.vToken,
                        isButtonDisabled: false,
                        showTenantForm: true,
                        sortedTenantListDropdown: tenantListDropdown
                    });
                }
            } else {
                toastrMessage(nextprops.verifyCodeApiResponse.message, nextprops.verifyCodeApiResponse.responseType);
                this.setState({
                    isButtonDisabled: false
                })
            }
        }
        if (
            nextprops.verifySSOTokenApiResponse &&
            this.props.verifySSOTokenApiResponse !== nextprops.verifySSOTokenApiResponse
        ) {
            if (nextprops.verifySSOTokenApiResponse.responseType === SUCCESS && nextprops.verifySSOTokenApiResponse.type == 'default') {
                let tenantListDropdown = [];
                if (nextprops.verifySSOTokenApiResponse.responseData?.user?.hasGTRole) {
                    tenantListDropdown.push({
                        id: 0,
                        name: "Admin Module"
                    });
                }
                tenantListDropdown = [...tenantListDropdown, ...nextprops.verifySSOTokenApiResponse.responseData?.user?.tenantList]?.map((opt) => ({
                    value: opt.id,
                    label: opt.isLite ? `Lite - ${opt.name}` :opt.name,
                })).sort((item1, item2) => compareString(item1.label, item2.label))
                if (tenantListDropdown.length == 1) {
                    this.props.verifyTokenRequest(
                        'tenant',
                        tenantListDropdown[0].value
                    )
                } else {
                    this.setState({
                        isSSO: true,
                        showTenantForm: true,
                        sortedTenantListDropdown: tenantListDropdown
                    }, () => {
                        this.validator.purgeFields();
                    });
                }
            } else {
                removeLoggedInUserDetails();
                toastrMessage(nextprops.verifySSOTokenApiResponse.message, nextprops.verifySSOTokenApiResponse.responseType);
            }
        }
        if (
            nextprops.loginApiResponse &&
            this.props.loginApiResponse !== nextprops.loginApiResponse
        ) {
            if (nextprops.loginApiResponse.responseType === SUCCESS) {

                // set user details to local storage in browser
                setUserDetails(nextprops.loginApiResponse.responseData);
                if (
                    nextprops.loginApiResponse.responseData.user
                        .issetPassword === false && nextprops.loginApiResponse.responseData.user.login_type == 'LC'
                ) {
                    window.location.replace('/set-new-password');
                } else {
                    toastrMessage(
                        nextprops.loginApiResponse.message,
                        nextprops.loginApiResponse.responseType
                    );
                    if (getValueByKey(authConstants.CURRENT_ROLE) == '') {
                        window.location.replace('/coming-soon');
                    } else {
                        const currentRole = JSON.parse(decryptData(nextprops?.loginApiResponse?.responseData?.user?.userRoles));
                        if (nextprops?.loginApiResponse?.responseData?.user?.isLite == 1 && currentRole[0].roleId === ROLE_COMPANY_GROUP_ADMIN) {
                            if (nextprops?.loginApiResponse?.responseData?.user?.isPlanSelected == 0) {
                                window.location.replace('/pricing');
                            } else if (nextprops?.loginApiResponse?.responseData?.user?.isEntityConfigured == 0) {
                                window.location.replace('/countries-list');
                            } else {
                                window.location.replace('/home');
                            }
                        } else {
                            window.location.replace("/home");
                        }
                    }
                }
            } else {
                this.setState({
                    isButtonDisabled: false
                }, () => {
                    removeLoggedInUserDetails();
                    toastrMessage(nextprops.loginApiResponse.message, nextprops.loginApiResponse.responseType);
                })
            }
        }
        if (
            nextprops.logoutApiResponse &&
            this.props.logoutApiResponse !== nextprops.logoutApiResponse
        ) {
            if (nextprops.logoutApiResponse.responseType === SUCCESS) {
                toastrMessage(nextprops.logoutApiResponse.message, nextprops.logoutApiResponse.responseType);
                removeLoggedInUserDetails();
            } else {
                removeLoggedInUserDetails();
            }
        }

        if (
            nextprops.sendCodeApiResponse &&
            this.props.sendCodeApiResponse !== nextprops.sendCodeApiResponse
        ) {
            toastrMessage(
                nextprops.sendCodeApiResponse.message,
                nextprops.sendCodeApiResponse.responseType
            );
            if (nextprops.sendCodeApiResponse.responseType === SUCCESS) {
                this.setState({
                    showOtpForm: true,
                });
            }
        }
    }
    // componentDidMount() {
    //     const { instance, accounts } = this.context;
    //     if (accounts.length > 0) {

    //     }
    // }
    render() {
        return (
            <React.Fragment>
                <div className="login-wrapper">
                    <LoginPageSlider />
                    <div className="wrapper-page">
                        <Row className="h-100 d-flex justify-content-center align-items-center login_form_row">
                            {/* <Col md="5" className='login_sub_slides'>

                            </Col> */}
                            <Col md="6" lg="6" xl="6" className="login-form">
                                <Card className="login_sub_slider">
                                    <h2 className='login_sub_heading'>Welcome to <span>GCMS Pro</span></h2>
                                    <div className='login_inner_slider'>
                                        <Login_slider />
                                    </div>
                                </Card>
                                <Card className="account-card">
                                    <div className="login-main">
                                        <div className="loging-head">
                                            <div className="logo logo-login">
                                                {isDarkMode() ? (
                                                    <img src={logoLight} alt="logo" />
                                                ) : (
                                                    <img src={logoLight} alt="logo" />
                                                )}
                                                {/* <h3>
                                                    {__t(
                                                        'common.app_name'
                                                    )}
                                                </h3> */}
                                            </div>
                                        </div>
                                        {!this.state.showOtpForm && !this.state.showTenantForm && (
                                            <Formik
                                                initialValues={
                                                    this.state.loginFormData
                                                }
                                                enableReinitialize={true}
                                                onSubmit={this.sendOtpSubmit}
                                            >
                                                {({
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form className="form-horizontal form-login">
                                                        <h3>
                                                            {__t(
                                                                'page_headers.login'
                                                            )}
                                                        </h3>

                                                        <FormGroup className="col-12">
                                                            <Field
                                                                autoFocus
                                                                name="email"
                                                                className="form-control"
                                                                type="text"
                                                                maxLength={50}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.login.email'
                                                                )}
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.login.email'
                                                                ),
                                                                values.email,
                                                                'required|email|max:150',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.login.email_required'
                                                                            ),
                                                                        email:
                                                                            __t(
                                                                                'validations.login.email_email'
                                                                            ),
                                                                        max: __t(
                                                                            'validations.login.email_max',
                                                                            {
                                                                                max: 150,
                                                                            }
                                                                        ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup className="col-12">
                                                            <Field
                                                                name="password"
                                                                type="password"
                                                                className="form-control"
                                                                autoComplete="on"
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.login.password'
                                                                )}
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.login.password'
                                                                ),
                                                                values.password,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.login.password_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>

                                                        <Col
                                                            lg="12"
                                                            className="mb-3 d-flex justify-content-between align-items-center"
                                                        >
                                                            <Link
                                                                to="/forgot-password"
                                                                className="forgot_password"
                                                            >
                                                                {__t(
                                                                    'form_labels.login.forgot_password'
                                                                )}
                                                            </Link>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Button
                                                                color="primary"
                                                                className="w-100 waves-effect waves-light"
                                                                type="submit"
                                                                disabled={this.state.isButtonDisabled}
                                                            >
                                                                {__t(
                                                                    'form_labels.login.send_code_btn'
                                                                )}
                                                            </Button>
                                                        </Col>
                                                        <Col
                                                            lg="12"
                                                            className="mb-0 d-flex justify-content-between align-items-center"
                                                        >
                                                            <SSOWrappedComponent setLocalStorage={(token) => {
                                                                this.setLocalStorage(token)
                                                            }} />
                                                            {/* {accounts.length > 0 && ( */}
                                                            {/* <span>{accounts[0].name}</span> */}
                                                            {/* <button onClick={this.handleLogout}>Logout</button> */}
                                                            {/* {profile && (
                                                                        <div>
                                                                            <p>Given Name: {profile.givenName}</p>
                                                                            <p>Surname: {profile.surname}</p>
                                                                            <p>Email: {profile.userPrincipalName}</p>
                                                                        </div>
                                                                    )} */}
                                                            {/* )} */}
                                                        </Col>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                        {this.state.showOtpForm && !this.state.showTenantForm && (
                                            <Formik
                                                initialValues={{ code: '' }}
                                                enableReinitialize={true}
                                                onSubmit={this.verifyCode}
                                            >
                                                {({
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form className="form-horizontal form-login">
                                                        <h3>
                                                            {__t(
                                                                'page_headers.enter_code'
                                                            )}
                                                        </h3>
                                                        <FormGroup className="col-12 verify_code_input mb-2">
                                                            <Field
                                                                as={
                                                                    CodeComponent
                                                                }
                                                                autoFocus={true}
                                                                digits={6}
                                                                name="code"
                                                                className=""
                                                                onChange={(
                                                                    opt
                                                                ) => {
                                                                    setFieldValue(
                                                                        'code',
                                                                        opt
                                                                    );
                                                                }}
                                                            />
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.login.code'
                                                                ),
                                                                values.code,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.login.code_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <Col
                                                            lg="12"
                                                            className="mb-2 d-flex justify-content-between resend-box align-items-center"
                                                        >
                                                            <OtpTimer
                                                                text="Resend in "
                                                                ButtonText={__t(
                                                                    'form_labels.login.resend_code'
                                                                )}
                                                                className="forgot_password"
                                                                seconds={59}
                                                                minutes={4}
                                                                // seconds={10}
                                                                textColor={
                                                                    '#344767'
                                                                }
                                                                buttonColor={
                                                                    '#000'
                                                                }
                                                                background={
                                                                    '#fff'
                                                                }
                                                                resend={() =>
                                                                    this.resendCode()
                                                                }
                                                            />
                                                        </Col>
                                                        <Col lg="12">
                                                            <Button
                                                                color="primary"
                                                                className="w-100 waves-effect waves-light"
                                                                type="submit"
                                                                disabled={this.state.isButtonDisabled}
                                                            >
                                                                {__t(
                                                                    'form_labels.login.verify_code_btn'
                                                                )}
                                                            </Button>
                                                        </Col>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}

                                        {this.state.showTenantForm && (
                                            <Formik
                                                initialValues={{ tenantId: '' }}
                                                enableReinitialize={true}
                                                onSubmit={this.formSubmit}
                                            >
                                                {({
                                                    values,
                                                    setFieldValue,
                                                }) => (
                                                    <Form className="form-horizontal form-login">
                                                        <h3>
                                                            {__t(
                                                                'page_headers.select_tenant'
                                                            )}
                                                        </h3>
                                                        <FormGroup className="col-12 tenant_input mb-2">
                                                            <Field
                                                                component={Select}
                                                                name="tenantId"
                                                                className="form-control selectbox p-0 border-0"
                                                                options={this.state.sortedTenantListDropdown}
                                                                onChange={(opt) => {
                                                                    setFieldValue(
                                                                        'tenantId',
                                                                        opt.value
                                                                    );
                                                                }}
                                                                value={this.state.sortedTenantListDropdown.filter(
                                                                    (option) =>
                                                                        option.value ===
                                                                        values.tenantId
                                                                )}
                                                            />
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.login.tenant'
                                                                ),
                                                                values.tenantId,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.login.tenant_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <Col lg="12">
                                                            <Button
                                                                color="primary"
                                                                className="w-100 waves-effect waves-light"
                                                                type="submit"
                                                                disabled={this.state.isButtonDisabled}
                                                            >
                                                                {__t(
                                                                    'form_labels.login.login_btn'
                                                                )}
                                                            </Button>
                                                        </Col>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                        <div className="text-center copy-right w-100">
                                            <p>
                                                © {new Date().getFullYear()}{' '}
                                                {__t('common.footer_text')}{' '}
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    loginApiResponse: makeSelectField('loginApiResponse'),
    logoutApiResponse: makeSelectField('logoutApiResponse'),
    sendCodeApiResponse: makeSelectField('sendCodeApiResponse'),
    verifyCodeApiResponse: makeSelectField('verifyCodeApiResponse'),
    verifySSOTokenApiResponse: makeSelectField('verifySSOTokenApiResponse')
});
const withConnect = connect(mapStatetoProps, {
    loginRequest,
    logoutRequest,
    sendCodeRequest,
    verifyTokenRequest,
    verifyCodeRequest
});
export default compose(withConnect, withReducer, withSaga, withRouter)(Login);
