import { isDarkMode } from 'helpers/generalUtils';
import { roundOffAmount } from 'helpers/mathHelper';

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const getMonthNameWiseData = (data) => {
    let monthWiseData = [];
    monthNames.forEach((month, key) => {
        if (data.map((m) => m.monthNo).includes(key + 1)) {
            monthWiseData.push(
                data.find((m) => m.monthNo === key + 1).compliancesCount
            );
        } else {
            monthWiseData.push(0);
        }
    });
    return monthWiseData;
};

export const complianceAnalysis = (data1, data2) => {
    return {
        series: [
            {
                name: 'Compliances',
                data: getMonthNameWiseData(data1),
            },
            {
                name: 'Updates',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            xaxis: {
                categories: monthNames,
            },
            colors: ['#9BD732', '#4F2D7F'],
        },
    };
};

export const complianceAnalysisByBarChart = (data1, data2) => {
    return {
        data: {
            labels: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Total Created',
                    data: getMonthNameWiseData(data1),
                    borderColor: 'rgb(79 45 127)',
                    backgroundColor: isDarkMode()
                        ? '#8568a5'
                        : 'rgb(79 45 127)',
                    tension: 0.4,
                    borderWidth: 0,
                    borderRadius: {
                        topLeft: 8,
                        topRight: 8,
                        bottomLeft: 0,
                        bottomRight: 0,
                    },
                    style: {
                        textShadow: false,
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    borderSkipped: false,
                    maxBarThickness: 25,
                },
                {
                    type: 'line',
                    label: 'Updates',
                    borderColor: isDarkMode()
                        ? '#5bbc7b'
                        : 'rgba(155, 215, 50, 1)',
                    borderWidth: 2,
                    fill: false,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    data: getMonthNameWiseData(data2),
                    style: {
                        textShadow: false,
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                },
            ],
        },
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: false,
                    text: 'Compliance Status - Overall',
                },
                datalabels: {
                    display: true,
                    color: isDarkMode() ? '#ced4da' : 'black', // Text color for the data labels
                },
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        color: isDarkMode() ? '#264945' : '#e9ecef',
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                    },
                    ticks: {
                        align: 'center',
                        display: true,
                        padding: 10,
                        color: isDarkMode() ? '#dee2e6' : '#344767',
                        font: {
                            size: 12,
                            family: 'GT Walsheim Pro',
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        color: isDarkMode() ? '#264945' : '#e9ecef',
                        borderDash: [5, 5],
                    },
                    ticks: {
                        align: 'center',
                        display: true,
                        padding: 10,
                        color: isDarkMode() ? '#dee2e6' : '#4F2D7F',
                        font: {
                            size: 14,
                            family: 'GT Walsheim Pro',
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
            },
            layout: {
                autoPadding: false,
                padding: 1,
            },
        },
    };
};

export const regulatoryUpdatesChart = (data1, data2) => {
    return {
        labels: monthNames,
        datasets: [
            {
                label: 'Laws',
                data: getMonthNameWiseData(data1),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 4,
                    topRight: 4,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                backgroundColor: isDarkMode()
                    ? '#8568a5'
                    : 'rgba(79, 45, 127, 1)',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
            {
                label: 'Compliances',
                data: getMonthNameWiseData(data2),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 4,
                    topRight: 4,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(155 215 50)',
                backgroundColor: isDarkMode() ? '#5bbc7b' : 'rgb(155 215 50)',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
        ],
    };
};

const getMonthWiseData = (monthNumber, data) => {
    let monthWiseData = [];

    monthNumber.map((month, key) => {
        if (data?.map((m) => m.taskMonth).includes(month)) {
            monthWiseData.push(
                data.find((m) => m.taskMonth === month).taskCount
            );
        } else {
            monthWiseData.push(0);
        }
    });
    return monthWiseData;
};

export const monthlyComplianceActivityBarChart = (
    monthNumber,
    data,
    data2,
    data3,
    data4
) => {
    return [
        {
            name: 'Overdue',
            data: getMonthWiseData(monthNumber, data),
        },
        {
            name: 'Delay Reporting',
            data: getMonthWiseData(monthNumber, data2),
        },
        {
            name: 'Upcoming Reporting',
            data: getMonthWiseData(monthNumber, data3),
        },
        {
            name: 'Closed',
            data: getMonthWiseData(monthNumber, data4),
        },
    ];
};

export const monthlyComplianceActivityBarChartOptions = (
    monthNumbers,
    monthLabels,
    type,
    clickEvent
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: true,
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            events: {
                click: clickEvent,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'top',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            color: isDarkMode() ? '#ffffff' : '#373d3f',
                            fontSize: '12px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
            },
        },
        xaxis: {
            categories: monthLabels,
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        colors: isDarkMode()
            ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
            : ['#DE002E', '#C24200', '#4F2D7F', '#9BD732'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '10px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            style: {
                fontSize: '10px',
                fontWeight: '400',
            },
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    };
};

export const departmentWiseGraphStackedOptions = (departments, clickEvent) => {
    return {
        chart: {
            departmentIds: departments?.map(
                (department) => department.departmentId
            ),
            type: 'bar',
            height: '100%',
            stacked: false,
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            events: {
                click: clickEvent,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                    },
                },
            },
        },
        xaxis: {
            categories: departments?.map(
                (department) => department.departmentName
            ),
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            floating: false,
            decimalsInFloat: false,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                formatter: function (val, index) {
                    return val?.toFixed(0);
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        colors: isDarkMode()
            ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
            : ['#DE002E', '#C24200', '#4F2D7F', '#9BD732'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            itemMargin: {
                horizontal: 3,
                vertical: 0,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabel(seriesName, opts);
            },
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            style: {
                fontSize: '10px',
                fontWeight: '400',
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val?.toFixed(0);
                },
            },
        },
    };
};
function calculateTotalAndLabel(seriesName, opts) {
    let totalValues = opts.w.globals.series[opts.seriesIndex].reduce((a, b) => {
        return a + b;
    }, 0);
    const labelCount =
        "<span class='legend-custom-text'>" + totalValues + '</span>';
    return [seriesName, ' ', labelCount];
}

export const departmentWiseGraphStackedSub = (data) => {
    return [
        {
            name: 'Overdue',
            data: data?.map((department) => department.overdueSubTasks),
        },
        {
            name: 'Delay Reporting',
            data: data?.map((department) => department.delaySubTasks),
        },
        {
            name: 'Upcoming Reportng',
            data: data?.map((department) => department.upcomingSubTasks),
        },
        {
            name: 'Closed',
            data: data?.map((department) => department.closedSubTasks),
        },
    ];
};

export const departmentWiseGraphStacked = (data) => {
    return [
        {
            name: 'Overdue',
            data: data?.map((department) => department.overdueTasks),
        },
        {
            name: 'Delay Reporting',
            data: data?.map((department) => department.delayTasks),
        },
        {
            name: 'Upcoming Reportng',
            data: data?.map((department) => department.upcomingTasks),
        },
        {
            name: 'Closed',
            data: data?.map((department) => department.closedTasks),
        },
    ];
};
export const lawCategoriesComplianceClassWiseGraphStacked = (data) => {
    return {
        labels: data?.map((x) => x?.lawCategoryName),
        datasets: [
            {
                label: 'One Time',
                data: data?.map((x) => x.counts.oneTimeCount),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                backgroundColor: '#4F2D7F',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
            {
                label: 'Event Based',
                data: data?.map((x) => x.counts.eventBasedCount),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                backgroundColor: '#9BD732',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
            {
                label: 'on Going',
                data: data?.map((x) => x.counts.onGoingCount),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                backgroundColor: '#00A7B5',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
            {
                label: 'Due Date',
                data: data?.map((x) => x.counts.dueDateCount),
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 4,
                    topRight: 4,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                backgroundColor: '#DE002E',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
            },
        ],
    };
};

export const statusWiseBarData = (data) => {
    return {
        labels: ['To Do', 'In Progress', 'Executed', 'Reopened'],
        datasets: [
            {
                data: [data[0], data[1], data[2], data[3]],
                tension: 0.4,
                borderWidth: 0,
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                borderSkipped: false,
                borderColor: 'rgb(0 167 181)',
                maxBarThickness: 20,
                categoryPercentage: 0.4,
                barPercentage: 1.0,
                backgroundColor: ['#4F2D7F', '#00A7B5', '#9BD732', '#DE002E'],
            },
        ],
    };
};
export const generateAnalysisOptionsGraph = (data, total, history) => {
    return {
        chart: {
            type: 'donut',
            width: '100%',
            height: 250,
            offsetX: 0,
            fontFamily: 'GT Walsheim Pro',
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    const label = config.selectedDataPoints[0];
                    if (label[0] === 0) {
                        // change in existing compliances
                        window.open(
                            '/relevant-updates/from-dashboard/update-type/1',
                            '_blank'
                        );
                    } else if (label[0] === 1) {
                        // due date change
                        window.open(
                            '/relevant-updates/from-dashboard/update-type/4',
                            '_blank'
                        );
                    } else if (label[0] === 2) {
                        // new added compliances
                        window.open(
                            '/relevant-updates/from-dashboard/update-type/6',
                            '_blank'
                        );
                    } else if (label[0] === 3) {
                        // deactivation of existing compliances
                        window.open(
                            '/relevant-updates/from-dashboard/update-type/5',
                            '_blank'
                        );
                    }
                },
            },
        },
        responsive: [
            {
                breakpoint: 1460,
                options: {
                    chart: {
                        // width: 330,
                        // height:"100%",
                    },
                    legend: {
                        position: 'bottom',
                        fontSize: '12px',
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                background: isDarkMode()
                                    ? '#0F1421'
                                    : '#EAE6F0',
                                labels: {
                                    name: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 1300,
                options: {
                    chart: {
                        // width: 300,
                        // height:"100%",
                    },
                    legend: {
                        position: 'bottom',
                        fontSize: '11px',
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                background: isDarkMode()
                                    ? '#0F1421'
                                    : '#EAE6F0',
                                labels: {
                                    name: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        plotOptions: {
            pie: {
                size: 200,
                expandOnClick: false,
                donut: {
                    size: '65%',
                    background: isDarkMode() ? '#30384e' : '#EAE6F0',
                    stroke: 'transparent',
                    strokeWidth: 0,
                    labels: {
                        show: true,
                        background: isDarkMode() ? '#2a3142' : '#ced4da',
                        name: {
                            show: 'label',
                            formatter: function (val, opts) {
                                var len = val.length;
                                var maxLabelLength = 12;
                                if (len > maxLabelLength) {
                                    return (
                                        val.substring(0, maxLabelLength - 2) +
                                        '...'
                                    );
                                } else {
                                    return val;
                                }
                            },
                        },
                        value: {
                            show: 'label',
                            color: isDarkMode() ? '#ced4da' : '#000',
                            fontSize: '25px',
                            fontWeight: 800,
                        },
                        total: {
                            show: true,
                            showAlways: false,
                            label: 'Total',
                            fontSize: '18px',
                            fontWeight: 600,
                            color: isDarkMode() ? '#ced4da' : '#000',
                            background: '#ddd',
                            lineHeight: 0.2,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Number(val).toFixed(2) + '%';
            },
            textAnchor: 'middle',
            distributed: false,
            style: {
                fontSize: '10px',
                fontWeight: '300',
            },
            dropShadow: {
                enabled: false,
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            // showForSingleSeries: false,
            // showForNullSeries: true,
            // showForZeroSeries: false,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            width: '50px',
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
        },
        labels: [
            'Change in Existing Compliances',
            'Due Date Change',
            'New Added Compliances',
            'De-activation of Existing Compliances',
        ],
        colors: isDarkMode()
            ? ['#8568a5', '#5bbc7b', '#00A7B5', '#f6732f']
            : ['#4F2D7F', '#9BD732', '#00A7B5', '#C24300'],
    };
};

// CGA + EA + Management
export const newComplianceUpdatesAnalysisByBarChart = (data1, data2) => {
    return {
        data: {
            labels: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
            datasets: [
                {
                    type: 'bar',
                    label: 'Mapped Compliance',
                    data: getMonthNameWiseData(data1),
                    borderColor: 'rgb(79 45 127)',
                    backgroundColor: isDarkMode()
                        ? '#8568a5'
                        : 'rgb(79 45 127)',
                    tension: 0.4,
                    borderWidth: 0,
                    borderRadius: {
                        topLeft: 8,
                        topRight: 8,
                        bottomLeft: 0,
                        bottomRight: 0,
                    },
                    style: {
                        textShadow: false,
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    borderSkipped: false,
                    maxBarThickness: 25,
                },
                {
                    type: 'line',
                    label: 'Updates',
                    borderColor: 'rgba(155, 215, 50, 1)',
                    borderWidth: 2,
                    fill: false,
                    pointStyle: 'circle',
                    style: {
                        textShadow: false,
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    data: getMonthNameWiseData(data2),
                },
            ],
        },
        options: {
            responsive: true,
            // maintainAspectRatio: false,
            plugins: {
                title: {
                    display: false,
                    text: 'Compliance Status - Overall',
                },
                datalabels: {
                    display: true,
                    color: 'black', // Text color for the data labels
                },
                legend: {
                    display: false,
                },
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: isDarkMode() ? '#343a40' : '#e9ecef',
                        borderColor: isDarkMode() ? '#dee2e6' : '#adb5bd',
                    },
                    ticks: {
                        align: 'center',
                        display: true,
                        padding: 10,
                        color: isDarkMode() ? '#dee2e6' : '#344767',
                        font: {
                            size: 12,
                            family: 'GT Walsheim Pro',
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: isDarkMode() ? '#343a40' : '#e9ecef',
                        borderColor: isDarkMode() ? '#dee2e6' : '#adb5bd',
                    },
                    ticks: {
                        align: 'center',
                        display: true,
                        padding: 10,
                        color: isDarkMode()
                            ? '#dee2e6'
                            : 'rgba(79, 45, 127, 1)#344767',
                        font: {
                            size: 14,
                            family: 'GT Walsheim Pro',
                            style: 'normal',
                            lineHeight: 2,
                        },
                    },
                },
            },
            layout: {
                autoPadding: false,
                padding: 1,
            },
        },
    };
};
export const areaOfLawOptions = (
    labels,
    lawCategoryIds,
    handleDataPointSelection
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: {
                show: false,
            },
            lawCategoryIds: lawCategoryIds,
            events: {
                click: handleDataPointSelection,
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value?.toFixed(0);
                },
            },
        },
        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '20px',
                dataLabels: {
                    position: 'center',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                    },
                },
            },
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            offsetY: 0,
            style: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                textShadow: false,
                fontSize: '12px',
                fontWeight: 400,
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        fill: {
            opacity: 1,
        },
        stroke: {
            show: false,
        },
        xaxis: {
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                show: true,
                style: {
                    colors: isDarkMode() ? '#dee2e6' : '#343a40',
                },
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
        },
        colors: isDarkMode() ? ['#3b739f'] : ['#BCE8EB'],
    };
};

export const stackedColumnsChart = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {

    return {
        tooltip: {
            colors: '#000',
            shared: false,
            intersect: true,
        },
        chart: {
            type: 'bar',
            height: '320',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            departmentId: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#343a40' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '11px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                colors: ['#fff'],
                fontSize: '11px',
                fontWeight: 400,
                textShadow: false,
            },
            offsetY: 2,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            // type: 'datetime',
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabel(seriesName, opts);
            },
        },
        colors: isDarkMode()
            ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
            : ['#DE002E', '#C24200', '#4F2D7F', '#9bd732'],
        fill: {
            colors: isDarkMode()
                ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
                : ['#DE002E', '#C24200', '#4F2D7F', '#9bd732'],
            opacity: 0.9,
            type: 'solid',
        },
    };
};

export const stackedColumnsChartForUpdates = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            dataTypeValueId: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                click: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                            colors: '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                color: '#000',
                fontSize: '12px',
                fontWeight: 400,
                textShadow: false,
            },
            // offsetY: -18,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        colors: ['#A796BF', '#89E4E4', '#CDEB98', '#EF8097'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            style: {
                colors: '#000',
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        fill: {
            opacity: 1,
        },
        yaxis: {
            floating: false,
            decimalsInFloat: false,
            labels: {
                formatter: function (val, index) {
                    return val?.toFixed(0);
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
        },
    };
};

export const yearComplianceActivityBarChart = (monthNumber, data, data2) => {
    return [
        {
            name: 'Delay',
            data: getMonthWiseData(monthNumber, data),
        },
        {
            name: 'Overdue',
            data: getMonthWiseData(monthNumber, data2),
        },
    ];
};

export const yearComplianceActivityBarChartOptions = (
    monthLabels,
    clickEvent
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: false,
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: {
                show: false,
                color: '#000',
            },
            zoom: {
                enabled: false,
            },
            events: {
                dataPointSelection: clickEvent,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                textShadow: false,
                fontSize: '12px',
                fontWeight: 400,
            },
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            categories: monthLabels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            show: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                formatter: function (value) {
                    return Math.round(value);
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        colors: isDarkMode() ? ['#f6732f', '#81192f'] : ['#F8961E', '#DE002E'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                let totalValues = opts.w.globals.series[
                    opts.seriesIndex
                ].reduce((a, b) => {
                    return a + b;
                }, 0);
                const labelCount =
                    "<span class='legend-custom-text'>" +
                    totalValues +
                    '</span>';
                return [seriesName, ' ', labelCount];
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            shared: true,
            intersect: false,
            style: {
                colors: '#000',
            },
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    };
};

export const expenseChartOptions = (
    departmentLabels,
    clickEvent,
    currencySymbol
) => {
    return {
        departmentLabels: departmentLabels,
        labels: departmentLabels.map((department) => department.departmentName),
        departmentId: departmentLabels.map(
            (department) => department.departmentId
        ),
        chart: {
            width: 380,
            type: 'donut',
            fontFamily: 'GT Walsheim Pro',
            events: {
                dataPointSelection: clickEvent,
            },
        },
        stroke: {
            colors: isDarkMode() ? ['#242c40'] : ['#fff'],
        },
        dataLabels: {
            enabled: false,
        },
        colors: isDarkMode()
            ? [
                '#8568a5',
                '#2D9CDB',
                '#5bbc7b',
                '#f6732f',
                '#F9C74F',
                '#90BE6D',
                '#f6732f',
                '#1FB1BE',
                '#81192f',
            ]
            : [
                '#4F2D7F',
                '#2D9CDB',
                '#9BD732',
                '#F8961E',
                '#F9C74F',
                '#90BE6D',
                '#C24200',
                '#1FB1BE',
                '#DE002E',
            ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return currencySymbol + ' ' + parseFloat(val).toFixed(2);
                },
                title: {
                    formatter: function (seriesName) {
                        return seriesName;
                    },
                },
            },
        },
        plotOptions: {
            pie: {
                size: 200,
                donut: {
                    size: '85%',
                    background: isDarkMode() ? '#242c40' : '#EAE6F0',
                    labels: {
                        show: true,
                        background: isDarkMode() ? '#2a3142' : '#ced4da',
                        name: {
                            show: 'label',
                        },
                        value: {
                            show: 'label',
                            color: isDarkMode() ? '#ced4da' : '#000',
                            fontSize: '25px',
                            fontWeight: 800,
                            formatter: function (val) {
                                return (
                                    currencySymbol +
                                    ' ' +
                                    parseFloat(val)?.toFixed(2)
                                );
                            },
                        },
                        total: {
                            show: true,
                            showAlways: false,
                            label: 'Total',
                            fontSize: '18px',
                            fontWeight: 600,
                            color: isDarkMode() ? '#ced4da' : '#000',
                            background: '#ddd',
                            lineHeight: 0.2,
                            formatter: function (w) {
                                let total = w.globals.seriesTotals.reduce(
                                    (a, b) => {
                                        return a + b;
                                    },
                                    0
                                );
                                return currencySymbol + ' ' + total.toFixed(2);
                            },
                        },
                    },
                },
            },
            polarArea: {
                rings: {
                    strokeWidth: 0,
                    strokeColor: isDarkMode() ? '#0F1421' : '#EAE6F0',
                },
                spokes: {
                    strokeWidth: 0,
                    connectorColors: isDarkMode() ? '#0F1421' : '#EAE6F0',
                },
            },
        },
        responsive: [
            {
                breakpoint: 1460,
                options: {
                    chart: {
                        width: 280,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    name: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 1300,
                options: {
                    chart: {
                        width: 200,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    name: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        legend: {
            show: false
        }
    };
};

export const expenseChartOptions1 = (
    departmentLabels,
    clickEvent,
    currencySymbol
) => {
    return {
        departmentLabels: departmentLabels,
        labels: departmentLabels.map((department) => department.departmentName),
        departmentId: departmentLabels.map(
            (department) => department.departmentId
        ),
        chart: {
            width: 380,
            type: 'donut',
            fontFamily: 'GT Walsheim Pro',
            events: {
                dataPointSelection: clickEvent,
            },
        },
        stroke: {
            colors: isDarkMode() ? ['#242c40'] : ['#fff'],
        },
        dataLabels: {
            enabled: false,
        },
        colors: isDarkMode()
            ? [
                '#8568a5',
                '#2D9CDB',
                '#5bbc7b',
                '#f6732f',
                '#F9C74F',
                '#90BE6D',
                '#f6732f',
                '#1FB1BE',
                '#81192f',
            ]
            : [
                '#4F2D7F',
                '#2D9CDB',
                '#9BD732',
                '#F8961E',
                '#F9C74F',
                '#90BE6D',
                '#C24200',
                '#1FB1BE',
                '#DE002E',
            ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return currencySymbol + ' ' + parseFloat(val).toFixed(2);
                },
                title: {
                    formatter: function (seriesName) {
                        return seriesName;
                    },
                },
            },
        },
        plotOptions: {
            pie: {
                size: 200,
                donut: {
                    size: '85%',
                    background: isDarkMode() ? '#242c40' : '#EAE6F0',
                    labels: {
                        show: true,
                        background: isDarkMode() ? '#2a3142' : '#ced4da',
                        name: {
                            show: 'label',
                        },
                        value: {
                            show: 'label',
                            color: isDarkMode() ? '#ced4da' : '#000',
                            fontSize: '25px',
                            fontWeight: 800,
                            formatter: function (val) {
                                return (
                                    currencySymbol +
                                    ' ' +
                                    parseFloat(val)?.toFixed(2)
                                );
                            },
                        },
                        total: {
                            show: true,
                            showAlways: false,
                            label: 'Total',
                            fontSize: '18px',
                            fontWeight: 600,
                            color: isDarkMode() ? '#ced4da' : '#000',
                            background: '#ddd',
                            lineHeight: 0.2,
                            formatter: function (w) {
                                let total = w.globals.seriesTotals.reduce(
                                    (a, b) => {
                                        return a + b;
                                    },
                                    0
                                );
                                return currencySymbol + ' ' + total.toFixed(2);
                            },
                        },
                    },
                },
            },
            polarArea: {
                rings: {
                    strokeWidth: 0,
                    strokeColor: isDarkMode() ? '#0F1421' : '#EAE6F0',
                },
                spokes: {
                    strokeWidth: 0,
                    connectorColors: isDarkMode() ? '#0F1421' : '#EAE6F0',
                },
            },
        },
        responsive: [
            {
                breakpoint: 1460,
                options: {
                    chart: {
                        width: 280,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    name: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '18px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 1300,
                options: {
                    chart: {
                        width: 200,
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    name: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    value: {
                                        fontSize: '16px',
                                        lineHeight: 0.1,
                                    },
                                    total: {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: 0.1,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        legend: {
            show: false
        }
    };
};
const calculateArraySum = (array) => {
    return array.reduce((a, b) => a + b, 0);
};
export const overallStatusChartOptions = (
    seriesData,
    handleDataPointSelection
) => {
    let overallStatusLabels = ['Compliant', 'Delay', 'Overdue', 'In Progress'];

    return {
        dataLabels: overallStatusLabels,
        labels: overallStatusLabels.map((data) => data),
        dataId: overallStatusLabels.map((data) => data),
        chart: {
            type: 'donut',
            fontFamily: 'GT Walsheim Pro',
            height: '100%',
            width: '100%',
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        stroke: {
            width: 0,
        },
        fill: {
            colors: isDarkMode()
                ? ['#5bbc7b', '#81192f', '#f6732f', '#8568a5']
                : ['#9bd732', '#DE002E', '#C24200', '#4F2D7F'],
        },
        colors: isDarkMode()
            ? ['#5bbc7b', '#81192f', '#f6732f', '#8568a5']
            : ['#9bd732', '#DE002E', '#C24200', '#4F2D7F'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            horizontalAlign: 'center',
            // floating: false,
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
            formatter: function (seriesName, opts) {
                const labelCount =
                    "<span class='legend-custom-text'>" +
                    seriesData[opts.seriesIndex] +
                    '</span>';
                return [seriesName, ' ', labelCount];
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            textAnchor: 'middle',
            style: {
                fontSize: '10px',
                fontWeight: '100',
            },
        },
        plotOptions: {
            pie: {
                // customScale: 1.1,
                size: 200,
                donut: {
                    size: '80%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontWeight: 600,
                            offsetY: -10,
                            formatter: function (val) {
                                return val;
                            },
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontWeight: 400,
                            offsetY: 16,
                            formatter: function (val) {
                                return val;
                            },
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: 0.1,
                            color: isDarkMode() ? '#ced4da' : '#4F2D7F',
                            formatter: function (w) {
                                return calculateArraySum(
                                    w.globals.seriesTotals
                                );
                            },
                        },
                    },
                },
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            fillSeriesColor: true,
            style: {
                fontSize: '12px',
            },

            onDatasetHover: {
                highlightDataSeries: true,
            },
        },
    };
};

export const entityGraphOptions = (
    labels,
    entityIds,
    handleDataPointSelection,
    groupedData
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            fontFamily: 'GT Walsheim Pro',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: {
                show: false,
            },
            entityIds: entityIds,
            events: {
                click: handleDataPointSelection,
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value?.toFixed(0);
                },
            },
        },
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: true,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                // columnWidth: '20px',
                barHeight: '20px',
                dataLabels: {
                    position: 'left',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'end', // Set the text anchor to start (left-aligned)
            offsetX: 0,
            style: {
                colors: ['#fff'],
                textShadow: false,
                fontSize: '12px',
                fontWeight: 400,
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex];
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff'],
        },
        fill: {
            opacity: 1,
        },
        xaxis: {
            categories: labels,
            axisBorder: {
                show: true,
                color: '#ece7f3',
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#ece7f3',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                show: false,
            },
        },
        colors: ['#00A7B5'],
    };
};

export const taskForEntityOptions = (
    labels,
    dataTypeValueIds,
    handleDataPointSelection
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: true,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            entityIds: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                click: handleDataPointSelection,
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                show: 'false',
                formatter: function (val) {
                    return val;
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '30px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    textAnchor: 'middle',
                    total: {
                        enabled: false,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                            colors: '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                colors: isDarkMode() ? ['#363650'] : ['#363650'],
                fontSize: '11px',
                fontWeight: 400,
                textShadow: false,
            },
            // offsetY: -18,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        // colors: isDarkMode()
        //     ? ['#8568a5', '#b9a6cb']
        //     : ['#4F2D7F', '#a796bf'],
        // colors: isDarkMode()
        //     ? ['#dfbf7b', '#ea9d75', '#56b6bd', '#accc74']
        //     : ['#ffd170', '#f8a377', '#71d3db', '#bce378'],
        colors: isDarkMode() ? ['#25aaf8', '#fcbf59', '#a891bf', '#accc74'] : ['#25aaf8', '#fcbf59', '#a891bf', '#add764'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            style: {
                colors: '#000',
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        fill: {
            opacity: 1,
        },
        yaxis: {
            floating: false,
            decimalsInFloat: false,
            labels: {
                formatter: function (val, index) {
                    return val?.toFixed(0);
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
        },
    };
};
export const taskForClassificationOptions = (
    labels,
    dataTypeValueIds,
    handleDataPointSelection
) => {
    return {
        chart: {
            // height: 'auto',
            type: 'radar',
            dropShadow: {
                enabled: true,
                blur: 2,
                left: 1,
                top: 1,
            },
            classificationIds: dataTypeValueIds,
            toolbar: {
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset:
                        false |
                        '<img src="/static/icons/reset.png" width="20">',
                    customIcons: [],
                },
                export: {
                    csv: {
                        filename: 'Types Of Compliances',
                        columnDelimiter: ',',
                        headerCategory: 'Types Of Compliances',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        },
                    },
                    svg: {
                        filename: 'Types Of Compliances',
                    },
                    png: {
                        filename: 'Types Of Compliances',
                    },
                },
            },
            events: {
                click: handleDataPointSelection,
            },
        },
        colors: isDarkMode()
            ? ['#f6732f', '#81192f', '#5bbc7b', '#86a5ff']
            : ['#f6732f', '#DE002E', '#9BD732', '#3f70fd'],
        labels: labels,
        stroke: {
            width: 1,
        },
        fill: {
            opacity: 0.3,
            colors: isDarkMode()
                ? ['#f6732f', '#81192f', '#5bbc7b', '#86a5ff']
                : ['#f6732f', '#DE002E', '#9BD732', '#3f70fd'],
        },
        tooltip: {
            intersect: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let tooltipHtml =
                    '<div class="risk_level-tooltip"><span class="tooltip-title">' +
                    (w.config.labels[dataPointIndex] != undefined
                        ? w.config.labels[dataPointIndex]
                        : '') +
                    '</span><ul>';
                if (w.config.series[0].data[dataPointIndex] != undefined) {
                    tooltipHtml +=
                        '<li class="risk_level"><span class="bg-Delay"></span>' +
                        w.config.series[0].name +
                        ': ' +
                        w.config.series[0].data[dataPointIndex] +
                        '</li>';
                }
                if (w.config.series[1].data[dataPointIndex] != undefined) {
                    tooltipHtml +=
                        '<li class="risk_level"><span class="bg-overdue"></span>' +
                        w.config.series[1].name +
                        ': ' +
                        w.config.series[1].data[dataPointIndex] +
                        '</li>';
                }
                tooltipHtml += '</ul></div>';
                return tooltipHtml;
            },
        },
        xaxis: {
            labels: {
                show: true,
                style: {
                    colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                    fontSize: '12px',
                    fontFamily: 'GT Walsheim Pro',
                },
                formatter: (value) => {
                    var len = value.length;
                    return len > 25 ? value.substring(0, 22) + '...' : value;
                },
            },
        },
        yaxis: {
            min: 0,
            forceNiceScale: true,
            decimalsInFloat: 0,
            labels: {
                show: true,
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                    fontSize: '10px',
                    fontFamily: 'GT Walsheim Pro',
                },
                formatter: (value) => {
                    var len = value.length;
                    return len > 25 ? value.substring(0, 22) + '...' : value;
                },
            },
        },
        legend: {
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
        },
    };
};

export const riskGraphOptions = (
    seriesData,
    handleDataPointSelectionForRisk
) => {
    let seriesPercentage = [0, 0, 0, 0];
    let riskTotals =
        seriesData[0] + seriesData[1] + seriesData[2] + seriesData[3];
    let risk1 = roundOffAmount((seriesData[0] / riskTotals) * 100);
    let risk2 = roundOffAmount((seriesData[1] / riskTotals) * 100);
    let risk3 = roundOffAmount((seriesData[2] / riskTotals) * 100);
    let risk4 = roundOffAmount((seriesData[3] / riskTotals) * 100);
    seriesPercentage = [risk1, risk2, risk3, risk4];
    return {
        seriesData: seriesData,
        seriesPercentage: seriesPercentage,
        options: {
            chart: {
                height: 420,
                type: 'radialBar',
                margin: 0,
                padding: 0,
                events: {
                    dataPointSelection: handleDataPointSelectionForRisk,
                },
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: true,
                fillSeriesColor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class=""><span><b style="color:' +
                        w.globals.colors[seriesIndex] +
                        '">' +
                        w.globals.labels[seriesIndex] +
                        ':</b> ' +
                        seriesData[seriesIndex] +
                        ' <small>(' +
                        series[seriesIndex] +
                        '%)</small></span></div>'
                    );
                },
            },
            plotOptions: {
                radialBar: {
                    offsetX: 0,
                    offsetY: 0,
                    hollow: {
                        margin: 5,
                        size: '40%',
                        background: 'transparent',
                        image: undefined,
                        imageWidth: 150,
                        imageHeight: 150,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        imageClipped: true,
                        position: 'front',
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5,
                        },
                    },
                    track: {
                        show: true,
                        startAngle: undefined,
                        endAngle: undefined,
                        background: isDarkMode()
                            ? [
                                '#81192f47',
                                '#f6732f1a',
                                '#ffcb5f1a',
                                '#8568a51a',
                            ]
                            : [
                                '#de002e24',
                                '#c242001a',
                                '#f8b42530',
                                '#4F2D7F1a',
                            ],
                        strokeWidth: '100%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5,
                        },
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val) {
                                return val + '%';
                            },
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: isDarkMode() ? '#ced4da' : '#4F2D7F',
                            formatter: function (w) {
                                return seriesData.reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
            colors: isDarkMode()
                ? ['#81192f', '#f6732f', '#ffcb5f', '#8568a5']
                : ['#DE002E', '#C24200', '#f8b425', '#4F2D7F'],
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                labels: {
                    colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                },
                onItemHover: {
                    highlightDataSeries: true,
                },
                formatter: function (seriesName, opts) {
                    const labelCount =
                        "<span class='mlegend-custom-text'>" +
                        seriesData[opts.seriesIndex] +
                        '</span>';
                    return [seriesName, ' ', labelCount];
                },
            },
            stroke: {
                lineCap: 'round',
            },
            labels: ['Critical', 'High', 'Moderate', 'Low'],
        },
    };
};

export const criticalRiskGraph = (
    seriesData,
    handleDataPointSelectionForCritical
) => {
    let seriesPercentage = [0, 0, 0, 0];
    let riskTotals =
        seriesData[0] + seriesData[1] + seriesData[2] + seriesData[3];
    let risk1 = roundOffAmount((seriesData[0] / riskTotals) * 100);
    let risk2 = roundOffAmount((seriesData[1] / riskTotals) * 100);
    let risk3 = roundOffAmount((seriesData[2] / riskTotals) * 100);
    let risk4 = roundOffAmount((seriesData[3] / riskTotals) * 100);
    seriesPercentage = [risk1, risk2, risk3, risk4];
    return {
        seriesData: seriesData,
        seriesPercentage: seriesPercentage,
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                events: {
                    dataPointSelection: handleDataPointSelectionForCritical,
                },
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: true,
                fillSeriesColor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class=""><span><b style="color:' +
                        w.globals.colors[seriesIndex] +
                        '">' +
                        w.globals.labels[seriesIndex] +
                        ':</b> ' +
                        seriesData[seriesIndex] +
                        ' <small>(' +
                        series[seriesIndex] +
                        '%)</small></span></div>'
                    );
                },
            },
            plotOptions: {
                radialBar: {
                    track: {},
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            color: isDarkMode() ? '#ced4da' : '#000',
                            formatter: function (val) {
                                return val + '%';
                            },
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: isDarkMode() ? '#ced4da' : '#000',
                            formatter: function (w) {
                                return seriesData.reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
            colors: isDarkMode()
                ? ['#5bbc7b', '#81192f', '#f6732f', '#f8b425']
                : ['#9BD732', '#DE002E', '#f6732f', '#f8b425'],
            legend: {
                show: true,
                showForSingleSeries: true,
                labels: {
                    colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                },
                onItemHover: {
                    highlightDataSeries: true,
                },
                formatter: function (seriesName, opts) {
                    const labelCount =
                        "<span class='legend-custom-text'>" +
                        seriesData[opts.seriesIndex] +
                        '</span>';
                    return [seriesName, ' ', labelCount];
                },
            },
            labels: ['Compliant', 'Overdue', 'Delay', 'In Progress'],
        },
    };
};

export const overallMGraphOptions = (
    seriesData1,
    handleDataOverallStatus
) => {
    let seriesPercentage = [0, 0, 0, 0];
    let riskTotals = seriesData1[0] + seriesData1[1] + seriesData1[2] + seriesData1[3];
    let risk1 = roundOffAmount((seriesData1[0] / riskTotals) * 100);
    let risk2 = roundOffAmount((seriesData1[1] / riskTotals) * 100);
    let risk3 = roundOffAmount((seriesData1[2] / riskTotals) * 100);
    let risk4 = roundOffAmount((seriesData1[3] / riskTotals) * 100);
    seriesPercentage = [risk1, risk2, risk3, risk4];
    return {
        seriesData1: seriesData1,
        seriesPercentage: seriesPercentage,
        options: {
            chart: {
                height: 420,
                type: 'radialBar',
                margin: 0,
                padding: 0,
                events: {
                    dataPointSelection: handleDataOverallStatus,
                },
                seriesData1: seriesData1
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: true,
                fillSeriesColor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class=""><span><b style="color:' +
                        w.globals.colors[seriesIndex] +
                        '">' +
                        w.globals.labels[seriesIndex] +
                        ':</b> ' +
                        seriesData1[seriesIndex] +
                        ' <small>(' +
                        series[seriesIndex] +
                        '%)</small></span></div>'
                    );
                },
            },
            plotOptions: {
                radialBar: {
                    offsetX: 0,
                    offsetY: 0,
                    hollow: {
                        margin: 5,
                        size: '40%',
                        background: 'transparent',
                        image: undefined,
                        imageWidth: 150,
                        imageHeight: 150,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        imageClipped: true,
                        position: 'front',
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5,
                        },
                    },
                    track: {
                        show: true,
                        startAngle: undefined,
                        endAngle: undefined,
                        background: isDarkMode()
                            ? [
                                '#a8d64c24',
                                '#f6732f1a',
                                '#81192f47',
                                '#8568a51a',
                            ]
                            : [
                                '#a8d64c33',
                                '#c242001a',
                                '#de002e24',
                                '#4F2D7F1a',
                            ],
                        strokeWidth: '100%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5,
                        },
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                            formatter: function (val) {
                                return val + '%';
                            },
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: isDarkMode() ? '#ced4da' : '#4F2D7F',
                            formatter: function (w) {
                                return seriesData1.reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
            colors: isDarkMode()
                ? ['#5bbc7b', '#f6732f', '#81192f', '#8568a5']
                : ['#9bd732', '#C24200', '#DE002E', '#4F2D7F'],
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                labels: {
                    colors: isDarkMode() ? ['#ced4da'] : ['#000'],
                },
                onItemHover: {
                    highlightDataSeries: true,
                },
                formatter: function (seriesName, opts) {
                    const labelCount =
                        "<span class='mlegend-custom-text'>" +
                        seriesData1[opts.seriesIndex] +
                        '</span>';
                    return [seriesName, ' ', labelCount];
                },
            },
            stroke: {
                lineCap: 'round',
            },
            labels: ['Compliant', 'Delay', 'Overdue', 'In Progress'],
        },
    };
};


export const barChartForEntityOnMDashboard = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {
    return {
        tooltip: {
            colors: '#000',
            shared: false,
            intersect: true,
        },
        chart: {
            type: 'bar',
            height: '320',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            entityIds: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#343a40' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '11px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                colors: ['#fff'],
                fontSize: '11px',
                fontWeight: 400,
                textShadow: false,
            },
            offsetY: 2,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            // type: 'datetime',
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabel(seriesName, opts);
            },
        },
        colors: isDarkMode()
            ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
            : ['#DE002E', '#C24200', '#4F2D7F', '#9bd732'],
        fill: {
            colors: isDarkMode()
                ? ['#81192f', '#f6732f', '#8568a5', '#5bbc7b']
                : ['#DE002E', '#C24200', '#4F2D7F', '#9bd732'],
            opacity: 0.9,
            type: 'solid',
        },
    };
};

export const barChartForRiskAudit = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {
    return {
        chart: {
            type: 'bar',
            height: '100%',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            dataTypeValueId: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                click: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#6c757d' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            fontWeight: 400,
                            colors: '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                color: '#000',
                fontSize: '12px',
                fontWeight: 400,
                textShadow: false,
            },
            // offsetY: -18,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        colors: ['#CDEB98', '#A796BF', '#EF8097', '#89E4E4'],
        // colors: ['#A796BF', '#89E4E4', '#CDEB98', '#EF8097'],
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            style: {
                colors: '#000',
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabel(seriesName, opts);
            },
        },
        fill: {
            opacity: 1,
        },
        yaxis: {
            floating: false,
            decimalsInFloat: false,
            labels: {
                formatter: function (val, index) {
                    return val?.toFixed(0);
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
        },
    };
};


export const barChartForEntityOnCGAEADashboard = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {
    return {
        tooltip: {
            colors: '#000',
            shared: false,
            intersect: true,
        },
        chart: {
            type: 'bar',
            height: '320',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            entityIds: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#343a40' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '11px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                colors: ['#fff'],
                fontSize: '11px',
                fontWeight: 400,
                textShadow: false,
            },
            offsetY: 2,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            // type: 'datetime',
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            showForSingleSeries:true,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabelForCGAEA(seriesName, opts);
            },
        },
        colors: isDarkMode()
            ? ['#8568a5', '#5bbc7b']
            : ['#4F2D7F', '#9bd732'],
        fill: {
            colors: isDarkMode()
                ? ['#8568a5', '#5bbc7b']
                : ['#4F2D7F', '#9bd732'],
            opacity: 0.9,
            type: 'solid',
        },
    };
};


export const barChartForUserOnCGAEADashboard = (
    labels,
    handleDataPointSelection,
    dataTypeValueIds
) => {
    return {
        tooltip: {
            colors: '#000',
            shared: false,
            intersect: true,
        },
        chart: {
            type: 'bar',
            height: '320',
            stacked: false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            entityIds: dataTypeValueIds,
            zoom: {
                enabled: false,
            },
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        grid: {
            show: true,
            borderColor: isDarkMode() ? '#343a40' : '#ece7f3',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                // borderRadius: 5,
                // borderRadiusWhenStacked: 'last',
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                    maxItems: 100,
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '11px',
                            fontWeight: 400,
                            color: isDarkMode() ? '#ced4da' : '#000',
                        },
                    },
                },
            },
        },
        dataLabels: {
            style: {
                colors: ['#fff'],
                fontSize: '11px',
                fontWeight: 400,
                textShadow: false,
            },
            offsetY: 2,
            formatter: function (val, opt) {
                return val !== 0 ? val : '';
            },
        },
        xaxis: {
            // type: 'datetime',
            categories: labels,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                color: isDarkMode() ? '#dee2e6' : '#adb5bd',
            },
            labels: {
                hideOverlappingLabels: true,
                trim: true,
                formatter: function (val) {
                    return val;
                },
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        yaxis: {
            show: true,
            showAlways: true,
            axisBorder: {
                show: true,
                color: isDarkMode() ? '#adb5bd' : '#adb5bd',
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                style: {
                    colors: isDarkMode() ? '#ced4da' : '#000',
                },
            },
        },
        legend: {
            show: true,
            showForSingleSeries:true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
                colors: isDarkMode() ? ['#ced4da'] : ['#000'],
            },
            fontSize: '12px',
            fontFamily: 'GT Walsheim Pro',
            fontWeight: 400,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
            formatter: function (seriesName, opts) {
                return calculateTotalAndLabelForCGAEA(seriesName, opts);
            },
        },
        colors: isDarkMode()
            ? ['#8568a5', '#81192f', '#f6732f']
            : ['#4F2D7F', '#DE002E', '#C24200',],
        fill: {
            colors: isDarkMode()
                ? ['#8568a5', '#81192f', '#f6732f']
                : ['#4F2D7F', '#DE002E', '#C24200',],
            opacity: 0.9,
            type: 'solid',
        },
    };
};


function calculateTotalAndLabelForCGAEA(seriesName, opts) {
    let totalValues = opts.w.globals.series[opts.seriesIndex].reduce((a, b) => {
        return a + b;
    }, 0);
    const labelCount =
        "<span class='cga-ea-dashboard legend-custom-text'>" + totalValues + '</span>';
    return [seriesName, ' ', labelCount];
}