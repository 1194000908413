import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    CANCEL,
    SUBMIT,
    ACTIVE
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { toastrMessage } from 'helpers/messageHelper';
import { createStructuredSelector } from 'reselect';
import {
    addNewTicketRequest,
    editTicketRequest,
    listTicketCategoryRequest,
    getTicketDetailsByIdRequest,
    listTicketAssigneeUsersRequest,
    getDepartmentListByStatusRequest,
    getUserEntityListRequest
} from 'store/actions';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import ticketSaga from 'store/Ticket/saga';
import ticketReducer from 'store/Ticket/reducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import { makeTicketSelectField, selectSortedTicketAssigneeUserList, selectSortedTicketCategoryList } from 'store/Ticket/selector';
import __t from 'i18n/translator';
import JoditEditor from 'jodit-react';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { redirectUrl } from 'helpers/generalUtils';
import ShowMoreText from "react-show-more-text";
import { TICKET_OPEN } from 'constants/databaseConstants';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import { customTicketChatFileValidator } from 'helpers/customValidators';
import Dropzone from 'react-dropzone';
import { userIsLite } from 'helpers/litehelper';

const ticketKey = 'ticket';
const withTicketReducer = injectReducer({
    key: ticketKey,
    reducer: ticketReducer,
});
const withTicketSaga = injectSaga({ key: ticketKey, saga: ticketSaga });
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const config = {
    minHeight: 200,
    spellcheck: true,
    askBeforePasteFromWord: false,
    limitChars: 60000,
    limitHTML: false,
    askBeforePasteHTML: false,
    useSearch: true,
    placeholder: 'Enter your description',
    buttons: ['bold', 'italic', 'underline', '|', 'ul', 'ol']
};
const initialTicketDataState = () => {
    return {
        title: '',
        ticketCategoryId: '',
        status: TICKET_OPEN, // open
        priority: 2, // Medium
        description: '',
        departmentId: '',
        entityId: ''
    }
}
class CreateSupportTicket extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
            ticketId: this.props.match.params.ticketId
                ? parseInt(this.props.match.params.ticketId)
                : '',
            pageHeaderName: this.props.match.params.ticketId
                ? __t('page_headers.ticket_edit')
                : __t('page_headers.ticket_add'),
            ticketData: initialTicketDataState(),
            selectedFiles: [],
            errors: '',
        };
    }
    componentDidMount() {
        this.props.listTicketCategoryRequest();
        this.props.getDepartmentListByStatusRequest(ACTIVE,userIsLite() ? 1:null);
        this.props.getUserEntityListRequest();
    }
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            const formData = new FormData();
            for (const [formKey, formVal] of Object.entries(values)) {
                formData.append(formKey, formVal);
            }
            this.state.selectedFiles.map((file) => {
                formData.append('attachmentFile', file);
            });
            this.props.addNewTicketRequest(formData);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    getShowMoreTextElement = (element) => {
        return <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >{`` + element + '\n'}</ShowMoreText>
    }
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file, key) => {
            if (!customTicketChatFileValidator(file.name)) {
                errorsArray.push(
                    __t('validations.custom.ticket_chat_file')
                );
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({
                errors:
                    __t('form_labels.support_tickets.attachment_file') +
                    ' ' +
                    __t('validations.custom.ticket_chat_file'),
            });
        } else {
            this.setState({ errors: '' });
        }
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFiles: [files[0]] });
    };
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };

    removeFile = (file) => {
        var array = [...this.state.selectedFiles]; // make a separate copy of the array
        var index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ selectedFiles: array }, () => {
                if (this.state.selectedFiles.length === 0) {
                    this.setState({
                        errors: '',
                    });
                }
            });
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.ticketApiResponse &&
            this.props.ticketApiResponse !== nextprops.ticketApiResponse
        ) {
            toastrMessage(
                nextprops.ticketApiResponse.message,
                nextprops.ticketApiResponse.responseType
            );
            if (nextprops.ticketApiResponse.responseType === SUCCESS) {
                this.props.history.push('/support-tickets');
            }
        }
        if (
            nextprops.ticketDetailsApiResponse &&
            this.props.ticketDetailsApiResponse !==
            nextprops.ticketDetailsApiResponse
        ) {
            this.setState({
                operationType: UPDATE,
                ticketData: {
                    ...nextprops.ticketDetailsApiResponse.data?.ticketDetails,
                },
            }, () => {
                this.validator.purgeFields();
                this.validator.hideMessages();
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    {
                        <Col lg="12">
                            <CommonBreadcrum
                                type={'add-support-ticket'}
                                edit={this.state.operationType == UPDATE}
                            />
                        </Col>
                    }
                    <Col lg="12">
                        <Formik
                            initialValues={this.state.ticketData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="news_edit_form">
                                    <div className="page-header">
                                        <h4 className="with-back-btn">
                                            <a
                                                href={() => false}
                                                className="dripicons-arrow-thin-left"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/support-tickets'
                                                    );
                                                }}
                                            >
                                                { }
                                            </a>{' '}
                                            {this.state.pageHeaderName}{' '}
                                        </h4>
                                    </div>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                {this.state.operationType == UPDATE && (
                                                    <Col>
                                                        <span className='text-secondary'>{' '}#{this.state.ticketData?.code}</span>
                                                        {(this.state.ticketData?.createdByUser) ? this.state.ticketData?.createdByUser.fullName : '-'}
                                                        {(this.state.ticketData?.tenant) ? this.state.ticketData?.tenant.name + '[' + this.state.ticketData?.tenant.code + ']' : '-'}
                                                    </Col>
                                                )}
                                                <FormGroup className="col-lg-6">
                                                    <Field
                                                        name="title"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.support_tickets.subject'
                                                        )}
                                                        value={values.title}
                                                        maxLength={255}
                                                        disabled={this.state.operationType == UPDATE}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.support_tickets.subject'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.support_tickets.subject'
                                                        ),
                                                        values.title,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.support_tickets.subject_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>

                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="entityId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props.sortedEntityListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            let entityId = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue('entityId', entityId);
                                                        }}
                                                        value={this.props.sortedEntityListDropdown?.filter(
                                                            (option) =>
                                                                values.entityId === option.value
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t('form_labels.support_tickets.entity')}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="departmentId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedDepartmentListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                'departmentId',
                                                                opt
                                                                    ? opt.value
                                                                    : ''
                                                            );
                                                        }}
                                                        value={this.props.sortedDepartmentListDropdown?.filter(
                                                            (option) =>
                                                                values.departmentId == option.value
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.support_tickets.department'
                                                        )}
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup className="col-lg-12 mb-0">
                                                    <div className='JoditEditor-border' dangerouslySetInnerHTML={{
                                                        __html: this.state.ticketData.description
                                                    }}></div>
                                                    {this.state.operationType == SUBMIT && (
                                                        <>
                                                            <JoditEditor
                                                                value={
                                                                    values.description
                                                                }
                                                                config={config}
                                                                onBlur={(
                                                                    newContent
                                                                ) => {
                                                                    setFieldValue(
                                                                        'description',
                                                                        newContent
                                                                    );
                                                                }}
                                                                maxLength={20000}
                                                                className="form-control"
                                                            />
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.support_tickets.description'
                                                                ),
                                                                values.description,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required: __t(
                                                                            'validations.support_tickets.description_required'
                                                                        ),                                                                        
                                                                        max: __t(
                                                                            'validations.support_tickets.description_max',
                                                                            {
                                                                                max: 20000,
                                                                            }
                                                                        ),
                                                                    },
                                                                }
                                                            )}
                                                        </>
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-12 mt-3">
                                                    <Label>
                                                        {__t(
                                                            'form_labels.support_tickets.attachment_file'
                                                        )}
                                                    </Label>
                                                    <Dropzone
                                                        onDrop={(acceptedFiles) =>
                                                            this.handleAcceptedFiles(
                                                                acceptedFiles
                                                            )
                                                        }
                                                    >
                                                        {({
                                                            getRootProps,
                                                            getInputProps,
                                                        }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input
                                                                        {...getInputProps()}
                                                                    />
                                                                    <h3>
                                                                        {__t('common.drag_drop')}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {this.state.errors != '' && (
                                                        <div className="srv-validation-message">
                                                            {this.state.errors}
                                                        </div>
                                                    )}
                                                    <div
                                                        className="dropzone-previews support-doc mt-2"
                                                        id="file-previews"
                                                    >
                                                        {this.state.selectedFiles.map(
                                                            (f, i) => {
                                                                return (
                                                                    <Card className="shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={
                                                                            i +
                                                                            '-file'
                                                                        }>
                                                                        <div>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {
                                                                                    f.name
                                                                                }
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>
                                                                                    {
                                                                                        f.formattedSize
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                        </div>
                                                                        <Button
                                                                            className="btn btn-danger btn-sm"
                                                                            title="Delete file"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                this.removeFile(
                                                                                    f
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg width="15" height="18" viewBox="0 0 15 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6 3.6H9C9 3.12261 8.84196 2.66477 8.56066 2.32721C8.27936 1.98964 7.89782 1.8 7.5 1.8C7.10218 1.8 6.72064 1.98964 6.43934 2.32721C6.15804 2.66477 6 3.12261 6 3.6ZM4.5 3.6C4.5 2.64522 4.81607 1.72955 5.37868 1.05442C5.94129 0.379285 6.70435 0 7.5 0C8.29565 0 9.05871 0.379285 9.62132 1.05442C10.1839 1.72955 10.5 2.64522 10.5 3.6H14.25C14.4489 3.6 14.6397 3.69482 14.7803 3.8636C14.921 4.03239 15 4.2613 15 4.5C15 4.73869 14.921 4.96761 14.7803 5.1364C14.6397 5.30518 14.4489 5.4 14.25 5.4H13.5885L12.924 14.706C12.8601 15.6046 12.5175 16.4417 11.9639 17.0517C11.4103 17.6617 10.6861 18.0001 9.9345 18H5.0655C4.31393 18.0001 3.58971 17.6617 3.03611 17.0517C2.48252 16.4417 2.13988 15.6046 2.076 14.706L1.4115 5.4H0.75C0.551088 5.4 0.360322 5.30518 0.21967 5.1364C0.0790176 4.96761 0 4.73869 0 4.5C0 4.2613 0.0790176 4.03239 0.21967 3.8636C0.360322 3.69482 0.551088 3.6 0.75 3.6H4.5ZM9.75 9C9.75 8.7613 9.67098 8.53239 9.53033 8.3636C9.38968 8.19482 9.19891 8.1 9 8.1C8.80109 8.1 8.61032 8.19482 8.46967 8.3636C8.32902 8.53239 8.25 8.7613 8.25 9V12.6C8.25 12.8387 8.32902 13.0676 8.46967 13.2364C8.61032 13.4052 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.4052 9.53033 13.2364C9.67098 13.0676 9.75 12.8387 9.75 12.6V9ZM6 8.1C6.19891 8.1 6.38968 8.19482 6.53033 8.3636C6.67098 8.53239 6.75 8.7613 6.75 9V12.6C6.75 12.8387 6.67098 13.0676 6.53033 13.2364C6.38968 13.4052 6.19891 13.5 6 13.5C5.80109 13.5 5.61032 13.4052 5.46967 13.2364C5.32902 13.0676 5.25 12.8387 5.25 12.6V9C5.25 8.7613 5.32902 8.53239 5.46967 8.3636C5.61032 8.19482 5.80109 8.1 6 8.1ZM3.57 14.553C3.60195 15.0025 3.77338 15.4211 4.05033 15.7262C4.32729 16.0312 4.68959 16.2003 5.0655 16.2H9.9345C10.3102 16.1998 10.6721 16.0305 10.9487 15.7255C11.2254 15.4206 11.3966 15.0022 11.4285 14.553L12.0825 5.4H2.9175L3.5715 14.553H3.57Z" fill="CurrentColor"/>
                                                                            </svg>
                                                                        </Button>
                                                                    </Card>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </FormGroup>
                                                <Col lg="12">
                                                    <FormGroup className="mt-2 mb-0 d-flex justify-content-end">
                                                        <div className="d-inline-block">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="mr-2"
                                                            >
                                                                {
                                                                    this.state
                                                                        .operationType
                                                                }
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    redirectUrl(
                                                                        this.props.history,
                                                                        '/support-tickets'
                                                                    );
                                                                }}
                                                            >
                                                                {CANCEL}
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    ticketApiResponse: makeTicketSelectField('ticketApiResponse'),
    ticketDetailsApiResponse: makeTicketSelectField('ticketDetailsApiResponse'),
    sortedTicketCategoryListDropDown: selectSortedTicketCategoryList(),
    sortedTicketAssigneeUserListDropDown: selectSortedTicketAssigneeUserList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    sortedEntityListDropdown: selectSortedEntityList()
});
const withConnect = connect(mapStatetoProps, {
    addNewTicketRequest,
    editTicketRequest,
    listTicketCategoryRequest,
    getTicketDetailsByIdRequest,
    listTicketAssigneeUsersRequest,
    getDepartmentListByStatusRequest,
    getUserEntityListRequest
});
export default compose(
    withConnect,
    withTicketReducer,
    withTicketSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withEntityReducer,
    withEntitySaga,
    withRouter
)(CreateSupportTicket);
