import React,{Component} from "react";
import noAccess from '../../images/no_access.webp';
import __t from "i18n/translator";

class NoAccess extends Component{
    render(){
        return <div className='w-100 d-flex align-items-center justify-content-center flex-column py-3 no_access'>
        <img src={noAccess} alt='No Access' style={{width: '30%'}} />
        <h2 className='text-primary w-100 text-center'>{this.props.isLite ? __t("lite.alert_plan_message_basic"):__t("lite.alert_plan_message_premium")}</h2>
        <p className='w-50 text h6 text-center'>Unlock limitless possibilities and access advanced analytics with a premium upgrade. Go beyond your limits with detailed reports and exclusive features.</p>
    </div>
    }
}

export default NoAccess;