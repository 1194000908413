import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    NavLink,
    NavItem,
    TabContent,
    TabPane,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Progress,
    CustomInput,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
    DATE_FORMAT_DATEPICKER, SUBMIT, SUCCESS, DANGER, UPDATE, ADD_SUB_TASK, DRAFT, PENDING_WITH_REVIEWER_STATUS, COMPLETED_FUND_STATUS, REJECTED_BY_REVIEWER, PENDING_WITH_APPROVER_STATUS, REJECTED_BY_APPROVER, RECURRING, ONE_TIME, TO_DO_FUND_STATUS, SEND_BACK, APPROVE_REVIEW,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedSideModal from 'components/Application/CustomisedSideModal';
import { initialTaskState, initialSubTaskState } from './CommonFunctions';
import {
    customDateValidator,
    getDateObjectWithValueFromMoment,
    formatDateForApi
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { frequencyDropdown, fundStatusList, taskPriorityStatusList } from 'constants/dropdowns';
import {
    addFundTaskRequest,
    updateFundTaskRequest,
    deleteFundSubTaskBySubTaskIdRequest,
    getFundTaskDetailsRequest,
    addFundTaskAttachmentRequest,
    getFundTaskActivityRequest,
    deleteFundTaskDocumentByDocumentIdRequest,
} from 'store/actions';
import { customFundTaskFileValidator } from 'helpers/customValidators';
import ContentEditable from 'react-contenteditable';
import { toastrMessage, commonConfirmBox } from 'helpers/messageHelper';
import { makeFundsSelectField } from 'store/Masters/Funds/selector';
import SubTaskForm from './SubTaskForm';
import TaskCommentForm from './TaskCommentForm';
import { getInitials } from 'helpers/generalUtils';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import { FUND_ACTIVITY_TYPE_COMMENT, PROJECT_TASK_APPROVER, PROJECT_TASK_REVIEWER, PROJECT_TASK_SUBMITTER } from 'constants/databaseConstants';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { isTenantAdminRole } from 'helpers/projectUtils';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import CommentDetails from './CommentDetails';
import ActivityDetails from './ActivityDetails';

class AddEditFundTaskModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customFundTaskFileValidator()),
        });
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.validator2 = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            downloadFileUrl: '',
            downloadFileName: '',
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskFormData: initialTaskState(),
            subTaskFormData: initialSubTaskState(),
            operationType: SUBMIT,
            subTaskForm: false,
            fundTaskAttchments: [],
            fundActivityTypes: [],
            taskActivityList: [],
            isPriority: false,
            isModalDismiss: true,
            fundTaskUsers: [],
            userId: '',
            activeTab: 'commentTab',
            actionId: '',
            subTask: false,
            subTaskToggleUserId: '',
            subTaskToggleReviewerUserId: '',
            subTaskId: '',
            fileProgress: 100,
            isFileProgress: false,
            parentFundTaskUsers: [],
            isStatus: false,
            selectedFiles: [],
            isSubTaskCompleted: false,
            fundTeamUsers: [],
            taskUserToggle: '',
            taskUser1Toggle: '',
            taskUserApproverToggle: '',
            viewOnlyAccess: false,
            fundOwnerUserIds: [],
            fundManagementUserIds: []
        };
        this.contentEditable = React.createRef();
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }
    subTaskEditDisable = (subTask) => {
        if (this.state.taskFormData.fundStatusId !== COMPLETED_FUND_STATUS) {
            let fundSubmitters = this.state.taskFormData.submitterId?.includes(this.state.loginUserId) || false;
            if (fundSubmitters || subTask.fundTaskUsers.filter((su) => su.userId === this.state.loginUserId).length > 0) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }
    handleChange = (evt, subTask) => {
        let fundSubmitters = this.state.fundTeamUsers.filter((e) => e.isSubmitter == 1 && e.userId === this.state.loginUserId);
        if (fundSubmitters.length > 0 || subTask.fundTaskUsers.filter((su) => su.userId === this.state.loginUserId).length > 0) {
            this.setState({ isModalDismiss: false });
            if (evt.target.innerHTML !== '') {
                let formValues = {
                    fundId: this.props.fundDetails.id,
                    taskTitle: evt.target.innerHTML,
                };
                this.props.updateFundTaskRequest(subTask.id, formValues);
            }
        } else {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
    };
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = async (values) => {
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        if (this.validator.allValid()) {
            let response = null;
            if (
                this.state.isSubTaskCompleted === true &&
                this.state.operationType == UPDATE &&
                values.fundStatusId == COMPLETED_FUND_STATUS
            ) {
                response = await commonConfirmBox(
                    __t('common.are_you_sure'),
                    __t('alerts.sub_task_close')
                );
            }
            if (response != null && !response) {
                return;
            }
            let formValues = {
                ...values,
                startDate: formatDateForApi(values.startDate),
                dueDate: values.dueDate
                    ? formatDateForApi(values.dueDate)
                    : null,
                fundId: this.props.fundDetails.id,
                entityId: this.props.selectedEntityId,
                fundMasterId: this.props.selectedFundMasterId,
            };
            if (this.state.operationType === UPDATE) {
                if (
                    this.props.taskType == 'task' &&
                    !this.state.subTask
                ) {
                    formValues.userData = [
                        { userType: 'submitter', userIds: formValues.submitterId },
                        { userType: 'reviewer', userIds: formValues.reviewerId },
                        { userType: 'approver', userIds: formValues.approverId },
                    ]
                }
                if (
                    this.props.taskType == 'subTask' ||
                    this.state.subTask
                ) {
                    formValues.userData = [
                        { userType: 'submitter', userIds: formValues.submitterId },
                        { userType: 'reviewer', userIds: formValues.reviewerId },
                    ]
                }
                delete formValues.id;
                this.props.updateFundTaskRequest(values.id, formValues);
            } else {
                let formData = new FormData();
                formData.append('dueDate', formValues.dueDate);
                formData.append('entityId', formValues.entityId);
                formData.append('fundMasterId', formValues.fundMasterId);
                formData.append('priority', formValues.priority);
                formData.append('fundId', formValues.fundId);
                formData.append('fundStatusId', formValues.fundStatusId);
                formData.append('startDate', formValues.startDate);
                formData.append('taskDescription', formValues.taskDescription);
                formData.append('taskTitle', formValues.taskTitle);
                formData.append('isDraft', formValues.isDraft);
                formData.append('taskType', formValues.taskType);
                formData.append('frequencyId', formValues.frequencyId);
                formData.append('isCopySubtasks', formValues.isCopySubtasks);
                if (
                    (this.props.taskType == 'task' &&
                        !this.state.subTask) ||
                    (this.props.taskType == '' && this.props.addNewTask == true)
                ) {
                    formValues.submitterId.forEach((userId) => {
                        formData.append('userId[]', userId);
                        formData.append('submitterId[]', userId);
                    });
                    formValues.reviewerId.forEach((userId) => {
                        formData.append('userId[]', userId);
                        formData.append('reviewerId[]', userId);
                    });
                    formValues.approverId.forEach((userId) => {
                        formData.append('userId[]', userId);
                        formData.append('approverId[]', userId);
                    });
                }
                if (
                    this.props.taskType == 'subTask' ||
                    this.state.subTask
                ) {
                    formValues.userId.forEach((userId) => {
                        formData.append('userId[]', userId);
                    });
                }
                this.state.selectedFiles.forEach((file) => {
                    formData.append('file', file);
                });
                this.props.addFundTaskRequest(formData);
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    getFundTaskDetailsByTaskId(taskId) {
        this.props.getFundTaskDetailsRequest(taskId);
    }
    getFundTaskActivityByTaskId(taskId) {
        this.props.getFundTaskActivityRequest(taskId);
    }
    onChangeDateInput = (selectedDate, subTask, type) => {
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        let formValues = '';
        this.setState({ isModalDismiss: false });
        if (type == 'startDate') {
            formValues = {
                fundId: this.props.fundDetails.id,
                startDate: formatDateForApi(selectedDate),
                taskTitle: subTask.taskTitle,
            };
        } else {
            formValues = {
                fundId: this.props.fundDetails.id,
                dueDate: formatDateForApi(selectedDate),
                taskTitle: subTask.taskTitle,
            };
        }
        this.props.updateFundTaskRequest(subTask.id, formValues);
    };
    onChangeUser = (userId, subTask, type) => {
        //this.props.fundDetails?.fundTeamUsers.filter(x => x.isSubmitter)[0].userId
        //subTask.fundTaskUsers.map(x=> x.id).includes(userId)
        let user = subTask.fundTaskUsers?.map(x => x.userId);
        let submitterUsersIds = subTask.fundTaskUsers?.filter(x => x.userType == PROJECT_TASK_SUBMITTER).map((u) => u.userId);
        let reviewerUsersIds = subTask.fundTaskUsers?.filter(x => x.userType == PROJECT_TASK_REVIEWER).map((u) => u.userId);

        if (!subTask.fundTaskUsers?.map(x => x.userId).includes(userId)) {
            user.push(userId);
            if (type === 'submitter') {
                submitterUsersIds.push(userId);
            }
            if (type === 'reviewer') {
                reviewerUsersIds.push(userId);
            }
        }
        //subTask.fundTaskUsers
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        this.setState({ isModalDismiss: false });
        let formValues = {
            fundId: this.props.fundDetails.id,
            userId: user,
            submitterId: submitterUsersIds,
            reviewerId: reviewerUsersIds,
            taskTitle: subTask.taskTitle,
            userData: [
                { userType: "submitter", userIds: submitterUsersIds },
                { userType: "reviewer", userIds: reviewerUsersIds },
            ]
        };
        this.props.updateFundTaskRequest(subTask.id, formValues);
    };
    onModalDismiss = () => {
        this.validator.hideMessages();
        this.validator2.hideMessages();
        this.setState({
            taskFormData: initialTaskState(),
            subTaskFormData: initialSubTaskState(),
            operationType: SUBMIT,
            subTaskForm: false,
            selectedFiles: [],
            fundTaskAttchments: [],
            taskActivityList: [],
            isPriority: false,
            isModalDismiss: true,
            fundTaskUsers: [],
            subTask: false,
        });
        this.closeSubTaskModal();
        this.props.onModalDismiss();
    };
    addSubTask() {
        this.setState({
            subTaskFormData: initialSubTaskState(),
            subTaskForm: true,
        });
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    deleteSubTask = async (subTaskId) => {
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.fund_subtask_delete')
        );
        if (response) {
            this.props.deleteFundSubTaskBySubTaskIdRequest(subTaskId);
        }
    };
    deleteTaskDocument = async (subTaskId) => {
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.document_delete')
        );
        if (response) {
            this.props.deleteFundTaskDocumentByDocumentIdRequest(subTaskId);
        }
    };
    closeSubTaskModal() {
        this.setState({
            subTaskFormData: initialSubTaskState(),
            subTaskForm: false,
        });
    }
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    };
    priorityColor(priority) {
        let priorityColor = '';
        switch (priority) {
            case 'Urgent':
                priorityColor = '#007bff';
                break;
            case 'High':
                priorityColor = '#dc3545';
                break;
            case 'Normal':
                priorityColor = '#28a745';
                break;
            case 'Low':
                priorityColor = '#ffc107';
                break;
            default:
                priorityColor = '#6c757d';
                break;
        }
        return priorityColor;
    }
    priorityToggle(value) {
        this.setState({
            isPriority: this.state.isPriority === value ? 0 : value,
        });
    }
    statusToggle(value) {
        this.setState({
            isStatus: this.state.isStatus === value ? 0 : value,
        });
    }
    subTaskUsersToggle(value) {
        this.setState({
            subTaskToggleUserId:
                this.state.subTaskToggleUserId === value ? 0 : value,
        });
    }
    subTaskReviewerUsersToggle(value) {
        this.setState({
            subTaskToggleReviewerUserId:
                this.state.subTaskToggleReviewerUserId === value ? 0 : value,
        });
    }
    selectSortedUserFilterArray(selectedUserId, values) {
        if (this.state.subTask) {
            return [selectedUserId];
        } else {
            const index = values.findIndex((item) => item === selectedUserId);
            const newArray = values;
            if (index !== -1) {
                newArray.splice(index, 1);
            } else {
                newArray.push(selectedUserId);
            }
            return newArray;
        }
    }
    selectedUserDisplayName(usersList, value) {
        let userName = usersList.find((user) => user.userId === value)?.user
            ?.fullName;
        if (userName) {
            return userName;
        } else {
            return '';
        }
    }
    getSubtaskUserName(subUsersList, type) {
        const subTaskUserIds = subUsersList.map(user => user.userId);
        const fundTeamUsers = this.props.fundDetails?.fundTeamUsers || [];
        const matchedUsers = fundTeamUsers.filter(teamUser => subTaskUserIds.includes(teamUser.userId));
        let userName = "";
        if (type === "reviewer") {
            userName = matchedUsers.find(user => user.isReviewer)?.user?.fullName || "";
        } else if (type === "submitter") {
            userName = matchedUsers.find(user => user.isSubmitter)?.user?.fullName || "";
        }
        return userName == "" ? "select " + type : userName;
    }
    taskUsersToggle(value) {
        this.setState({
            taskUserToggle: this.state.taskUserToggle === value ? false : true,
        });
    }
    taskUsers1Toggle(value) {
        this.setState({
            taskUser1Toggle:
                this.state.taskUser1Toggle === value ? false : true,
        });
    }
    taskUsersApproverToggle(value) {
        this.setState({
            taskUserApproverToggle:
                this.state.taskUserApproverToggle === value ? false : true,
        });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    if (this.props.taskId) {
                        this.getFundTaskDetailsByTaskId(this.props.taskId);
                    }
                }
            );
        }
    }
    actionToggle(taskId) {
        this.setState({
            actionId: this.state.actionId === taskId ? 0 : taskId,
        });
    }
    editSubTask(subTaskId) {
        this.setState({
            subTaskId: subTaskId,
            subTask: true,
        });
        this.getFundTaskDetailsByTaskId(subTaskId);
        this.getFundTaskActivityByTaskId(subTaskId);
    }
    statusColor(status) {
        let statusColor = '';
        switch (status) {
            case 1:
                statusColor = 'todo';
                break;
            case 2:
                statusColor = 'inprogress';
                break;
            case 3:
                statusColor = 'completed';
                break;
            case 4:
                statusColor = 'pending_with_reviewer';
                break;
            case 5:
                statusColor = 'rejected_by_reviewer';
                break;
            case 6:
                statusColor = 'pending_with_approver';
                break;
            case 7:
                statusColor = 'rejected_by_approver';
                break;
            default:
                statusColor = 'todo';
                break;
        }
        return statusColor;
    }
    handleFileUpload = (files) => {
        this.setState({ isFileProgress: true }, () => {
            this.setState({ fileProgress: 80 });
        });
        const chosenFiles = Array.prototype.slice.call(files);
        let errorsArray = [];
        chosenFiles.map((file) => {
            if (!customFundTaskFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.chat_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            toastrMessage(__t('validations.custom.proof_file'), DANGER);
            this.setState({ fileProgress: 0 }, () => {
                this.setState({ isFileProgress: false });
            });
            return false;
        }
        if (chosenFiles) {
            let formData = new FormData();
            chosenFiles.map((x) => formData.append('file', x));
            formData.append('fundTaskId', this.state.taskFormData.id);
            chosenFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: this.formatBytes(file.size),
                })
            );
            if (this.state.operationType === UPDATE) {
                this.setState({ fileProgress: 30 });
                this.props.addFundTaskAttachmentRequest(formData);
            } else {
                this.setState(
                    {
                        fileProgress: 0,
                        selectedFiles: [
                            ...this.state.selectedFiles,
                            ...chosenFiles,
                        ],
                    },
                    () => {
                        this.setState({ isFileProgress: false });
                    }
                );
            }
        }
    };
    removeFile = async (file) => {
        if (this.state.viewOnlyAccess) {
            toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
            return false;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.task_delete')
        );
        if (response) {
            var array = [...this.state.selectedFiles]; // make a separate copy of the array
            var index = array.indexOf(file);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ selectedFiles: array });
            }
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.fundTaskApiResponse &&
            this.props.fundTaskApiResponse !== nextprops.fundTaskApiResponse
        ) {
            toastrMessage(
                nextprops.fundTaskApiResponse.message,
                nextprops.fundTaskApiResponse.responseType
            );
            if (
                this.state.isModalDismiss === true &&
                !this.state.subTask
            ) {
                this.onModalDismiss();
            } else {
                this.setState(
                    {
                        isModalDismiss: true,
                    },
                    () => {
                        if (this.state.subTask) {
                            this.getFundTaskDetailsByTaskId(
                                this.state.subTaskId
                            );
                            this.getFundTaskActivityByTaskId(
                                this.state.subTaskId
                            );
                        } else {
                            this.getFundTaskDetailsByTaskId(nextprops.taskId);
                            this.getFundTaskActivityByTaskId(nextprops.taskId);
                        }
                    }
                );
            }
        }
        if (
            nextprops.fundSubTaskApiResponse &&
            this.props.fundSubTaskApiResponse !==
            nextprops.fundSubTaskApiResponse
        ) {
            toastrMessage(
                nextprops.fundSubTaskApiResponse.message,
                nextprops.fundSubTaskApiResponse.responseType
            );
            if (this.props.taskId) {
                this.getFundTaskDetailsByTaskId(this.props.taskId);
            }
            this.closeSubTaskModal();
        }
        if (nextprops.taskId && this.props.taskId !== nextprops.taskId) {
            this.getFundTaskDetailsByTaskId(nextprops.taskId);
            this.getFundTaskActivityByTaskId(nextprops.taskId);
            if (nextprops.addSubtask === true) {
                this.addSubTask();
            }
        }
        if (
            nextprops.addNewTask != this.props.addNewTask &&
            nextprops.addNewTask == true
        ) {
            this.setState({
                fundTeamUsers: this.props.fundTeamUsers,
            });
        }
        if (
            nextprops.fundTaskDetailsApiResponse &&
            this.props.fundTaskDetailsApiResponse !==
            nextprops.fundTaskDetailsApiResponse
        ) {
            this.setState({
                fundTaskAttchments:
                    nextprops.fundTaskDetailsApiResponse.fundTaskAttchments,
            });
            if (nextprops.fundTaskDetailsApiResponse.id) {
                let data = nextprops.fundTaskDetailsApiResponse;
                let isSubTaskCompleted = false;
                data.fundSubTasks.map((x) => {
                    if (x.fundStatusId != COMPLETED_FUND_STATUS) {
                        isSubTaskCompleted = true;
                    }
                });
                let allFundTeamUsers = data.fundTeamUsers;
                let fundOwners = allFundTeamUsers.filter(
                    (teamUser) =>
                        teamUser.userId === this.state.loginUserId &&
                        teamUser.isOwner
                );
                let fundManagements = allFundTeamUsers.filter(
                    (teamUser) =>
                        teamUser.userId === this.state.loginUserId &&
                        teamUser.isManagement
                );
                let viewOnlyAccess = false;
                if (
                    isTenantAdminRole() == true ||
                    (fundOwners.length == 0 && fundManagements.length > 0) ||
                    data?.fundStatusId == COMPLETED_FUND_STATUS
                    // (fundOwners.length == 0 && fundSubmitters.length > 0) ||
                    // (fundOwners.length == 0 && fundReviwers.length > 0)
                ) {
                    viewOnlyAccess = true;
                }
                const submitterUsers = allFundTeamUsers
                    ?.filter((u) => u.isSubmitter == 1)
                    .map((opt) => opt.userId);
                const reviewerUsers = allFundTeamUsers
                    ?.filter((u) => u.isReviewer == 1)
                    .map((opt) => opt.userId);
                const approverUsers = allFundTeamUsers
                    ?.filter((u) => u.isApprover == 1)
                    .map((opt) => opt.userId);
                let submitterUserArray = [];
                let reviewerUserArray = [];
                let approverUserArray = [];
                data.fundTaskUsers.map((x) => {
                    if (submitterUsers.includes(x.userId) && x.userType === PROJECT_TASK_SUBMITTER) {
                        submitterUserArray.push(x.userId);
                    }
                    if (reviewerUsers.includes(x.userId) && x.userType === PROJECT_TASK_REVIEWER) {
                        reviewerUserArray.push(x.userId);
                    }
                    if (approverUsers.includes(x.userId) && x.userType === PROJECT_TASK_APPROVER) {
                        approverUserArray.push(x.userId);
                    }
                });
                this.setState(
                    {
                        viewOnlyAccess: viewOnlyAccess,
                        taskFormData: {
                            fundId:
                                this.props.taskType == 'task'
                                    ? data?.fund.id
                                    : data?.fund?.id,
                            taskTitle: data?.taskTitle,
                            isDraft: data?.isDraft,
                            taskDescription: data?.taskDescription,
                            startDate: data?.startDate
                                ? getDateObjectWithValueFromMoment(
                                    data?.startDate
                                )
                                : null,
                            dueDate: data?.dueDate
                                ? getDateObjectWithValueFromMoment(
                                    data?.dueDate
                                )
                                : null,
                            entityId: data.entityId,
                            fundMasterId: data.fundMasterId,
                            userId:
                                this.props.taskType == 'subTask' ||
                                    this.state.subTask
                                    ? data.fundTaskUsers.map((x) => x.userId)
                                    : [],
                            submitterId: submitterUserArray,
                            reviewerId: reviewerUserArray,
                            approverId: approverUserArray,
                            oldFundStatusId: data?.fundStatusId,
                            fundStatusId: data?.fundStatusId,
                            priority: data?.priority,
                            id: data.id,
                            fundSubTasks: data?.fundSubTasks,
                            taskType: data?.taskType,
                            frequencyId: data?.frequencyId,
                            isCopySubtasks: data?.isCopySubtasks,
                            parentFundTaskId: data?.parentFundTaskId
                        },
                        fundTeamUsers: allFundTeamUsers,
                        parentFundTaskUsers:
                            this.props.taskType == 'subTask' ||
                                this.state.subTask
                                ? data?.parentFundTask?.fundTaskUsers?.map(
                                    (x) => x.userId
                                )
                                : data.fundTaskUsers?.map((x) => x.userId),
                        isSubTaskCompleted: isSubTaskCompleted,
                    },
                    () => {
                        this.validator.purgeFields();
                    }
                );
            }
        }
        if (
            nextprops.fundTaskAttachmentApiResponse &&
            this.props.fundTaskAttachmentApiResponse !==
            nextprops.fundTaskAttachmentApiResponse
        ) {
            toastrMessage(
                nextprops.fundTaskAttachmentApiResponse.message,
                nextprops.fundTaskAttachmentApiResponse.responseType
            );
            if (
                nextprops.fundTaskAttachmentApiResponse.responseType === DANGER
            ) {
                this.setState({ isFileProgress: false });
            }
            this.setState({ fileProgress: 0 }, () => {
                this.setState({ isFileProgress: false }, () => {
                    if (
                        this.state.subTask &&
                        this.state.subTaskId != ''
                    ) {
                        this.getFundTaskDetailsByTaskId(this.state.subTaskId);
                        this.getFundTaskActivityByTaskId(this.state.subTaskId);
                    } else {
                        this.getFundTaskDetailsByTaskId(this.props.taskId);
                        this.getFundTaskActivityByTaskId(this.props.taskId);
                    }
                });
            });
        }
        if (
            nextprops.fundTaskActivityApiResponse &&
            this.props.fundTaskActivityApiResponse !==
            nextprops.fundTaskActivityApiResponse
        ) {
            let userId = localStorage.getItem('userId');
            this.setState({
                fundActivityTypes:
                    nextprops.fundTaskActivityApiResponse.data
                        ?.fundActivityTypes,
                taskActivityList:
                    nextprops.fundTaskActivityApiResponse.data
                        ?.fundTaskActivites,
                userId: userId ? userId : '',
            });
        }
        if (
            nextprops.operationType &&
            this.state.operationType !== nextprops.operationType
        ) {
            this.setState({
                operationType: nextprops.operationType,
            });
        }
        if (
            nextprops.fundTaskDocumentApiResponse &&
            this.props.fundTaskDocumentApiResponse !==
            nextprops.fundTaskDocumentApiResponse
        ) {
            toastrMessage(
                nextprops.fundTaskDocumentApiResponse.message,
                nextprops.fundTaskDocumentApiResponse.responseType
            );
            if (
                nextprops.fundTaskDocumentApiResponse.responseType === SUCCESS
            ) {
                if (this.state.subTask && this.state.subTaskId != '') {
                    this.getFundTaskDetailsByTaskId(this.state.subTaskId);
                    this.getFundTaskActivityByTaskId(this.state.subTaskId);
                } else {
                    this.getFundTaskDetailsByTaskId(this.props.taskId);
                    this.getFundTaskActivityByTaskId(this.props.taskId);
                }
            }
        }
        if (
            nextprops.activeTab &&
            this.props.activeTab !== nextprops.activeTab
        ) {
            this.setState({
                activeTab: nextprops.activeTab,
            });
        }
    }
    getUserName = (fundTeamUsers, selectedUser) => {
        return this.selectedUserDisplayName(fundTeamUsers, selectedUser);
    };
    render() {
        let userName = '';
        let fundStatus = '';
        let activeTaskTypeSubTask = false;
        let assignEditAllowed = false;
        let titleEditAllowed = false;
        if (this.state.operationType === SUBMIT) {
            titleEditAllowed = true;
            assignEditAllowed = true;
        } else {
            if (this.state.taskFormData.fundStatusId !== COMPLETED_FUND_STATUS) {
                activeTaskTypeSubTask = (this.state.taskFormData.parentFundTaskId && this.state.taskFormData.parentFundTaskId != '') ? true : false;
                if (activeTaskTypeSubTask === true) {
                    if (this.state.taskFormData.submitterId.includes(this.state.loginUserId)) {
                        assignEditAllowed = true;
                        titleEditAllowed = true;
                    }
                } else {
                    if (this.props.fundOwnerUserIds.includes(this.state.loginUserId)) { /** If login user is fund owner then can edit assigne, title, description */
                        assignEditAllowed = true;
                        titleEditAllowed = true;
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <CustomisedSideModal
                    modalSize={
                        'modal-xxl modal-dialog-centered modal-dialog-scrollable side_slider project_task_modal add_project_detail_task fund_task_modual'
                    }
                    modalName={this.props.modalName}
                    isModalOpen={this.props.isModalOpen}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <div className="add-project-task-detail-box">
                        <Formik
                            initialValues={this.state.taskFormData}
                            enableReinitialize={true}
                            onSubmit={this.formSubmit}
                        >
                            {({ values, setFieldValue, handleSubmit }) => (
                                <Form>
                                    {/* {JSON.stringify(values)} */}
                                    <div className="d-flex w-100 add-task-header-modual">
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex add-task-title-box">
                                                <a
                                                    href={() => false}
                                                    title="Back"
                                                    className="back-btn"
                                                    onClick={() => {
                                                        this.onModalDismiss();
                                                        this.validator.purgeFields();
                                                    }}
                                                >
                                                    <svg
                                                        width="28"
                                                        height="19"
                                                        viewBox="0 0 28 19"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M16.4 1L26 9.5M26 9.5L16.4 18M26 9.5H1"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>
                                                </a>
                                                <h6>
                                                    {!this.state.subTask ? (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this
                                                                    .props
                                                                    .modalName,
                                                            }}
                                                        ></div>
                                                    ) : (
                                                        <span id="edit_sub_task">
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        this
                                                                            .props
                                                                            .fundEntityName +
                                                                        __t(
                                                                            'form_labels.fund_task.edit_sub_task'
                                                                        ),
                                                                }}
                                                            ></div>
                                                        </span>
                                                    )}
                                                </h6>
                                                <div className="d-flex">
                                                    <Dropdown
                                                        title="Priority"
                                                        isOpen={
                                                            this.state
                                                                .isPriority ===
                                                            true
                                                        }
                                                        toggle={() => {
                                                            if (!assignEditAllowed) {
                                                                return false;
                                                            }
                                                            this.priorityToggle(true);
                                                        }}
                                                        className="notification-list list-inline-item mr-1"
                                                        tag="li"
                                                    >
                                                        <DropdownToggle
                                                            className="nav-link arrow-none waves-effect"
                                                            tag="a"
                                                        >
                                                            <svg
                                                                color={this.priorityColor(
                                                                    values.priority
                                                                )}
                                                                width="16"
                                                                height="20"
                                                                viewBox="0 0 16 20"
                                                                fill={this.priorityColor(
                                                                    values.priority
                                                                )}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                    stroke="currentColor"
                                                                    stroke-opacity="0.5"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu"
                                                            right
                                                        >
                                                            {taskPriorityStatusList.map(
                                                                (priority) => (
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        className="priority-dropdown"
                                                                        active={
                                                                            priority.value ===
                                                                            values.priority
                                                                        }
                                                                        onClick={() => {
                                                                            if (!assignEditAllowed) {
                                                                                toastrMessage(__t('alerts.fund_permission_denied'), DANGER);
                                                                                return false;
                                                                            }
                                                                            setFieldValue('priority', priority.value);
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            color={this.priorityColor(
                                                                                priority.value
                                                                            )}
                                                                            width="16"
                                                                            height="20"
                                                                            viewBox="0 0 16 20"
                                                                            fill="currentColor"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M1 11V1.5C1 1.2643 1 1.14645 1.07322 1.07322C1.14645 1 1.2643 1 1.5 1H13.8486C14.5228 1 14.8598 1 14.9136 1.17755C14.9673 1.35511 14.6869 1.54208 14.126 1.91603L8.47581 5.68279C8.25942 5.82705 8.15123 5.89918 8.1489 6.00333C8.14657 6.10748 8.25143 6.18438 8.46115 6.33817L14.1926 10.5412C14.7276 10.9336 14.9951 11.1297 14.9347 11.3041C14.8742 11.4784 14.5427 11.467 13.8797 11.4441L1 11ZM1 11V18.5"
                                                                                stroke="currentColor"
                                                                                stroke-opacity="0.5"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                            />
                                                                        </svg>
                                                                        {' ' + priority.label}
                                                                    </DropdownItem>
                                                                )
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="d-flex submit-back-btn">
                                                {values.id && (
                                                    <Dropdown
                                                        className="notification-list list-inline-item mr-1"
                                                        tag="li"
                                                    >
                                                        <DropdownToggle className={'nav-link arrow-none waves-effect ' + this.statusColor(fundStatusList?.filter((x) => x.value === values.fundStatusId)?.[0]?.value)} tag="a">
                                                            {fundStatusList?.filter((x) => x.value === values.fundStatusId)?.[0]?.label}
                                                        </DropdownToggle>
                                                    </Dropdown>
                                                )}
                                                {values.id && (
                                                    (
                                                        values?.submitterId.includes(this.state.loginUserId) &&
                                                        (this.state.taskFormData.fundStatusId === TO_DO_FUND_STATUS || this.state.taskFormData.fundStatusId === REJECTED_BY_REVIEWER)
                                                    ) ||
                                                    (
                                                        values?.reviewerId.includes(this.state.loginUserId) &&
                                                        (this.state.taskFormData.fundStatusId === PENDING_WITH_REVIEWER_STATUS || this.state.taskFormData.fundStatusId === REJECTED_BY_APPROVER)
                                                    ) ||
                                                    (
                                                        values?.approverId.includes(this.state.loginUserId) &&
                                                        (this.state.taskFormData.fundStatusId === PENDING_WITH_APPROVER_STATUS)
                                                    )
                                                ) &&
                                                    <Button
                                                        type="button"
                                                        onClick={async () => {
                                                            const response = await commonConfirmBox(
                                                                __t('common.are_you_sure'),
                                                                __t('alerts.fund_draft')
                                                            );
                                                            if (response) {
                                                                setFieldValue(
                                                                    'isDraft',
                                                                    true
                                                                );
                                                                handleSubmit()
                                                            }
                                                        }}
                                                        outline
                                                        color='primary'
                                                        className="submit-btn"
                                                        title="Draft"
                                                    >
                                                        {DRAFT}
                                                    </Button>
                                                }
                                                {!this.state.viewOnlyAccess && (
                                                    <>
                                                        {(this.props.fundOwnerUserIds.includes(this.state.loginUserId) && !activeTaskTypeSubTask) && (
                                                            <Button
                                                                type="button"
                                                                className="btn btn-primary submit-btn"
                                                                onClick={async () => {
                                                                    handleSubmit();
                                                                }}
                                                            >
                                                                {SUBMIT}
                                                            </Button>
                                                        )}
                                                        {(this.state.operationType === UPDATE &&
                                                            this.state.taskFormData.submitterId.includes(this.state.loginUserId) &&
                                                            (this.state.taskFormData.fundStatusId == TO_DO_FUND_STATUS ||
                                                                this.state.taskFormData.fundStatusId == REJECTED_BY_REVIEWER)) && (
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-primary submit-btn"
                                                                    onClick={async () => {
                                                                        const response = await commonConfirmBox(
                                                                            __t('common.are_you_sure'),
                                                                            __t('alerts.fund_submit')
                                                                        );
                                                                        if (response) {
                                                                            setFieldValue('fundStatusId', PENDING_WITH_REVIEWER_STATUS);
                                                                            setFieldValue('isDraft', false);
                                                                            handleSubmit();
                                                                        }
                                                                    }}
                                                                >
                                                                    {SUBMIT}
                                                                </Button>
                                                            )}
                                                        {/* Reviewer Buttons */}
                                                        {this.state.operationType === UPDATE &&
                                                            this.state.taskFormData.reviewerId.includes(this.state.loginUserId) &&
                                                            (this.state.taskFormData.fundStatusId === PENDING_WITH_REVIEWER_STATUS ||
                                                                this.state.taskFormData.fundStatusId === REJECTED_BY_APPROVER) && (
                                                                <>
                                                                    <Button
                                                                        type="button"
                                                                        color='warning'
                                                                        className="submit-btn"
                                                                        onClick={async () => {
                                                                            const response = await commonConfirmBox(
                                                                                __t('common.are_you_sure'),
                                                                                __t('alerts.fund_reject')
                                                                            );
                                                                            if (response) {
                                                                                setFieldValue('fundStatusId', REJECTED_BY_REVIEWER);
                                                                                setFieldValue('isDraft', false);
                                                                                handleSubmit();
                                                                            }
                                                                        }}
                                                                    >
                                                                        {SEND_BACK}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        color='info'
                                                                        className="submit-btn"
                                                                        onClick={async () => {
                                                                            const response = await commonConfirmBox(
                                                                                __t('common.are_you_sure'),
                                                                                __t('alerts.fund_approve')
                                                                            );
                                                                            if (response) {
                                                                                let reviewerStatus = (this.state.taskFormData.approverId.length === 0) ? COMPLETED_FUND_STATUS : PENDING_WITH_APPROVER_STATUS
                                                                                setFieldValue('fundStatusId', reviewerStatus);
                                                                                setFieldValue('isDraft', false);
                                                                                handleSubmit();
                                                                            }
                                                                        }}
                                                                    >
                                                                        {APPROVE_REVIEW}
                                                                    </Button>
                                                                </>
                                                            )}
                                                        {/* Approver Buttons */}
                                                        {!activeTaskTypeSubTask && this.state.operationType === UPDATE &&
                                                            this.state.taskFormData.approverId.includes(this.state.loginUserId) &&
                                                            (this.state.taskFormData.fundStatusId === PENDING_WITH_APPROVER_STATUS) && (
                                                                <>
                                                                    <Button
                                                                        type="button"
                                                                        color='warning'
                                                                        className="submit-btn"
                                                                        onClick={async () => {
                                                                            const response = await commonConfirmBox(
                                                                                __t('common.are_you_sure'),
                                                                                __t('alerts.fund_reject')
                                                                            );
                                                                            if (response) {
                                                                                setFieldValue('fundStatusId', REJECTED_BY_APPROVER);
                                                                                setFieldValue('isDraft', false);
                                                                                handleSubmit();
                                                                            }
                                                                        }}
                                                                    >
                                                                        {SEND_BACK}
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        color='info'
                                                                        className="submit-btn"
                                                                        onClick={async () => {
                                                                            const response = await commonConfirmBox(
                                                                                __t('common.are_you_sure'),
                                                                                __t('alerts.fund_approve')
                                                                            );
                                                                            if (response) {
                                                                                setFieldValue('fundStatusId', COMPLETED_FUND_STATUS);
                                                                                setFieldValue('isDraft', false);
                                                                                handleSubmit();
                                                                            }
                                                                        }}
                                                                    >
                                                                        {APPROVE_REVIEW}
                                                                    </Button>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {((this.props.taskType == 'task' &&
                                            !this.state.subTask) ||
                                            (this.props.taskType == '' &&
                                                this.props.addNewTask ==
                                                true)) && (
                                                <div className="assign_card_head">
                                                    <React.Fragment>
                                                        <div className="assign_user_submitter">
                                                            <span>Submitter<strong className="text-danger">*</strong></span>
                                                            <div className="assigne-group-box">
                                                                {values?.submitterId?.map(
                                                                    (x) => (
                                                                        <>
                                                                            <div className="d-none">
                                                                                {(() => {
                                                                                    userName = this.getUserName(this.state.fundTeamUsers, x);
                                                                                    return null;
                                                                                })()}
                                                                            </div>
                                                                            <span
                                                                                className="assigne-box green-round"
                                                                                title={__t(
                                                                                    userName
                                                                                )}
                                                                            >
                                                                                {getInitials(
                                                                                    userName
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )
                                                                )}
                                                                {!assignEditAllowed && (
                                                                    <span className="assign-box-false">&nbsp;</span>
                                                                )}
                                                                {assignEditAllowed === true && (
                                                                    <Dropdown
                                                                        isOpen={
                                                                            this.state
                                                                                .taskUserToggle ==
                                                                            true
                                                                        }
                                                                        toggle={() => { this.taskUsersToggle(true); }}
                                                                        className="notification-list list-inline-item mr-1"
                                                                        tag="li"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="arrow-none waves-effect"
                                                                            tag="a"
                                                                        >
                                                                            <a
                                                                                href={() => false}
                                                                                title={__t(
                                                                                    'form_labels.funds.submitter'
                                                                                )}
                                                                                className="assigne-box assign-dropdown"
                                                                            >
                                                                                <svg
                                                                                    width="19"
                                                                                    height="20"
                                                                                    viewBox="0 0 19 20"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <circle
                                                                                        cx="8.22754"
                                                                                        cy="5"
                                                                                        r="4"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                        fill="currentColor"
                                                                                        fillOpacity="0.5"
                                                                                    />
                                                                                    <path
                                                                                        d="M14.2275 11L14.2275 19"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M18.2275 15L10.2275 15"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu small_scroll"
                                                                            right
                                                                        >
                                                                            {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                (
                                                                                    users
                                                                                ) => {
                                                                                    if (
                                                                                        users.isSubmitter ==
                                                                                        1
                                                                                    ) {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                tag="a"
                                                                                                className="priority-dropdown"
                                                                                                active={
                                                                                                    values.submitterId.indexOf(
                                                                                                        users.userId
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this
                                                                                                            .state
                                                                                                            .viewOnlyAccess
                                                                                                    ) {
                                                                                                        toastrMessage(
                                                                                                            __t(
                                                                                                                'alerts.fund_permission_denied'
                                                                                                            ),
                                                                                                            DANGER
                                                                                                        );
                                                                                                        return false;
                                                                                                    }
                                                                                                    setFieldValue(
                                                                                                        'submitterId',
                                                                                                        this.selectSortedUserFilterArray(
                                                                                                            users.userId,
                                                                                                            values.submitterId
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {' ' +
                                                                                                    users
                                                                                                        ?.user
                                                                                                        ?.fullName}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                            {assignEditAllowed === true && (
                                                                this.validator.message(
                                                                    __t('form_labels.fund_task.submitter'),
                                                                    values.submitterId,
                                                                    'required',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.fund_task.submitter_required')
                                                                        }
                                                                    }
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="assign_user_submitter">
                                                            <span>Reviewer<strong className="text-danger">*</strong></span>
                                                            <div className="assigne-group-box">
                                                                {values?.reviewerId?.map(
                                                                    (x) => (
                                                                        <>
                                                                            <div className="d-none">
                                                                                {(() => {
                                                                                    userName = this.getUserName(this.state.fundTeamUsers, x);
                                                                                    return null;
                                                                                })()}
                                                                            </div>
                                                                            <span
                                                                                className="assigne-box green-round"
                                                                                title={__t(
                                                                                    userName
                                                                                )}
                                                                            >
                                                                                {getInitials(
                                                                                    userName
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )
                                                                )}
                                                                {!assignEditAllowed && (
                                                                    <span className="assign-box-false">&nbsp;</span>
                                                                )}
                                                                {assignEditAllowed && (
                                                                    <Dropdown
                                                                        isOpen={
                                                                            this.state
                                                                                .taskUser1Toggle ==
                                                                            true
                                                                        }
                                                                        toggle={() => {
                                                                            if (
                                                                                (this
                                                                                    .state
                                                                                    .viewOnlyAccess
                                                                                ) || values.submitterId.includes(this.state.loginUserId) || values.reviewerId.includes(this.state.loginUserId)) {
                                                                                return false;
                                                                            }
                                                                            this.taskUsers1Toggle(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className="notification-list list-inline-item mr-1"
                                                                        tag="li"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="arrow-none waves-effect"
                                                                            tag="a"
                                                                        >
                                                                            <a
                                                                                href={() => false}
                                                                                title={__t(
                                                                                    'form_labels.funds.reviewer'
                                                                                )}
                                                                                className="assigne-box assign-dropdown"
                                                                            >
                                                                                <svg
                                                                                    width="19"
                                                                                    height="20"
                                                                                    viewBox="0 0 19 20"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <circle
                                                                                        cx="8.22754"
                                                                                        cy="5"
                                                                                        r="4"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                        fill="currentColor"
                                                                                        fillOpacity="0.5"
                                                                                    />
                                                                                    <path
                                                                                        d="M14.2275 11L14.2275 19"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M18.2275 15L10.2275 15"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu small_scroll"
                                                                            right
                                                                        >
                                                                            {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                (
                                                                                    users
                                                                                ) => {
                                                                                    if (
                                                                                        users.isReviewer ==
                                                                                        1
                                                                                    ) {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                tag="a"
                                                                                                className="priority-dropdown"
                                                                                                active={
                                                                                                    values.reviewerId.indexOf(
                                                                                                        users.userId
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this
                                                                                                            .state
                                                                                                            .viewOnlyAccess
                                                                                                    ) {
                                                                                                        toastrMessage(
                                                                                                            __t(
                                                                                                                'alerts.fund_permission_denied'
                                                                                                            ),
                                                                                                            DANGER
                                                                                                        );
                                                                                                        return false;
                                                                                                    }
                                                                                                    setFieldValue(
                                                                                                        'reviewerId',
                                                                                                        this.selectSortedUserFilterArray(
                                                                                                            users.userId,
                                                                                                            values.reviewerId
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {' ' +
                                                                                                    users
                                                                                                        ?.user
                                                                                                        ?.fullName}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                            {assignEditAllowed === true && (
                                                                this.validator.message(
                                                                    __t('form_labels.fund_task.reviewer'),
                                                                    values.reviewerId,
                                                                    'required',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.fund_task.reviewer_required')
                                                                        }
                                                                    }
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="assign_user_submitter">
                                                            <span>Approver</span>
                                                            <div className="assigne-group-box">
                                                                {values?.approverId?.map(
                                                                    (x) => (
                                                                        <>
                                                                            <div className="d-none">
                                                                                {(() => {
                                                                                    userName = this.getUserName(this.state.fundTeamUsers, x);
                                                                                    return null;
                                                                                })()}
                                                                            </div>
                                                                            <span
                                                                                className="assigne-box green-round"
                                                                                title={__t(
                                                                                    userName
                                                                                )}
                                                                            >
                                                                                {getInitials(
                                                                                    userName
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )
                                                                )}
                                                                {!assignEditAllowed && (
                                                                    <span className="assign-box-false">&nbsp;</span>
                                                                )}
                                                                {assignEditAllowed && (
                                                                    <Dropdown
                                                                        isOpen={
                                                                            this.state
                                                                                .taskUserApproverToggle ==
                                                                            true
                                                                        }
                                                                        toggle={() => {
                                                                            if (
                                                                                (this
                                                                                    .state
                                                                                    .viewOnlyAccess
                                                                                ) || values.submitterId.includes(this.state.loginUserId) || values.reviewerId.includes(this.state.loginUserId) || values.approverId.includes(this.state.loginUserId)) {
                                                                                return false;
                                                                            }
                                                                            this.taskUsersApproverToggle(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className="notification-list list-inline-item mr-1"
                                                                        tag="li"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="arrow-none waves-effect"
                                                                            tag="a"
                                                                        >
                                                                            <a
                                                                                href={() => false}
                                                                                title={__t(
                                                                                    'form_labels.funds.reviewer'
                                                                                )}
                                                                                className="assigne-box assign-dropdown"
                                                                            >
                                                                                <svg
                                                                                    width="19"
                                                                                    height="20"
                                                                                    viewBox="0 0 19 20"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <circle
                                                                                        cx="8.22754"
                                                                                        cy="5"
                                                                                        r="4"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                        fill="currentColor"
                                                                                        fillOpacity="0.5"
                                                                                    />
                                                                                    <path
                                                                                        d="M14.2275 11L14.2275 19"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M18.2275 15L10.2275 15"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu small_scroll"
                                                                            right
                                                                        >
                                                                            {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                (
                                                                                    users
                                                                                ) => {
                                                                                    if (
                                                                                        users.isApprover ==
                                                                                        1
                                                                                    ) {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                tag="a"
                                                                                                className="priority-dropdown"
                                                                                                active={
                                                                                                    values.approverId.indexOf(
                                                                                                        users.userId
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this
                                                                                                            .state
                                                                                                            .viewOnlyAccess
                                                                                                    ) {
                                                                                                        toastrMessage(
                                                                                                            __t(
                                                                                                                'alerts.fund_permission_denied'
                                                                                                            ),
                                                                                                            DANGER
                                                                                                        );
                                                                                                        return false;
                                                                                                    }
                                                                                                    setFieldValue(
                                                                                                        'approverId',
                                                                                                        this.selectSortedUserFilterArray(
                                                                                                            users.userId,
                                                                                                            values.approverId
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {' ' +
                                                                                                    users
                                                                                                        ?.user
                                                                                                        ?.fullName}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                        {/* start sub task assign start  */}
                                        {(this.props.taskType ==
                                            'subTask' ||
                                            this.state.subTask) && (
                                                <div className="assign_card_head">
                                                    <React.Fragment>
                                                        <div className="assign_user_submitter">
                                                            <span>Submitter<strong className="text-danger">*</strong></span>
                                                            <div className="assigne-group-box">
                                                                {values?.submitterId?.map(
                                                                    (x) => (
                                                                        <>
                                                                            <div className="d-none">
                                                                                {(() => {
                                                                                    userName = this.getUserName(this.state.fundTeamUsers, x);
                                                                                    return null;
                                                                                })()}
                                                                            </div>
                                                                            <span
                                                                                className="assigne-box green-round"
                                                                                title={__t(
                                                                                    userName
                                                                                )}
                                                                            >
                                                                                {getInitials(
                                                                                    userName
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )
                                                                )}
                                                                {assignEditAllowed && (
                                                                    <Dropdown
                                                                        isOpen={
                                                                            this.state
                                                                                .taskUserToggle ==
                                                                            true
                                                                        }
                                                                        toggle={() => {
                                                                            this.taskUsersToggle(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className="notification-list list-inline-item mr-1"
                                                                        tag="li"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="arrow-none waves-effect"
                                                                            tag="a"
                                                                        >
                                                                            <a
                                                                                href={() => false}
                                                                                title={__t(
                                                                                    'form_labels.funds.submitter'
                                                                                )}
                                                                                className="assigne-box assign-dropdown"
                                                                            >
                                                                                <svg
                                                                                    width="19"
                                                                                    height="20"
                                                                                    viewBox="0 0 19 20"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <circle
                                                                                        cx="8.22754"
                                                                                        cy="5"
                                                                                        r="4"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                        fill="currentColor"
                                                                                        fillOpacity="0.5"
                                                                                    />
                                                                                    <path
                                                                                        d="M14.2275 11L14.2275 19"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M18.2275 15L10.2275 15"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu small_scroll"
                                                                            right
                                                                        >
                                                                            {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                (
                                                                                    users
                                                                                ) => {
                                                                                    if (this.state.parentFundTaskUsers?.includes(
                                                                                        users.userId
                                                                                    ) && users.isSubmitter == 1
                                                                                    ) {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                tag="a"
                                                                                                className="priority-dropdown"
                                                                                                active={
                                                                                                    values.submitterId.indexOf(
                                                                                                        users.userId
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this
                                                                                                            .state
                                                                                                            .viewOnlyAccess
                                                                                                    ) {
                                                                                                        toastrMessage(
                                                                                                            __t(
                                                                                                                'alerts.fund_permission_denied'
                                                                                                            ),
                                                                                                            DANGER
                                                                                                        );
                                                                                                        return false;
                                                                                                    }
                                                                                                    setFieldValue(
                                                                                                        'submitterId',
                                                                                                        this.selectSortedUserFilterArray(
                                                                                                            users.userId,
                                                                                                            values.submitterId
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {' ' +
                                                                                                    users
                                                                                                        ?.user
                                                                                                        ?.fullName}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                            {assignEditAllowed === true && (
                                                                this.validator.message(
                                                                    __t('form_labels.fund_task.submitter'),
                                                                    values.submitterId,
                                                                    'required',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.fund_task.submitter_required')
                                                                        }
                                                                    }
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="assign_user_submitter">
                                                            <span>Reviewer<strong className="text-danger">*</strong></span>
                                                            <div className="assigne-group-box">
                                                                {values?.reviewerId?.map(
                                                                    (x) => (
                                                                        <>
                                                                            <div className="d-none">
                                                                                {(() => {
                                                                                    userName = this.getUserName(this.state.fundTeamUsers, x);
                                                                                    return null;
                                                                                })()}
                                                                            </div>
                                                                            <span
                                                                                className="assigne-box green-round"
                                                                                title={__t(
                                                                                    userName
                                                                                )}
                                                                            >
                                                                                {getInitials(
                                                                                    userName
                                                                                )}
                                                                            </span>
                                                                        </>
                                                                    )
                                                                )}
                                                                {assignEditAllowed && (
                                                                    <Dropdown
                                                                        isOpen={
                                                                            this.state
                                                                                .taskUser1Toggle ==
                                                                            true
                                                                        }
                                                                        toggle={() => {
                                                                            this.taskUsers1Toggle(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className="notification-list list-inline-item mr-1"
                                                                        tag="li"
                                                                    >
                                                                        <DropdownToggle
                                                                            className="arrow-none waves-effect"
                                                                            tag="a"
                                                                        >
                                                                            <a
                                                                                href={() => false}
                                                                                title={__t(
                                                                                    'form_labels.funds.reviewer'
                                                                                )}
                                                                                className="assigne-box assign-dropdown"
                                                                            >
                                                                                <svg
                                                                                    width="19"
                                                                                    height="20"
                                                                                    viewBox="0 0 19 20"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <circle
                                                                                        cx="8.22754"
                                                                                        cy="5"
                                                                                        r="4"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M11.5034 13C10.1248 12.5613 8.61714 12.4073 7.1342 12.5538C5.4937 12.7157 3.94226 13.2397 2.67801 14.0712C1.41367 14.9028 0.481283 16.0137 0.0292835 17.2789C-0.0636214 17.5389 0.0718722 17.825 0.331917 17.9179C0.591962 18.0108 0.878084 17.8754 0.970989 17.6153C1.33802 16.588 2.11269 15.64 3.22754 14.9067C4.34249 14.1734 5.73554 13.6967 7.23246 13.5489C7.78231 13.4946 8.3351 13.4858 8.88116 13.521C9.23652 13.1974 9.70898 13 10.2275 13L11.5034 13Z"
                                                                                        fill="currentColor"
                                                                                        fillOpacity="0.5"
                                                                                    />
                                                                                    <path
                                                                                        d="M14.2275 11L14.2275 19"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M18.2275 15L10.2275 15"
                                                                                        stroke="currentColor"
                                                                                        stroke-opacity="1.5"
                                                                                        strokeLinecap="round"
                                                                                    />
                                                                                </svg>
                                                                            </a>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu
                                                                            className="dropdown-menu small_scroll"
                                                                            right
                                                                        >
                                                                            {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                (
                                                                                    users
                                                                                ) => {
                                                                                    if (this.state.parentFundTaskUsers?.includes(users.userId) && users.isReviewer == 1) {
                                                                                        return (
                                                                                            <DropdownItem
                                                                                                tag="a"
                                                                                                className="priority-dropdown"
                                                                                                active={
                                                                                                    values.reviewerId.indexOf(
                                                                                                        users.userId
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        this
                                                                                                            .state
                                                                                                            .viewOnlyAccess
                                                                                                    ) {
                                                                                                        toastrMessage(
                                                                                                            __t(
                                                                                                                'alerts.fund_permission_denied'
                                                                                                            ),
                                                                                                            DANGER
                                                                                                        );
                                                                                                        return false;
                                                                                                    }
                                                                                                    setFieldValue(
                                                                                                        'reviewerId',
                                                                                                        this.selectSortedUserFilterArray(
                                                                                                            users.userId,
                                                                                                            values.reviewerId
                                                                                                        )
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {' ' +
                                                                                                    users
                                                                                                        ?.user
                                                                                                        ?.fullName}
                                                                                            </DropdownItem>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </div>
                                                            {assignEditAllowed === true && (
                                                                this.validator.message(
                                                                    __t('form_labels.fund_task.reviewer'),
                                                                    values.reviewerId,
                                                                    'required',
                                                                    {
                                                                        messages: {
                                                                            required: __t('validations.fund_task.reviewer_required')
                                                                        }
                                                                    }
                                                                )
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                        {/* start sub task assign end */}
                                    </div>
                                    <div className="add-task-content-body pro-task-content">
                                        <div className='pro-form-main'>
                                            <Row className="m-0">
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        name="taskTitle"
                                                        className="form-control task-title-form-control"
                                                        value={values.taskTitle}
                                                        placeholder={__t(
                                                            'form_labels.fund_task.title'
                                                        )}
                                                        disabled={!titleEditAllowed}
                                                    />
                                                    <Label className='form-label'>
                                                        {__t('form_labels.fund_task.title')}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.fund_task.title'
                                                        ),
                                                        values.taskTitle,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.fund_task.title_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6 pr-0 sticky_label">
                                                    <Field
                                                        component={DatePicker}
                                                        className="form-control"
                                                        name="startDate"
                                                        selected={values.startDate ? getDateObjectWithValueFromMoment(values.startDate) : null}
                                                        dateFormat={DATE_FORMAT_DATEPICKER}
                                                        onChange={(date) => {
                                                            setFieldValue('startDate', date);
                                                            let dateCheck = (getDateObjectWithValueFromMoment(values.dueDate) < getDateObjectWithValueFromMoment(date)) ? true : false;
                                                            if (dateCheck) {
                                                                setFieldValue('dueDate', '');
                                                            }
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        placeholderText="DD/MM/YYYY"
                                                        onFocus={(e) => e.target.blur()}
                                                        disabled={!titleEditAllowed}
                                                    ></Field>
                                                    <Label className='form-label'>
                                                        {__t(
                                                            'form_labels.fund_task.start_date'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.fund_task.start_date'
                                                        ),
                                                        values.startDate,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required:
                                                                    __t(
                                                                        'validations.fund_task.start_date_required'
                                                                    ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-6 sticky_label">
                                                    <Field
                                                        component={
                                                            DatePicker
                                                        }
                                                        className="form-control"
                                                        name="dueDate"
                                                        selected={
                                                            values.dueDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.dueDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(
                                                            date
                                                        ) => {
                                                            setFieldValue(
                                                                'dueDate',
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={
                                                            true
                                                        }
                                                        showYearDropdown={
                                                            true
                                                        }
                                                        dropdownMode="select"
                                                        placeholderText="DD/MM/YYYY"
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                        minDate={getDateObjectWithValueFromMoment(
                                                            values?.startDate
                                                        )}
                                                        disabled={!titleEditAllowed}
                                                    ></Field>
                                                    <Label className='form-label'>
                                                        {__t(
                                                            'form_labels.fund_task.due_date'
                                                        )}
                                                        <span className="text-danger required d-inline-block">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.fund_task.due_date'
                                                        ),
                                                        values.dueDate,
                                                        'required',
                                                        {
                                                            messages:
                                                            {
                                                                required:
                                                                    __t(
                                                                        'validations.fund_task.due_date_required'
                                                                    ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <>
                                                    {((this.state.operationType === UPDATE && !activeTaskTypeSubTask) || (this.state.operationType === SUBMIT)) && (
                                                        <Col lg="6">
                                                            <FormGroup className="sticky_label radio_btn">
                                                                <div className="form-check form-check-inline">
                                                                    <Field
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        title={__t(
                                                                            'form_labels.fund_task.one_time'
                                                                        )}
                                                                        name="taskType"
                                                                        id="taskTypeOneTime"
                                                                        onChange={(opt) => {
                                                                            setFieldValue('frequencyId', '');
                                                                            if (opt) {
                                                                                setFieldValue(
                                                                                    'taskType',
                                                                                    ONE_TIME
                                                                                );
                                                                            } else {
                                                                                setFieldValue(
                                                                                    'taskType',
                                                                                    RECURRING
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={ONE_TIME}
                                                                        checked={values.taskType === ONE_TIME}
                                                                        disabled={!titleEditAllowed}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        for="taskTypeOneTime"
                                                                        data-tip={__t(
                                                                            'form_labels.fund_task.one_time'
                                                                        )}
                                                                    >
                                                                        {__t(
                                                                            'form_labels.fund_task.one_time'
                                                                        )}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <Field
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="taskType"
                                                                        id="tastTypeRecurring"
                                                                        onChange={(opt) => {
                                                                            setFieldValue('frequencyId', '');
                                                                            if (opt) {
                                                                                setFieldValue('taskType', RECURRING);
                                                                            } else {
                                                                                setFieldValue('taskType', ONE_TIME);
                                                                            }
                                                                        }}
                                                                        value={RECURRING}
                                                                        checked={values.taskType === RECURRING}
                                                                        disabled={!titleEditAllowed}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        for="tastTypeRecurring"
                                                                        data-tip={__t(
                                                                            'form_labels.fund_task.recurring'
                                                                        )}
                                                                    >
                                                                        {__t(
                                                                            'form_labels.fund_task.recurring'
                                                                        )}
                                                                    </Label>
                                                                </div>
                                                                <Label className="form-label">
                                                                    {__t(
                                                                        'form_labels.fund_task.taskType'
                                                                    )}
                                                                    <span className="text-danger required">
                                                                        *
                                                                    </span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    )}
                                                    {values.taskType === RECURRING && (
                                                        <Col lg="12">
                                                            <Row>
                                                                <FormGroup className="col-lg-4">
                                                                    <Field
                                                                        component={
                                                                            Select
                                                                        }
                                                                        name="frequencyId"
                                                                        placeholder={__t(
                                                                            'form_labels.fund_task.frequency'
                                                                        )}
                                                                        className="form-control selectbox"
                                                                        options={frequencyDropdown}
                                                                        onChange={(
                                                                            opt
                                                                        ) => {
                                                                            let selectedValue =
                                                                                opt?.value
                                                                                    ? opt.value
                                                                                    : '';
                                                                            setFieldValue(
                                                                                'frequencyId',
                                                                                selectedValue
                                                                            );
                                                                        }}
                                                                        value={frequencyDropdown?.filter(
                                                                            (option) =>
                                                                                option.value ===
                                                                                values.frequencyId
                                                                        )}
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        disabled={!titleEditAllowed && this.props.operationType === UPDATE}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className="col-lg-8 radio_btn mb-0 d-flex align-items-center pb-3">
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        id="isCopySubtasks"
                                                                        name="isCopySubtasks"
                                                                        disabled={!titleEditAllowed && this.props.operationType === UPDATE}
                                                                        onChange={(e) => {
                                                                            const { checked } = e.target;
                                                                            setFieldValue('isCopySubtasks', (checked) ? 1 : 0);
                                                                        }}
                                                                        checked={values.isCopySubtasks === 1 ? true : false}
                                                                        label={__t('form_labels.fund_task.is_copy_sub_task')}
                                                                    />
                                                                </FormGroup>
                                                            </Row>
                                                        </Col>
                                                    )}
                                                </>
                                                <FormGroup className="col-lg-12 mt-2">
                                                    <Field
                                                        type="text"
                                                        component="textarea"
                                                        name="taskDescription"
                                                        rows="2"
                                                        className="form-control"
                                                        placeholder={__t(
                                                            'form_labels.fund_task.description'
                                                        )}
                                                        maxLength={250}
                                                        disabled={!titleEditAllowed}
                                                    />
                                                    <Label className='form-label'>
                                                        {__t('form_labels.fund_task.description')}
                                                    </Label>
                                                </FormGroup>
                                                {this.props.taskType === 'task' &&
                                                    this.state.operationType ===
                                                    UPDATE &&
                                                    !this.state.subTask && (
                                                        <Col lg="12">
                                                            <div className="add-sub-task-box">
                                                                <div className="add-sub-task-header d-flex justify-content-between mb-2">
                                                                    <h4>
                                                                        {__t('form_labels.fund_task.sub_task_list')}
                                                                    </h4>
                                                                    <div className="m-0 d-flex">
                                                                        {(!activeTaskTypeSubTask && this.state.taskFormData.submitterId.includes(this.state.loginUserId)) && (
                                                                            <a href={() => false}
                                                                                className="btn btn-outline-primary add-subtask-btn"
                                                                                onClick={() => {
                                                                                    this.addSubTask();
                                                                                }}>
                                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M7.5 2L7.5 13" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" /> <path d="M13 7.5L2 7.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" /></svg>
                                                                                {ADD_SUB_TASK}
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {this.state.taskFormData?.fundSubTasks && this.state.taskFormData.fundSubTasks.length > 0 && (
                                                                    <ul className='mt-3'>
                                                                        {this.state.taskFormData?.fundSubTasks?.map(
                                                                            (
                                                                                subTask,
                                                                                key
                                                                            ) => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <li>
                                                                                            <div className="d-flex">
                                                                                                <span className="project-list-status-box"></span>
                                                                                                <ContentEditable
                                                                                                    key={
                                                                                                        key
                                                                                                    }
                                                                                                    html={
                                                                                                        subTask?.taskTitle
                                                                                                    }
                                                                                                    disabled={this.subTaskEditDisable(subTask)}
                                                                                                    onBlur={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        this.handleChange(
                                                                                                            e,
                                                                                                            subTask
                                                                                                        )
                                                                                                    }
                                                                                                    tagName="article"
                                                                                                />
                                                                                            </div>
                                                                                            <div className="add-sub-task-list-box">
                                                                                                <div className={
                                                                                                    subTask?.startDate !=
                                                                                                        ''
                                                                                                        ? 'projectdetail-date-picker'
                                                                                                        : 'projectdetail-date-icon'
                                                                                                }
                                                                                                >
                                                                                                    <DatePicker
                                                                                                        selected={
                                                                                                            subTask?.startDate
                                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                                    subTask?.startDate
                                                                                                                )
                                                                                                                : null
                                                                                                        }
                                                                                                        dateFormat={
                                                                                                            DATE_FORMAT_DATEPICKER
                                                                                                        }
                                                                                                        disabled={this.subTaskEditDisable(subTask)}
                                                                                                        onChange={(
                                                                                                            date
                                                                                                        ) =>
                                                                                                            this.onChangeDateInput(
                                                                                                                date,
                                                                                                                subTask,
                                                                                                                'startDate'
                                                                                                            )
                                                                                                        }
                                                                                                        onFocus={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            e.target.blur()
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <div className={
                                                                                                    subTask?.dueDate !=
                                                                                                        ''
                                                                                                        ? 'projectdetail-date-picker'
                                                                                                        : 'projectdetail-date-icon'
                                                                                                }
                                                                                                >
                                                                                                    <DatePicker
                                                                                                        selected={
                                                                                                            subTask?.dueDate
                                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                                    subTask?.dueDate
                                                                                                                )
                                                                                                                : null
                                                                                                        }
                                                                                                        dateFormat={
                                                                                                            DATE_FORMAT_DATEPICKER
                                                                                                        }
                                                                                                        disabled={this.subTaskEditDisable(subTask)}
                                                                                                        onChange={(
                                                                                                            date
                                                                                                        ) =>
                                                                                                            this.onChangeDateInput(
                                                                                                                date,
                                                                                                                subTask
                                                                                                            )
                                                                                                        }
                                                                                                        minDate={
                                                                                                            subTask?.startDate
                                                                                                                ? getDateObjectWithValueFromMoment(
                                                                                                                    subTask?.startDate
                                                                                                                )
                                                                                                                : ''
                                                                                                        }
                                                                                                        onFocus={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            e.target.blur()
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <Dropdown
                                                                                                    isOpen={
                                                                                                        subTask.id ===
                                                                                                        this
                                                                                                            .state
                                                                                                            .subTaskToggleUserId
                                                                                                    }
                                                                                                    toggle={() =>
                                                                                                        this.subTaskUsersToggle(
                                                                                                            subTask.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="notification-list list-inline-item mr-1"
                                                                                                    tag="li"
                                                                                                    disabled={this.subTaskEditDisable(subTask)}
                                                                                                >
                                                                                                    <DropdownToggle
                                                                                                        className="arrow-none waves-effect"
                                                                                                        tag="a"
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            title={
                                                                                                                subTask
                                                                                                                    .fundTaskUsers
                                                                                                                    .length >
                                                                                                                    0
                                                                                                                    ? this.getSubtaskUserName(subTask?.fundTaskUsers, "submitter")
                                                                                                                    : __t(
                                                                                                                        'form_labels.funds.submitter'
                                                                                                                    )
                                                                                                            }
                                                                                                            className="calander-dropdown assigne-box"
                                                                                                        >
                                                                                                            <svg
                                                                                                                width="16"
                                                                                                                height="22"
                                                                                                                viewBox="0 0 16 22"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <path
                                                                                                                    d="M14.5073 15.2708C14.1235 14.1964 13.2777 13.2471 12.1012 12.57C10.9246 11.8929 9.48304 11.5259 8.00003 11.5259C6.51702 11.5259 5.07545 11.8929 3.8989 12.57C2.72235 13.2471 1.87657 14.1964 1.49274 15.2708"
                                                                                                                    stroke="black"
                                                                                                                    stroke-opacity="0.5"
                                                                                                                    strokeWidth="1.2"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                                <circle
                                                                                                                    cx="8.00001"
                                                                                                                    cy="4.78932"
                                                                                                                    r="3.36842"
                                                                                                                    fill="#7E869E"
                                                                                                                    fillOpacity="0.25"
                                                                                                                    stroke="currentColor"
                                                                                                                    stroke-opacity="0.5"
                                                                                                                    strokeWidth="1.2"
                                                                                                                    strokeLinecap="round"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </a>
                                                                                                    </DropdownToggle>
                                                                                                    <DropdownMenu
                                                                                                        className="dropdown-menu"
                                                                                                        right
                                                                                                    >
                                                                                                        {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                                            (
                                                                                                                users
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    this.state.parentFundTaskUsers?.includes(
                                                                                                                        users.userId
                                                                                                                    ) && users?.isSubmitter
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <DropdownItem
                                                                                                                            tag="a"
                                                                                                                            className="priority-dropdown"
                                                                                                                            active={
                                                                                                                                users.value ===
                                                                                                                                subTask
                                                                                                                                    .fundTaskUsers[0]
                                                                                                                                    ?.userId
                                                                                                                            }
                                                                                                                            onClick={() =>
                                                                                                                                this.onChangeUser(
                                                                                                                                    users.userId,
                                                                                                                                    subTask,
                                                                                                                                    'submitter'
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {' ' +
                                                                                                                                users
                                                                                                                                    ?.user
                                                                                                                                    ?.fullName}
                                                                                                                        </DropdownItem>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                                {/* abc */}
                                                                                                <Dropdown
                                                                                                    isOpen={
                                                                                                        subTask.id === this.state.subTaskToggleReviewerUserId
                                                                                                    }
                                                                                                    toggle={() =>
                                                                                                        this.subTaskReviewerUsersToggle(
                                                                                                            subTask.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="notification-list list-inline-item mr-1"
                                                                                                    tag="li"
                                                                                                    disabled={this.subTaskEditDisable(subTask)}
                                                                                                >
                                                                                                    <DropdownToggle
                                                                                                        className="arrow-none waves-effect"
                                                                                                        tag="a"
                                                                                                    >
                                                                                                        <a
                                                                                                            href={() => false}
                                                                                                            title={
                                                                                                                subTask
                                                                                                                    .fundTaskUsers
                                                                                                                    .length >
                                                                                                                    0
                                                                                                                    ? this.getSubtaskUserName(subTask?.fundTaskUsers, "reviewer")
                                                                                                                    : __t(
                                                                                                                        'form_labels.funds.reviewer'
                                                                                                                    )
                                                                                                            }
                                                                                                            className="calander-dropdown assigne-box"
                                                                                                        >
                                                                                                            <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M14.5073 15.2708C14.1235 14.1964 13.2777 13.2471 12.1012 12.57C10.9246 11.8929 9.48304 11.5259 8.00003 11.5259C6.51702 11.5259 5.07545 11.8929 3.8989 12.57C2.72235 13.2471 1.87657 14.1964 1.49274 15.2708" stroke="black" stroke-opacity="0.5" strokeWidth="1.2" strokeLinecap="round" /> <circle cx="8.00001" cy="4.78932" r="3.36842" fill="#7E869E" fillOpacity="0.25" stroke="currentColor" stroke-opacity="0.5" strokeWidth="1.2" strokeLinecap="round" /></svg>
                                                                                                        </a>
                                                                                                    </DropdownToggle>
                                                                                                    <DropdownMenu
                                                                                                        className="dropdown-menu"
                                                                                                        right
                                                                                                    >
                                                                                                        {this.props.fundDetails?.fundTeamUsers?.map(
                                                                                                            (
                                                                                                                users
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    this.state.parentFundTaskUsers?.includes(
                                                                                                                        users.userId
                                                                                                                    ) && users?.isReviewer
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <DropdownItem
                                                                                                                            tag="a"
                                                                                                                            className="priority-dropdown"
                                                                                                                            active={
                                                                                                                                users.value ===
                                                                                                                                subTask
                                                                                                                                    .fundTaskUsers[0]
                                                                                                                                    ?.userId
                                                                                                                            }
                                                                                                                            onClick={() =>
                                                                                                                                this.onChangeUser(
                                                                                                                                    users.userId,
                                                                                                                                    subTask,
                                                                                                                                    'reviewer'
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {' ' +
                                                                                                                                users
                                                                                                                                    ?.user
                                                                                                                                    ?.fullName}
                                                                                                                        </DropdownItem>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </DropdownMenu>
                                                                                                </Dropdown>
                                                                                                {/* endabc */}
                                                                                                {this.props.fundDetails?.fundTeamUsers?.filter(
                                                                                                    (
                                                                                                        users
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            users.userId ==
                                                                                                            this
                                                                                                                .state
                                                                                                                .loginUserId &&
                                                                                                            users.isSubmitter ==
                                                                                                            1
                                                                                                        ) {
                                                                                                            return users;
                                                                                                        }
                                                                                                    }
                                                                                                )
                                                                                                    ?.length >
                                                                                                    0 && (
                                                                                                        <Dropdown
                                                                                                            isOpen={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .actionId ===
                                                                                                                subTask.id
                                                                                                            }
                                                                                                            toggle={() =>
                                                                                                                this.actionToggle(
                                                                                                                    subTask.id
                                                                                                                )
                                                                                                            }
                                                                                                            className="notification-list list-inline-item"
                                                                                                            tag="li"
                                                                                                        >
                                                                                                            <DropdownToggle
                                                                                                                className="nav-link arrow-none waves-effect"
                                                                                                                tag="a"
                                                                                                                title={__t(
                                                                                                                    'form_labels.funds.action'
                                                                                                                )}
                                                                                                            >
                                                                                                                <svg
                                                                                                                    width="20"
                                                                                                                    height="4"
                                                                                                                    viewBox="0 0 20 4"
                                                                                                                    fill="none"
                                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                                >
                                                                                                                    <path
                                                                                                                        d="M16 2C16 1.60444 16.1173 1.21776 16.3371 0.88886C16.5568 0.559962 16.8692 0.303617 17.2346 0.152242C17.6001 0.000866562 18.0022 -0.0387401 18.3902 0.0384303C18.7781 0.115601 19.1345 0.306082 19.4142 0.585787C19.6939 0.865492 19.8844 1.22186 19.9616 1.60982C20.0387 1.99778 19.9991 2.39992 19.8478 2.76537C19.6964 3.13082 19.44 3.44318 19.1111 3.66294C18.7822 3.8827 18.3956 4 18 4C17.4696 4 16.9609 3.78929 16.5858 3.41421C16.2107 3.03914 16 2.53043 16 2V2ZM8 2C8 1.60444 8.1173 1.21776 8.33706 0.88886C8.55682 0.559962 8.86918 0.303617 9.23463 0.152242C9.60008 0.000866562 10.0022 -0.0387401 10.3902 0.0384303C10.7781 0.115601 11.1345 0.306082 11.4142 0.585787C11.6939 0.865492 11.8844 1.22186 11.9616 1.60982C12.0387 1.99778 11.9991 2.39992 11.8478 2.76537C11.6964 3.13082 11.44 3.44318 11.1111 3.66294C10.7822 3.8827 10.3956 4 10 4C9.46957 4 8.96086 3.78929 8.58579 3.41421C8.21071 3.03914 8 2.53043 8 2V2ZM0 2C0 1.60444 0.117298 1.21776 0.337061 0.88886C0.556824 0.559962 0.869181 0.303617 1.23463 0.152242C1.60009 0.000866562 2.00222 -0.0387401 2.39018 0.0384303C2.77814 0.115601 3.13451 0.306082 3.41421 0.585787C3.69392 0.865492 3.8844 1.22186 3.96157 1.60982C4.03874 1.99778 3.99913 2.39992 3.84776 2.76537C3.69638 3.13082 3.44004 3.44318 3.11114 3.66294C2.78224 3.8827 2.39556 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2H0Z"
                                                                                                                        fill="currentColor"
                                                                                                                    />
                                                                                                                </svg>
                                                                                                            </DropdownToggle>
                                                                                                            <DropdownMenu
                                                                                                                className="dropdown-menu"
                                                                                                                right
                                                                                                            >
                                                                                                                <DropdownItem
                                                                                                                    tag="a"
                                                                                                                    onClick={() =>
                                                                                                                        this.editSubTask(
                                                                                                                            subTask.id
                                                                                                                        )
                                                                                                                    }
                                                                                                                    className="text-primary notify-all"
                                                                                                                >
                                                                                                                    <i className="fas fa-pencil-alt"></i>{' '}
                                                                                                                    Edit
                                                                                                                </DropdownItem>
                                                                                                                <DropdownItem
                                                                                                                    tag="a"
                                                                                                                    onClick={() => {
                                                                                                                        this.deleteSubTask(
                                                                                                                            subTask.id
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    className="text-primary notify-all"
                                                                                                                >
                                                                                                                    <i className="fas fa-trash-alt"></i>{' '}
                                                                                                                    Delete
                                                                                                                </DropdownItem>
                                                                                                            </DropdownMenu>
                                                                                                        </Dropdown>
                                                                                                    )}
                                                                                            </div>
                                                                                        </li>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                )}
                                                                {this.state.subTaskForm === true && (
                                                                    <SubTaskForm
                                                                        subTaskFormData={this.state.subTaskFormData}
                                                                        taskId={values.id}
                                                                        fundUsers={this.state.fundTeamUsers}
                                                                        fundMasterId={this.props.selectedFundMasterId}
                                                                        onModalDismiss={() => this.closeSubTaskModal()}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Col>
                                                    )}
                                                <div className="add-attachment-box">
                                                    <h6>
                                                        File Attachment(s)
                                                        {this.state.taskFormData.fundStatusId !== COMPLETED_FUND_STATUS && (
                                                            <Dropzone multiple={true} onDrop={(acceptedFiles) => this.handleFileUpload(acceptedFiles)}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input
                                                                                {...getInputProps()}
                                                                            />
                                                                            <h3>
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 13C1 15.8284 1 17.2426 1.87868 18.1213C2.75736 19 4.17157 19 7 19H13C15.8284 19 17.2426 19 18.1213 18.1213C19 17.2426 19 15.8284 19 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M10 14V1M10 1L14 5.375M10 1L6 5.375" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                                                Upload
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        )}
                                                    </h6>
                                                    <div className="d-flex">
                                                        {this.state.isFileProgress ===
                                                            true && (
                                                                <div>
                                                                    <div className="attachment-data-box">
                                                                        <a
                                                                            href={() => false}
                                                                            title="File"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <svg
                                                                                width="20"
                                                                                height="20"
                                                                                viewBox="0 0 20 20"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <path
                                                                                    d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                            <div className="progress_bar">
                                                                                <Progress
                                                                                    animated
                                                                                    color="primary"
                                                                                    style={{
                                                                                        height: '1rem',
                                                                                    }}
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .fileProgress
                                                                                    }
                                                                                ></Progress>
                                                                            </div>
                                                                            <span>
                                                                                File name
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {this.state.fundTaskAttchments?.map((file) => (
                                                            <div>
                                                                <div className="attachment-data-box">
                                                                    {(this.state.taskFormData.fundStatusId !== COMPLETED_FUND_STATUS) && (
                                                                        <a
                                                                            href={() => false}
                                                                            className="remove-box"
                                                                            title="remove"
                                                                            onClick={() =>
                                                                                this.deleteTaskDocument(
                                                                                    file.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                width="16"
                                                                                height="17"
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M1.3335 4H14.6668"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M6.3335 7.33325V12.3333"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.6665 7.33325V12.3333"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1"
                                                                                    strokeLinecap="round"
                                                                                />
                                                                            </svg>
                                                                        </a>
                                                                    )}
                                                                    <a
                                                                        href={() => false}
                                                                        title={__t(
                                                                            'common.download_file'
                                                                        )}
                                                                        onClick={() =>
                                                                            this.saveFile(
                                                                                {
                                                                                    id: file.id,
                                                                                    fileName:
                                                                                        file.fileName.replace(
                                                                                            'fund-tasks/',
                                                                                            ''
                                                                                        ),
                                                                                    fileUrl:
                                                                                        file.fileUrl,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 20 20"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                                fill="currentColor"
                                                                            />
                                                                            <path
                                                                                d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                        <span className="attchment-file_name">
                                                                            {file.fileName.replace(
                                                                                /fund-tasks\//gi,
                                                                                ''
                                                                            )}
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                        )}
                                                        {this.state.selectedFiles.map((f, i) => {
                                                            return (
                                                                <div>
                                                                    <div className="attachment-data-box">
                                                                        <a
                                                                            href={
                                                                                f.preview
                                                                            }
                                                                            title={
                                                                                f.name
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <svg
                                                                                width="20"
                                                                                height="20"
                                                                                viewBox="0 0 20 20"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M11.2594 7.90347C11.0936 7.90347 10.9347 7.83762 10.8174 7.7204C10.7002 7.60319 10.6344 7.44422 10.6344 7.27847V1.95972H5C4.50272 1.95972 4.02581 2.15726 3.67419 2.50889C3.32253 2.86052 3.125 3.33744 3.125 3.83472V16.3597C3.125 16.857 3.32253 17.3339 3.67419 17.6855C4.02581 18.0372 4.50272 18.2347 5 18.2347H14.7063C15.2033 18.2338 15.6796 18.036 16.0311 17.6845C16.3825 17.3331 16.5803 16.8567 16.5813 16.3597V7.90347H11.2594Z"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <path
                                                                                    d="M16.2124 6.65335L11.8843 2.3252V2.58458V6.65335H15.9562H16.2124Z"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                            <a
                                                                                href={() => false}
                                                                                className="remove-box"
                                                                                title="remove"
                                                                                onClick={() =>
                                                                                    this.removeFile(
                                                                                        f
                                                                                    )
                                                                                }
                                                                            >
                                                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4" stroke="currentColor" strokeWidth="1" strokeLinecap="round" /><path d="M1.3335 4H14.6668" stroke="currentColor" strokeWidth="1" strokeLinecap="round" /><path d="M6.3335 7.33325V12.3333" stroke="currentColor" strokeWidth="1" strokeLinecap="round" /><path d="M9.6665 7.33325V12.3333" stroke="currentColor" strokeWidth="1" strokeLinecap="round" /><path d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4" stroke="currentColor" strokeWidth="1" strokeLinecap="round" /> </svg>
                                                                            </a>
                                                                            <span className="attchment-file_name">
                                                                                {
                                                                                    f.name
                                                                                }
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        {this.props.operationType === UPDATE && (
                                            <div className="pro-comments">
                                                <Nav
                                                    tabs
                                                    className="nav-tabs-custom nav-justified"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        this
                                                                            .state
                                                                            .activeTab ===
                                                                        'commentTab',
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                this.toggle(
                                                                    'commentTab'
                                                                );
                                                            }}
                                                        >
                                                            Response
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        this
                                                                            .state
                                                                            .activeTab ===
                                                                        'activityLogTab',
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                this.toggle(
                                                                    'activityLogTab'
                                                                );
                                                            }}
                                                        >
                                                            Activity Log
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane className="laws_tab" tabId="commentTab">
                                                        <>
                                                            {this.state.operationType === UPDATE && (
                                                                <div className="comment-box">
                                                                    <ul>
                                                                        {this.state.taskActivityList.filter((activityInfo) => {
                                                                            return activityInfo.fundActivityTypeId === FUND_ACTIVITY_TYPE_COMMENT
                                                                        }).map((activity) => (
                                                                            <>
                                                                                <li key={activity.id} className={this.state.userId == activity.createdBy ? 'sent_comment_box' : 'receive_comment_box'}>
                                                                                    <CommentDetails
                                                                                        key={activity.id}
                                                                                        loginUserId={this.state.userId}
                                                                                        activity={activity}
                                                                                    />
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                    </ul>
                                                                    {!this.state.viewOnlyAccess && (
                                                                        <div className="comment-chat-box">
                                                                            <TaskCommentForm
                                                                                taskId={
                                                                                    values.id
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>
                                                    </TabPane>
                                                    <TabPane
                                                        className="laws_tab"
                                                        tabId="activityLogTab"
                                                    >
                                                        {this.state.operationType === UPDATE && (
                                                            <div className="comment-box activity-log">
                                                                <ul>
                                                                    {this.state.taskActivityList.map((activity) => (
                                                                        <>
                                                                            <li
                                                                                className={
                                                                                    this
                                                                                        .state
                                                                                        .userId ==
                                                                                        activity.createdBy
                                                                                        ? 'sent_comment_box'
                                                                                        : 'receive_comment_box'
                                                                                }
                                                                            >
                                                                                <ActivityDetails
                                                                                    loginUserId={this.state.userId}
                                                                                    activity={activity}
                                                                                    fundActivityTypes={this.state.fundActivityTypes}
                                                                                />
                                                                            </li>
                                                                        </>
                                                                    )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}

                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </CustomisedSideModal>

                <DownloadFileByModel
                    modelType='fundTask'
                    downloadFileName={this.state.downloadFileName}
                    downloadFileUrl={this.state.downloadFileUrl}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    fundTaskApiResponse: makeFundsSelectField('fundTaskApiResponse'),
    fundSubTaskApiResponse: makeFundsSelectField('fundSubTaskApiResponse'),
    fundTaskDetailsApiResponse: makeFundsSelectField(
        'fundTaskDetailsApiResponse'
    ),
    fundTaskAttachmentApiResponse: makeFundsSelectField(
        'fundTaskAttachmentApiResponse'
    ),
    fundTaskActivityApiResponse: makeFundsSelectField(
        'fundTaskActivityApiResponse'
    ),
    fundTaskDocumentApiResponse: makeFundsSelectField(
        'fundTaskDocumentApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    addFundTaskRequest,
    updateFundTaskRequest,
    deleteFundSubTaskBySubTaskIdRequest,
    getFundTaskDetailsRequest,
    addFundTaskAttachmentRequest,
    getFundTaskActivityRequest,
    deleteFundTaskDocumentByDocumentIdRequest,
});
export default compose(withConnect, withRouter)(AddEditFundTaskModal);
