import produce from 'immer';
import { ADD_COUNTRY_RESPONSE, DELETE_COUNTRY_BY_COUNTRY_ID_RESPONSE, GET_ALL_COUNTRY_LIST_RESPONSE, GET_COUNTRY_BY_COUNTRY_ID_RESPONSE, GET_COUNTRY_LIST_BY_STATUS_RESPONSE, UPDATE_COUNTRY_RESPONSE, UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_RESPONSE, GET_GCM_AUTH_USER_COUNTRY_LIST_RESPONSE } from './actionTypes';
import { decryptData } from 'helpers/encryptor';
import * as authConstants from "constants/authConstants";
import { getValueByKey } from 'helpers/authUtils';
import { userIsLite } from 'helpers/litehelper';
export const initialState = {
    countryApiResponse: null,
    editCountryApiResponse: null,
    countryList: [],
    gcmAuthUserCountryList: []
};
const country = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case ADD_COUNTRY_RESPONSE:
            case UPDATE_COUNTRY_RESPONSE:
            case UPDATE_COUNTRY_STATUS_BY_COUNTRY_ID_RESPONSE:
            case DELETE_COUNTRY_BY_COUNTRY_ID_RESPONSE:
                draft.countryApiResponse = {
                    message: action.payload.message,
                    responseType: action.payload.responseType,
                };
                break;
            case GET_ALL_COUNTRY_LIST_RESPONSE:
                draft.countryList = action.payload.countryList;
                break;
            case GET_COUNTRY_BY_COUNTRY_ID_RESPONSE:
                draft.editCountryApiResponse = {
                    message: action.payload.message,
                    data: action.payload.data,
                    responseType: action.payload.responseType,
                };
                break;



            case GET_COUNTRY_LIST_BY_STATUS_RESPONSE:
                const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
                const allowCountries = isLite.litePlan?.allowedCountries ? isLite.litePlan?.allowedCountries.map((ele)=>ele.id):null;
                draft.countryList = userIsLite() ? action.payload.countryList.filter((ele)=>allowCountries.includes(ele.id))  :action.payload.countryList;
                break;



            case GET_GCM_AUTH_USER_COUNTRY_LIST_RESPONSE:
                draft.gcmAuthUserCountryList = action.payload.list;
                break;

            default:
                state = { ...state };
                break;
        }
    });
export default country;
