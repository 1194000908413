import { CLIENT_REGISTRATION_LIST_REQUEST, CLIENT_REGISTRATION_LIST_RESPONSE, CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST, CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE, LITE_ACTIVE_ENTITY_REQUEST, LITE_ACTIVE_ENTITY_RESPONSE, LITE_CITY_LIST_REQUEST, LITE_CITY_LIST_RESPONSE, LITE_CLIENT_RESPONSE_QUESTION_REQUEST, LITE_CLIENT_RESPONSE_QUESTION_RESPONSE, LITE_COMPLIANCE_ALLOCATION_REQUEST, LITE_COMPLIANCE_ALLOCATION_RESPONSE, LITE_COMPLIANCE_GENERATE_REQUEST, LITE_COMPLIANCE_GENERATE_RESPONSE, LITE_COMPLIANCE_LIST_REQUEST, LITE_COMPLIANCE_LIST_RESPONSE, LITE_COMPLIANCE_REQUEST, LITE_COMPLIANCE_RESPONSE, LITE_COMPLIANCE_START_STOP_SCHEDULER_REQUEST, LITE_COMPLIANCE_START_STOP_SCHEDULER_RESPONSE, LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST, LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST, LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST, LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE, LITE_ENTITY_ADD_SETUP_REQUEST, LITE_ENTITY_DETAILS_REQUEST, LITE_ENTITY_DETAILS_RESPONSE, LITE_ENTITY_SETUP_LOCATION_LIST_REQUEST, LITE_ENTITY_SETUP_LOCATION_LIST_RESPONSE, LITE_ENTITY_SETUP_STATE_CITY_REQUEST, LITE_ENTITY_SETUP_STATE_CITY_RESPONSE, LITE_ENTITY_UPDATE_REQUEST, LITE_ENTITY_UPDATE_RESPONSE, LITE_MANAGEMENT_USER_LIST_REQUEST, LITE_MANAGEMENT_USER_LIST_RESPONSE, LITE_QUESTION_ADD_REQUEST, LITE_QUESTION_ADD_RESPONSE, LITE_QUESTION_INACTIVE_REQUEST, LITE_QUESTION_INACTIVE_RESPONSE, LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_REQUEST, LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_RESPONSE, LITE_QUESTION_LIST_REQUEST, LITE_QUESTION_LIST_RESPONSE, LITE_QUESTION_RESET_REQUEST, LITE_QUESTION_RESET_RESPONSE, LITE_QUESTION_SINGLE_REQUEST, LITE_QUESTION_SINGLE_RESPONSE, LITE_QUESTION_UPDATE_REQUEST, LITE_QUESTION_UPDATE_RESPONSE, LITE_USER_MAPPED_REQUEST, LITE_USER_MAPPED_RESPONSE } from "./actionTypes";

export const getClientRegistrationRequest = data => {
    return {
        type: CLIENT_REGISTRATION_LIST_REQUEST,
        payload: { data }
    };
};

export const getClientRegistrationResponse = data => {
    return {
        type: CLIENT_REGISTRATION_LIST_RESPONSE,
        payload: { data },
    };
};


export const putResendEmailRequest = (data) => {
    return {
        type: CLIENT_REGISTRATION_RESEND_EMAIL_REQUEST,
        payload: { data },
    };
}
export const putResendEmailResponse = (data) => {
    return {
        type: CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE,
        payload: { data },
    };
}

export const putStatusUpdateClientRequest = (data) => {
    return {
        type: CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_REQUEST,
        payload: { data },
    };
}
export const putStatusUpdateClientResponse = (data) => {
    return {
        type: CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE,
        payload: { data },
    };
}


export const getLiteCountriesListRequest = (data) => {
    return {
        type: LITE_COUNTRIES_LIST_AS_PER_PLAN_REQUEST,
        payload: { data },
    };
}

export const getLiteCountriesListResponse = (data) => {
    return {
        type: LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE,
        payload: { data },
    };
}


export const getLiteCountriesEntityRequest = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_LIST_REQUEST,
        payload: { data },
    };
}

export const getLiteCountriesEntityResponse = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE,
        payload: { data },
    };
}


export const addLiteEntityRequest = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_ADD_REQUEST,
        payload: { data },
    };
}

export const addLiteEntityResponse = (data) => {
    return {
        type: LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE,
        payload: { data },
    };
}

export const getListManagementUserListRequest = (data) => {
    return {
        type: LITE_MANAGEMENT_USER_LIST_REQUEST,
        payload: { data },
    };
}

export const getListManagementUserListResponse = (data) => {
    return {
        type: LITE_MANAGEMENT_USER_LIST_RESPONSE,
        payload: { data },
    };
}


export const postInActiveLiteEntityRequest = (data) => {
    return {
        type: LITE_ACTIVE_ENTITY_REQUEST,
        payload: { data },
    };
}

export const postInActiveLiteEntityResponse = (data) => {
    return {
        type: LITE_ACTIVE_ENTITY_RESPONSE,
        payload: { data },
    };
}

export const postAddLiteEntitySetupRequest = (data) => {
    return {
        type: LITE_ENTITY_ADD_SETUP_REQUEST,
        payload: { data }
    }
}


export const getEntityDetailsRequest = (data) => {
    return {
        type: LITE_ENTITY_DETAILS_REQUEST,
        payload: { data },
    };
}

export const getEntityDetailsResponse = (data) => {
    return {
        type: LITE_ENTITY_DETAILS_RESPONSE,
        payload: { data },
    };
}


export const putLiteEntityRequest = (data) => {
    return {
        type: LITE_ENTITY_UPDATE_REQUEST,
        payload: { data },
    };
}

export const putLiteEntityResponse = (data) => {
    return {
        type: LITE_ENTITY_UPDATE_RESPONSE,
        payload: { data },
    };
}


export const getQuestionMasterRequest = (data) => {
    return {
        type: LITE_QUESTION_LIST_REQUEST,
        payload: { data },
    };
}

export const getQuestionMasterResponse = (data) => {
    return {
        type: LITE_QUESTION_LIST_RESPONSE,
        payload: { data },
    };
}


export const postQuestionMasterRequest = (data) => {
    return {
        type: LITE_QUESTION_ADD_REQUEST,
        payload: { data },
    };
}

export const postQuestionMasterResponse = (data) => {
    return {
        type: LITE_QUESTION_ADD_RESPONSE,
        payload: { data },
    };
}


export const putQuestionMasterInactiveRequest = (data) => {
    return {
        type: LITE_QUESTION_INACTIVE_REQUEST,
        payload: { data },
    };
}

export const putQuestionMasterInactiveResponse = (data) => {
    return {
        type: LITE_QUESTION_INACTIVE_RESPONSE,
        payload: { data },
    };
}

export const putQuestionMasterUpdateRequest = (data) => {
    return {
        type: LITE_QUESTION_UPDATE_REQUEST,
        payload: { data },
    };
}

export const putQuestionMasterUpdateResponse = (data) => {
    return {
        type: LITE_QUESTION_UPDATE_RESPONSE,
        payload: { data },
    };
}

export const getSingleQuestionMasterRequest = (data) => {
    return {
        type: LITE_QUESTION_SINGLE_REQUEST,
        payload: { data },
    };
}

export const getSingleQuestionMasterResponse = (data) => {
    return {
        type: LITE_QUESTION_SINGLE_RESPONSE,
        payload: { data },
    };
}


export const setResetQuestionnaireRequest = (data) => {
    return {
        type: LITE_QUESTION_RESET_REQUEST,
        payload: { data },
    };
}

export const setResetQuestionnaireResponse = (data) => {
    return {
        type: LITE_QUESTION_RESET_RESPONSE,
        payload: { data },
    };
}


export const getLiteComplianceListRequest = (data) => {
    return {
        type: LITE_COMPLIANCE_LIST_REQUEST,
        payload: { data },
    };
}

export const getLiteComplianceListResponse = (data) => {
    return {
        type: LITE_COMPLIANCE_LIST_RESPONSE,
        payload: { data },
    };
}


export const getLiteCityListRequest = (data) => {
    return {
        type: LITE_CITY_LIST_REQUEST,
        payload: { data },
    };
}

export const getLiteCityListResponse = (data) => {
    return {
        type: LITE_CITY_LIST_RESPONSE,
        payload: { data },
    };
}



export const getLiteQuestionRequest = (data) => {
    return {
        type: LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_REQUEST,
        payload: { data },
    };
}

export const getLiteQuestionResponse = (data) => {
    return {
        type: LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_RESPONSE,
        payload: { data },
    };
}


export const postClientQuestionRequest = (data) => {
    return {
        type: LITE_CLIENT_RESPONSE_QUESTION_REQUEST,
        payload: { data },
    };
}

export const postClientQuestionResponse = (data) => {
    return {
        type: LITE_CLIENT_RESPONSE_QUESTION_RESPONSE,
        payload: { data },
    };
}


export const getEntitySetupStateAndCityRequest = (data) => {
    return {
        type: LITE_ENTITY_SETUP_STATE_CITY_REQUEST,
        payload: { data },
    };
}

export const getEntitySetupStateAndCityResponse = (data) => {
    return {
        type: LITE_ENTITY_SETUP_STATE_CITY_RESPONSE,
        payload: { data },
    };
}


export const generateLiteComplianceRequest = (data) => {
    return {
        type: LITE_COMPLIANCE_GENERATE_REQUEST,
        payload: { data },
    };
}

export const generateLiteComplianceResponse = (data) => {
    return {
        type: LITE_COMPLIANCE_GENERATE_RESPONSE,
        payload: { data },
    };
}

export const postUserMappedRequest = (data) => {
    return {
        type: LITE_USER_MAPPED_REQUEST,
        payload: { data },
    };
}

export const postUserMappedResponse = (data) => {
    return {
        type: LITE_USER_MAPPED_RESPONSE,
        payload: { data },
    };
}

export const getLiteComplianceAllocationRequest = (data) => {
    return {
        type: LITE_COMPLIANCE_ALLOCATION_REQUEST,
        payload: { data },
    };
}

export const getLiteComplianceAllocationResponse = (data) => {
    return {
        type: LITE_COMPLIANCE_ALLOCATION_RESPONSE,
        payload: { data },
    };
}


export const getEntitySetupListRequest = (data) => {
    return {
        type: LITE_ENTITY_SETUP_LOCATION_LIST_REQUEST,
        payload: { data },
    };
}

export const getEntitySetupListResponse = (data) => {
    return {
        type: LITE_ENTITY_SETUP_LOCATION_LIST_RESPONSE,
        payload: { data },
    };
}

export const getStartStopSchedulerRequest = (data) => {
    return {
        type: LITE_COMPLIANCE_START_STOP_SCHEDULER_REQUEST,
        payload: { data },
    };
}

export const getStartStopSchedulerResponse = (data) => {
    return {
        type: LITE_COMPLIANCE_START_STOP_SCHEDULER_RESPONSE,
        payload: { data },
    };
}