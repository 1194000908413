import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CANCEL, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getEmailTemplateTypesListRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import emailTemplateReducer from 'store/EmailTemplate/reducer';
import emailTemplateSaga from 'store/EmailTemplate/saga';
import roleReducer from 'store/Masters/Role/reducer';
import roleSaga from 'store/Masters/Role/saga';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedEmailTemplateTypeList } from 'store/EmailTemplate/selector';
import { IntComponent } from 'components/Application/NumberFormat';
import { lessThanValidator } from 'helpers/customValidators';
import { emailConfigRoleList } from 'constants/dropdowns';
import { LITE_EMAIL_CONFIGURED_ID, LITE_EMAIL_TO_CC_ROLE_EXCLUDED, TASK_COMPLETED_WITHOUT_AUDIT } from 'constants/databaseConstants';
const emailTemplateKey = 'emailTemplate';
const withEmailTemplateReducer = injectReducer({
    key: emailTemplateKey,
    reducer: emailTemplateReducer,
});
const withEmailTemplateSaga = injectSaga({
    key: emailTemplateKey,
    saga: emailTemplateSaga,
});

const roleKey = 'role';
const withRoleReducer = injectReducer({
    key: roleKey,
    reducer: roleReducer,
});
const withRoleSaga = injectSaga({ key: roleKey, saga: roleSaga });
class AddEditEmailTemplateFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                lessThanValidator()
            )
        });
        this.state = {
            emailTemplateList: this.props.emailTemplateList,
            emailTemplateData: this.props.emailTemplateData,
        };
    }
    componentDidMount() {
        this.props.getEmailTemplateTypesListRequest();
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onModalDismiss();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.emailTemplateList !== this.props.emailTemplateList) {
            this.validator.hideMessages();
            this.setState({
                emailTemplateList: nextprops.emailTemplateList,
            });
        }
        if (nextprops.operationType !== this.props.operationType) {
            this.setState({
                emailTemplateData: nextprops.emailTemplateData,
                operationType: nextprops.operationType,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={
                        this.props.operationType === SUBMIT
                            ? __t('form_labels.entity.add_email_template')
                            : __t('form_labels.entity.edit_email_template')
                    }
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => this.resetForm()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.emailTemplateData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>

                                        <Row>
                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component={Select}
                                                    name="emailTemplateTypeId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedEmailTemplateTypeListDropDown.filter(
                                                        (option) => {
                                                            let emailTemplatesArr =
                                                                this.state.emailTemplateList?.map(
                                                                    ({
                                                                        emailTemplateTypeId,
                                                                        ...rest
                                                                    }) =>
                                                                        emailTemplateTypeId
                                                                );
                                                            return this.props.isLite ?  LITE_EMAIL_CONFIGURED_ID.includes(option.value) && !emailTemplatesArr?.includes(
                                                                option.value
                                                            ): !emailTemplatesArr?.includes(
                                                                option.value
                                                            );
                                                        }
                                                    ).map((element)=>{
                                                        if(this.props.isLite){
                                                            if(element.value == TASK_COMPLETED_WITHOUT_AUDIT){
                                                                return {
                                                                    ...element,
                                                                    label:"Task completed"
                                                                }
                                                            }else{
                                                                return element
                                                            }
                                                        }else{
                                                            return element
                                                        }
                                                    })}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            'emailTemplateTypeId',
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            'emailTemplateTypeName',
                                                            opt.label
                                                        );
                                                    }}
                                                    value={this.props.sortedEmailTemplateTypeListDropDown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.emailTemplateTypeId
                                                    ).map((element)=>{
                                                        if(this.props.isLite){
                                                            if(element.value == TASK_COMPLETED_WITHOUT_AUDIT){
                                                                return {
                                                                    ...element,
                                                                    label:"Task completed"
                                                                }
                                                            }else{
                                                                return element
                                                            }
                                                        }else{
                                                            return element
                                                        }
                                                    })}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.email_template'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.email_template'
                                                    ),
                                                    values.emailTemplateTypeId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.email_template_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            {values.emailTemplateTypeId && values.emailTemplateTypeId != '' && (
                                                <>
                                                    <FormGroup className="col-lg-3">
                                                        <Field
                                                            component={Select}
                                                            name="toRoles"
                                                            className="form-control selectbox"
                                                            options={emailConfigRoleList.filter(
                                                                (opt) =>{
                                                                    return this.props.isLite ? !LITE_EMAIL_TO_CC_ROLE_EXCLUDED.includes(opt.value) && !values.ccRoles?.includes(opt.value)  : !values.ccRoles?.includes(opt.value)
                                                                }
                                                                   
                                                            )}
                                                            onChange={(opt) => {
                                                                let selectedValues = opt
                                                                    ? opt.map(
                                                                        (x) => x.value
                                                                    )
                                                                    : [];
                                                                setFieldValue(
                                                                    'toRoles',
                                                                    selectedValues
                                                                );
                                                                setFieldValue(
                                                                    'toRolesName',
                                                                    opt
                                                                        ? opt.map(
                                                                            (x) =>
                                                                                x.label
                                                                        )
                                                                        : []
                                                                );
                                                            }}
                                                            value={emailConfigRoleList.filter(
                                                                (option) =>
                                                                    values.toRoles?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.to_roles'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.entity.to_roles'
                                                            ),
                                                            values.toRoles,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.entity.to_roles_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-3">
                                                        <Field
                                                            component={Select}
                                                            name="ccRoles"
                                                            className="form-control selectbox"
                                                            options={emailConfigRoleList.filter(
                                                                (opt) =>{
                                                                    return this.props.isLite ? !LITE_EMAIL_TO_CC_ROLE_EXCLUDED.includes(opt.value) && !values.toRoles?.includes(opt.value) :!values.toRoles?.includes(opt.value)
                                                                })}
                                                            onChange={(opt) => {
                                                                let selectedValues = opt
                                                                    ? opt.map(
                                                                        (x) => x.value
                                                                    )
                                                                    : [];
                                                                setFieldValue(
                                                                    'ccRoles',
                                                                    selectedValues
                                                                );
                                                                setFieldValue(
                                                                    'ccRolesName',
                                                                    opt
                                                                        ? opt.map(
                                                                            (x) =>
                                                                                x.label
                                                                        )
                                                                        : []
                                                                );
                                                            }}
                                                            value={emailConfigRoleList.filter(
                                                                (option) =>
                                                                    values.ccRoles?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.cc_roles'
                                                            )}
                                                        </Label>
                                                    </FormGroup>
                                                    {values.emailTemplateTypeId != 8 && values.emailTemplateTypeId != 10 && values.emailTemplateTypeId != 11 && values.emailTemplateTypeId != 12 && values.emailTemplateTypeId != 14 && values.emailTemplateTypeId != 15 && (
                                                        <FormGroup className="col-lg-3">
                                                            <Field
                                                                as={IntComponent}
                                                                name="daysFirstNotification"
                                                                className="form-control"
                                                                placeholder={__t(
                                                                    'form_labels.entity.days_first_notification'
                                                                )}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.entity.days_first_notification'
                                                                )}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.entity.days_first_notification'
                                                                ),
                                                                values.daysFirstNotification,
                                                                'required|numeric|min:1,num',
                                                                {
                                                                    messages: {
                                                                        required: __t(
                                                                            'validations.entity.days_first_notification_required'
                                                                        ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                    )}
                                                    {values.emailTemplateTypeId != 8 && values.emailTemplateTypeId != 9 && values.emailTemplateTypeId != 10 && values.emailTemplateTypeId != 11 && values.emailTemplateTypeId != 12 && values.emailTemplateTypeId != 14 && values.emailTemplateTypeId != 15 && (
                                                        <FormGroup className="col-lg-3">
                                                            <Field
                                                                as={IntComponent}
                                                                name="daysSecondNotification"
                                                                className="form-control"
                                                                placeholder={__t(
                                                                    'form_labels.entity.days_second_notification'
                                                                )}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.entity.days_second_notification'
                                                                )}
                                                            </Label>
                                                            {(values.emailTemplateTypeId != 4) && this.validator.message(
                                                                __t(
                                                                    'form_labels.entity.days_second_notification'
                                                                ),
                                                                values.daysSecondNotification,
                                                                `numeric|min:${Number((values?.daysFirstNotification) ? values.daysFirstNotification : 1) + 1},num`
                                                            )}

                                                            {(values.emailTemplateTypeId === 4) && this.validator.message(
                                                                __t('form_labels.entity.days_second_notification'),
                                                                values.daysSecondNotification,
                                                                `numeric|min:1,num|max:${Number(values.daysFirstNotification || 1) - 1},num`
                                                            )}

                                                        </FormGroup>
                                                    )}
                                                </>
                                            )}
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                        {
                                                            this.props
                                                                .operationType
                                                        }
                                                    </Button>
                                                    <Button
                                                        type="reset"
                                                        color="secondary"
                                                        onClick={() =>
                                                            this.resetForm()
                                                        }
                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedEmailTemplateTypeListDropDown: selectSortedEmailTemplateTypeList(),
});
const withConnect = connect(mapStatetoProps, {
    getEmailTemplateTypesListRequest,
});
export default compose(
    withConnect,
    withEmailTemplateReducer,
    withEmailTemplateSaga,
    withRoleReducer,
    withRoleSaga,

    withRouter
)(AddEditEmailTemplateFormModal);
