export const monthList = [
    { label: 'January', value: 'January' },
    { label: 'February', value: 'February' },
    { label: 'March', value: 'March' },
    { label: 'April', value: 'April' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'June' },
    { label: 'July', value: 'July' },
    { label: 'August', value: 'August' },
    { label: 'September', value: 'September' },
    { label: 'October', value: 'October' },
    { label: 'November', value: 'November' },
    { label: 'December', value: 'December' },
];

export const completionStatusList = [
    {
        value: 1,
        label: 'Completed'
    },
    {
        value: 2,
        label: 'Conditional Execution'
    },
    {
        value: 3,
        label: 'Dropped'
    },
];

export const expenseTypeList = [
    {
        value: 1,
        label: 'None'
    },
    {
        value: 2,
        label: 'Statutory Payment'
    },
    {
        value: 3,
        label: 'Penalty'
    },
];


export const discrepancyTypeList = [
    {
        value: 1,
        label: 'Monetary'
    },
    {
        value: 2,
        label: 'Non-monetary'
    },
    {
        value: 3,
        label: 'Others'
    },
];

export const userAllocationMappedList = [
    {
        value: 1,
        label: 'All'
    },
    {
        value: 2,
        label: 'Pending Mapping'
    },
    {
        value: 3,
        label: 'Completed Mapping'
    },
];

export const departmentsList = [
    { Id: 1, Name: 'HR' },
    { Id: 2, Name: 'Secretarial' },
    { Id: 3, Name: 'IT' },
    { Id: 4, Name: 'Tax' },
    { Id: 5, Name: 'Accounting' },
    { Id: 6, Name: 'Finance' },
];

export const riskStatusList = [
    { Id: 1, Name: 'High' },
    { Id: 2, Name: 'Medium' },
    { Id: 3, Name: 'Low' },
];

export const complianceStatusList = [
    { Id: 1, Name: 'Pending At Submitter' },
    { Id: 2, Name: 'Pending At Approver' },
    { Id: 3, Name: 'Delayed Compliance' },
    { Id: 4, Name: 'Conditional Compliance' },
    { Id: 5, Name: 'Due' },
    { Id: 6, Name: 'Overdue' },
];

export const servicesList = [
    { Id: 1, Name: 'New Entity Set Up Support' },
    { Id: 2, Name: 'Multi-National Payroll Management' },
    { Id: 3, Name: 'CLM Services' },
    { Id: 4, Name: 'Litigation/Case Management Services' },
    { Id: 5, Name: 'Global Tax/Accounting Inquiry' },
    { Id: 6, Name: 'Last Mile Compliance Execution Support' },
];

export const topicsList = [
    { Id: 1, Name: 'Commercial Law' },
    { Id: 2, Name: 'Unfair competition' },
    { Id: 3, Name: 'Compliance' },
    { Id: 4, Name: 'Regulatory Compliance' },
    { Id: 5, Name: 'Common Law' },
    { Id: 6, Name: 'Financial Law' },
];

export const languageList = [
    { Id: 1, Name: 'Arabic' },
    { Id: 2, Name: 'Chinese' },
    { Id: 3, Name: 'English' },
    { Id: 4, Name: 'Hindi' },
];

export const delegationTypeList = [
    { value: 'PERMANENT', label: 'Permanent' },
    { value: 'TEMPORARY', label: 'Temporary' },
];

export const roleList = [
    { value: 'SUBMITTER', label: 'Submitter' },
    { value: 'FIRST_APPROVER', label: 'Reviewer' },
    { value: 'SECOND_APPROVER', label: 'Approver' },
    { value: 'AUDITOR', label: 'Auditor' },
    { value: 'HOD', label: 'HOD' },
    { value: 'COLLABORATOR', label: 'Collaborator' },
];

export const dashboardRoleList = [
    { value: 'SUBMITTER', label: 'Submitter' },
    { value: 'FIRST_APPROVER', label: 'Reviewer' },
    { value: 'SECOND_APPROVER', label: 'Approver' },
    { value: 'AUDITOR', label: 'Auditor' },
    { value: 'COLLABORATOR', label: 'Collaborator' },
];

export const emailConfigRoleList = [
    { value: 'SUBMITTER', label: 'Submitter' },
    { value: 'FIRST_APPROVER', label: 'Reviewer' },
    { value: 'SECOND_APPROVER', label: 'Approver' },
    { value: 'AUDITOR', label: 'Auditor' },
    { value: 'HOD', label: 'HOD' },
    { value: 'COLLABORATOR', label: 'Collaborator' },
    // { value: 'ENTITY_TEAM', label: 'Entity Team' },
    { value: 'MANAGEMENT_TEAM', label: 'Management Team' },
];

export const projectStatusList = [
    {
        value: 1,
        label: 'To do'
    },
    {
        value: 2,
        label: 'Inprogress'
    },
    {
        value: 3,
        label: 'Completed'
    },
];

export const taskPriorityStatusList = [
    {
        value: 'Urgent',
        label: 'Urgent'
    },
    {
        value: 'High',
        label: 'High'
    },
    {
        value: 'Normal',
        label: 'Normal'
    },
    {
        value: 'Low',
        label: 'Low'
    },
];

export const roleType = [
    {
        value: 'CHECKER',
        label: 'Checker'
    },
    {
        value: 'APPROVER',
        label: 'Approver'
    },
    {
        value: 'UPDATER',
        label: 'Updater'
    },
];

export const listTypeForTaskDropdown = [
    { value: 'ALL', label: 'All' },
    { value: 'PARENT_TASK', label: 'Statutory' },
    { value: 'SUB_TASK', label: 'Non-Statutory' },
];

export const EXCLUDED_TASK_TYPE=["SUB_TASK"];

export const CompliancePeriodStatusList = [
    {
        value: 'Past',
        label: 'Past'
    },
    {
        value: 'Present',
        label: 'Present'
    },
    {
        value: 'Future',
        label: 'Future'
    },
    {
        value: 'NA',
        label: 'NA'
    },
];

export const legalUpdatesStatus = [
    {
        value: 1,
        label: 'Pending'
    },
    {
        value: 2,
        label: 'Pending With Approver'
    },
    {
        value: 3,
        label: 'Pending With Updater'
    },
    {
        value: 4,
        label: 'Acknowledged'
    },
    {
        value: 5,
        label: 'Rejected By Approver'
    },
    {
        value: 6,
        label: 'Rejected By Updater'
    },
];

export const priorityStatus = [
    {
        value: 'CRITICAL',
        label: 'Critical'
    },
    {
        value: 'HIGH',
        label: 'High'
    },
    {
        value: 'MEDIUM',
        label: 'Medium'
    },
];

export const contentStatus = [
    {
        value: 1,
        label: 'Content Updated'
    },
    {
        value: 2,
        label: 'Content Not Updated'
    },
];

export const documentAttachedValues = [
    {
        value: 'Y',
        label: 'Yes'
    },
    {
        value: 'N',
        label: 'No'
    },
];

export const localAttornyValidationStatus = [
    {
        value: 'Y',
        label: 'YES'
    },
    {
        value: 'N',
        label: 'NO'
    },
];

export const displayModeValues = [
    {
        value: 0,
        label: 'No'
    },
    {
        value: 1,
        label: 'Yes'
    },
];

export const isGtValues = [
    {
        value: 0,
        label: 'No'
    },
    {
        value: 1,
        label: 'Yes'
    },
];

export const fundStatusList = [
    { value: 1, label: 'To-do' },
    { value: 3, label: 'Completed' },
    { value: 4, label: 'Pending with Reviewer' },
    { value: 5, label: 'Rejected By Reviewer' },
    { value: 6, label: 'Pending with Approver' },
    { value: 7, label: 'Rejected By Approver' }
];

export const customStatusList = [
    { value: 16, label: 'Overdue' },
    { value: 17, label: 'Delay Reporting' },
];

export const reportTypeList = [
    { value: 'department', label: 'Department wise analysis' },
    { value: 'user', label: 'User wise analysis' },
    { value: 'country', label: 'Country wise analysis' },
    { value: 'entity', label: 'Entity wise analysis' },
    { value: 'law', label: 'Regulation wise analysis' },
    { value: 'auditor', label: 'Auditor wise analysis' },
];

export const certificateReportTypeList = [
    { value: 'department', label: 'Department wise analysis' },
    // { value: 'user', label: 'User wise analysis' },
    { value: 'country', label: 'Country wise analysis' },
    { value: 'entity', label: 'Entity wise analysis' },
    { value: 'law', label: 'Regulation wise analysis' },
    { value: 'auditor', label: 'Auditor wise analysis' },
];

export const statusvalues = [
    {
        value: 1,
        label: 'All'
    },
    {
        value: 2,
        label: 'Active'
    },
    {
        value: 3,
        label: 'InActive'
    },
];
export const userTypeList = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 2,
        label: 'Inactive'
    },
    {
        value: 3,
        label: 'Dormant'
    },
];

export const ticketStatusList = [
    {
        value: 1,
        label: 'Open'
    },
    {
        value: 2,
        label: 'In Progress'
    },
    {
        value: 3,
        label: 'Resolved'
    },
    {
        value: 4,
        label: 'On Hold'
    },
    {
        value: 5,
        label: 'Reopen'
    }
];

export const ticketPriorityList = [
    {
        value: 1,
        label: 'Low'
    },
    {
        value: 2,
        label: 'Medium'
    },
    {
        value: 3,
        label: 'High'
    },
    {
        value: 4,
        label: 'Urgent'
    },
];

export const frequencyDropdown = [
    {
        value: 1,
        label: 'Weekly'
    },
    {
        value: 2,
        label: 'Monthly'
    },
    {
        value: 3,
        label: 'Quarterly'
    },
    {
        value: 4,
        label: 'Half Yearly'
    },
    {
        value: 5,
        label: 'Yearly'
    },

];

export const liteStatusOfEL = [
    {
        value: 1,
        label: 'Pending'
    },
    {
        value: 2,
        label: 'To do'
    },
    {
        value: 3,
        label: 'Executed'
    },
];


export const PaymentStatus = [
    {
        value: 0,
        label: 'Pending'
    },
    {
        value: 1,
        label: 'Completed'
    },
];

export const PlanList = [
    {
        value: 1,
        label: 'Basic'
    },
    {
        value: 2,
        label: 'Premium'
    },
    {
        value: 3,
        label: 'Renewal Current Plan'
    },
];
export const TrailExtensionPeriodDuration = [
    {
        value: 5,
        label: '5 Days'
    },
    {
        value: 10,
        label: '10 Days'
    },
    {
        value: 15,
        label: '15 Days'
    },
    {
        value: 20,
        label: '20 Days'
    },
    {
        value: 25,
        label: '25 Days'
    },
    {
        value: 30,
        label: '30 Days'
    },
];