import React, { Component } from 'react';
import { Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ACTIVE, CANCEL, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import {
    getDepartmentListByStatusRequest,
    getUsersByEntityRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import __t from 'i18n/translator';
import CustomisedModal from 'components/Application/CustomisedModal';
import { selectSortedUserList } from 'store/User/selector';
import { createStructuredSelector } from 'reselect';
import { userIsLite } from 'helpers/litehelper';
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
class AddEditHodFormModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            hodList: this.props.hodList,
        };
    }
    componentDidMount() {
        this.props.getDepartmentListByStatusRequest(ACTIVE,userIsLite() ? 1:null);
        if (this.props.entityId)
            this.props.getUsersByEntityRequest(this.props.entityId);
    }
    resetForm = () => {
        this.validator.hideMessages();
        this.props.onFormReset();
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormValid(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.hodList !== this.props.hodList) {
            this.setState({
                hodList: nextprops.hodList,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <CustomisedModal
                    modalName={
                        this.props.operationType === SUBMIT
                            ? __t('form_labels.entity.add_hod')
                            : __t('form_labels.entity.edit_hod')
                    }
                    isModalOpen={this.props.isModalOpen}
                    width="150%"
                    onModalDismiss={() => this.props.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.props.hodData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Row className="d-flex mr-0">
                                            <FormGroup className="col mb-0">
                                                {this.props.operationType ===
                                                SUBMIT ? (
                                                    <Field
                                                        component={Select}
                                                        name="departmentId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedDepartmentListDropDown.filter(item=>item.companyGroupId==this.props.companyGroupId)}
                                                        onChange={(opt) => {
                                                            let department = opt
                                                                ? opt.map(
                                                                      (x) =>
                                                                          x.value
                                                                  )
                                                                : [];
                                                            let departmentName =
                                                                opt
                                                                    ? opt.map(
                                                                          (x) =>
                                                                              x.label
                                                                      )
                                                                    : [];

                                                            setFieldValue(
                                                                'departmentId',
                                                                department
                                                            );
                                                            setFieldValue(
                                                                `departmentName`,
                                                                departmentName
                                                            );
                                                        }}
                                                        isMulti
                                                        value={this.props.sortedDepartmentListDropDown?.filter(
                                                            (option) =>
                                                                Array.isArray(
                                                                    values.departmentId
                                                                )
                                                                    ? values.departmentId?.includes(
                                                                          option.value
                                                                      )
                                                                    : []
                                                        )}
                                                        isClearable={true}
                                                        closeMenuOnSelect={false}
                                                    />
                                                ) : (
                                                    <Field
                                                        component={Select}
                                                        name="departmentId"
                                                        className="form-control selectbox"
                                                        options={this.props.sortedDepartmentListDropDown}
                                                        onChange={(opt) => {
                                                            setFieldValue(
                                                                `departmentId`,
                                                                opt.value
                                                            );
                                                            setFieldValue(
                                                                `departmentName`,
                                                                opt.label
                                                            );
                                                        }}
                                                        value={this.props.sortedDepartmentListDropDown.filter(
                                                            (option) =>
                                                                option.value ===
                                                                values.departmentId
                                                        )}
                                                    />
                                                )}

                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.department'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.department'
                                                    ),
                                                    values.departmentId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.department_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="col mb-0">
                                                <Field
                                                    component={Select}
                                                    name="userId"
                                                    className="form-control selectbox"
                                                    options={this.props.sortedUserListDropDown.filter(
                                                        (opt) => {
                                                            let checkUsersWithSelectedDepartment =
                                                                this.state.hodList
                                                                    .filter(
                                                                        (
                                                                            optU
                                                                        ) =>
                                                                            optU.departmentId ==
                                                                            values.departmentId
                                                                    )
                                                                    ?.map(
                                                                        (
                                                                            optUR
                                                                        ) =>
                                                                            optUR.userId
                                                                    );
                                                            return !checkUsersWithSelectedDepartment.includes(
                                                                opt.value
                                                            );
                                                        }
                                                    )}
                                                    onChange={(opt) => {
                                                        setFieldValue(
                                                            `userId`,
                                                            opt.value
                                                        );
                                                        setFieldValue(
                                                            `userName`,
                                                            opt.label
                                                        );
                                                    }}
                                                    value={this.props.sortedUserListDropDown.filter(
                                                        (option) =>
                                                            option.value ===
                                                            values.userId
                                                    )}
                                                />
                                                <Label className="form-label">
                                                    {__t(
                                                        'form_labels.entity.user'
                                                    )}{' '}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {this.validator.message(
                                                    __t(
                                                        'form_labels.entity.user'
                                                    ),
                                                    values.userId,
                                                    'required',
                                                    {
                                                        messages: {
                                                            required: __t(
                                                                'validations.entity.user_required'
                                                            ),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                            <FormGroup className="mb-0 d-flex align-items-centerr">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-2"
                                                >
                                                    {this.props.operationType}
                                                </Button>
                                                <Button
                                                    type="reset"
                                                    color="secondary"
                                                    onClick={
                                                        this.props
                                                            .onModalDismiss
                                                    }
                                                >
                                                    {CANCEL}
                                                </Button>
                                            </FormGroup>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedDepartmentListDropDown: selectSortedDepartmentList(),
    sortedUserListDropDown: selectSortedUserList(),
});
const withConnect = connect(mapStatetoProps, {
    getDepartmentListByStatusRequest,
    getUsersByEntityRequest,
});
export default compose(
    withConnect,
    withDepartmentReducer,
    withDepartmentSaga,
    withUserReducer,
    withUserSaga,
    withRouter
)(AddEditHodFormModal);
