import CustomisedModal from 'components/Application/CustomisedModal';
import { Field, Form, Formik } from 'formik';
import { getValueByKey } from 'helpers/authUtils';
import { decryptData } from 'helpers/encryptor';
import __t from 'i18n/translator';
import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    Collapse,
    FormGroup,
    Label,
} from 'reactstrap';
import * as authConstants from 'constants/authConstants';

import { ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
class AccountEntityAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewDialog: false,
            isModalOpen: false,
            isRmUser:JSON.parse(decryptData(getValueByKey(authConstants.IS_RM_USER))),
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = () => {
        this.setState(
            {
                viewDialog: !this.state.viewDialog,
            },
            () => {
                if (this.props?.dataEvent) {
                    this.props.toggleCollapse(this.props.dataEvent);
                }
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (nextprops.viewDialog !== this.props.viewDialog) {
            this.setState({
                viewDialog: nextprops.viewDialog,
            });
        }
    }

    onModalDismiss = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        });
    };

    render() {
        const currentRole = JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))

        return (
            <Card className="accordion-main">
                <CardHeader className="p-0">
                    <div
                        onClick={() => this.toggle()}
                        className="page-header accordion_header d-flex justify-content-between pl-3 py-2 m-0 cursor-pointer"
                    >
                        <div className="accordion-title">
                            <span>
                                <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292893L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292894L7.70711 6.29289L6.29289 7.70711Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <h6 className="m-0">{this.props.entityName}</h6>
                        </div>
                        {
                           !this.props.isAccountTerminated && (currentRole[0].roleId == ROLE_SUPER_ADMIN || this.state.isRmUser) ?  <p
                            onClick={(event) => {
                                event.stopPropagation();
                               this.props.OpenAddUserModel();
                            }}
                            className="edit-btn"
                            title="Edit"
                        >
                            {/* <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.02344 13.9062H14.3118"
                                    stroke="currentColor"
                                    stroke-width="1.09375"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.31875 1.76704C8.88433 1.09109 9.90103 0.991978 10.591 1.54606C10.6291 1.57612 11.8548 2.52828 11.8548 2.52828C12.6128 2.98649 12.8483 3.9606 12.3798 4.70397C12.3549 4.74378 5.42538 13.4116 5.42538 13.4116C5.19484 13.6992 4.84488 13.869 4.47087 13.8731L1.81716 13.9064L1.21924 11.3756C1.13548 11.0198 1.21924 10.6461 1.44978 10.3585L8.31875 1.76704Z"
                                    stroke="currentColor"
                                    stroke-width="1.09375"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.03613 3.375L11.0117 6.42811"
                                    stroke="currentColor"
                                    stroke-width="1.09375"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg> */}
                            <i className="fas fa-user-plus"></i>
                        </p>:null
                        }
                       
                    </div>
                   {this.props.customizeModel}
                </CardHeader>
                <Collapse
                    className="entitydetail-table-box table-responsive small_scroll"
                    isOpen={this.state.viewDialog}
                >
                    {this.props.children}
                </Collapse>
            </Card>
        );
    }
}

export default AccountEntityAccordion;
