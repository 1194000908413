import produce from 'immer';
import { CLIENT_REGISTRATION_LIST_RESPONSE, CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE, CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE, LITE_ACTIVE_ENTITY_RESPONSE, LITE_CITY_LIST_RESPONSE, LITE_CLIENT_RESPONSE_QUESTION_RESPONSE, LITE_COMPLIANCE_ALLOCATION_RESPONSE, LITE_COMPLIANCE_GENERATE_RESPONSE, LITE_COMPLIANCE_LIST_RESPONSE, LITE_COMPLIANCE_START_STOP_SCHEDULER_RESPONSE, LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE, LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE, LITE_ENTITY_DETAILS_RESPONSE, LITE_ENTITY_SETUP_LOCATION_LIST_RESPONSE, LITE_ENTITY_SETUP_STATE_CITY_RESPONSE, LITE_ENTITY_UPDATE_RESPONSE, LITE_ENTITY_USER_RESPONSE, LITE_MANAGEMENT_USER_LIST_RESPONSE, LITE_QUESTION_ADD_RESPONSE, LITE_QUESTION_INACTIVE_RESPONSE, LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_RESPONSE, LITE_QUESTION_LIST_RESPONSE, LITE_QUESTION_RESET_RESPONSE, LITE_QUESTION_SINGLE_RESPONSE, LITE_QUESTION_UPDATE_RESPONSE, LITE_USER_MAPPED_RESPONSE } from './actionTypes';
import { DANGER, SUCCESS } from 'constants/commonConstants';

export const initialState = {
    registeredAccounts: [],
    resendEmail: null,
    statusUpdate: null,
    liteCountriesList: null,
    listOfLiteEntity: null,
    addLiteEntity: null,
    listManagementUser: [],
    inActiveEntity: null,
    entityDetails: [],
    updateEntityApiResponse: null,
    listOfQuestionApiResponse: [],
    addQuestionApiResponse: null,
    inActiveQuestionApiResponse: null,
    updateQuestionApiResponse: null,
    singleQuestionApiResponse: null,
    resetQuestionApiResponse: null,
    listComplianceApiResponse: null,
    liteCityListApiResponse: null,
    liteOfQuestionAsPerPracticeAreaApiResponse: null,
    liteClientQuestionResponseApiResponse: null,
    liteEntitySetupStateCityApiResponse:null,
    liteComplianceGenerateApiResponse:null,
    addLiteUserMappedApiResponse:null,
    liteComplianceAllocationListApiResponse:null,
    liteEntitySetupLocationListApiResponse:null,
    liteComplianceStartStopScheduler:null
}


const registeredAccountReducer = (state = initialState, action = '') =>
    produce(state, draft => {
        switch (action.type) {
            case CLIENT_REGISTRATION_LIST_RESPONSE:
                draft.registeredAccounts = action.payload.data;
                break;
            case CLIENT_REGISTRATION_RESEND_EMAIL_RESPONSE:
                draft.resendEmail = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                };
                break;
            case CLIENT_REGISTRATION_STATUS_UPDATE_CLIENT_RESPONSE:
                draft.statusUpdate = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                };
                break;
            case LITE_COUNTRIES_LIST_AS_PER_PLAN_RESPONSE:
                draft.liteCountriesList = {
                    message: action.payload.data.message,
                    data: action.payload.data.data,
                    responseType: action.payload.data.status,
                }
                break;
            case LITE_COUNTRIES_WISE_ENTITY_LIST_RESPONSE:
                draft.listOfLiteEntity = action.payload.data.list;
                break;
            case LITE_COUNTRIES_WISE_ENTITY_ADD_RESPONSE:
                draft.addLiteEntity = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_MANAGEMENT_USER_LIST_RESPONSE:
                draft.listManagementUser = action.payload.data
                break;
            case LITE_ACTIVE_ENTITY_RESPONSE:
                draft.inActiveEntity = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_ENTITY_DETAILS_RESPONSE:
                draft.entityDetails = action.payload?.data?.data;
                break;
            case LITE_ENTITY_UPDATE_RESPONSE:
                draft.updateEntityApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_QUESTION_LIST_RESPONSE:
                draft.listOfQuestionApiResponse = action.payload.data;
                break;
            case LITE_QUESTION_ADD_RESPONSE:
                draft.addQuestionApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_QUESTION_INACTIVE_RESPONSE:
                draft.inActiveQuestionApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_QUESTION_UPDATE_RESPONSE:
                draft.updateQuestionApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_QUESTION_SINGLE_RESPONSE:
                draft.singleQuestionApiResponse = action.payload.data.questionDetails;
                break;
            case LITE_QUESTION_RESET_RESPONSE:
                draft.resetQuestionApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_COMPLIANCE_LIST_RESPONSE:
                draft.listComplianceApiResponse = action.payload.data;
                break;
            case LITE_CITY_LIST_RESPONSE:
                draft.liteCityListApiResponse = action.payload.data;
                break;
            case LITE_QUESTION_LIST_AS_PER_PRACTICE_AREA_RESPONSE:
                draft.liteOfQuestionAsPerPracticeAreaApiResponse = action.payload.data;
                break;
            case LITE_CLIENT_RESPONSE_QUESTION_RESPONSE:
                draft.liteClientQuestionResponseApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_ENTITY_SETUP_STATE_CITY_RESPONSE:
                draft.liteEntitySetupStateCityApiResponse =action.payload.data;
                break;
            case LITE_COMPLIANCE_GENERATE_RESPONSE:
                draft.liteComplianceGenerateApiResponse = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_USER_MAPPED_RESPONSE:
                draft.addLiteUserMappedApiResponse={
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                }
                break;
            case LITE_COMPLIANCE_ALLOCATION_RESPONSE:
                draft.liteComplianceAllocationListApiResponse = action.payload.data;
                break;
            case LITE_ENTITY_SETUP_LOCATION_LIST_RESPONSE:
                draft.liteEntitySetupLocationListApiResponse= action.payload.data;
                break;
            case LITE_COMPLIANCE_START_STOP_SCHEDULER_RESPONSE:
                draft.liteComplianceStartStopScheduler = {
                    message: action.payload.data.message,
                    responseType: action.payload.data.status ? SUCCESS : DANGER,
                };
                break;
            default:
                state = { ...state };
                break;
        }
    });
export default registeredAccountReducer;