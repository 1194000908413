import React, { Component } from 'react';
import { Button, Card, Col, FormGroup, Label, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logoLight from '../../images/logo-light.webp';
import logoDark from '../../images/logo-dark.webp';
import { forgotPasswordRequest } from 'store/actions';
import reducer from 'store/Auth/ForgotPassword/reducer';
import saga from 'store/Auth/ForgotPassword/saga';
import { makeSelectField } from 'store/Auth/ForgotPassword/selector';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { toastrMessage } from 'helpers/messageHelper';
import { Field, Form, Formik } from 'formik';
import SimpleReactValidator from 'simple-react-validator';
import __t from 'i18n/translator';
import { isDarkMode } from 'helpers/generalUtils';
import Login_slider from './LoginSlider';
import LoginPageSlider from 'components/Application/LoginPageSlider';
const key = 'forgotPassword';
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = { userName: "", email: "" }
        this.validator = new SimpleReactValidator();
    }
    formSubmit = values => {
        if (this.validator.allValid()) {
            this.setState({ values });
            this.props.forgotPasswordRequest(values.email);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.forgotPasswordApiResponse &&
            this.props.forgotPasswordApiResponse !== nextprops.forgotPasswordApiResponse
        ) {
            toastrMessage(nextprops.forgotPasswordApiResponse.message, nextprops.forgotPasswordApiResponse.responseType);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="login-wrapper">
                    <LoginPageSlider />
                    <div className="wrapper-page">
                        <Row className='h-100 d-flex justify-content-center align-items-center login_form_row'>
                            <Col md="7" className="login-form">
                                <Card className="login_sub_slider">
                                    <h2 className='login_sub_heading'>Welcome to <span>GCMS Pro</span></h2>
                                    <div className='login_inner_slider'>
                                        <Login_slider />
                                    </div>
                                </Card>
                                <Card className="account-card">
                                    <div className='login-main'>
                                        <div className='loging-head'>
                                            <div className="logo logo-login">
                                                {isDarkMode() ? (
                                                    <img src={logoLight} alt="logo" />
                                                ) : (
                                                    <img src={logoLight} alt="logo" />
                                                )}
                                                {/* <h3>
                                                    {__t(
                                                        'common.app_name'
                                                    )}
                                                </h3> */}
                                            </div>
                                        </div>
                                        <Formik
                                            initialValues={this.state}
                                            enableReinitialize={true}
                                            onSubmit={this.formSubmit}
                                        >
                                            {({ values }) => (
                                                <Form className="form-horizontal form-login">
                                                    <h3>{__t('page_headers.forgot_password')}</h3>
                                                    <FormGroup className="col-12">
                                                        <Field
                                                            autoFocus
                                                            name="email"
                                                            className="form-control"
                                                            placeholder={__t('form_labels.forgot_password.enter_email')}
                                                            type="text"
                                                        />
                                                        <Label className="form-label">
                                                            {__t('form_labels.forgot_password.email')}
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.forgot_password.email'
                                                            ),
                                                            values.email,
                                                            'required|email|max:150',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.forgot_password.email_required'
                                                                        ),
                                                                    email:
                                                                        __t(
                                                                            'validations.forgot_password.email_email'
                                                                        ),
                                                                    max: __t(
                                                                        'validations.forgot_password.email_max',
                                                                        {
                                                                            max: 150,
                                                                        }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Col lg="12">
                                                        <Button
                                                            color="primary"
                                                            className="w-100 waves-effect waves-light"
                                                            type="submit"
                                                        >{__t('form_labels.forgot_password.reset_password_btn')}</Button>
                                                        <Link to='/login' className='mt-3 d-flex align-items-center justify-content-center forgot_password'>Back To Login</Link>
                                                    </Col>
                                                </Form>
                                            )}
                                        </Formik>
                                        <div className="text-center copy-right w-100">
                                            <p>© {new Date().getFullYear()} {__t('common.footer_text')} </p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    forgotPasswordApiResponse: makeSelectField('forgotPasswordApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    forgotPasswordRequest
});
export default compose(withConnect, withReducer, withSaga, withRouter)(ForgotPassword);
