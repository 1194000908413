import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PremiumIcon from 'app/Lite/components/PremiumIcon';
import { userIsLite } from 'helpers/litehelper';
import { LITE_PLAN_BASIC } from 'constants/databaseConstants';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';

class MenuItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLite:JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)))
    };
    this.MenuClick = this.MenuClick.bind(this);
  }

  MenuClick = (event) => {
    var width = window.innerWidth;
    if (width < 992) {
      document.body.classList.toggle('enlarged');
    }
  }

  checkIsLiteAccess=(item)=>{
    if(item.isLiteIcon && userIsLite()){
        if(item.isPlanWise){
            if(this.state.isLite?.litePlan?.id == LITE_PLAN_BASIC){
              return <PremiumIcon isSmall/>
            }else{
              return null
            }
        }else if(item.isSupportTicketSupport){
          if(!this.state?.isLite?.litePlan?.isSupportTicketEnabled){ 
            return <PremiumIcon isSmall/>
          }else{
            return null
          }
        }else if(item.isLegalWatchDog){
          if((!this.state?.isLite.litePlan?.isLegalWatchdogEnabled)){
            return <PremiumIcon isSmall/>
          }else{
            return null
          }
        }else{
          return <PremiumIcon isSmall/>
        }
    }else{
      return null
    }
  }

  render() {
    const item = this.props.item || {};
    return (
      <React.Fragment>
        <Link to={item?.isModifiedUrl ? item?.url + `/${this.props.isLite?.clientAccountId}` : item?.url} onClick={this.MenuClick}>
          {item.svgIcon && (
            <React.Fragment>
              {parse(item.svgIcon)}
            </React.Fragment>
          )}
          {item.badgeicon && <span className={item.badgeicon}>{item.bagdevalue} </span>}
          <span className='d-flex justify-content-between align-items-center'>{' '}<span>{item.label}</span> <span>{this.checkIsLiteAccess(item)}</span></span>
        </Link>
      </React.Fragment>
    );
  }
}

export default MenuItem;




