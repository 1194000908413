import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Progress,
    Button,
} from 'reactstrap';
import { compose } from 'redux';
import { Field, Form, Formik } from 'formik';
import Select, { components } from 'react-select';
import CountUp from 'react-countup';
import classnames from 'classnames';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUserEntityListRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { Chart as GoogleChart } from 'react-google-charts';
import { geoOptions } from './Charts/GeoChartData.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ArcElement,
    LineController,
    BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import ApexCharts from 'react-apexcharts';
import { ACTIVE } from 'constants/commonConstants';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { TableBody, TableHead } from '@material-ui/core';
import { barChartForEntityOnCGAEADashboard, barChartForUserOnCGAEADashboard, criticalRiskGraph, newComplianceUpdatesAnalysisByBarChart } from './CommonFunctions.js';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCityList } from 'store/Masters/City/selector.js';
import { selectSortedStateList } from 'store/Masters/State/selector.js';
import { compareString, isDarkMode } from 'helpers/generalUtils.js';
import Laws from '../../images/laws_icon+.png';
import Country from '../../images/country_icon+.png';
import { selectSortedEntityList } from 'store/Entity/selector.js';
import { ROLE_COMPANY_GROUP_ADMIN } from 'constants/databaseConstants.js';
import moment from 'moment';
import Slider from 'react-slick';
import QuickAccessTable from './QuickAccessTable.js';
import CustomisedDatatable from 'components/Application/CustomisedDatatable.js';
import OverallUpdateIndex from './OverallUpdateIndex.js';
import OverallAllocationIndex from './OverallAllocationIndex.js';
import { getValueByKey } from 'helpers/authUtils.js';
import * as authConstants from 'constants/authConstants';
import { PopupPremium, userIsLite } from 'helpers/litehelper.js';
import CommonOverlay from './CommonOverlay.js';
import CommonOverlayTile from './CommonOverlayTile.js';
import PremiumIcon from 'app/Lite/components/PremiumIcon.js';
import { decryptData } from 'helpers/encryptor.js';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
/*--------Charts--------*/
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    LineController,
    BarController
);
const initialDashboardSearchDataState = () => {
    return {
        entityId: '',
        countryId: 0,
        stateId: '',
        cityId: ''
    };
};
const placeholderStyle = (base) => ({
    ...base,
    fontSize:
        '1em',
    fontWeight: 500,
});

const logColumns = [
    // {
    //     label: 'Country',
    //     field: 'countryName',
    // },
    // {
    //     label: 'Entity Name',
    //     field: 'entityName',
    // },
    // {
    //     label: 'Department Name',
    //     field: 'departmentName',
    // },
    {
        label: 'User Name',
        field: 'userName'
    },
    // {
    //     label: 'User Role',
    //     field: 'userRole'
    // },
    {
        label: 'User Email ID',
        field: 'userEmail'
    },
    {
        label: 'User Contact No.',
        field: 'userContactNo'
    },
    {
        label: 'Last Login Date',
        field: 'lastLoginDate'
    },
    {
        label: 'Total System Usage',
        field: 'totalLoginTime'
    }
    // {
    //     label: 'Compliance Score %',
    //     field: 'complianceScore'
    // }
];

const departmentColumns = [
    {
        label: 'Department Name',
        field: 'departmentName',
    }
];
class AdminIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            dashboardSearchData: initialDashboardSearchDataState(),
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            totals: {
                activeCountries: 0,
                activeEntities: 0,
                activeDepartments: 0,
                activeUsers: 0,
                totalSystemHours: 0
            },
            lawCompliancePerEntityOptions: {},
            lawCompliancePerEntityData: [],
            lawCompliancePerEntityCurrentIndex: 0,
            defaultEntityIndex: 4,
            statusOfNewAdditions: {
                pendingCount: 0,
                acceptedCount: 0,
                rejectedCount: 0,
                discussionCount: 0
            },
            statusOfChangeInExistingCompliance: {
                periodicityCount: 0,
                dueDatesCount: 0,
                eventsCount: 0,
                documentsCount: 0,
                deactivatedComplianceCount: 0
            },
            usersPerEntityOptions: {},
            usersPerEntityData: [],
            usersPerEntityCurrentIndex: 0,
            defaultUserIndex: 4,
            supportTicketTracker: {
                solvedInLast24Hours: 0,
                pendingForLast3Days: 0,
                ticketOpenLast48Hours: 0,
                totalTicketsRaised: 0
            },
            quickAccessDataPoints: {
                newLawsCompliances: [],
                newAssignedEvents: [],
                delgatedCompliances: [],
            },
            last15DaysDate: null,
            isQuickAccessType: '',
            showUserLogs: false,
            showDepartments: false,
            activeDepartmentsList: [],
            clickableType: '',
            allocationDataList: [],
            updatesDataList: [],
            overAllUpdatesView: false,
            overAllNewAdditionView: false,
            allocationDataList: []
        };

        this.handleUserPerEntityClick = this.handleUserPerEntityClick.bind(this);
    }
    componentDidMount() {
        this.props.getUserEntityListRequest(ROLE_COMPANY_GROUP_ADMIN);
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? [countryIds] : []);
        this.props.getCityListByStateRequest([]);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? [stateIds] : []);
    }
    dashboardFilter = (values) => {
        this.setState({
            dashboardSearchData: values,
        }, () => {
            this.props.dashboardFilter(values);
        })
    };
    showNextCharts = (direction, value) => {
        if (value == "lawCompliancePerEntity") {
            const { lawCompliancePerEntityCurrentIndex } = this.state;
            const newIndex = direction === "previous" ? lawCompliancePerEntityCurrentIndex - this.state.defaultEntityIndex : lawCompliancePerEntityCurrentIndex + this.state.defaultEntityIndex;
            this.setState({ lawCompliancePerEntityCurrentIndex: newIndex });
        } else if (value == "usersPerEntity") {
            const { usersPerEntityCurrentIndex } = this.state;
            const newIndex = direction === "previous" ? usersPerEntityCurrentIndex - this.state.defaultUserIndex : usersPerEntityCurrentIndex + this.state.defaultUserIndex;
            this.setState({ usersPerEntityCurrentIndex: newIndex })
        }
    };
    handleLawCompliancePerEntityClick = (event, chartContext, config) => {
        const clickedIndex = this.state.lawCompliancePerEntityCurrentIndex + config.dataPointIndex;
        const seriesIndex = config.seriesIndex;
        if (
            this.state.lawCompliancePerEntityOptions.chart?.entityIds
                .length > 0 &&
            this.state?.lawCompliancePerEntityOptions.chart?.entityIds[
            clickedIndex
            ] != undefined &&
            config.w.config?.series[seriesIndex]?.name
        ) {
            let entityId = this.state?.lawCompliancePerEntityOptions.chart?.entityIds[clickedIndex];
            let typeClicked = config.w.config?.series[seriesIndex]?.name.toLowerCase();
            let url = '/statutory-compliances/from-dashboard/' + typeClicked + '/entity/' + entityId;
            this.props.history.push(url);
        }
    };
    handleUserPerEntityClick = (event, chartContext, config) => {
        const clickedIndex = this.state.usersPerEntityCurrentIndex + config.dataPointIndex;
        const seriesIndex = config.seriesIndex;
        if (
            this.state.usersPerEntityOptions.chart?.entityIds
                .length > 0 &&
            this.state?.usersPerEntityOptions.chart?.entityIds[
            clickedIndex
            ] != undefined &&
            config.w.config?.series[seriesIndex]?.name
        ) {
            let entityId = this.state?.usersPerEntityOptions.chart?.entityIds[clickedIndex];
            let typeClicked = '';
            if (seriesIndex === 0) {
                typeClicked = 'active'
            } else if (seriesIndex === 1) {
                typeClicked = 'inactive';
            } else if (seriesIndex === 2) {
                typeClicked = 'dormant';
            }
            if (entityId > 0 && typeClicked !== '') {
                let url = '/users/from-dashboard/' + typeClicked + '/' + this.state.companyGroupId + '/' + entityId;
                this.props.history.push(url);
            } else { return false; }
        }
    };
    clickDataByType = (type, statusType) => {
        if (type === 'cga-ea-updates') {
            this.setState({
                overAllUpdatesView: true
            });
        }
        if (type === 'cga-ea-new-additions') {
            this.setState({
                overAllNewAdditionView: true
            })
        }
        this.props.getDataByClickableType({
            filter: this.state.dashboardSearchData,
            clickableType: type,
            clickableTypeData: {
                statusType: statusType
            },
        });
    }
    closeClickData = () => {
        this.setState({
            updatesDataList: [],
            allocationDataList: [],
            overAllUpdatesView: false,
            overAllNewAdditionView: false,
        });
    };
    changeIsQuickAccessType = (type) => {
        this.setState({
            isQuickAccessType: type,
        });
    };

    closeQuickAccessPanel = () => {
        this.setState({
            isQuickAccessType: '',
        });
    };

    generateRuntimeTable = (records) => {
        let runtimeRecords = [];
        runtimeRecords = records.map((logData, i) => {
            return {
                userName: logData.userName,
                userEmail: logData.userEmail || '',
                userContactNo: logData.userContactNo || '',
                lastLoginDate: logData.lastLoginDate,
                totalLoginTime: logData.displayTime,
            };
        });
        return runtimeRecords;
    };
    generateDepartmentTable = (records) => {
        let departmentRecords = [];
        departmentRecords = records.map((department, i) => {
            return {
                departmentName: department.departmentName,
            };
        });
        return departmentRecords;
    };

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.apiResponseData &&
            nextprops.apiResponseData != this.props.apiResponseData
        ) {
            let lawCompliancePerEntity = {
                entityNames: nextprops.apiResponseData.lawsAndComplianceCount.map((e) => e.entityName),
                entityIds: nextprops.apiResponseData.lawsAndComplianceCount.map((e) => e.entityId),
                laws: nextprops.apiResponseData.lawsAndComplianceCount.map((l) => l.lawsCount),
                compliances: nextprops.apiResponseData.lawsAndComplianceCount.map((lc) => lc.compliancesCount)
            };

            let lawCompliancePerEntityData = [
                {
                    name: 'Laws',
                    data: lawCompliancePerEntity.laws
                },
                {
                    name: 'Compliances',
                    data: lawCompliancePerEntity.compliances
                }
            ];
            let usersPerEntity = {
                entityNames: nextprops.apiResponseData.usersPerEntity.map((e) => e.entityName),
                entityIds: nextprops.apiResponseData.usersPerEntity.map((e) => e.id),
                activeUsers: nextprops.apiResponseData.usersPerEntity.map((l) => l.activeUserCount),
                inactiveUsers: nextprops.apiResponseData.usersPerEntity.map((lc) => lc.inActiveUserCount),
                dormantUsers: nextprops.apiResponseData.usersPerEntity.map((lc) => lc.dormantUserCount)
            };
            let usersPerEntityData = [
                {
                    name: 'Active Users',
                    data: usersPerEntity.activeUsers,
                    userType: 'active'
                },
                {
                    name: 'Deactivated Users',
                    data: usersPerEntity.inactiveUsers,
                    userType: 'deactive'
                },
                {
                    name: 'Dormant Users',
                    data: usersPerEntity.dormantUsers,
                    userType: 'dormant'
                }
            ];
            this.setState({
                companyGroupId: (nextprops.apiResponseData.companyGroup) ? nextprops.apiResponseData.companyGroup.id : '',
                totals: {
                    activeCountries:
                        nextprops.apiResponseData?.totals?.activeCountries,
                    activeEntities:
                        nextprops.apiResponseData?.totals?.activeEntities,
                    activeDepartments:
                        nextprops.apiResponseData?.totals?.activeDepartments.length,
                    activeUsers:
                        nextprops.apiResponseData?.totals?.activeUsers,
                    totalSystemHours: nextprops.apiResponseData?.totals.totalDisplayTime
                },
                activeDepartmentsList: this.generateDepartmentTable(nextprops.apiResponseData?.totals?.activeDepartments),
                lawCompliancePerEntityOptions: barChartForEntityOnCGAEADashboard(lawCompliancePerEntity.entityNames, this.handleLawCompliancePerEntityClick, lawCompliancePerEntity.entityIds),
                lawCompliancePerEntityData: lawCompliancePerEntityData,
                statusOfNewAdditions: {
                    pendingCount: nextprops.apiResponseData.statusOfNewAdditions.pendingCount,
                    acceptedCount: nextprops.apiResponseData.statusOfNewAdditions.acceptedCount,
                    rejectedCount: nextprops.apiResponseData.statusOfNewAdditions.rejectedCount,
                    discussionCount: nextprops.apiResponseData.statusOfNewAdditions.discussionCount
                },
                statusOfChangeInExistingCompliance: {
                    periodicityCount: nextprops.apiResponseData.statusOfChangeInExistingCompliance.periodicityCount,
                    dueDatesCount: nextprops.apiResponseData.statusOfChangeInExistingCompliance.dueDatesCount,
                    eventsCount: nextprops.apiResponseData.statusOfChangeInExistingCompliance.eventsCount,
                    documentsCount: nextprops.apiResponseData.statusOfChangeInExistingCompliance.documentsCount,
                    deactivatedComplianceCount: nextprops.apiResponseData.statusOfChangeInExistingCompliance.deactivatedComplianceCount,
                },
                supportTicketTracker: {
                    solvedInLast24Hours: nextprops.apiResponseData.supportTicketCount.solvedInLast24Hours,
                    pendingForLast3Days: nextprops.apiResponseData.supportTicketCount.pendingForLast3Days,
                    ticketOpenLast48Hours: nextprops.apiResponseData.supportTicketCount.ticketOpenLast48Hours,
                    totalTicketsRaised: nextprops.apiResponseData.supportTicketCount.totalTicketsRaised
                },
                usersPerEntityOptions: barChartForUserOnCGAEADashboard(usersPerEntity.entityNames, this.handleUserPerEntityClick, usersPerEntity.entityIds),
                usersPerEntityData: usersPerEntityData,
                quickAccessDataPoints: {
                    newLawsCompliances:
                        nextprops.apiResponseData?.quickAccessDataPoints
                            ?.newLawsCompliances,
                    newAssignedEvents:
                        nextprops.apiResponseData?.quickAccessDataPoints
                            ?.newAssignedEvents,
                    delgatedCompliances:
                        nextprops.apiResponseData?.quickAccessDataPoints
                            ?.delgatedCompliances,
                },
                last15DaysDate:
                    nextprops.apiResponseData?.quickAccessDataPoints
                        ?.last15DaysDate,
                userLogs: this.generateRuntimeTable(nextprops.apiResponseData.userLogs),
                clickableType: nextprops.apiResponseData.requestBody.clickableType,
                allocationDataList: (nextprops.apiResponseData.requestBody.clickableType == 'cga-ea-new-additions') ? nextprops.apiResponseData.clickableData : [],
                updatesDataList: (nextprops.apiResponseData.requestBody.clickableType == 'cga-ea-updates') ? nextprops.apiResponseData.clickableData : []
            });
        }
        if (nextprops.sortedStateListDropdown && nextprops.sortedStateListDropdown !== this.props.sortedStateListDropdown) {
            this.setState({
                sortedStateListDropdown: nextprops.sortedStateListDropdown
            })
        }
        if (nextprops.sortedCityListDropdown && nextprops.sortedCityListDropdown !== this.props.sortedCityListDropdown) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown
            })
        }
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 2,
            arrows: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 1460,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 2,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                    },
                },
            ],
        };

        const userDataLogs = {
            columns: logColumns,
            rows: this.state.userLogs,
        };

        const activeDepartmentsList = {
            columns: departmentColumns,
            rows: this.state.activeDepartmentsList,
        };
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        return (
            <React.Fragment>
                {this.state.isQuickAccessType == '' && this.state.showUserLogs === false && this.state.showDepartments === false && this.state.overAllUpdatesView === false && this.state.overAllNewAdditionView === false && (
                    <div className='dashboard_section mb-0 cga-ea-dashboard'>
                        <Row className="mb-3">
                            <Col>
                                <div className="dashboard-filter">
                                    <Formik
                                        initialValues={this.state.dashboardSearchData}
                                        enableReinitialize={true}
                                    >
                                        {({ values, setFieldValue }) => (
                                            <Form className="entity_filter_row w-100 m-0">
                                                <div className="entity_filter">
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="entityId"
                                                            placeholder={'Entity'}
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={this.props.sortedEntityListDropdown}
                                                            onChange={(opt) => {
                                                                let selectedValue = opt
                                                                    ? opt.value
                                                                    : '';
                                                                setFieldValue('entityId', selectedValue);
                                                                this.dashboardFilter({
                                                                    ...values,
                                                                    entityId: selectedValue,
                                                                });
                                                            }}
                                                            value={this.props.sortedEntityListDropdown?.filter(
                                                                (option) =>
                                                                    values.entityId == option.value
                                                            )}
                                                            closeMenuOnSelect={true}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="countryId"
                                                            placeholder={'Country'}
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedCountryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'countryId',
                                                                    selectedValue
                                                                );
                                                                setFieldValue(
                                                                    'stateId',
                                                                    ''
                                                                );
                                                                setFieldValue(
                                                                    'cityId',
                                                                    ''
                                                                );
                                                                if (selectedValue == '') {
                                                                    this.setState({
                                                                        sortedStateListDropdown: [],
                                                                        sortedCityListDropdown: []
                                                                    })
                                                                }
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        countryId:
                                                                            selectedValue,
                                                                        stateId: '',
                                                                        cityId: ''
                                                                    }
                                                                );
                                                                if (selectedValue) {
                                                                    this.getStateListByCountry(
                                                                        opt.value
                                                                    );
                                                                }
                                                            }}
                                                            value={this.props.sortedCountryListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.countryId
                                                            )}
                                                            closeMenuOnSelect={true}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="stateId"
                                                            placeholder={'State'}
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={this.state.sortedStateListDropdown.filter(
                                                                (opt) =>
                                                                    opt.value != -1
                                                            )}
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'stateId',
                                                                    selectedValue
                                                                );
                                                                setFieldValue(
                                                                    'cityId',
                                                                    ''
                                                                );
                                                                if (selectedValue == '') {
                                                                    this.setState({
                                                                        sortedCityListDropdown: []
                                                                    })
                                                                }
                                                                if (selectedValue) {
                                                                    this.getCityListByState(
                                                                        opt.value
                                                                    );
                                                                }
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        stateId:
                                                                            selectedValue,
                                                                        cityId: ''
                                                                    }
                                                                );
                                                            }}
                                                            value={this.state.sortedStateListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.stateId
                                                            )}
                                                            closeMenuOnSelect={true}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field
                                                            component={Select}
                                                            name="cityId"
                                                            placeholder={'City'}
                                                            styles={{
                                                                placeholder: placeholderStyle,
                                                            }}
                                                            className="form-control selectbox"
                                                            options={this.state.sortedCityListDropdown.filter(
                                                                (opt) =>
                                                                    opt.value != -1
                                                            )}
                                                            onChange={(opt) => {
                                                                let selectedValue =
                                                                    opt?.value
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'cityId',
                                                                    selectedValue
                                                                );
                                                                this.dashboardFilter(
                                                                    {
                                                                        ...values,
                                                                        cityId: selectedValue,
                                                                    }
                                                                );
                                                            }}
                                                            value={this.state.sortedCityListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.cityId
                                                            )}
                                                            isClearable={true}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <div className="dashboard_stats gx-2 mb-3">
                                    <div className="">
                                        <Card className="m-0 stats_cards">
                                            <Slider
                                                {...settings}
                                            >
                                                <div className="stats_item">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.push('statutory-compliances/country-coverage')
                                                        }}
                                                    >
                                                        <div className="stats_icon bg-success">
                                                            <svg
                                                                width="64"
                                                                height="74"
                                                                viewBox="0 0 64 74"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M35.752 4.54604C40.4185 4.54604 44.9801 5.9298 48.8601 8.52234C52.7401 11.1149 55.7643 14.7997 57.5501 19.111C59.3359 23.4222 59.8031 28.1662 58.8927 32.743C57.9823 37.3198 55.7352 41.5239 52.4355 44.8236C49.1358 48.1232 44.9317 50.3703 40.355 51.2807C35.7782 52.1911 31.0342 51.7238 26.723 49.9381C22.4118 48.1523 18.7269 45.1282 16.1344 41.2482C13.5418 37.3682 12.1581 32.8065 12.1581 28.14C12.1653 21.8847 14.6533 15.8877 19.0765 11.4645C23.4996 7.04136 29.4967 4.55327 35.752 4.54604ZM35.752 -0.00878906C30.1847 -0.00878906 24.7425 1.64212 20.1134 4.73515C15.4844 7.82818 11.8765 12.2244 9.74595 17.368C7.61543 22.5115 7.05796 28.1712 8.14409 33.6316C9.23022 39.0919 11.911 44.1076 15.8477 48.0443C19.7844 51.981 24.8001 54.6619 30.2605 55.748C35.7208 56.8341 41.3806 56.2767 46.5242 54.1462C51.6677 52.0156 56.0639 48.4077 59.157 43.7787C62.25 39.1496 63.9008 33.7073 63.9008 28.14C63.9008 20.6745 60.9353 13.5147 55.6563 8.23578C50.3774 2.95685 43.2176 -0.00878906 35.752 -0.00878906Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                                <path
                                                                    d="M38.7854 65.1256C1.14659 65.1256 0.108174 36.2479 0.101342 35.9541C0.094997 35.6549 0.147702 35.3573 0.256468 35.0784C0.365235 34.7995 0.527903 34.5448 0.735192 34.3289C0.942482 34.1129 1.19034 33.9399 1.46454 33.8198C1.73874 33.6997 2.03387 33.6348 2.33316 33.6289C2.93078 33.6333 3.5042 33.866 3.936 34.2792C4.3678 34.6923 4.6254 35.2549 4.65617 35.8517C4.6835 36.8606 5.7128 60.5707 38.7854 60.5707C39.3894 60.5707 39.9688 60.8107 40.3959 61.2378C40.823 61.6648 41.0628 62.2442 41.0628 62.8482C41.0628 63.4522 40.823 64.0314 40.3959 64.4585C39.9688 64.8856 39.3894 65.1256 38.7854 65.1256Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                                <path
                                                                    d="M22.8595 59.7959H18.3047V71.7227H22.8595V59.7959Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                                <path
                                                                    d="M38.7854 74.007H2.37879C1.77479 74.007 1.19555 73.767 0.768451 73.3399C0.341354 72.9128 0.101379 72.3336 0.101379 71.7296C0.101379 71.1256 0.341354 70.5463 0.768451 70.1192C1.19555 69.6921 1.77479 69.4521 2.37879 69.4521H38.7854C39.3894 69.4521 39.9688 69.6921 40.3959 70.1192C40.823 70.5463 41.0629 71.1256 41.0629 71.7296C41.0629 72.3336 40.823 72.9128 40.3959 73.3399C39.9688 73.767 39.3894 74.007 38.7854 74.007Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                                <path
                                                                    d="M53.6934 48.1794L44.1465 44.629C39.3753 42.1648 36.6061 39.3909 35.6723 36.1228C34.4949 31.9847 36.6993 28.2908 38.1569 25.8494L38.5919 25.1183C38.6844 24.9844 38.7339 24.8256 38.7339 24.6629C38.7339 24.5002 38.6844 24.3413 38.5919 24.2074C37.5739 22.3285 32.8711 20.1422 26.155 19.7892C24.6546 19.8367 23.165 19.5196 21.8139 18.8652C20.4629 18.2107 19.2908 17.2384 18.3981 16.0315C17.6363 14.6413 17.1811 13.1041 17.0633 11.5233C16.9454 9.94256 17.1677 8.35491 17.7149 6.86719L21.9965 8.41122L19.8557 7.63921L22.0009 8.40212C21.6938 9.22591 21.5526 10.1023 21.585 10.9808C21.6175 11.8594 21.823 12.7229 22.19 13.5218C22.7098 14.1117 23.3589 14.5735 24.0865 14.8714C24.8141 15.1692 25.6008 15.295 26.385 15.2389C34.2171 15.6534 40.5778 18.3225 42.5865 22.037C43.0534 22.8577 43.2935 23.788 43.2819 24.7322C43.2703 25.6764 43.0074 26.6004 42.5204 27.4094L42.0649 28.1906C40.8214 30.2721 39.4118 32.6338 40.0517 34.8771C40.6029 36.8151 42.6435 38.7145 46.112 40.5251L55.258 43.9048L53.6934 48.1794Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <span>Country(s) Covered</span>
                                                            <h4>
                                                                {
                                                                    this
                                                                        .state
                                                                        .totals
                                                                        ?.activeCountries
                                                                }
                                                            </h4>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="stats_item">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.push('entities')
                                                        }}
                                                    >
                                                        <div className="stats_icon bg-success">
                                                            <svg
                                                                width="61"
                                                                height="61"
                                                                viewBox="0 0 61 61"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M50.6176 2H56.12C57.1281 2 57.6321 2 58.0172 2.19619C58.3559 2.36876 58.6312 2.64413 58.8038 2.98282C59 3.36786 59 3.87191 59 4.88V56.12C59 57.1281 59 57.6321 58.8038 58.0172C58.6312 58.3559 58.3559 58.6312 58.0172 58.8038C57.6321 59 57.1281 59 56.12 59H4.88C3.87191 59 3.36786 59 2.98282 58.8038C2.64413 58.6312 2.36876 58.3559 2.19619 58.0172C2 57.6321 2 57.1281 2 56.12V4.88C2 3.87191 2 3.36786 2.19619 2.98282C2.36876 2.64413 2.64413 2.36876 2.98282 2.19619C3.36786 2 3.87191 2 4.88 2H10.3824"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2.5"
                                                                ></path>
                                                                <path
                                                                    d="M59 35.5293H47.0298C46.334 35.5293 45.9862 35.5293 45.7243 35.7181C45.4624 35.9068 45.3524 36.2368 45.1324 36.8968L42.6911 44.2206C42.4711 44.8806 42.3611 45.2106 42.0992 45.3994C41.8374 45.5881 41.4895 45.5881 40.7938 45.5881H20.2062C19.5105 45.5881 19.1626 45.5881 18.9008 45.3994C18.6389 45.2106 18.5289 44.8806 18.3089 44.2206L15.8676 36.8968C15.6476 36.2368 15.5376 35.9068 15.2757 35.7181C15.0138 35.5293 14.666 35.5293 13.9702 35.5293H2M44.4706 38.8822H16.5294"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2.5"
                                                                ></path>
                                                                <path
                                                                    d="M59 18.7637H47.0298C46.334 18.7637 45.9862 18.7637 45.7243 18.9524C45.4624 19.1412 45.3524 19.4712 45.1324 20.1312L42.6911 27.455C42.4711 28.115 42.3611 28.445 42.0992 28.6337C41.8374 28.8225 41.4895 28.8225 40.7938 28.8225H20.2062C19.5105 28.8225 19.1626 28.8225 18.9008 28.6337C18.6389 28.445 18.5289 28.115 18.3089 27.455L15.8676 20.1312C15.6476 19.4712 15.5376 19.1412 15.2757 18.9524C15.0138 18.7637 14.666 18.7637 13.9702 18.7637H2M44.4706 22.1166H16.5294"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2.5"
                                                                ></path>
                                                                <path
                                                                    d="M52.294 2H47.0296C46.3339 2 45.986 2 45.7241 2.18876C45.4622 2.37752 45.3522 2.70753 45.1322 3.36754L42.691 10.6913C42.471 11.3513 42.361 11.6813 42.0991 11.8701C41.8372 12.0588 41.4893 12.0588 40.7936 12.0588H20.2061C19.5103 12.0588 19.1625 12.0588 18.9006 11.8701C18.6387 11.6813 18.5287 11.3513 18.3087 10.6913L15.8674 3.36754C15.6474 2.70752 15.5374 2.37752 15.2756 2.18876C15.0137 2 14.6658 2 13.9701 2H8.70572M44.4704 5.35294H16.5292"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2.5"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Active Entities
                                                            </span>
                                                            <h4>
                                                                {
                                                                    this
                                                                        .state
                                                                        .totals
                                                                        ?.activeEntities
                                                                }
                                                            </h4>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="stats_item">
                                                    <a
                                                        onClick={() => {
                                                            this.setState({
                                                                showDepartments: true
                                                            })
                                                        }}
                                                    >
                                                        <div className="stats_icon bg-success">
                                                            <svg
                                                                width="54"
                                                                height="54"
                                                                viewBox="0 0 54 54"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <rect
                                                                    width="18.75"
                                                                    height="18.75"
                                                                    rx="1"
                                                                    transform="matrix(1 0 0 -1 33.25 20.75)"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></rect>
                                                                <path
                                                                    d="M20.75 33.25H37.5C38.4428 33.25 38.9142 33.25 39.2071 33.5429C39.5 33.8358 39.5 34.3072 39.5 35.25V50C39.5 50.9428 39.5 51.4142 39.2071 51.7071C38.9142 52 38.4428 52 37.5 52H20.75V33.25Z"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                                <path
                                                                    d="M20.75 16.5C20.75 15.5572 20.75 15.0858 20.4571 14.7929C20.1642 14.5 19.6928 14.5 18.75 14.5H4C3.05719 14.5 2.58579 14.5 2.29289 14.7929C2 15.0858 2 15.5572 2 16.5V33.25H20.75V16.5Z"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                                <path
                                                                    d="M20.75 52H4C3.05719 52 2.58579 52 2.29289 51.7071C2 51.4142 2 50.9428 2 50V33.25H20.75V52Z"
                                                                    stroke="currentColor"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Departments
                                                            </span>
                                                            <h4>
                                                                {
                                                                    this
                                                                        .state
                                                                        .totals
                                                                        ?.activeDepartments
                                                                }
                                                            </h4>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="stats_item">
                                                    <a
                                                       
                                                        onClick={() => {
                                                            this.props.history.push('users/from-dashboard/' + this.state.companyGroupId)
                                                        }}
                                                    >
                                                        <div className="stats_icon bg-success">
                                                            <svg
                                                                width="84"
                                                                height="84"
                                                                viewBox="0 0 84 84"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M69.0459 71.5648C67.4506 67.0995 63.9354 63.1538 59.0453 60.3396C54.1553 57.5254 48.1638 56 42 56C35.8362 56 29.8447 57.5254 24.9547 60.3396C20.0646 63.1538 16.5494 67.0995 14.9541 71.5648"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4.2"
                                                                    strokeLinecap="round"
                                                                ></path>
                                                                <circle
                                                                    cx="42"
                                                                    cy="28"
                                                                    r="14"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4.2"
                                                                    strokeLinecap="round"
                                                                ></circle>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Total System
                                                                Users
                                                            </span>
                                                            <h4>
                                                                {
                                                                    this
                                                                        .state
                                                                        .totals
                                                                        ?.activeUsers
                                                                }
                                                            </h4>
                                                        </div>
                                                       
                                                    </a>
                                                </div>
                                                <div className="stats_item">
                                                    <a
                                                       className={`position-relative ${userIsLite()?"user-select-none":""}`}
                                                        onClick={async() => {
                                                            if(userIsLite()){
                                                                await PopupPremium();
                                                            }else{
                                                                this.setState({
                                                                    showUserLogs: true
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <div className="stats_icon bg-info">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="9" cy="9" r="8.5" stroke="currentColor" />
                                                                <path d="M13.5 9H9.25C9.11193 9 9 8.88807 9 8.75V5.5" stroke="currentColor" strokeLinecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Overall System Usage (In Hours)
                                                            </span>
                                                            <h4>
                                                                {
                                                                   userIsLite()? "04:00" : this
                                                                        .state
                                                                        .totals
                                                                        ?.totalSystemHours
                                                                }
                                                            </h4>
                                                        </div>
                                                        {userIsLite() ? <CommonOverlayTile />:null}
                                                    </a>
                                                </div>
                                            </Slider>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" className='pr-2'>
                                <Card className="chart-card dashboard_head compliance-chart m-0">
                                    <CardHeader>
                                        <h5>
                                            Laws and Compliances Per Entity
                                        </h5>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        {this.state
                                            .lawCompliancePerEntityData
                                            .length > 0 && (
                                                <ApexCharts
                                                    options={
                                                        this.state.lawCompliancePerEntityOptions && this.state.lawCompliancePerEntityOptions.xaxis && this.state.lawCompliancePerEntityOptions.xaxis.categories ?
                                                            {
                                                                ...this.state.lawCompliancePerEntityOptions,
                                                                xaxis: {
                                                                    ...this.state.lawCompliancePerEntityOptions.xaxis,
                                                                    categories: this.state.lawCompliancePerEntityOptions.xaxis.categories.slice(this.state.lawCompliancePerEntityCurrentIndex, this.state.lawCompliancePerEntityCurrentIndex + this.state.defaultEntityIndex)
                                                                },
                                                                chart: {
                                                                    ...this.state.lawCompliancePerEntityOptions.chart,
                                                                    entityIds: this.state.lawCompliancePerEntityOptions.chart.entityIds.slice(this.state.lawCompliancePerEntityCurrentIndex, this.state.lawCompliancePerEntityCurrentIndex + this.state.defaultEntityIndex)
                                                                },
                                                            }
                                                            : {}
                                                    }
                                                    series={
                                                        this
                                                            .state
                                                            .lawCompliancePerEntityData.map((data) => {
                                                                return {
                                                                    name: data.name,
                                                                    data: data.data.slice(this
                                                                        .state
                                                                        .lawCompliancePerEntityCurrentIndex, this
                                                                            .state
                                                                            .lawCompliancePerEntityCurrentIndex + this.state.defaultEntityIndex)
                                                                }
                                                            })
                                                    }
                                                    type="bar"
                                                    height={250}
                                                />
                                            )}
                                        <div className="activity_chart_btn">
                                            <Button
                                                className="btn"
                                                type="button"
                                                disabled={
                                                    this.state
                                                        .lawCompliancePerEntityCurrentIndex > 0 ? false : true
                                                }
                                                onClick={() => {
                                                    this.showNextCharts("previous", "lawCompliancePerEntity");
                                                }}
                                            >
                                                <svg
                                                    width="9"
                                                    height="14"
                                                    viewBox="0 0 9 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8 13L2 7L8 1"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </Button>
                                            <Button
                                                className="btn"
                                                type="button"
                                                disabled={
                                                    this.state.lawCompliancePerEntityOptions?.xaxis?.categories?.length > this
                                                        .state
                                                        .lawCompliancePerEntityCurrentIndex + this.state.defaultEntityIndex ? false : true
                                                }
                                                onClick={() => {
                                                    this.showNextCharts("next", "lawCompliancePerEntity");
                                                }}
                                            >
                                                <svg
                                                    width="9"
                                                    height="14"
                                                    viewBox="0 0 9 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L7 7L1 13"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6" className='pl-2'>
                                <Card className="chart-card dashboard_head compliance-chart m-0">
                                    <CardHeader>
                                        <h5>
                                            Users Per Entity
                                        </h5>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        {this.state
                                            .usersPerEntityData
                                            .length > 0 && (
                                                <ApexCharts
                                                    options={
                                                        this.state.usersPerEntityOptions && this.state.usersPerEntityOptions.xaxis && this.state.usersPerEntityOptions.xaxis.categories ?
                                                            {
                                                                ...this.state.usersPerEntityOptions,
                                                                xaxis: {
                                                                    ...this.state.usersPerEntityOptions.xaxis,
                                                                    categories: this.state.usersPerEntityOptions.xaxis.categories.slice(this.state.usersPerEntityCurrentIndex, this.state.usersPerEntityCurrentIndex + this.state.defaultUserIndex)
                                                                },
                                                                chart: {
                                                                    ...this.state.usersPerEntityOptions.chart,
                                                                    entityIds: this.state.usersPerEntityOptions.chart.entityIds.slice(this.state.usersPerEntityCurrentIndex, this.state.usersPerEntityCurrentIndex + this.state.defaultUserIndex)
                                                                },
                                                            }
                                                            : {}
                                                    }
                                                    series={
                                                        this
                                                            .state
                                                            .usersPerEntityData.map((data) => {
                                                                return {
                                                                    name: data.name,
                                                                    data: data.data.slice(this
                                                                        .state
                                                                        .usersPerEntityCurrentIndex, this
                                                                            .state
                                                                            .usersPerEntityCurrentIndex + this.state.defaultUserIndex)
                                                                }
                                                            })
                                                    }
                                                    type="bar"
                                                    height={250}
                                                />
                                            )}
                                        <div className="activity_chart_btn">
                                            <Button
                                                className="btn"
                                                type="button"
                                                disabled={
                                                    this.state
                                                        .usersPerEntityCurrentIndex > 0 ? false : true
                                                }
                                                onClick={() => {
                                                    this.showNextCharts("previous", "usersPerEntity");
                                                }}
                                            >
                                                <svg
                                                    width="9"
                                                    height="14"
                                                    viewBox="0 0 9 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8 13L2 7L8 1"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </Button>
                                            <Button
                                                className="btn"
                                                type="button"
                                                disabled={
                                                    this.state.usersPerEntityOptions?.xaxis?.categories?.length > this
                                                        .state
                                                        .usersPerEntityCurrentIndex + this.state.defaultUserIndex ? false : true
                                                }
                                                onClick={() => {
                                                    this.showNextCharts("next", "usersPerEntity");
                                                }}
                                            >
                                                <svg
                                                    width="9"
                                                    height="14"
                                                    viewBox="0 0 9 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L7 7L1 13"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-3 cga_admin_dashboard'>
                            <Col className='pr-2'>
                                <Card className="dashboard_head management_Overview_card card_list m-0">
                                    <CardHeader>
                                        <h5 className='pr-0'>
                                            Status of Change in Existing Compliance
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <ul>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-updates', 'updates-periodicity')
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Change in Periodicity</p>
                                                    <span>{this.state.statusOfChangeInExistingCompliance.periodicityCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-updates', 'updates-duedate')
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Change in Due Date</p>
                                                    <span>{this.state.statusOfChangeInExistingCompliance.dueDatesCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-updates', 'updates-events')
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Change in Events</p>
                                                    <span>{this.state.statusOfChangeInExistingCompliance.eventsCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-updates', 'updates-documents')
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Change in Documents</p>
                                                    <span>{this.state.statusOfChangeInExistingCompliance.documentsCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-updates', 'updates-deactivations')
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Deactivated Compliances</p>
                                                    <span>{this.state.statusOfChangeInExistingCompliance.deactivatedComplianceCount}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className='px-2'>
                                <Card className="dashboard_head management_Overview_card card_list m-0">
                                    <CardHeader>
                                        <h5>
                                            Status of New Additions
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <ul>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-new-additions', 'pending');
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Pending Action</p>
                                                    <span>{this.state.statusOfNewAdditions.pendingCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-new-additions', 'accepted');
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Accepted Compliances</p>
                                                    <span>{this.state.statusOfNewAdditions.acceptedCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-new-additions', 'rejected');
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Rejected Compliances</p>
                                                    <span>{this.state.statusOfNewAdditions.rejectedCount}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                                this.clickDataByType('cga-ea-new-additions', 'discussion');
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Compliances in Discussion</p>
                                                    <span>{this.state.statusOfNewAdditions.discussionCount}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col className='px-2'>
                                <Card className="dashboard_head management_Overview_card card_list m-0">
                                    <CardHeader>
                                        <h5>
                                            Support Ticket Tracker
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            userIsLite() ? <CommonOverlay />:null
                                        }
                                        <ul className={`${userIsLite() ? "user-select-none":""}`}>
                                            <li onClick={() => {
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Solved in Last 24 hours</p>
                                                    <span>{userIsLite()  ? 10 :this.state.supportTicketTracker.solvedInLast24Hours}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Pending Solution for Last 3 days</p>
                                                    <span>{userIsLite()  ? 30 :this.state.supportTicketTracker.pendingForLast3Days}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Ticket Opened in Last 48 hours</p>
                                                    <span>{userIsLite()  ? 50 : this.state.supportTicketTracker.ticketOpenLast48Hours}</span>
                                                </div>
                                            </li>
                                            <li onClick={() => {
                                            }}>
                                                <div className="country_list_info">
                                                    <p>Total Tickets Raised</p>
                                                    <span>{userIsLite()  ? 80 : this.state.supportTicketTracker.totalTicketsRaised}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="5" className="pl-2">
                                <Card className="chart-card dashboard_head quick-access-card card_list m-0">
                                    <CardHeader>
                                        <h5>Quick Access panel</h5>
                                    </CardHeader>
                                    <CardBody className={`quick_panel ${userIsLite() ? "user-select-none":""}`}>
                                        {
                                            userIsLite() ? <CommonOverlay />:null
                                        }
                                        <Row>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a
                                                        href={() => false}
                                                        onClick={async() => {
                                                            if(userIsLite()){
                                                               await PopupPremium();
                                                            }else{
                                                                this.changeIsQuickAccessType(
                                                                    'compliances'
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            New Laws &
                                                            Compliances
                                                        </span>
                                                        <strong>
                                                            {
                                                               userIsLite() ? null: this
                                                                    .state
                                                                    .quickAccessDataPoints
                                                                    .newLawsCompliances
                                                                    .length
                                                            }{' '}
                                                        </strong>
                                                        
                                                            {
                                                                userIsLite() ? <PremiumIcon /> : <small> <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg></small>
                                                            }
                                                           
                                                        
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a
                                                        href={() => false}
                                                        onClick={async() => {
                                                            if(userIsLite()){
                                                                await PopupPremium();
                                                            }else{
                                                                this.changeIsQuickAccessType(
                                                                    'event-master'
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            New Assigned
                                                            Events
                                                        </span>
                                                        <strong>
                                                            {userIsLite() ? null:this.state
                                                                .quickAccessDataPoints
                                                                .newAssignedEvents
                                                                ? this
                                                                    .state
                                                                    .quickAccessDataPoints
                                                                    .newAssignedEvents
                                                                    .length
                                                                : 0}{' '}
                                                        </strong>
                                                    {
                                                         
                                                            userIsLite() ? <PremiumIcon  /> : <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                    }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a href={() => false} onClick={async()=>{
                                                        if(userIsLite()){
                                                            await PopupPremium();
                                                        }
                                                    }} title='Under Maintenance'>
                                                        <span>
                                                            Delegated
                                                            Compliances
                                                        </span>
                                                        <strong>
                                                            {/* {
                                                                this
                                                                    .state
                                                                    .quickAccessDataPoints
                                                                    .delgatedCompliances
                                                                    .length
                                                            }{' '} */}
                                                          {userIsLite() ? null :"-"}
                                                        </strong>
                                                        {
                                                            userIsLite() ? <PremiumIcon /> : <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                        }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a
                                                        href={userIsLite() ? ()=>false :'/allocations/users/from-dashboard/type/2'}
                                                        onClick={async()=>{
                                                            if(userIsLite()){
                                                                await PopupPremium();
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            Pending Compliance Mapping
                                                        </span>
                                                        {
                                                            userIsLite() ? <PremiumIcon /> : <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                        }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>

                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a
                                                        href={userIsLite() ? ()=>false :'/allocations/users/from-dashboard/type/3'}
                                                        onClick={async()=>{
                                                            if(userIsLite()){
                                                                await PopupPremium();
                                                            }
                                                        }}
                                                    >
                                                        <span>
                                                            Pending Compliance Scheduling
                                                        </span>
                                                        {
                                                             
                                                                userIsLite() ? <PremiumIcon /> : <small>
                                                                <svg
                                                                    width="17"
                                                                    height="12"
                                                                    viewBox="0 0 17 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </small>
                                                        }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a href={() => false} onClick={async() => {
                                                         if(userIsLite()){
                                                            await PopupPremium();
                                                        }else{
                                                            this.props.history.push(
                                                                '/reports-and-certificates/generate-report'
                                                            );
                                                        }
                                                    }}>
                                                        <span>
                                                            Generate Reports
                                                        </span>
                                                        {
                                                             userIsLite() ? <PremiumIcon /> : <small>
                                                             <svg
                                                                 width="17"
                                                                 height="12"
                                                                 viewBox="0 0 17 12"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                             >
                                                                 <path
                                                                     d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                     stroke="currentColor"
                                                                     strokeWidth="1.5"
                                                                     strokeLinecap="round"
                                                                 />
                                                             </svg>
                                                         </small>
                                                        }
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a href={() => false} onClick={async() =>{
                                                         if(userIsLite()){
                                                            await PopupPremium();
                                                        }else{
                                                            this.getOverAllTaskByStatus('overall', 'todo-renewals')
                                                        }
                                                    }}>
                                                        <span>
                                                            Upcoming Renewals
                                                        </span>
                                                        {
                                                            userIsLite() ? <PremiumIcon /> : <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                        }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>
                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a
                                                        href={
                                                            userIsLite() ? ()=>false: '/legal-watch-dog/from-dashboard/' +
                                                            this.state
                                                                .last15DaysDate
                                                        }
                                                        onClick={async() =>{
                                                            if(userIsLite()){
                                                               await PopupPremium();
                                                           }
                                                       }}
                                                    >
                                                        <span>
                                                            Global Legal
                                                            Buzz
                                                        </span>
                                                        {
                                                           userIsLite() ? <PremiumIcon /> : <small>
                                                           <svg
                                                               width="17"
                                                               height="12"
                                                               viewBox="0 0 17 12"
                                                               fill="none"
                                                               xmlns="http://www.w3.org/2000/svg"
                                                           >
                                                               <path
                                                                   d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                   stroke="currentColor"
                                                                   strokeWidth="1.5"
                                                                   strokeLinecap="round"
                                                               />
                                                           </svg>
                                                       </small>
                                                        }
                                                        
                                                    </a>
                                                </Card>
                                            </Col>

                                            <Col lg="4" className='col'>
                                                <Card>
                                                    <a href={userIsLite() ? ()=>false :'/support-tickets'}  onClick={async() =>{
                                                            if(userIsLite()){
                                                               await PopupPremium();
                                                           }
                                                       }}>
                                                        <span>
                                                            Raise Support Ticket
                                                        </span>
                                                        {
                                                            userIsLite() ? <PremiumIcon /> : <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                        }
                                                       
                                                    </a>
                                                </Card>
                                            </Col>
                                            {/* <Col lg="4" className='col'>
                                                <Card>
                                                    <a href={'#'}>
                                                        <span>
                                                            Deactivated Compliance
                                                        </span>
                                                        <small>
                                                            <svg
                                                                width="17"
                                                                height="12"
                                                                viewBox="0 0 17 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M9.63508 10.8049L15.1683 6.20842M15.1683 6.20842L9.93562 1.27252M15.1683 6.20842L1.15014 5.76645"
                                                                    stroke="currentColor"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </small>
                                                    </a>
                                                </Card>
                                            </Col> */}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
                {this.state.isQuickAccessType != '' && (
                    <QuickAccessTable
                        data={
                            this.state.isQuickAccessType == 'event-master'
                                ? this.state.quickAccessDataPoints
                                    .newAssignedEvents
                                : this.state.quickAccessDataPoints
                                    .newLawsCompliances
                        }
                        isQuickAccessType={this.state.isQuickAccessType}
                        closeQuickAccessPanel={() =>
                            this.closeQuickAccessPanel()
                        }
                    />
                )}
                {
                    this.state.showUserLogs == true && (
                        <Row>
                            <Col lg="12">
                                <Link
                                    to="#"
                                    className="btn btn-primary mb-3 dashboard_back_btn"
                                    onClick={() => {
                                        this.setState({
                                            showUserLogs: false
                                        })
                                    }}
                                >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                            <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </span>
                                    Back
                                </Link>
                                <Card>
                                    <CardBody className="custom_table">
                                        <CustomisedDatatable
                                            entries={50}
                                            tableRecords={userDataLogs}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )
                }

                {
                    this.state.showDepartments == true && (
                        <Row>
                            <Col lg="12">
                                <Link
                                    to="#"
                                    className="btn btn-primary mb-3 dashboard_back_btn"
                                    onClick={() => {
                                        this.setState({
                                            showDepartments: false
                                        })
                                    }}
                                >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                            <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </span>
                                    Back
                                </Link>
                                <Card>
                                    <CardBody className="custom_table">
                                        <CustomisedDatatable
                                            entries={10}
                                            tableRecords={activeDepartmentsList}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )
                }

                {this.state.overAllUpdatesView === true && (
                    <OverallUpdateIndex
                        tableData={this.state.updatesDataList}
                        entityDepartments={this.state.activeDepartmentsList}
                        closeOverallUpdate={() => {
                            this.closeClickData();
                        }}
                    />
                )
                }
                {this.state.overAllNewAdditionView === true && (
                    <OverallAllocationIndex
                        tableData={this.state.allocationDataList}
                        closeClickData={() => {
                            this.closeClickData();
                        }}
                    />
                )
                }
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList()
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUserEntityListRequest
});
export default compose(
    withConnect,
    withRouter,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
)(AdminIndex);
