import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Nav,
} from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'helpers/injectReducer';
import notificationReducer from 'store/Notification/reducer';
import notificationSaga from 'store/Notification/saga';
import { makeNotificationSelectField } from 'store/Notification/selector';
import { withRouter } from 'react-router-dom';
import injectSaga from 'helpers/injectSaga';
import {
    getUserNotificationRequest,
    getUserByUserIdRequest,
    readUserNotificationRequest,
    readNotificationByIdRequest
} from 'store/actions';
import { CHAT_NOTIFICATION, PROJECT_NOTIFICATION, PROJECT_TASK_NOTIFICATION, LEGAL_UPDATE_NOTIFICATION, TASK_NOTIFICATION, FUND_NOTIFICATION, FUND_TASK_NOTIFICATION, RECENT_ACTIVITY_NOTIFICATION, TICKET_NOTIFICATION } from 'constants/databaseConstants';
import { makeUserSelectField } from 'store/User/selector';
import { io } from 'socket.io-client';
import { getBaseEndPoint, getSocketOptions } from 'helpers/axiosHelper';
import { getValueByKey } from 'helpers/authUtils';
import classnames from 'classnames';
import * as authConstants from 'constants/authConstants';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';

import NotificationItem from './notificationItem';
import { decryptData } from 'helpers/encryptor';
import { PopupBasic, PopupPremium, userIsLite } from 'helpers/litehelper';
import { alertBoxIconPlan } from 'helpers/messageHelper';
import __t from 'i18n/translator';
import PremiumIcon from 'app/Lite/components/PremiumIcon';
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });

const notificationKey = 'notification';
const withNotificationReducer = injectReducer({
    key: notificationKey,
    reducer: notificationReducer,
});
const withNotificationSaga = injectSaga({
    key: notificationKey,
    saga: notificationSaga,
});

class NotificationMenu extends Component {
    constructor(props) {
        super(props);
        const loginUserRoles = JSON.parse(decryptData(getValueByKey(authConstants.ROLES)));
        this.state = {
            isTenantUser: '',
            selectedLoginUserRole: loginUserRoles[0]?.id,
            menu: false,
            notificationList: [],
            unReadCount: 0,
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            socketConnected: false,
            loginUserRoles: loginUserRoles,
            activeTab: 'allNotificationTab',
            notifications: {
                taskNotifications: [],
                chatNotifications: [],
                projectNotifications: [],
                legalUpdateNotifications: [],
                fundNotifications: [],
                recentActivityNotification: [],
                ticketNotification: [],
                unReadChatCount: 0,
                unReadProjectCount: 0,
                unReadFundCount: 0,
                unReadTaskCount: 0,
                unReadLegalUpdateCount: 0,
                unReadRecentActivityCount: 0,
                unReadticketCount: 0
            }
        };
        this.socket = null;
        if (process.env.REACT_APP_ENABLE_SOCKET === '1') {
            this.socket = io(getBaseEndPoint(), getSocketOptions());
        }
        this.toggle = this.toggle.bind(this);
        this.toggleNotification = this.toggleNotification.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu,
        }));
    }

    toggleNotification(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    readNotification(notificationTypes) {
        this.props.readUserNotificationRequest({ type: notificationTypes });
    }
    readNotificationById(id) {
        this.props.readNotificationByIdRequest(id);
    }
    componentDidMount() {
        this.props.getUserNotificationRequest();
        if (this.socket) {
            this.socket.emit('setup', { id: this.state.loginUserId });
            this.socket.on('connected', () =>
                this.setState({ socketConnected: true })
            );
            this.socket.on('new notification', (newNotification) => {
                if (newNotification?.id)
                    this.setState({
                        notificationList: [
                            newNotification,
                            ...this.state.notificationList,
                        ],
                    });
            });
        }
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.notificationList &&
            nextprops.notificationList !== this.props.notificationList
        ) {
            this.setState({
                isTenantUser: nextprops.notificationList.data.isTenantUser,
                notificationList: nextprops.notificationList.data.list,
                unReadCount: nextprops.notificationList.data.list?.filter((notification) => notification.isRead === 0).length,
                notifications: {
                    chatNotifications: nextprops.notificationList.data.list?.filter((notification) => notification.type == CHAT_NOTIFICATION),
                    unReadChatCount: nextprops.notificationList.data.list?.filter((notification) => notification.type == CHAT_NOTIFICATION && notification.isRead === 0).length,
                    projectNotifications: nextprops.notificationList.data.list?.filter((notification) => ((notification.type == PROJECT_NOTIFICATION) || (notification.type == PROJECT_TASK_NOTIFICATION))),
                    unReadProjectCount: nextprops.notificationList.data.list?.filter((notification) => (
                        ((notification.type == PROJECT_NOTIFICATION) ||
                            (notification.type == PROJECT_TASK_NOTIFICATION)) &&
                        notification.isRead === 0
                    )).length,
                    fundNotifications: nextprops.notificationList.data.list?.filter((notification) => ((notification.type == FUND_NOTIFICATION) || (notification.type == FUND_TASK_NOTIFICATION))),
                    unReadFundCount: nextprops.notificationList.data.list?.filter((notification) => (
                        ((notification.type == FUND_NOTIFICATION) ||
                            (notification.type == FUND_TASK_NOTIFICATION)) &&
                        notification.isRead === 0
                    )).length,
                    taskNotifications: nextprops.notificationList.data.list?.filter((notification) => notification.type == TASK_NOTIFICATION),
                    unReadTaskCount: nextprops.notificationList.data.list?.filter((notification) => notification.type == TASK_NOTIFICATION && notification.isRead === 0).length,
                    legalUpdateNotifications: nextprops.notificationList.data.list?.filter((notification) => notification.type == LEGAL_UPDATE_NOTIFICATION),
                    unReadLegalUpdateCount: nextprops.notificationList.data.list?.filter((notification) => notification.type == LEGAL_UPDATE_NOTIFICATION && notification.isRead === 0).length,
                    recentActivityNotification: nextprops.notificationList.data.list?.filter((notification) => notification.type == RECENT_ACTIVITY_NOTIFICATION),
                    unReadRecentActivityCount: nextprops.notificationList.data.list?.filter((notification) => notification.type == RECENT_ACTIVITY_NOTIFICATION && notification.isRead === 0).length,
                    ticketNotification: nextprops.notificationList.data.list?.filter((notification) => notification.type == TICKET_NOTIFICATION),
                    unReadticketCount: nextprops.notificationList.data.list?.filter((notification) => notification.type == TICKET_NOTIFICATION && notification.isRead === 0).length,
                }
            });
            this.props.projectNotificationFlag(nextprops.notificationList.data.list?.filter((notification) => ((notification.type == PROJECT_NOTIFICATION) || (notification.type == PROJECT_TASK_NOTIFICATION))).length > 0 ? true : false);
        }
        if (
            nextprops.readUserNotificationApiResponse &&
            nextprops.readUserNotificationApiResponse !== this.props.readUserNotificationApiResponse
        ) {
            this.props.getUserNotificationRequest();
        }
        if (
            nextprops.readNotificationByIdApiResponse &&
            nextprops.readNotificationByIdApiResponse !== this.props.readNotificationByIdApiResponse
        ) {
            this.props.getUserNotificationRequest();
        }
    }
    render() {
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        
        return (
            <React.Fragment>
                <Dropdown
                    isOpen={this.state.menu}
                    toggle={this.toggle}
                    className="notification-list list-inline-item mr-1"
                    tag="li"
                >
                    <DropdownToggle
                        className="nav-link arrow-none waves-effect pr-2"
                        tag="a"
                    >
                        <i className="mdi mdi-bell-outline notification-icon"></i>
                        <span className="badge badge-pill badge-danger notification-icon-badge">
                            {this.state.unReadCount}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg mega-dropdown-menu" right>
                        <h6 className="dropdown-item-text">
                            {' '}
                            Notifications{' '}

                        </h6>

                        <div className='mt-0'>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'allNotificationTab',
                                            }
                                        )}
                                        // className='1tab active'
                                        onClick={() => {
                                            this.toggleNotification(
                                                'allNotificationTab'
                                            );
                                        }}>
                                        All {this.state.unReadCount > 0 && <span>{this.state.unReadCount}</span>}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'taskTab',
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleNotification(
                                                'taskTab'
                                            );
                                        }}
                                    >
                                        Task {this.state.notifications.unReadTaskCount > 0 && <span>{this.state.notifications.unReadTaskCount}</span>}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'chatNotificationTab',
                                            }
                                        )}
                                        onClick={async() => {
                                            if(userIsLite()){
                                               await PopupPremium();
                                            }else{
                                                this.toggleNotification(
                                                    'chatNotificationTab'
                                                );
                                            }
                                        }}
                                    >
                                        Chat {userIsLite() ? <PremiumIcon /> :this.state.notifications.unReadChatCount > 0 && <span>{this.state.notifications.unReadChatCount}</span>}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'projectNotificationTab',
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleNotification(
                                                'projectNotificationTab'
                                            );
                                        }}
                                    >
                                        Project {this.state.notifications.unReadProjectCount > 0 && <span>{this.state.notifications.unReadProjectCount}</span>}
                                    </NavLink>
                                </NavItem>

                                {getValueByKey(authConstants.TENANT_FUND) && getValueByKey(authConstants.TENANT_FUND) == 'true' && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                {
                                                    active:
                                                        this
                                                            .state
                                                            .activeTab ===
                                                        'fundNotificationTab',
                                                }
                                            )}
                                            onClick={() => {
                                                this.toggleNotification(
                                                    'fundNotificationTab'
                                                );
                                            }}
                                        >
                                            Project Pro {this.state.notifications.unReadFundCount > 0 && <span>{this.state.notifications.unReadFundCount}</span>}
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'legal_update',
                                            }
                                        )}
                                        onClick={() => {
                                            this.toggleNotification(
                                                'legal_update'
                                            );
                                        }}
                                    >
                                        Updates{this.state.notifications.unReadLegalUpdateCount > 0 && <span>{this.state.notifications.unReadLegalUpdateCount}</span>}
                                    </NavLink>
                                </NavItem>
                                {this.state.isTenantUser < 1 && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                {
                                                    active:
                                                        this
                                                            .state
                                                            .activeTab ===
                                                        'recentActivityTab',
                                                }
                                            )}
                                            onClick={() => {
                                                this.toggleNotification(
                                                    'recentActivityTab'
                                                );
                                            }}
                                        >
                                            Recent Activity{this.state.notifications.unReadRecentActivityCount > 0 && <span>{this.state.notifications.unReadRecentActivityCount}</span>}
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            {
                                                active:
                                                    this
                                                        .state
                                                        .activeTab ===
                                                    'ticket',
                                            }
                                        )}
                                        onClick={async() => {
                                            if(userIsLite()  && !isLite?.litePlan?.isSupportTicketEnabled){
                                                await PopupBasic();
                                            }else{
                                                this.toggleNotification(
                                                    'ticket'
                                                );
                                            }
                                        }}
                                    >
                                        Tickets{userIsLite()  && !isLite?.litePlan?.isSupportTicketEnabled ? <PremiumIcon /> :this.state.notifications.unReadticketCount > 0 && <span>{this.state.notifications.unReadticketCount}</span>}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent className='small_scroll' activeTab={
                                this.state.activeTab
                            }>
                                <TabPane
                                    className=""
                                    tabId="allNotificationTab"
                                >
                                    <div className='comment-box'>
                                        {this.state.notificationList?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            CHAT_NOTIFICATION,
                                            PROJECT_NOTIFICATION,
                                            PROJECT_TASK_NOTIFICATION,
                                            LEGAL_UPDATE_NOTIFICATION,
                                            TASK_NOTIFICATION,
                                            FUND_NOTIFICATION,
                                            FUND_TASK_NOTIFICATION,
                                            RECENT_ACTIVITY_NOTIFICATION,
                                            TICKET_NOTIFICATION
                                        ])}>Mark As Read</a>}
                                        <ul>
                                            {this.state.notificationList?.length > 0
                                                ?
                                                this.state.notificationList?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'all_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'all_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>

                                </TabPane>
                                <TabPane
                                    className=""
                                    tabId="taskTab"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.taskNotifications?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            TASK_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.taskNotifications?.length > 0
                                                ?
                                                this.state.notifications.taskNotifications?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'task_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />

                                                }) : <li key={'task_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>

                                </TabPane>
                                {this.state.isTenantUser < 1 && (
                                    <TabPane
                                        className=""
                                        tabId="recentActivityTab"
                                    >
                                        <div className='comment-box'>
                                            {this.state.notifications.recentActivityNotification.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                                RECENT_ACTIVITY_NOTIFICATION
                                            ])}>Mark As Read </a>}
                                            <ul>
                                                {this.state.notifications.recentActivityNotification.length > 0
                                                    ?
                                                    this.state.notifications.recentActivityNotification.map((notification, keyIndex) => {
                                                        return <NotificationItem
                                                            key={'recent_activity_' + keyIndex}
                                                            notification={notification}
                                                            readNotificationById={(id) => {
                                                                this.readNotificationById(id)
                                                            }
                                                            }
                                                        />

                                                    }) : <li key={'task_0'} className='read-notification-box'>You have read all notifications</li>}
                                            </ul>
                                        </div>

                                    </TabPane>
                                )}
                                 <TabPane
                                    className=""
                                    tabId="chatNotificationTab"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.chatNotifications?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            CHAT_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.chatNotifications?.length > 0
                                                ? this.state.notifications.chatNotifications?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'chat_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'chat_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>

                                </TabPane>
                                <TabPane
                                    className=""
                                    tabId="projectNotificationTab"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.projectNotifications?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            PROJECT_NOTIFICATION,
                                            PROJECT_TASK_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.projectNotifications?.length > 0
                                                ? this.state.notifications.projectNotifications?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'project_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'project_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>

                                </TabPane>
                                <TabPane
                                    className=""
                                    tabId="legal_update"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.legalUpdateNotifications?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            LEGAL_UPDATE_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.legalUpdateNotifications?.length > 0
                                                ? this.state.notifications.legalUpdateNotifications?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'updates_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'updates_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>
                                </TabPane>
                                <TabPane
                                    className=""
                                    tabId="fundNotificationTab"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.fundNotifications?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            FUND_NOTIFICATION,
                                            FUND_TASK_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.fundNotifications?.length > 0
                                                ? this.state.notifications.fundNotifications?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'fund_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'project_0'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>

                                </TabPane>
                                <TabPane
                                    className=""
                                    tabId="ticket"
                                >
                                    <div className='comment-box'>
                                        {this.state.notifications.ticketNotification?.filter((notification) => !notification.isRead).length > 0 && <a href={() => false} className='read_notificion btn btn-link btn-sm' onClick={() => this.readNotification([
                                            TICKET_NOTIFICATION
                                        ])}>Mark As Read </a>}
                                        <ul>
                                            {this.state.notifications.ticketNotification?.length > 0
                                                ? this.state.notifications.ticketNotification?.map((notification, keyIndex) => {
                                                    return <NotificationItem
                                                        key={'ticket_' + keyIndex}
                                                        notification={notification}
                                                        readNotificationById={(id) => {
                                                            this.readNotificationById(id)
                                                        }
                                                        }
                                                    />
                                                }) : <li key={'ticket_'} className='read-notification-box'>You have read all notifications</li>}
                                        </ul>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>


                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    notificationList: makeNotificationSelectField('notificationListApiResponse'),
    editUserApiResponse: makeUserSelectField('editUserApiResponse'),
    readUserNotificationApiResponse: makeNotificationSelectField('readUserNotificationApiResponse'),
    readNotificationByIdApiResponse: makeNotificationSelectField('readNotificationByIdApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getUserNotificationRequest,
    getUserByUserIdRequest,
    readUserNotificationRequest,
    readNotificationByIdRequest
});

export default compose(
    withConnect,
    withRouter,
    withNotificationReducer,
    withNotificationSaga,
    withUserReducer,
    withUserSaga
)(NotificationMenu);
