import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { isGTROLE } from 'helpers/projectUtils';
import MainDetails from './MainDetails';
import NewsDetails from './NewsDetails';
import injectReducer from 'helpers/injectReducer';
import injectSaga from 'helpers/injectSaga';
import newsSaga from 'store/News/saga';
import newsReducer from 'store/News/reducer';
import { getNewsDetailsByIdRequest } from 'store/actions';
import { makeNewsSelectField } from 'store/News/selector';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { Col } from 'reactstrap';
import { userIsLite } from 'helpers/litehelper';
import NoAccess from 'components/Application/NoAccess';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { LITE_PLAN_BASIC } from 'constants/databaseConstants';

const newsKey = 'news';
const withNewsReducer = injectReducer({
    key: newsKey,
    reducer: newsReducer,
});
const withNewsSaga = injectSaga({ key: newsKey, saga: newsSaga });

class CommonDetailsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsId: parseInt(this.props.match.params.newsId),
            isGtRole: isGTROLE(),
            newsDetails: {},
            isLite:JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)))
        };
    }

    componentDidMount() {
            this.props.getNewsDetailsByIdRequest(this.state.newsId);
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.newsDetailsApiResponse &&
            this.props.newsDetailsApiResponse !==
                nextprops.newsDetailsApiResponse
        ) {
            this.setState({
                newsDetails: nextprops.newsDetailsApiResponse.data?.news,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                {
                    userIsLite() && this.state.isLite.litePlan.id == LITE_PLAN_BASIC ? <NoAccess />:<>
                    <Col lg="12">
                    <CommonBreadcrum type={'view-legal-watch-dog'} />
                        </Col>
                        {this.state.isGtRole == true ? (
                            <MainDetails newsDetails={this.state.newsDetails} />
                        ) : (
                            <NewsDetails newsDetails={this.state.newsDetails} />
                        )}
                    </>
                }
                
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    newsDetailsApiResponse: makeNewsSelectField('newsDetailsApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getNewsDetailsByIdRequest,
});
export default compose(
    withConnect,
    withNewsReducer,
    withNewsSaga,
    withRouter
)(CommonDetailsIndex);
