import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import eventAllocationReducer from 'store/EventAllocation/reducer';
import eventAllocationSaga from 'store/EventAllocation/saga';
import { getExecutedEventsListRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { initialExecutedFilterDataState, listColumns } from './CommonFunctions';
import __t from 'i18n/translator';
import { makeEventAllocationSelectField } from 'store/EventAllocation/selector';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import ExecutedEventFilterForm from './ExecutedEventFilterForm';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import ViewEventTasksModal from '../ViewEventTasksModal';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import { getFirstCapitalize } from 'helpers/generalUtils';
import { userIsLite } from 'helpers/litehelper';
import NoAccess from 'components/Application/NoAccess';

const eventAllocationKey = 'eventAllocation';
const withEventAllocationReducer = injectReducer({
    key: eventAllocationKey,
    reducer: eventAllocationReducer,
});
const withEventAllocationSaga = injectSaga({
    key: eventAllocationKey,
    saga: eventAllocationSaga,
});
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            executedEventList: [],
            searchFormData: initialExecutedFilterDataState(),
            isViewEventTaskModalOpen: false,
            eventData: {},
        };
        this.onStatusChange = this.onStatusChange.bind(this);
    }
    componentDidMount() {
        if(!userIsLite()){
            this.getExecutedEventsListRequest({});
        }
    }
    getExecutedEventsListRequest = (values = {}) => {
        let apiData = {
            filter: values,
        };
        this.props.getExecutedEventsListRequest(apiData, true);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
            },
            () => {
                this.getExecutedEventsListRequest(this.state.searchFormData);
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                searchFormData: initialExecutedFilterDataState(),
            },
            () => {
                this.getExecutedEventsListRequest({});
            }
        );
    };
    onStatusChange(checked, e, id) {
        this.props.updatePlanEventStatusByPlanEventIdRequest(id, checked);
    }
    openViewEventTaskModal = (eventData) => {
        this.setState({
            isViewEventTaskModalOpen: true,
            eventData: eventData,
        });
    };
    closeViewEventTaskModal = () => {
        this.setState({
            isViewEventTaskModalOpen: false,
            eventData: {},
        });
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((eventData, i) => {
            let actions = (
                <div className="text-center">
                    <a
                        href={() => false}
                        title={__t('common.view')}
                        className="btn btn-link text-success tbl-action"
                        onClick={() => {
                            this.openViewEventTaskModal(eventData);
                        }}
                    >
                        <i className="fas fa-eye"></i>
                    </a>
                </div>
            );
            return {
                entityName: eventData.entity?.entityName,
                countryName: eventData.event?.country?.countryName,
                stateName: eventData.event.state?.stateName,
                cityName: eventData.event.city?.cityName,
                eventName: eventData.event?.eventName,
                type: (eventData?.eventType) ? getFirstCapitalize(eventData?.eventType) : '',
                eventOccurrenceDate: eventData.eventOccurrenceDate,
                status: eventData.status,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.executedEventList &&
            this.props.executedEventList !== nextprops.executedEventList
        ) {
            this.setState({
                executedEventList: this.generateTable(
                    nextprops.executedEventList
                ),
            });
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.executedEventList,
        };
        return (
            <React.Fragment>
                {
                    userIsLite() ? <NoAccess /> : <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'executed-event'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={__t('page_headers.executed_events')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <ExecutedEventFilterForm
                                    filterData={this.state.searchFormData}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable tableRecords={data} />
                            </CardBody>
                        </Card>
                    </Col>

                    <ViewEventTasksModal
                        modalName={__t('form_labels.events.tasks')}
                        isModalOpen={this.state.isViewEventTaskModalOpen}
                        eventData={this.state.eventData}
                        onModalDismiss={() => this.closeViewEventTaskModal()}
                    />
                </Row>
                }
               
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    executedEventList: makeEventAllocationSelectField('executedEventList'),
});
const withConnect = connect(mapStatetoProps, {
    getExecutedEventsListRequest,
});
export default compose(
    withConnect,
    withEventAllocationReducer,
    withEventAllocationSaga,
    withRouter
)(Index);
