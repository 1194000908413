import { isDarkMode } from 'helpers/generalUtils';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';

class LiteToolTip extends Component {
    render() {
        const toolTipId = `tooltip-${Math.random()*1000}`;
        return (
            <>
                <Button
                    color="link"
                    type="button"
                    className="text-primary p-0 mr-2 bg-transparent"
                    data-tip
                    data-for={`${toolTipId}`}
                >
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.89999 16.5H12.1V9.89999H9.89999V16.5ZM11 7.7C11.3117 7.7 11.5731 7.5944 11.7843 7.3832C11.9955 7.172 12.1007 6.91093 12.1 6.6C12.0993 6.28906 11.9937 6.028 11.7832 5.8168C11.5727 5.6056 11.3117 5.5 11 5.5C10.6883 5.5 10.4273 5.6056 10.2168 5.8168C10.0063 6.028 9.90073 6.28906 9.89999 6.6C9.89926 6.91093 10.0049 7.17236 10.2168 7.3843C10.4287 7.59623 10.6898 7.70146 11 7.7ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5553 4.2075 19.7718 3.2175 18.7825C2.2275 17.7932 1.44393 16.6291 0.866801 15.29C0.289668 13.9509 0.000734725 12.5209 1.3924e-06 11C-0.00073194 9.47906 0.288201 8.04906 0.866801 6.71C1.4454 5.37093 2.22897 4.20676 3.2175 3.2175C4.20603 2.22823 5.3702 1.44467 6.71 0.8668C8.0498 0.288933 9.4798 0 11 0C12.5202 0 13.9502 0.288933 15.29 0.8668C16.6298 1.44467 17.794 2.22823 18.7825 3.2175C19.771 4.20676 20.555 5.37093 21.1343 6.71C21.7136 8.04906 22.0022 9.47906 22 11C21.9978 12.5209 21.7089 13.9509 21.1332 15.29C20.5575 16.6291 19.774 17.7932 18.7825 18.7825C17.791 19.7718 16.6269 20.5557 15.29 21.1343C13.9531 21.7129 12.5231 22.0015 11 22Z"
                            fill="currentColor"
                        />
                    </svg>
                </Button>
                <ReactTooltip id={`${toolTipId}`} type={!isDarkMode() ? ('dark' ) : ( 'light' )} effect="solid" className='text-center'>
                        {this.props.message}                             
                </ReactTooltip>
            </>
        );
    }
}

export default LiteToolTip;
