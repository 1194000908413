import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    SUCCESS,
    UPDATE,
    ACTIVE,
    CANCEL,
    SUBMIT,
    ADD_NEW,
    DANGER,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import * as authConstants from 'constants/authConstants';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest
} from 'store/actions';

import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import userReducer from 'store/User/reducer';
import userSaga from 'store/User/saga';
import entityRoleReducer from 'store/Masters/EntityRole/reducer';
import entityRoleSaga from 'store/Masters/EntityRole/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import LiteAccountDetailsReducer from 'store/Lite/AccountDetails/reducer';
import LiteAccountDetailsSaga from 'store/Lite/AccountDetails/saga';
import { formatDateForApi, formatDateForApiSystem } from 'helpers/dateTimeHelper';
import {
    customEntityFileValidator,
    customValidPhoneField,
    customValidTextField,
    customValidTextForEntityNameField,
} from 'helpers/customValidators';
import {
    emailTemplateListColumns,
    finListColumns,
    hodListColumns,
    initialEntityDataState,
    initialEntityEmailTemplatesDataState,
    initialEntityFinancialYearsDataState,
    initialEntityLocationsDataState,
    initialHodDataDataState,
    initialTeamDataState,
    locListColumns,
    teamColumns,
} from 'app/Masters/Entity/CommonFunctions';
import __t from 'i18n/translator';
import { makeEntitySelectField } from 'store/Entity/selector';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { selectSortedUserList } from 'store/User/selector';
import { selectSortedRolesWithEntityRoleList } from 'store/Masters/EntityRole/selector';
import { emailConfigRoleList } from 'constants/dropdowns';
import { checkModulePermission } from 'helpers/projectUtils';
import { LITE_PLAN_PREMIUM, MODULE_LITE_CLIENT_ACCOUNT_DETAILS, PERMISSION_ADD, PERMISSION_DELETE, PERMISSION_EDIT, ROLE_SUPER_ADMIN, TASK_COMPLETED_WITHOUT_AUDIT } from 'constants/databaseConstants';
import Dropzone from 'react-dropzone';
import { makeTaskSelectField } from 'store/Task/selector';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import DownloadFileByModel from 'components/Application/DownloadFileByModel';
import AddEditLocationFormModal from 'app/Masters/Entity/AddEditLocationFormModal';
import AddEditFinancialYearFormModal from 'app/Masters/Entity/AddEditFinancialYearFormModal';
import AddEditTeamFormModal from 'app/Masters/Entity/AddEditTeamFormModal';
import AddEditHodFormModal from 'app/Masters/Entity/AddEditHodFormModal';
import AddEditEmailTemplateFormModal from 'app/Masters/Entity/AddEditEmailTemplateFormModal';
import AuditLogModal from 'components/Application/AuditLogModal';
import classnames from "classnames";
import { getEntityWiseQuestionListRequest, LiteEntityDetailsByEntityIdRequest, LiteEntityUpdateByIdRequest } from 'store/Lite/AccountDetails/actions';
import { makeLiteAccountSelectField } from 'store/Lite/AccountDetails/selector';
import { join } from 'redux-saga/effects';
import QuestionAccordion from './components/QuestionAccordian';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import NoDataFound from './components/NoDataFound';

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const userKey = 'user';
const withUserReducer = injectReducer({
    key: userKey,
    reducer: userReducer,
});
const withUserSaga = injectSaga({ key: userKey, saga: userSaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const entityRoleKey = 'entityRole';
const withEntityRoleReducer = injectReducer({
    key: entityRoleKey,
    reducer: entityRoleReducer,
});
const withEntityRoleSaga = injectSaga({
    key: entityRoleKey,
    saga: entityRoleSaga,
});

const accountDetailsKey = 'LiteAccountDetails';
const withAccountDetailsReducer = injectReducer({
    key: accountDetailsKey,
    reducer: LiteAccountDetailsReducer,
});
const withAccountDetailsSaga = injectSaga({ key: accountDetailsKey, saga: LiteAccountDetailsSaga });
class LiteEntityDetails extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(
                customValidTextField(),
                customValidTextForEntityNameField(),
                customValidPhoneField()
            ),
        });
        this.state = {
            downloadFileUrl: '',
            activeTab: "basic_details",
            downloadFileName: '',
            selectedFile: [],
            entityName:"",
            errors: '',
            operationType: SUBMIT,
            entityId: this.props.match.params.entityId
                ? parseInt(this.props.match.params.entityId)
                : '',
            pageHeaderName: this.props.match.params.entityId
                ? __t('page_headers.entity_edit')
                : __t('page_headers.entity_add'),
            entityData: initialEntityDataState(),
            removeEntityLocations: [],
            removeFinancialYears: [],
            removeFinancialYearsData: [],
            removeEntityEmailTemplates: [],
            removeEntityHod: [],
            financialYearData: {},
            isModalOpen: false,
            financialYearOperationType: SUBMIT,
            isFinancialYearEdit: false,
            locationData: {},
            isLocationModalOpen: false,
            locationOperationType: SUBMIT,
            isLocationEdit: false,
            isEmailTemplateModalOpen: false,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: {},
            isEmailTemplateEdit: false,
            isHodModalOpen: false,
            hodOperationType: SUBMIT,
            hodData: {},
            isHodEdit: false,
            isTeamModalOpen: false,
            teamOperationType: SUBMIT,
            teamData: {},
            isTeamEdit: false,
            teamType: '',
            isAuditModalOpen: false,
            auditId: '',
            isAuditLocationModalOpen: false,
            auditLocationId: '',
            isAuditHodModalOpen: false,
            auditHodId: '',
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
            entityUsers: [],
            entitySystemFileName: '',
            entityFileName: '',
            entityType:"",
            industry:"",
            facilityType:"",
            subIndustry:"",
            liteEntityUpdateLoader:false,
            liteEntityQuestionList:[],
            roleId: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))),
            isRmUser:JSON.parse(decryptData(getValueByKey(authConstants.IS_RM_USER))),
            isLite: getValueByKey(authConstants.LITE_PLAN) && getValueByKey(authConstants.LITE_PLAN) !== "null" ? JSON.parse(decryptData(getValueByKey(authConstants.LITE_PLAN))) : null
        };
        this.toggle = this.toggle.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    }
    componentDidMount() {
        this.props.LiteEntityDetailsByEntityIdRequest({entityId:this.props.match.params.entityId,entitySetupId:this.props.match.params.entitySetupId})
        // this.props.getEntityByEntityIdRequest(this.props.match.params.entityId);
        this.props.getUsersByEntityRequest(this.props.match.params.entityId);
        this.props.getRolesWithEntityRoleByEntityListRequest(
            this.props.match.params.entityId
        );
        if (!this.props.match.params.entityId) {
            this.props.getRolesWithEntityRoleByEntityListRequest(-1);
        }
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        var countryIdsData = countryIds.filter(function (el) {
            return el !== '';
        });
        this.props.getStateListByCountryRequest(countryIdsData);
    }
    getCityListByState(stateIds) {
        var stateIdsData = stateIds.filter(function (el) {
            return el !== '';
        });
        this.props.getCityListByStateRequest(stateIdsData);
    }
    saveFile = (uploadedFileData) => {
        this.setState({
            downloadFileName: '',
            downloadFileUrl: ''
        }, () => {
            this.setState({
                downloadFileName: uploadedFileData.fileName,
                downloadFileUrl: uploadedFileData.fileUrl
            })
        });
        // return FileSaver.saveAs(
        //     uploadedFileData.fileUrl,
        //     uploadedFileData.fileName
        // );
    };
    handleAcceptedFiles = (files) => {
        let errorsArray = [];
        files.map((file) => {
            if (!customEntityFileValidator(file.name)) {
                errorsArray.push(__t('validations.custom.entity_document_file'));
                return false;
            }
        });
        if (errorsArray.length > 0) {
            this.setState({ errors: __t('form_labels.entity.document_file') + ' ' + __t('validations.custom.entity_document_file') });
        } else {
            this.setState({ errors: '' })
        }
        let formData = new FormData();
        files.map((file) => {
            formData.append('file', file);
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size)
            });
        });
        formData.append('type', 'entity');
        this.setState({ selectedFile: files }, () => {
            this.props.addFileUploadRequest(formData);
        });
    }
    /**
    * Formats the size
    */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    openAuditLogLocationModal = (auditLocationId) => {
        this.setState({
            isAuditLocationModalOpen: true,
            auditLocationId: auditLocationId,
        });
    };
    closeAuditLogLocationModal = () => {
        this.setState({
            isAuditLocationModalOpen: false,
            auditLocationId: '',
        });
    };
    openAuditLogEmailTemplateModal = (auditEmailTemplateId) => {
        this.setState({
            isAuditEmailTemplateModalOpen: true,
            auditEmailTemplateId: auditEmailTemplateId,
        });
    };
    closeAuditLogEmailTemplateModal = () => {
        this.setState({
            isAuditEmailTemplateModalOpen: false,
            auditEmailTemplateId: '',
        });
    };
    openAuditLogHodModal = (auditHodId) => {
        this.setState({
            isAuditHodModalOpen: true,
            auditHodId: auditHodId,
        });
    };
    closeAuditLogHodModal = () => {
        this.setState({
            isAuditHodModalOpen: false,
            auditHodId: '',
        });
    };
    addNewFinancialYear() {
        this.setState({
            isModalOpen: true,
            financialYearOperationType: SUBMIT,
            financialYearData: initialEntityFinancialYearsDataState(),
            isFinancialYearEdit: false,
        });
    }
    editFinancialYear = (data) => {
        this.setState({
            isModalOpen: true,
            financialYearOperationType: UPDATE,
            financialYearData: data,
            isFinancialYearEdit: true,
        });
    };
    deleteFinancialYear = async (data, setFieldValue, values) => {
        if (values.entityLocations.filter((entityLoc) => entityLoc.countryId == data.countryId)?.length > 0) {
            toastrMessage(__t('alerts.entity_financial_year_not_delete'), DANGER);
            return false;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_financial_year_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeFinancialYears: [
                        ...this.state.removeFinancialYears,
                        data.id,
                    ],
                    removeFinancialYearsData: [
                        ...this.state.removeFinancialYearsData,
                        data,
                    ]
                });
            }
            let entityFinancialYears = values.entityFinancialYears.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityFinancialYears', entityFinancialYears);
        }
    };
    closeFinancialYearModal() {
        this.setState({
            isModalOpen: false,
            financialYearOperationType: SUBMIT,
            financialYearData: {},
            isFinancialYearEdit: false,
        });
    }
    generateFinancialYearTable = (records, setFieldValue, values) => {
        let financialYearRecords = [];
        financialYearRecords = records.map((financialYearRecord, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) && financialYearRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editFinancialYear(financialYearRecord)
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}

                    {checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_DELETE) && !financialYearRecord.id && (
                        <a
                            href={() => false}
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                this.deleteFinancialYear(
                                    financialYearRecord,
                                    setFieldValue,
                                    values
                                );
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                    {financialYearRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogModal(financialYearRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                countryName: financialYearRecord.countryName,
                startMonth: financialYearRecord.startMonth,
                endMonth: financialYearRecord.endMonth,
                actions: actions,
            };
        });
        return financialYearRecords.reverse();
    };
    addNewLocation() {
        this.setState({
            isLocationModalOpen: true,
            locationOperationType: SUBMIT,
            locationData: initialEntityLocationsDataState(),
            isLocationEdit: false,
        });
    }
    editEntityLocation = (data) => {
        this.setState({
            isLocationModalOpen: true,
            locationOperationType: UPDATE,
            locationData: data,
            isLocationEdit: true,
        });
    };
    deleteLocation = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_location_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityLocations: [
                        ...this.state.removeEntityLocations,
                        data.id,
                    ],
                });
            }
            let entityLocations = values.entityLocations.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityLocations', entityLocations);
        }
    };
    closeLocationModal() {
        this.setState({
            isLocationModalOpen: false,
            locationOperationType: SUBMIT,
            locationData: {},
            isLocationEdit: false,
        });
    }

    generateLocationTable = (records, setFieldValue, values) => {
        let locationRecords = [];
        locationRecords = records.map((locationRecord, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) && locationRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editEntityLocation(locationRecord)
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    {checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_DELETE) && !locationRecord.id && (
                        <a
                            title={__t('common.delete')}
                            className="btn btn-link text-danger tbl-action"
                            onClick={() => {
                                this.deleteLocation(
                                    locationRecord,
                                    setFieldValue,
                                    values
                                );
                            }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </a>
                    )}
                    {locationRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogLocationModal(
                                    locationRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );

            return {
                countryName: locationRecord.countryName,
                stateName: locationRecord.stateName,
                cityName: locationRecord.cityName,
                pincode: (locationRecord.pincode && locationRecord.pincode != null) ? locationRecord.pincode : '',
                registrationNumber: (locationRecord.registrationNumber && locationRecord.registrationNumber != null) ? locationRecord.registrationNumber : '',
                registrationDate: (locationRecord.registrationDate && locationRecord.registrationDate != '0000-00-00')
                    ? formatDateForApiSystem(locationRecord.registrationDate)
                    : '',
                actions: actions,
            };
        });
        return locationRecords.reverse();
    };
    addNewEmailTemplate() {
        this.setState({
            isEmailTemplateModalOpen: true,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: initialEntityEmailTemplatesDataState(),
            isEmailTemplateEdit: false,
        });
    }
    editEntityEmailTemplate = (data) => {
        this.setState({
            isEmailTemplateModalOpen: true,
            emailTemplateOperationType: UPDATE,
            emailTemplateData: data,
            isEmailTemplateEdit: true,
        });
    };
    deleteEntityEmailTemplate = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_email_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityEmailTemplates: [
                        ...this.state.removeEntityEmailTemplates,
                        data.id,
                    ],
                });
            }
            let entityEmailTemplates = values.entityEmailTemplates.filter(
                (obj) => obj !== data
            );
            setFieldValue('entityEmailTemplates', entityEmailTemplates);
        }
    };
    closeEntityEmailTemplateModal() {
        this.setState({
            isEmailTemplateModalOpen: false,
            emailTemplateOperationType: SUBMIT,
            emailTemplateData: {},
            isEmailTemplateEdit: false,
        });
    }
    generateEmailTemplateTable = (records, setFieldValue, values) => {
        let emailTemplateRecords = [];
        emailTemplateRecords = records.map((emailTemplateRecord, i) => {
            let actions = (
                <div className="text-center">
                    {emailTemplateRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() =>
                                this.editEntityEmailTemplate(
                                    emailTemplateRecord
                                )
                            }
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteEntityEmailTemplate(
                                emailTemplateRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                    {emailTemplateRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogEmailTemplateModal(
                                    emailTemplateRecord.id
                                );
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            return {
                emailTemplateType: emailTemplateRecord.emailTemplateTypeId == TASK_COMPLETED_WITHOUT_AUDIT ? "Task Completed": emailTemplateRecord?.emailTemplateTypeName,
                toRoles: emailTemplateRecord?.toRolesName?.join(),
                ccRoles: emailTemplateRecord?.ccRolesName?.join(),
                daysFirstNotification:
                    emailTemplateRecord?.daysFirstNotification,
                daysSecondNotification:
                    emailTemplateRecord?.daysSecondNotification,
                actions: actions,
            };
        });
        return emailTemplateRecords.reverse();
    };
    addNewHod() {
        this.setState({
            isHodModalOpen: true,
            hodOperationType: SUBMIT,
            hodData: initialHodDataDataState(),
            isHodEdit: false,
        });
    }
    editEntityHod = (data) => {
        this.setState({
            isHodModalOpen: true,
            hodOperationType: UPDATE,
            hodData: data,
            isHodEdit: true,
        });
    };
    deleteEntityHod = async (data, setFieldValue, values) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.entity_hod_delete')
        );
        if (response) {
            if (data.id > 0) {
                this.setState({
                    removeEntityHod: [...this.state.removeEntityHod, data.id],
                });
            }
            let entityHod = values.entityHod.filter((obj) => obj !== data);
            setFieldValue('entityHod', entityHod);
        }
    };
    closeHodModal() {
        this.setState({
            isHodModalOpen: false,
            hodOperationType: SUBMIT,
            hodData: {},
            isHodEdit: false,
        });
    }
    generateHodTable = (records, setFieldValue, values) => {
        let hodRecords = [];
        hodRecords = records.map((hodRecord, i) => {
            let actions = (
                <div className="text-center">
                    {checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) && hodRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.edit')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => this.editEntityHod(hodRecord)}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </a>
                    )}
                    {
                        checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_DELETE) ?  <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteEntityHod(
                                hodRecord,
                                setFieldValue,
                                values
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>:null
                    }
                   
                    {hodRecord.id > 0 && (
                        <a
                            href={() => false}
                            title={__t('common.audit_logs')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                this.openAuditLogHodModal(hodRecord.id);
                            }}
                        >
                            <i className="fas fa-history"></i>
                        </a>
                    )}
                </div>
            );
            let userNameLabel = hodRecord?.user?.email;
            if(hodRecord?.user?.fullName){
                userNameLabel = hodRecord?.user?.fullName + "-" + hodRecord?.user?.email;
            }
            return {
                departmentName: hodRecord.departmentName,
                userName: userNameLabel,
                actions: actions,
            };
        });
        return hodRecords.reverse();
    };

    addNewTeam(type) {
        this.setState({
            isTeamModalOpen: true,
            teamOperationType: SUBMIT,
            teamData: initialTeamDataState(),
            isTeamEdit: false,
            teamType: type,
        });
    }
    getTeamModalNameByType = (type) => {
        let modalName = '';
        switch (type) {
            case 'entityAdminUsers':
                modalName = __t('form_labels.entity.entity_admin');
                break;
            case 'entityTeamUsers':
                modalName = __t('form_labels.entity.entity_team');
                break;
            case 'entityManagementUsers':
                modalName = __t('form_labels.entity.entity_management_team');
                break;
            case 'entityConsultantUsers':
                modalName = __t('form_labels.entity.entity_consultant_team');
                break;
            case "companyGroupAdmin":
                modalName = __t("lite.companyGroup")
                break;
            default:
                break;
        }
        return modalName;
    };
    deleteTeam = async (data, setFieldValue, values, type) => {
        let message = '';
        switch (type) {
            case 'entityAdminUsers':
                message = __t('alerts.entity_admin_delete');
                break;
            case 'entityTeamUsers':
                message = __t('alerts.entity_team_delete');
                break;
            case 'entityManagementUsers':
                message = __t('alerts.entity_management_team_delete');
                break;
            case 'entityConsultantUsers':
                message = __t('alerts.entity_consultant_team_delete');
                break;
            case "companyGroupAdmin":
                message = __t("alerts.company_group_team_delete")
                break;
            default:
                break;
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            message
        );
        if (response) {
            let users = values[type].filter(
                (obj) => obj.userId !== data.userId
            );
            setFieldValue(type, users);
        }
    };
    closeTeamModal() {
        this.setState({
            isTeamModalOpen: false,
            teamOperationType: SUBMIT,
            teamData: {},
            isTeamEdit: false,
            teamType: '',
        });
    }
    generateTeamTable = (records, setFieldValue, values, type) => {
        let teamRecords = [];
        teamRecords = records && records.length >0 ? records.map((teamRecord, i) => {
            let actions = (
                <div className="text-center">
                    {
                        // 
                        checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_DELETE) && (type == "entityManagementUsers" ? records.length > 1 ? true : false :true)  ? <a
                        href={() => false}
                        title={__t('common.delete')}
                        className="btn btn-link text-danger tbl-action"
                        onClick={() => {
                            this.deleteTeam(
                                teamRecord,
                                setFieldValue,
                                values,
                                type
                            );
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>:null
                    }
                    
                </div>
            );
            return {
                fullName: teamRecord?.user?.fullName ?? "",
                email: teamRecord?.user?.email,
                actions: actions,
            };
        }):[];
        return teamRecords.reverse();
    };
    formSubmit = (values) => {
        this.setState({
            liteEntityUpdateLoader:true
        })
        if (this.validator.allValid()) {
            let formValues = {
                ...values,
                removeEntityLocations: this.state.removeEntityLocations,
                removeFinancialYears: this.state.removeFinancialYears,
                removeFinancialYearsData: this.state.removeFinancialYearsData,
                removeEntityHod: this.state.removeEntityHod,
                removeEntityEmailTemplates:
                    this.state.removeEntityEmailTemplates,
            };
            // if file is uploaded then need to send to api
            if (this.state.entityFileName != '' && this.state.entitySystemFileName != '') {
                formValues.entityFileName = this.state.entityFileName;
                formValues.entitySystemFileName = this.state.entitySystemFileName;
            }
            if (formValues?.entityLocations?.length > 0) {
                formValues.entityLocations = formValues.entityLocations.map(
                    (item) => ({
                        ...item,
                        registrationDate: item.registrationDate
                            ? formatDateForApi(item.registrationDate)
                            : '',
                    })
                );
            }
            formValues.companyGroupAdmin= formValues.companyGroupAdmin.map(
                (opt) => opt.userId
            );
            formValues.entityAdmin = formValues.entityAdminUsers.map(
                (opt) => opt.userId
            );
            formValues.entityTeam = formValues.entityTeamUsers.map(
                (opt) => opt.userId
            );
            formValues.entityManagementTeam =
                formValues.entityManagementUsers.map((opt) => opt.userId);
            formValues.entityConsultantTeam =
                formValues.entityConsultantUsers.map((opt) => opt.userId);



            if (this.state.operationType === UPDATE) {
                delete formValues.companyGroup;
                this.props.LiteEntityUpdateByIdRequest({entityId:this.props.match.params.entityId,entitySetupId:this.props.match.params.entitySetupId,formValues})
                // this.props.updateEntityRequest(this.state.entityId, formValues);
            } else {
                this.props.addEntityRequest(formValues);
            }
        } else {
            window.scrollTo(0, 0);
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.entityApiResponse &&
            this.props.entityApiResponse !== nextprops.entityApiResponse
        ) {
            toastrMessage(
                nextprops.entityApiResponse.message,
                nextprops.entityApiResponse.responseType
            );
            // if (nextprops.entityApiResponse.responseType === SUCCESS) {
            //     this.props.history.push(`/lite/account-details/${this.props.match.params.accountId}/entity/${this.props.match.params.entityId}/configured-entity/${this.props.match.params.entitySet}`);
            // }
        }
        if (
            nextprops.entityDetailsByIdApiResponse &&
            this.props.entityDetailsByIdApiResponse !== nextprops.entityDetailsByIdApiResponse
        ) {
            const entitySetup = nextprops.entityDetailsByIdApiResponse.data?.entity.liteEntitySetupList && nextprops.entityDetailsByIdApiResponse.data?.entity.liteEntitySetupList.length > 0 && nextprops.entityDetailsByIdApiResponse.data?.entity.liteEntitySetupList.find((ele)=>ele.id ==  this.props.match.params.entitySetupId);
            const facilityName = entitySetup && entitySetup.liteEntityPropertyList && entitySetup.liteEntityPropertyList.length > 0 && entitySetup.liteEntityPropertyList.filter((ele)=>ele.type == 1).map((fac)=>fac?.facilityType?.facilityTypeName)?.join(",");
            const subIndustryName = entitySetup && entitySetup.liteEntityPropertyList && entitySetup.liteEntityPropertyList.length > 0 && entitySetup.liteEntityPropertyList.filter((ele)=>ele.type == 2).map((sub)=>sub?.subIndustry?.subIndustryName)?.join(",");
            this.setState({
                    operationType: UPDATE,
                    entityName:nextprops.entityDetailsByIdApiResponse.data?.entity?.entityName,
                    entityData: {
                        ...nextprops.entityDetailsByIdApiResponse.data.entity,
                        companyGroupId:
                            nextprops.entityDetailsByIdApiResponse.data.entity?.companyGroup
                                ?.id,
                        entityFinancialYears:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityFinancialYears && nextprops.entityDetailsByIdApiResponse.data.entity?.entityFinancialYears.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityFinancialYears?.map(
                                (opt) => ({
                                    ...opt,
                                    countryName: opt.country
                                        ? opt.country.countryName
                                        : '',
                                })
                            ) : [],
                        entityLocations:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityLocations && nextprops.entityDetailsByIdApiResponse.data.entity?.entityLocations.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityLocations?.map(
                                (opt) => ({
                                    ...opt,
                                    countryName: opt.country
                                        ? opt.country.countryName
                                        : '',
                                    stateName: opt.state ? opt.state.stateName : '',
                                    cityName: opt.city ? opt.city.cityName : '',
                                })
                            ) :[],
                        entityEmailTemplates:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityEmailTemplates && nextprops.entityDetailsByIdApiResponse.data.entity?.entityEmailTemplates.length > 0  ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityEmailTemplates?.map(
                                (opt) => ({
                                    ...opt,
                                    emailTemplateTypeName: opt.emailTemplateType
                                        ? opt.emailTemplateType.displayName
                                        : '',
                                    toRolesName:
                                        opt.toRoles.length > 0
                                            ? emailConfigRoleList
                                                .map((optionRole) =>
                                                    opt.toRoles.includes(
                                                        optionRole.value
                                                    )
                                                        ? optionRole.label
                                                        : ''
                                                )
                                                .filter((x) => x != '')
                                            : [],
                                    ccRolesName:
                                        opt.ccRoles.length > 0
                                            ? emailConfigRoleList
                                                .map((optionRole) =>
                                                    opt.ccRoles.includes(
                                                        optionRole.value
                                                    )
                                                        ? optionRole.label
                                                        : ''
                                                )
                                                .filter((x) => x != '')
                                            : [],
                                })
                            ) : [],
                        entityAdmin:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityAdmin && nextprops.entityDetailsByIdApiResponse.data.entity?.entityAdmin.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityAdmin?.map(
                                (opt) => opt.userId
                            ) : [],
                        entityTeam:
                        nextprops.entityDetailsByIdApiResponse.data?.entity?.entityTeam && nextprops.entityDetailsByIdApiResponse.data?.entity?.entityTeam.length > 0 ? nextprops.entityDetailsByIdApiResponse.data?.entity?.entityTeam?.map(
                                (opt) => opt.userId
                            ) : [],
                        entityManagementTeam:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityManagementTeam && nextprops.entityDetailsByIdApiResponse.data.entity?.entityManagementTeam.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityManagementTeam?.map(
                                (opt) => opt.userId
                            ) : [],
                        entityConsultantTeam:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityConsultantTeam && nextprops.entityDetailsByIdApiResponse.data.entity?.entityConsultantTeam.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityConsultantTeam?.map(
                                (opt) => opt.userId
                            ) :[],
                        entityAdminUsers:
                            nextprops.entityDetailsByIdApiResponse.data.entity?.entityAdmin,
                        companyGroupAdmin:
                            nextprops.entityDetailsByIdApiResponse.data.entity?.companyGroupAdmin,
                        entityManagementUsers:
                            nextprops.entityDetailsByIdApiResponse.data.entity
                                ?.entityManagementTeam,
                        entityTeamUsers:
                            nextprops.entityDetailsByIdApiResponse.data.entity?.entityTeam,
                        entityConsultantUsers:
                            nextprops.entityDetailsByIdApiResponse.data.entity
                                ?.entityConsultantTeam,
                        entityHod:
                        nextprops.entityDetailsByIdApiResponse.data.entity?.entityHods && nextprops.entityDetailsByIdApiResponse.data.entity?.entityHods.length > 0 ? nextprops.entityDetailsByIdApiResponse.data.entity?.entityHods?.map(
                                (opt) => ({
                                    ...opt,
                                    departmentName: opt.department
                                        ? opt.department.departmentName
                                        : '',
                                    userName: opt.user
                                        ? opt.user.fullName + ' - ' + opt.user.email
                                        : '',
                                })
                            ) : [],
                    },
                    entityType:entitySetup?.entityType?.entityTypeName,
                    industry:entitySetup?.industry?.industryName,
                    facilityType:facilityName,
                    subIndustry:subIndustryName
                });
        }
        if(nextprops.updateEntityDetailsByIdApiResponse && nextprops.updateEntityDetailsByIdApiResponse !== this.props.updateEntityDetailsByIdApiResponse){
            this.setState({
                liteEntityUpdateLoader:false
            })
            toastrMessage(nextprops.updateEntityDetailsByIdApiResponse.message,nextprops.updateEntityDetailsByIdApiResponse.responseType)
            if (nextprops.updateEntityDetailsByIdApiResponse.responseType === SUCCESS) {
             this.props.history.push(`/lite/account-details/${this.props.match.params.accountId}`)
            }
        }
        if(nextprops.entityWiseQuestionListApiResponse && nextprops.entityWiseQuestionListApiResponse !== this.props.entityWiseQuestionListApiResponse){
            this.setState({
                liteEntityQuestionList:nextprops.entityWiseQuestionListApiResponse.data
            })
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            }, () => {
                switch (tab) {
                    case 'basic_details':
                        this.props.LiteEntityDetailsByEntityIdRequest({entityId:this.props.match.params.entityId,entitySetupId:this.props.match.params.entitySetupId})
                        // this.props.getEntityByEntityIdRequest(this.props.match.params.entityId);
                        this.props.getUsersByEntityRequest(this.props.match.params.entityId);
                        this.props.getRolesWithEntityRoleByEntityListRequest(
                            this.props.match.params.entityId
                        );
                        if (!this.state.entityId) {
                            this.props.getRolesWithEntityRoleByEntityListRequest(-1);
                        }
                        this.props.getCountryListByStatusRequest(ACTIVE);
                        break;
                    case "questionnaire":
                        this.props.getEntityWiseQuestionListRequest({
                            liteClientId:parseInt(this.props.match.params.accountId),
                            entityId:parseInt(this.props.match.params.entityId),
                            entitySetUpId:parseInt(this.props.match.params.entitySetupId),
                            companyGroupId:this.state?.entityData?.companyGroupId,
                            tenantId:this.state?.entityData?.tenantId,
                            lawCategoryId:0
                        });
                        break;
                    default:
                        break;
                }
            });
        }
    }
    render() {
        const roleId = this.state.roleId && this.state.roleId.length > 0 ? this.state.roleId[0].roleId:null;
        const planId = this.state.isLite?.litePlan?.id;
        return <React.Fragment>
            <Col lg="12">
             {
                roleId == ROLE_SUPER_ADMIN || this.state.isRmUser ? <CommonBreadcrum type={'lite-entity-details'} accountId={this.props.match.params.accountId} edit={(this.state.operationType == UPDATE)} />:<CommonBreadcrum type={'lite-entity-details-company-group'} accountName={this.props.match.params.accountId} />
             }
            </Col>
            <div className="page-header">
                                <h4 className="with-back-btn mt-0 d-flex">
                                    <a
                                        href={() => false}
                                        className="dripicons-arrow-thin-left"
                                        onClick={() => {
                                            this.props.history.push(`/lite/account-details/${this.props.match.params.accountId}`);
                                        }}
                                    >
                                        { }
                                    </a>{' '}
                                    &nbsp;{roleId == ROLE_SUPER_ADMIN  || this.state.isRmUser ? this.state.pageHeaderName:"View Entity"}{' '}
                                </h4>
                            </div>
            <div className="edit-entity-tab">
                <div className='viewlaws_tabs tabs-main fix-header-table col-lg-12'>
                    <Nav tabs className="nav-tabs-custom nav-justified task_tabs nav nav-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === 'basic_details' })}
                                onClick={() => {
                                    this.toggle('basic_details');
                                }}
                            >Basic Detail</NavLink>                            
                        </NavItem>
                      <NavItem>
                            <NavLink
                                    className={classnames({ active: this.state.activeTab === 'questionnaire' })}
                                    onClick={() => {
                                        this.toggle('questionnaire');
                                    }}
                                >Questionnaire</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="task_card">
                        <TabPane
                            className="reporting_tab"
                            tabId="basic_details"
                        >
                         
                            <Formik
                                initialValues={this.state.entityData}
                                enableReinitialize={true}
                                onSubmit={this.formSubmit}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form className="add-entity">

                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.basic_details'
                                                        )}
                                                    </h4>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <FormGroup className="col-lg-6">
                                                      <Field
                                                            name="companyGroup.companyGroupName"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.entity.entity_name'
                                                            )}
                                                            disabled={true}
                                                            // value={values.companyGroup.companyGroupName}
                                                            maxLength={250}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.company_group'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.entity.company_group'
                                                            ),
                                                            values.companyGroupId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.entity.company_group_required'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-6">
                                                        <Field
                                                            name="entityName"
                                                            className="form-control"
                                                            placeholder={__t(
                                                                'form_labels.entity.entity_name'
                                                            )}
                                                            disabled={!checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT)}
                                                            value={values.entityName}
                                                            maxLength={250}

                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.entity_name'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.entity.entity_name'
                                                            ),
                                                            values.entityName,
                                                            'required|max:250|onlyCharactersForEntityName',
                                                            {
                                                                messages: {
                                                                    required: __t(
                                                                        'validations.entity.entity_name_required'
                                                                    ),
                                                                    max: __t(
                                                                        'validations.entity.entity_name_max',
                                                                        { max: 250 }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-6">
                                                        <Field
                                                            name="email"
                                                            className={`form-control ${!checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) ? "form-control-disabled":""}`}
                                                            placeholder={__t(
                                                                'form_labels.entity.registered_email_id'
                                                            )}
                                                            disabled={!checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT)}
                                                            maxLength={150}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.registered_email_id'
                                                            )}{' '}
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.entity.registered_email_id'
                                                            ),
                                                            values.email,
                                                            'email|max:150',
                                                            {
                                                                messages: {

                                                                    max: __t(
                                                                        'validations.entity.email_max',
                                                                        { max: 150 }
                                                                    ),
                                                                    email: __t(
                                                                        'validations.entity.email_email'
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-6">
                                                        <Field
                                                            name="contactNo"
                                                            className={`form-control ${!checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT) ? "form-control-disabled":""}`}
                                                            placeholder={__t(
                                                                'form_labels.entity.contact_no'
                                                            )}
                                                            disabled={!checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_EDIT)}
                                                            maxLength={14}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.entity.contact_no'
                                                            )}
                                                            {/* <span className="text-danger required">
                                            *
                                        </span> */}
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.entity.contact_no'
                                                            ),
                                                            values.contactNo,
                                                            'customPhone|max:14',
                                                            {
                                                                messages: {

                                                                    max: __t(
                                                                        'validations.entity.contact_no_max',
                                                                        { max: 14 }
                                                                    ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                </Row>
                                                <div className='compliance-basic-details'>
                                                 <div className='compliance-card border-0 p-0 m-0'>
                                                    <div className='compliance-details'>
                                                        <ul className="basic-details">
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t("lite.EntityType")}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.entityType}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t("lite.FacilityType")}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.facilityType}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t("lite.Industry")}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.industry}
                                                                    </p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="details-info">
                                                                    <b>
                                                                        {__t("lite.SubIndustry")}
                                                                    </b>
                                                                    <p>
                                                                        {this.state.subIndustry}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                 </div>
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* Entity locations */}
                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.locations'
                                                        )}<span className="text-danger required">
                                                            *
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div className="page-action">
                                                    {(checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) === true) && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-sm float-right"
                                                                onClick={() =>
                                                                    this.addNewLocation()
                                                                }
                                                            >
                                                                {ADD_NEW}
                                                            </button>
                                                        )}
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <AddEditLocationFormModal
                                                            isModalOpen={
                                                                this.state
                                                                    .isLocationModalOpen
                                                            }
                                                            isLite={true}
                                                            operationType={
                                                                this.state
                                                                    .locationOperationType
                                                            }
                                                            locationData={
                                                                this.state.locationData
                                                            }
                                                            locationList={
                                                                values.entityLocations
                                                            }
                                                            onFormValid={(locValues) => {
                                                                let entityLocationsList =
                                                                    values.entityLocations;
                                                                var updateDetailsIndex =
                                                                    entityLocationsList.findIndex(
                                                                        (opt) =>
                                                                            opt.id ===
                                                                            locValues.id
                                                                    );
                                                                if (
                                                                    updateDetailsIndex >
                                                                    -1 &&
                                                                    this.state
                                                                        .isLocationEdit
                                                                ) {
                                                                    entityLocationsList[
                                                                        updateDetailsIndex
                                                                    ] = locValues;
                                                                    setFieldValue(
                                                                        'entityLocations',
                                                                        entityLocationsList
                                                                    );
                                                                } else {
                                                                    let entityLocationValues =
                                                                        [
                                                                            ...values.entityLocations,
                                                                            locValues,
                                                                        ];
                                                                    let uniqueEntityLocationValues =
                                                                        Array.from(
                                                                            new Set(
                                                                                entityLocationValues.map(
                                                                                    JSON.stringify
                                                                                )
                                                                            )
                                                                        ).map(JSON.parse);
                                                                    setFieldValue(
                                                                        'entityLocations',
                                                                        uniqueEntityLocationValues
                                                                    );
                                                                }
                                                                this.closeLocationModal();
                                                            }}
                                                            onModalDismiss={() =>
                                                                this.closeLocationModal()
                                                            }
                                                        />
                                                        <CustomisedDatatable
                                                            tableRecords={{
                                                                columns: locListColumns,
                                                                rows: this.generateLocationTable(
                                                                    values.entityLocations,
                                                                    setFieldValue,
                                                                    values
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Financial Year  */}
                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.financial_year'
                                                        )}<span className="text-danger required">
                                                            *
                                                        </span>
                                                    </h4>
                                                </div>
                                                {/* <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewFinancialYear()
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div> */}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <AddEditFinancialYearFormModal
                                                            isModalOpen={
                                                                this.state.isModalOpen
                                                            }
                                                            operationType={
                                                                this.state
                                                                    .financialYearOperationType
                                                            }
                                                            financialYearData={
                                                                this.state.financialYearData
                                                            }
                                                            financialYearList={
                                                                values.entityFinancialYears
                                                            }
                                                            onFormValid={(finValues) => {
                                                                let financialYearDataList =
                                                                    values.entityFinancialYears;
                                                                var updateDetailsIndex =
                                                                    financialYearDataList.findIndex(
                                                                        (opt) =>
                                                                            opt.id ===
                                                                            finValues.id
                                                                    );
                                                                if (
                                                                    updateDetailsIndex >
                                                                    -1 &&
                                                                    this.state
                                                                        .isFinancialYearEdit
                                                                ) {
                                                                    financialYearDataList[
                                                                        updateDetailsIndex
                                                                    ] = finValues;
                                                                    setFieldValue(
                                                                        'entityFinancialYears',
                                                                        financialYearDataList
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'entityFinancialYears',
                                                                        [
                                                                            ...values.entityFinancialYears,
                                                                            finValues,
                                                                        ]
                                                                    );
                                                                }
                                                                this.closeFinancialYearModal();
                                                            }}
                                                            onModalDismiss={() =>
                                                                this.closeFinancialYearModal()
                                                            }
                                                            entityLocationsIds={
                                                                values.entityLocations &&
                                                                    values.entityLocations
                                                                        .length > 0
                                                                    ? values.entityLocations.map(
                                                                        (location) =>
                                                                            location.countryId
                                                                    )
                                                                    : []
                                                            }
                                                        />
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: finListColumns,
                                                                rows: this.generateFinancialYearTable(
                                                                    values.entityFinancialYears,
                                                                    setFieldValue,
                                                                    values
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                       {/* Company Group Admin */}
                                       <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'lite.companyGroup'
                                                        )}
                                                    </h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewTeam(
                                                                'companyGroupAdmin'
                                                            )
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: teamColumns,
                                                                rows: this.generateTeamTable(
                                                                    values.companyGroupAdmin,
                                                                    setFieldValue,
                                                                    values,
                                                                    'companyGroupAdmin'
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Entity User */}
                                        <AddEditTeamFormModal
                                            isModalOpen={this.state.isTeamModalOpen}
                                            operationType={this.state.teamOperationType}
                                            teamData={this.state.teamData}
                                            teamList={values[this.state.teamType]}
                                            modalName={this.getTeamModalNameByType(
                                                this.state.teamType
                                            )}
                                            onFormValid={(teamValues) => {
                                                setFieldValue(this.state.teamType, [
                                                    ...values[this.state.teamType],
                                                    ...teamValues.users,
                                                ]);
                                                this.closeTeamModal();
                                            }}
                                            onModalDismiss={() => this.closeTeamModal()}
                                            entityId={this.state.entityId}
                                        />
                                        {/* Entity Admins */}
                                        {/* <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.entity_admin'
                                                        )}
                                                    </h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewTeam(
                                                                'entityAdminUsers'
                                                            )
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: teamColumns,
                                                                rows: this.generateTeamTable(
                                                                    values.entityAdminUsers,
                                                                    setFieldValue,
                                                                    values,
                                                                    'entityAdminUsers'
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card> */}

                                        {/* Entity Team */}
                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.entity_team'
                                                        )}
                                                    </h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewTeam(
                                                                'entityTeamUsers'
                                                            )
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: teamColumns,
                                                                rows: this.generateTeamTable(
                                                                    values.entityTeamUsers,
                                                                    setFieldValue,
                                                                    values,
                                                                    'entityTeamUsers'
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Entity Management Team */}
                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.entity_management_team'
                                                        )}
                                                    </h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewTeam(
                                                                'entityManagementUsers'
                                                            )
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: teamColumns,
                                                                rows: this.generateTeamTable(
                                                                    values.entityManagementUsers,
                                                                    setFieldValue,
                                                                    values,
                                                                    'entityManagementUsers'
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Entity Consultant Team */}
                                        {/* <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>
                                                        {__t(
                                                            'form_labels.entity.entity_consultant_team'
                                                        )}
                                                    </h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() =>
                                                            this.addNewTeam(
                                                                'entityConsultantUsers'
                                                            )
                                                        }
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: teamColumns,
                                                                rows: this.generateTeamTable(
                                                                    values.entityConsultantUsers,
                                                                    setFieldValue,
                                                                    values,
                                                                    'entityConsultantUsers'
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card> */}

                                        {/* Entity HoD  */}
                                        <Card>
                                            <CardHeader className="py-2">
                                                <div className="page-header">
                                                    <h4>{__t('form_labels.entity.hod')}</h4>
                                                </div>
                                                {
                                                    checkModulePermission(MODULE_LITE_CLIENT_ACCOUNT_DETAILS,PERMISSION_ADD) ?  <div className="page-action">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm float-right"
                                                        onClick={() => this.addNewHod()}
                                                    >
                                                        {ADD_NEW}
                                                    </button>
                                                </div>:null
                                                }
                                               
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <AddEditHodFormModal
                                                            companyGroupId={values.companyGroupId}
                                                            isModalOpen={
                                                                this.state.isHodModalOpen
                                                            }
                                                            operationType={
                                                                this.state.hodOperationType
                                                            }
                                                            hodData={this.state.hodData}
                                                            hodList={values.entityHod}
                                                            departmentList={
                                                                values.entityHod
                                                            }
                                                            onFormValid={(finValues) => {
                                                                let hodDataList =
                                                                    values.entityHod;
                                                                var updateDetailsIndex =
                                                                    hodDataList.findIndex(
                                                                        (opt) =>
                                                                            opt.id ===
                                                                            finValues.id
                                                                    );
                                                                if (
                                                                    updateDetailsIndex >
                                                                    -1 &&
                                                                    this.state.isHodEdit
                                                                ) {
                                                                    hodDataList[
                                                                        updateDetailsIndex
                                                                    ] = finValues;
                                                                    setFieldValue(
                                                                        'entityHod',
                                                                        hodDataList
                                                                    );
                                                                } else {
                                                                    this.state
                                                                        .hodOperationType ===
                                                                        SUBMIT
                                                                        ? setFieldValue(
                                                                            'entityHod',
                                                                            [
                                                                                ...values.entityHod,
                                                                                ...finValues.departmentId.map(
                                                                                    (
                                                                                        x,
                                                                                        index
                                                                                    ) => ({
                                                                                        departmentId:
                                                                                            x,
                                                                                        departmentName:
                                                                                            finValues
                                                                                                .departmentName[
                                                                                            index
                                                                                            ],
                                                                                        id: 0,
                                                                                        userId: finValues.userId,
                                                                                        userName:
                                                                                            finValues.userName,
                                                                                    })
                                                                                ),
                                                                            ]
                                                                        )
                                                                        : setFieldValue(
                                                                            'entityHod',
                                                                            [
                                                                                ...values.entityHod,
                                                                                finValues,
                                                                            ]
                                                                        );
                                                                }
                                                                this.closeHodModal();
                                                            }}
                                                            onModalDismiss={() =>
                                                                this.closeHodModal()
                                                            }
                                                            entityId={this.state.entityId}
                                                        />
                                                        <CustomisedDatatable
                                                            entries={10}
                                                            tableRecords={{
                                                                columns: hodListColumns,
                                                                rows: this.generateHodTable(
                                                                    values.entityHod,
                                                                    setFieldValue,
                                                                    values
                                                                ),
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Entity Emails */}
                                    {
                                       roleId  == ROLE_SUPER_ADMIN  || this.state.isRmUser || planId == LITE_PLAN_PREMIUM ?   <Card>
                                        <CardHeader className="py-2">
                                            <div className="page-header">
                                                <h4>
                                                    {__t(
                                                        'form_labels.entity.email_templates'
                                                    )}
                                                </h4>
                                            </div>
                                            <div className="page-action">
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm float-right"
                                                    onClick={() =>
                                                        this.addNewEmailTemplate()
                                                    }
                                                >
                                                    {ADD_NEW}
                                                </button>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <AddEditEmailTemplateFormModal
                                                        isModalOpen={
                                                            this.state
                                                                .isEmailTemplateModalOpen
                                                        }
                                                        isLite={true}
                                                        operationType={
                                                            this.state
                                                                .emailTemplateOperationType
                                                        }
                                                        emailTemplateData={
                                                            this.state.emailTemplateData
                                                        }
                                                        emailTemplateList={
                                                            values.entityEmailTemplates
                                                        }
                                                        onFormValid={(
                                                            emailTemplateValues
                                                        ) => {
                                                            let entityEmailTemplatesList =
                                                                values.entityEmailTemplates;
                                                            var updateDetailsIndex =
                                                                entityEmailTemplatesList.findIndex(
                                                                    (opt) =>
                                                                        opt.id ===
                                                                        emailTemplateValues.id
                                                                );
                                                            if (
                                                                updateDetailsIndex >
                                                                -1 &&
                                                                this.state
                                                                    .isEmailTemplateEdit
                                                            ) {
                                                                entityEmailTemplatesList[
                                                                    updateDetailsIndex
                                                                ] = emailTemplateValues;
                                                                setFieldValue(
                                                                    'entityEmailTemplates',
                                                                    entityEmailTemplatesList
                                                                );
                                                            } else {
                                                                setFieldValue(
                                                                    'entityEmailTemplates',
                                                                    [
                                                                        ...values.entityEmailTemplates,
                                                                        emailTemplateValues,
                                                                    ]
                                                                );
                                                            }
                                                            this.closeEntityEmailTemplateModal();
                                                        }}
                                                        onModalDismiss={() =>
                                                            this.closeEntityEmailTemplateModal()
                                                        }
                                                        entityId={this.state.entityId}
                                                    />
                                                    <CustomisedDatatable
                                                        tableRecords={{
                                                            columns:
                                                                emailTemplateListColumns,
                                                            rows: this.generateEmailTemplateTable(
                                                                values.entityEmailTemplates,
                                                                setFieldValue,
                                                                values
                                                            ),
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>:null
                                    }
                                      
                                    {roleId  == ROLE_SUPER_ADMIN  || this.state.isRmUser || planId == LITE_PLAN_PREMIUM  ? <Row>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <div className="d-inline-block">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-2"
                                                            disabled={this.state.liteEntityUpdateLoader}
                                                        >
                                                            {this.state.operationType}
                                                        </Button>
                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                            onClick={() => {
                                                                this.props.history.push(
                                                                    '/entities'
                                                                );
                                                            }}
                                                        >
                                                            {CANCEL}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>:null}
                                        
                                    </Form>
                                )}
                            </Formik>
                            <AuditLogModal
                                exportBtn={true}
                                key={'finYear'}
                                isModalOpen={this.state.isAuditModalOpen}
                                modalName={__t('page_headers.audit_trails')}
                                auditType={'entityFinancialYear'}
                                auditId={this.state.auditId}
                                onModalDismiss={() => this.closeAuditLogModal()}
                            />
                            <AuditLogModal
                                exportBtn={true}
                                key={'location'}
                                isModalOpen={this.state.isAuditLocationModalOpen}
                                modalName={__t('page_headers.audit_trails')}
                                auditType={'entityLocation'}
                                auditId={this.state.auditLocationId}
                                onModalDismiss={() => this.closeAuditLogLocationModal()}
                            />
                            <AuditLogModal
                                exportBtn={true}
                                key={'entityEmailTemplate'}
                                isModalOpen={this.state.isAuditEmailTemplateModalOpen}
                                modalName={__t('page_headers.audit_trails')}
                                auditType={'entityEmailTemplate'}
                                auditId={this.state.auditEmailTemplateId}
                                onModalDismiss={() =>
                                    this.closeAuditLogEmailTemplateModal()
                                }
                            />

                            <AuditLogModal
                                exportBtn={true}
                                key={'entityDepartmentHod'}
                                isModalOpen={this.state.isAuditHodModalOpen}
                                modalName={__t('page_headers.audit_trails')}
                                auditType={'entityDepartmentHod'}
                                auditId={this.state.auditHodId}
                                onModalDismiss={() => this.closeAuditLogHodModal()}
                            />
                        </TabPane>
                        
                    <TabPane className="question_answer custom_table question-accordion"  tabId="questionnaire">
                        {/* {
                          this.state.liteEntityQuestionList?.lawCategoryList && this.state.liteEntityQuestionList?.lawCategoryList.length > 0 ? this.state.liteEntityQuestionList?.lawCategoryList.map((lawCategory,index)=>{
                                    return <div key={index} className='question-item'>
                                        <QuestionAccordion
                                            className="accordion"
                                            viewDialog={false}
                                            dialogName={lawCategory?.lawCategoryName}
                                        >
                                            <div>
                                                {
                                                   lawCategory && lawCategory.questions && lawCategory.questions.length > 0 ? lawCategory.questions.map((element,ind)=>{
                                                        return <div className='m-3'>
                                                                <h6 className="m-0">{ind+1}. {element?.questionText}</h6>
                                                            <div className='input-group'>
                                                                {element?.questionAnswerList && element?.questionAnswerList.length > 0 && element?.questionAnswerList?.map((option, optionIndex) => {
                                                                    return <div className='' key={optionIndex}>
                                                                        <input className="cl-custom-check" id={option?.liteQuestionOption?.answerText} type="checkbox" disabled={true} />
                                                                        <label className="cl-custom-check-label" htmlFor={option?.liteQuestionOption?.answerText} title={option?.liteQuestionOption?.answerText}>{option?.liteQuestionOption?.answerText}</label>
                                                                    </div>
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                    }): null
                                                }          
                                            </div>
                                        </QuestionAccordion>
                                    </div>
                                }): <p>No Question Answer Found</p>
                        } */}
                    <div className='question-item' >  
                        <div className="accordion">
                            {this.state.liteEntityQuestionList?.questionData || this.state.liteEntityQuestionList?.questionData?.length <=0 ? <NoDataFound /> :this.state.liteEntityQuestionList?.questionData && this.state.liteEntityQuestionList?.questionData.length > 0 && this.state.liteEntityQuestionList?.questionData.map((element,ind)=>{
                                return <div className='p-3 mb-3' key={ind}>
                                            <h6 className="m-0">{ind+1}. {element?.questionText}</h6>
                                        <div className='input-group'>
                                            {element?.liteClientQuestionAnswerList && element?.liteClientQuestionAnswerList.length > 0 && element?.liteClientQuestionAnswerList?.map((option, optionIndex) => {
                                                return <div className='' key={optionIndex}>
                                                    <input className="cl-custom-check" id={option?.liteQuestionOption?.answerText} type="checkbox" disabled={true} />
                                                    <label className="cl-custom-check-label" htmlFor={option?.liteQuestionOption?.answerText} title={option?.liteQuestionOption?.answerText}>{option?.liteQuestionOption?.answerText}</label>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                            })}
                        </div>
                    </div>
                    </TabPane>
                        
                    </TabContent>
                </div>
            </div>

        </React.Fragment>
    }
}

const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropDown: selectSortedCountryList(),
    sortedStateListDropDown: selectSortedStateList(),
    sortedCityListDropDown: selectSortedCityList(),
    sortedUserListDropdown: selectSortedUserList(),
    entityApiResponse: makeEntitySelectField('entityApiResponse'),
    editEntityApiResponse: makeEntitySelectField('editEntityApiResponse'),
    sortedRoleListDropDown: selectSortedRolesWithEntityRoleList(),
    entityDetailsByIdApiResponse:makeLiteAccountSelectField("entityDetailsByIdApiResponse"),
    updateEntityDetailsByIdApiResponse:makeLiteAccountSelectField("updateEntityDetailsByIdApiResponse"),
    entityWiseQuestionListApiResponse:makeLiteAccountSelectField("entityWiseQuestionListApiResponse")
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUsersByEntityRequest,
    addEntityRequest,
    getEntityByEntityIdRequest,
    updateEntityRequest,
    getRolesWithEntityRoleByEntityListRequest,
    addFileUploadRequest,
    LiteEntityDetailsByEntityIdRequest,
    LiteEntityUpdateByIdRequest,
    getEntityWiseQuestionListRequest
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withUserReducer,
    withUserSaga,
    withEntityReducer,
    withEntitySaga,
    withEntityRoleReducer,
    withEntityRoleSaga,
    withTaskReducer,
    withTaskSaga,
    withAccountDetailsReducer,
    withAccountDetailsSaga,
    withRouter
)(LiteEntityDetails); 