import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import { getDateTimeFromDateTimeString } from 'helpers/dateTimeHelper';
import { getInitials } from 'helpers/generalUtils';

class CommentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops) {

    }

    render() {
        return (
            <React.Fragment>
                <div className="chat-title">
                    <span className="assigne-box green-round" title={__t(this.props.activity.user.fullName)}                    >
                        {getInitials(this.props.activity.user.fullName)}
                    </span>
                </div>
                <div className="ml-3">
                    <div>
                        <h5>{this.props.loginUserId == this.props.activity.createdBy ? 'You' : this.props.activity.user.fullName}</h5>
                        <div className="small-title">
                            <p className="m-0">
                                <svg
                                    width="38"
                                    height="22"
                                    viewBox="0 0 38 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 12.8361L9.13634 22L11.4409 19.6721L2.32086 10.5246M35.6791 0L18.3871 17.3607L11.588 10.5246L9.25075 12.8361L18.3871 22L38 2.32787M28.7492 2.32787L26.4447 0L16.0662 10.4098L18.3871 12.7213L28.7492 2.32787Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </p>
                            <small>{getDateTimeFromDateTimeString(this.props.activity.createdAt)}</small>
                        </div>
                    </div>
                    <div className='response-msg'>
                        <p className="mb-1">{this.props.activity?.remarks}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});

export default compose(withConnect, withRouter)(CommentDetails);
