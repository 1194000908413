import React, { Component } from 'react';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    BACK,
    DATE_FORMAT_DATEPICKER,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest,
    getComplianceLegalUpdateDetailsByIdRequest,
    complianceLegalUpdateRequest,
    getLawCompliancebyLowIdRequest,
    getlawSectionByLowIdRequest,
    getComplianceCategoriesRequest,
    getAllDocumentTypeListRequest,
    getAllupdateTypeListRequest,
    getAllSubUpdateTypeListRequest,
    getDepartmentListByStatusRequest,
    updateAssigngcmLegalUpdateRequest,
} from 'store/actions';
import { compose } from 'redux';
import Select from 'react-select';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import industryReducer from 'store/Masters/Industry/reducer';
import industrySaga from 'store/Masters/Industry/saga';
import subIndustryReducer from 'store/Masters/SubIndustry/reducer';
import subIndustrySaga from 'store/Masters/SubIndustry/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedIndustryList } from 'store/Masters/Industry/selector';
import { selectSortedSubIndustryList } from 'store/Masters/SubIndustry/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import __t from 'i18n/translator';
import { selectSortedLawSearchList } from 'store/Masters/Law/selector';
import { selectSortedEventList } from 'store/Masters/Event/selector';
import { initialLegalUpdatesState } from './CommonFunctions';
import { formatDateForApiSystem, getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import legalUpdateReducer from 'store/UpdateCenter/LegalUpdates/reducer';
import legalUpdateSaga from 'store/UpdateCenter/LegalUpdates/saga';
import DatePicker from 'react-datepicker';
import {
    makeLegalUpdateSelectField,
    selectSortedSubUpdateTypeList,
    selectSortedUpdateTypeList,
} from 'store/UpdateCenter/LegalUpdates/selector';
import {
    commonConfirmBox,
    permissionDeniedMessage,
    toastrMessage,
} from 'helpers/messageHelper';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { compareString } from 'helpers/generalUtils';
import {
    documentAttachedValues,
    localAttornyValidationStatus,
    priorityStatus,
} from 'constants/dropdowns';
import lawDocumentReducer from 'store/LawDocument/reducer';
import lawDocumentSaga from 'store/LawDocument/saga';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
import { selectSortedDocumentTypeList } from 'store/LawDocument/selector';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import {
    ACKNOWLEDGE_BY_UPDATER,
    APPROVAL_BASED_UPDATE,
    APPROVED,
    DEACTIVATION,
    NEW_ADDITIONS,
    PENDING,
    PENDING_WITH_APPROVER,
    PENDING_WITH_SUPER_ADMIN,
} from 'constants/databaseConstants';
import ApproveModal from './ApproveModal';
import { decryptData } from 'helpers/encryptor';
import { userIsLite } from 'helpers/litehelper';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const industryKey = 'industry';
const withIndustryReducer = injectReducer({
    key: industryKey,
    reducer: industryReducer,
});
const withIndustrySaga = injectSaga({ key: industryKey, saga: industrySaga });
const subIndustryKey = 'subIndustry';
const withSubIndustryReducer = injectReducer({
    key: subIndustryKey,
    reducer: subIndustryReducer,
});
const withSubIndustrySaga = injectSaga({
    key: subIndustryKey,
    saga: subIndustrySaga,
});
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });

const legalUpdatesKey = 'legalUpdates';
const withLegalUpdatesReducer = injectReducer({
    key: legalUpdatesKey,
    reducer: legalUpdateReducer,
});
const withLegalUpdatesSaga = injectSaga({
    key: legalUpdatesKey,
    saga: legalUpdateSaga,
});

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});

const lawDocumentKey = 'lawDocument';
const withLawDocumentReducer = injectReducer({
    key: lawDocumentKey,
    reducer: lawDocumentReducer,
});
const withLawDocumentSaga = injectSaga({
    key: lawDocumentKey,
    saga: lawDocumentSaga,
});

const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});

class LegalUpdateForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            operationType: SUBMIT,
            legalUpdatesData: initialLegalUpdatesState(),
            legalUpdatesId: this.props.match.params.legalUpdatesId,
            lawComplianceList: [],
            lawSectionList: [],
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            userApproval: false,
            actionType: 'Approve',
            statusType: '',
            isFormOpen: false,
            countryId: '',
            formSubmit: [],
            isFormSubmit: true,
            userRoles: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES))),
            isChecker: false,
            sortedSubIndustryListDropdown: [],
            sortedSubUpdateTypeListDropdown: []
        };
    }
    componentDidMount() {
        this.props.getComplianceLegalUpdateDetailsByIdRequest(
            this.state.legalUpdatesId
        );
        this.props.getCompanyGroupListByStatusRequest(ACTIVE);
        this.props.getCountryListByStatusRequest(ACTIVE);
        this.props.getIndustryListByStatusRequest(ACTIVE);
        this.props.getLawCategoryListByStatusRequest(ACTIVE);
        this.props.getComplianceCategoriesRequest();
        this.props.getAllDocumentTypeListRequest();
        this.props.getAllupdateTypeListRequest();
        this.props.getDepartmentListByStatusRequest(ACTIVE,userIsLite() ? 1:null);

        let checkerRoles = this.state.userRoles.filter(
            (x) => x.roleId == 3 && x.type == 'CHECKER'
        );

        if (checkerRoles.length > 0) {
            this.setState({
                isChecker: true,
            });
        }
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? [countryIds] : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? [stateIds] : []);
    }
    getlawComplianceList(lawId) {
        this.props.getLawCompliancebyLowIdRequest(lawId);
        this.props.getlawSectionByLowIdRequest(lawId);
    }

    getLawsByFilters(values) {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: values.countryId ? values.countryId : [],
                //stateId: values.stateId ? values.stateId : [],
                //cityId: values.cityId ? values.cityId : [],
                //industryId: values.industryId ? values.industryId : [],
                //subIndustryId: values.subIndustryId ? values.subIndustryId : [],
                // lawCategoryId: values.lawCategoryId ? values.lawCategoryId : [],
                type: this.props.pageType,
            },
            orderBy: {},
        };
        this.props.getLawListRequest(apiData, false);
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                FormSubmit: values,
            });

            this.props.complianceLegalUpdateRequest(
                this.state.legalUpdatesId,
                {
                    ...values,
                    effectiveDate: (values.effectiveDate) ? formatDateForApiSystem(values.effectiveDate) : '',
                    dateOfPublication: (values.dateOfPublication) ? formatDateForApiSystem(values.dateOfPublication) : '',
                    dateOfUpdate: (values.dateOfUpdate) ? formatDateForApiSystem(values.dateOfUpdate) : '',
                }
            );
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    permissionDenied = async (message) => {
        const response = await permissionDeniedMessage(message);
        if (response) {
            this.props.history.goBack();
        }
    };
    filterForApproveButton = (value) => {
        let label = '';

        if (
            value.updateTypeId.includes(APPROVAL_BASED_UPDATE) == false &&
            value.updateTypeId.includes(NEW_ADDITIONS) == false &&
            value.updateTypeId.includes(DEACTIVATION) == false &&
            value.legalUpdateStatusId === APPROVED
        ) {
            label = 'Acknowledged';
        } else {
            switch (value.legalUpdateStatusId) {
                case PENDING:
                    label = 'Assign to Approver';
                    break;
                case PENDING_WITH_APPROVER:
                    label = 'Assign to Updater';
                    break;
                case APPROVED:
                    label = 'Assign to Super Admin';
                    break;
                case PENDING_WITH_SUPER_ADMIN:
                    label = 'Acknowledged';
                    break;
                default:
                    break;
            }
        }

        return label;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.compliancelegalUpdateDetailsApiResponse &&
            this.props.compliancelegalUpdateDetailsApiResponse !==
            nextprops.compliancelegalUpdateDetailsApiResponse
        ) {
            if (
                nextprops.compliancelegalUpdateDetailsApiResponse
                    .responseType === SUCCESS
            ) {
                let data =
                    nextprops.compliancelegalUpdateDetailsApiResponse.data;

                if (data.createdBy === this.state.loginUserId) {
                    this.permissionDenied(
                        __t('validations.legal_update.permission_denied')
                    );
                }
                if (data.industryId) {
                    this.props.getSubIndustryListByIndustryRequest(
                        data.industryId
                    );
                }

                if (data.updateTypeId) {
                    this.props.getAllSubUpdateTypeListRequest(
                        data.updateTypeId
                    );
                }
                this.getStateListByCountry(data?.countryId);

                this.getCityListByState(data?.stateId);

                this.getLawsByFilters({
                    countryId: data.countryId ? [data.countryId] : [],
                    // stateId: data.stateId ? data.stateId : [],
                    // cityId: data.cityId ? data.cityId : [],
                    // industryId: data.industryId ? data.industryId : [],
                    // subIndustryId: data.subIndustryId ? data.subIndustryId : [],
                    // lawCategoryId: data.lawCategoryId ? data.lawCategoryId : [],
                });

                if (data?.lawId) {
                    this.getlawComplianceList(data?.lawId);
                }
                this.setState({
                    legalUpdatesData: {
                        countryId: data?.countryId,
                        stateId: data?.stateId,
                        cityId: data?.cityId,
                        lawCategoryId: data?.lawCategoryId,
                        industryId: data?.industryId,
                        subIndustryId: data?.subIndustryId,
                        dateOfUpdate: data?.dateOfUpdate
                            ? getDateObjectWithValueFromMoment(
                                data?.dateOfUpdate
                            )
                            : null,
                        lawId: data?.lawId ? data?.lawId : '',
                        lawComplianceId: data?.lawComplianceId
                            ? data?.lawComplianceId
                            : '',
                        referenceNo: data?.referenceNo,
                        documentUrl: data?.documentUrl,
                        documentTypeId: data?.documentTypeId,
                        updateTypeId: data?.updateTypeId,
                        subUpdateTypeId: data?.subUpdateTypeId,
                        updateSummary: data?.updateSummary,
                        particularsOfUpdate: data?.particularsOfUpdate,
                        impactOfUpdate: data?.impactOfUpdate,
                        lawSectionId: data?.lawSectionId,
                        links: data?.links,
                        source: data?.source,
                        effectiveDate: data?.effectiveDate
                            ? getDateObjectWithValueFromMoment(
                                data?.effectiveDate
                            )
                            : null,
                        dateOfPublication: data?.dateOfPublication
                            ? getDateObjectWithValueFromMoment(
                                data?.dateOfPublication
                            )
                            : null,
                        isDocumentAttached: data?.isDocumentAttached,
                        complianceCategoryId: data?.complianceCategoryId,
                        legalUpdateStatusId: data?.legalUpdateStatusId,
                        approverUserId: data?.approverUserId,
                        updaterUserId: data?.updaterUserId,
                        remarks: data?.remarks,
                        departmentId: data?.departmentId,
                        updatePriority: data?.updatePriority,
                        localAttornyValidation: data?.localAttornyValidation,
                        superAdminId: data?.superAdminId,
                    },
                });
            } else {
                toastrMessage(
                    nextprops.compliancelegalUpdateDetailsApiResponse.message,
                    nextprops.compliancelegalUpdateDetailsApiResponse
                        .responseType
                );
            }
        }

        if (
            nextprops.lawComplianceListApiResponse &&
            this.props.lawComplianceListApiResponse !==
            nextprops.lawComplianceListApiResponse
        ) {
            this.setState({
                lawComplianceList: nextprops.lawComplianceListApiResponse
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.complianceCode + ' - ' + opt.complianceTitle,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.complianceLegalUpdateApiResponse &&
            this.props.complianceLegalUpdateApiResponse !==
            nextprops.complianceLegalUpdateApiResponse
        ) {
            toastrMessage(
                nextprops.complianceLegalUpdateApiResponse.message,
                nextprops.complianceLegalUpdateApiResponse.responseType
            );
            if (
                nextprops.complianceLegalUpdateApiResponse.responseType ===
                SUCCESS
            ) {
                if (this.state.isFormSubmit === false) {
                    this.openUserApprovalModal(this.state.FormSubmit);
                } else {
                    this.props.history.push('/legal-updates');
                }
            }
        }

        if (
            nextprops.lawSectionApiResponse &&
            this.props.lawSectionApiResponse !== nextprops.lawSectionApiResponse
        ) {
            this.setState({
                lawSectionList: nextprops.lawSectionApiResponse
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.sectionName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }


        if (nextprops.sortedSubIndustryListDropdown && this.props.sortedSubIndustryListDropdown != nextprops.sortedSubIndustryListDropdown) {
            this.setState({
                sortedSubIndustryListDropdown: nextprops.sortedSubIndustryListDropdown
            })
        }
        if (nextprops.sortedSubUpdateTypeListDropdown && this.props.sortedSubUpdateTypeListDropdown != nextprops.sortedSubUpdateTypeListDropdown) {
            this.setState({
                sortedSubUpdateTypeListDropdown: nextprops.sortedSubUpdateTypeListDropdown
            })
        }
    }

    closeUserApprovalModal = () => {
        this.setState({
            //resetTableSection: true,
            userApproval: false,
            actionType: 'Approve',
        });
    };

    acknowledgedUser = async (id) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.acknowledged_legal_update')
        );
        if (response) {
            this.props.updateAssigngcmLegalUpdateRequest(
                {},
                this.state.legalUpdatesId
            );
        }
    };
    openUserApprovalModal = (value) => {
        if (value.legalUpdateStatusId === PENDING_WITH_SUPER_ADMIN) {
            this.acknowledgedUser(value.id);
            return;
        }

        if (
            value.updateTypeId.includes(APPROVAL_BASED_UPDATE) == false &&
            value.updateTypeId.includes(NEW_ADDITIONS) == false &&
            value.updateTypeId.includes(DEACTIVATION) == false &&
            value.legalUpdateStatusId === APPROVED
        ) {
            this.acknowledgedUser(value.id);
        } else {
            this.setState({
                userApproval: true,
                actionType: 'Approve',
            });
        }
    };

    filterForApproveBtn = (value) => {
        let status = false;
        switch (value?.legalUpdateStatusId) {
            case PENDING:
                status = this.state.isChecker;
                break;
            case PENDING_WITH_APPROVER:
                status =
                    value.approverUserId === this.state.loginUserId
                        ? true
                        : false;
                break;
            case APPROVED:
                status =
                    value.updaterUserId === this.state.loginUserId
                        ? true
                        : false;
                break;
            case ACKNOWLEDGE_BY_UPDATER:
                status = false;
                break;

            case PENDING_WITH_SUPER_ADMIN:
                status =
                    value.superAdminId === this.state.loginUserId
                        ? true
                        : false;
                break;

            default:
                break;
        }
        return status;
    };
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <Card className="edit_compliance_form">
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/legal-updates'
                                                );
                                            }}
                                        >
                                            { }
                                        </a>
                                        {__t('page_headers.edit_legal_updates')}
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={this.state.legalUpdatesData}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({
                                        values,
                                        setFieldValue,
                                        submitForm,
                                    }) => (
                                        <Form>
                                            <React.Fragment>
                                                <Row className="edit_card_row">
                                                    <Col lg="12">
                                                        <h4>Basic</h4>
                                                    </Col>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="industryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedIndustryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let industry =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];

                                                                setFieldValue(
                                                                    'industryId',
                                                                    industry
                                                                );
                                                                setFieldValue(
                                                                    'subIndustryId',
                                                                    []
                                                                );

                                                                if (
                                                                    industry.length > 0) {
                                                                    this.props.getSubIndustryListByIndustryRequest(
                                                                        industry
                                                                    );
                                                                } else {
                                                                    this.setState({
                                                                        sortedSubIndustryListDropdown: []
                                                                    })
                                                                }
                                                            }}
                                                            isMulti
                                                            value={this.props.sortedIndustryListDropdown?.filter(
                                                                (option) =>
                                                                    values.industryId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.allocation.industry'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.allocation.industry'
                                                            ),
                                                            values.industryId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.industry_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="subIndustryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.state
                                                                    .sortedSubIndustryListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let subIndustry =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];
                                                                setFieldValue(
                                                                    'subIndustryId',
                                                                    subIndustry
                                                                );

                                                            }}
                                                            value={this.state.sortedSubIndustryListDropdown?.filter(
                                                                (option) =>
                                                                    values.subIndustryId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.allocation.sub_industry'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.name_of_business_function'
                                                            ),
                                                            values.subIndustryId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.sub_industry_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="countryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedCountryListDropdown
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            isDisabled={true}
                                                            value={this.props.sortedCountryListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.countryId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.allocation.country'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.allocation.country'
                                                            ),
                                                            values.countryId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.allocation.country_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="stateId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedStateListDropdown
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'stateId',
                                                                    opt.value
                                                                );

                                                                setFieldValue(
                                                                    'cityId',
                                                                    []
                                                                );
                                                                this.getCityListByState(
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedStateListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.stateId
                                                            )}
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.states'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={
                                                                CustomMultiSelect
                                                            }
                                                            name="cityId[]"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedCityListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValues =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];
                                                                setFieldValue(
                                                                    'cityId',
                                                                    selectedValues
                                                                );

                                                            }}
                                                            value={this.props.sortedCityListDropdown?.filter(
                                                                (option) =>
                                                                    values.cityId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            hideSelectedOptions={
                                                                false
                                                            }
                                                            allowSelectAll={
                                                                true
                                                            }
                                                            components={{
                                                                Option,
                                                                MultiValue,
                                                                animatedComponents,
                                                            }}
                                                            key="cityId"
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.allocation.city'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="lawCategoryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedLawCategoryListDropdown
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'lawCategoryId',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.lawCategoryId
                                                            )}
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.law_categorys'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.law_categorys'
                                                            ),
                                                            values.lawCategoryId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.allocation.law_category_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={
                                                                CustomMultiSelect
                                                            }
                                                            isClearable={true}
                                                            className="form-control selectbox"
                                                            name="lawId"
                                                            options={
                                                                this.props
                                                                    .sortedLawListDropDown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'lawId',
                                                                    opt ? opt.value : ''
                                                                );
                                                                setFieldValue(
                                                                    'lawComplianceId',
                                                                    []
                                                                );
                                                                setFieldValue(
                                                                    'lawSectionId',
                                                                    []
                                                                );
                                                                this.getlawComplianceList(
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedLawListDropDown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.lawId
                                                            )}
                                                            key="laws"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.law_id'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            className="form-control selectbox"
                                                            isClearable={true}
                                                            name="lawComplianceId"
                                                            options={
                                                                this.state
                                                                    .lawComplianceList
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'lawComplianceId',
                                                                    opt ? opt.value : ''
                                                                );
                                                            }}
                                                            value={this.state.lawComplianceList?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.lawComplianceId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.law_compliance'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={
                                                                CustomMultiSelect
                                                            }
                                                            isClearable={true}
                                                            className="form-control selectbox"
                                                            name="lawSectionId"
                                                            options={
                                                                this.state
                                                                    .lawSectionList
                                                            }
                                                            onChange={(opt) => {
                                                                let selectedValues =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];

                                                                setFieldValue(
                                                                    'lawSectionId',
                                                                    selectedValues
                                                                );
                                                            }}
                                                            value={this.state.lawSectionList?.filter(
                                                                (option) =>
                                                                    values.lawSectionId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            hideSelectedOptions={
                                                                false
                                                            }
                                                            allowSelectAll={
                                                                true
                                                            }
                                                            components={{
                                                                Option,
                                                                MultiValue,
                                                                animatedComponents,
                                                            }}
                                                            key="lawSectionId"
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.law_section'
                                                            )}{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="complianceCategoryId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.state
                                                                    .allCompliancesCategoriesList
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'complianceCategoryId',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.state.allCompliancesCategoriesList?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.complianceCategoryId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.compliance.compliance_classification'
                                                            )}
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="departmentId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedDepartmentListDropdown
                                                            }
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    `departmentId`,
                                                                    opt &&
                                                                    opt.map(
                                                                        (
                                                                            x
                                                                        ) =>
                                                                            x.value
                                                                    )
                                                                );
                                                            }}
                                                            value={this.props.sortedDepartmentListDropdown.filter(
                                                                (option) =>
                                                                    values.departmentId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti={true}
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.name_of_business_function'
                                                            )}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="updatePriority"
                                                            className="form-control selectbox"
                                                            options={
                                                                priorityStatus
                                                            }
                                                            onChange={(opt) => {
                                                                let updatePriority =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'updatePriority',
                                                                    updatePriority
                                                                );
                                                            }}
                                                            value={priorityStatus?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.updatePriority
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.priority'
                                                            )}
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.priority'
                                                            ),
                                                            values.updatePriority,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.priority_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="localAttornyValidation"
                                                            className="form-control selectbox"
                                                            options={
                                                                localAttornyValidationStatus
                                                            }
                                                            onChange={(opt) => {
                                                                let localAttornyValidation =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'localAttornyValidation',
                                                                    localAttornyValidation
                                                                );
                                                            }}
                                                            value={localAttornyValidationStatus?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.localAttornyValidation
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.local_attorny_validation'
                                                            )}
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="documentTypeId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .sortedDocumentTypeListDropDown
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            onChange={(opt) => {
                                                                setFieldValue(
                                                                    'documentTypeId',
                                                                    opt.value
                                                                );
                                                            }}
                                                            value={this.props.sortedDocumentTypeListDropDown?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.documentTypeId
                                                            )}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.document_type'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.document_type'
                                                            ),
                                                            values.documentTypeId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.document_type_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="updateTypeId"
                                                            className="form-control selectbox"
                                                            options={
                                                                this.props
                                                                    .updateTypeList
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            // onChange={(opt) => {
                                                            //     setFieldValue(
                                                            //         'updateTypeId',
                                                            //         opt.value
                                                            //     );
                                                            // }}

                                                            onChange={(opt) => {
                                                                let selectedValues =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];

                                                                setFieldValue(
                                                                    'updateTypeId',
                                                                    selectedValues
                                                                );
                                                                setFieldValue(
                                                                    'subUpdateTypeId',
                                                                    []
                                                                );

                                                                if (selectedValues.length > 0) {
                                                                    this.props.getAllSubUpdateTypeListRequest()
                                                                } else {
                                                                    this.setState({
                                                                        sortedSubUpdateTypeListDropdown: []
                                                                    })
                                                                }
                                                            }}
                                                            value={this.props.updateTypeList?.filter(
                                                                (option) =>
                                                                    values.updateTypeId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            hideSelectedOptions={
                                                                false
                                                            }
                                                            allowSelectAll={
                                                                true
                                                            }
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.update_type'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.update_type'
                                                            ),
                                                            values.updateTypeId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.update_type_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-8">
                                                        <Field
                                                            component={Select}
                                                            name="subUpdateTypeId"
                                                            className="form-control selectbox"
                                                            options={this.state
                                                                .sortedSubUpdateTypeListDropdown.filter((opt) => values.updateTypeId.includes(opt.parentUpdateTypeId))
                                                            }
                                                            styles={{
                                                                menuPortal: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                                menu: (
                                                                    provided
                                                                ) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                            onChange={(opt) => {
                                                                let selectedValues =
                                                                    opt
                                                                        ? opt.map(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x.value
                                                                        )
                                                                        : [];

                                                                setFieldValue(
                                                                    'subUpdateTypeId',
                                                                    selectedValues
                                                                );

                                                            }}
                                                            value={this.state.sortedSubUpdateTypeListDropdown?.filter(
                                                                (option) =>
                                                                    values.subUpdateTypeId?.includes(
                                                                        option.value
                                                                    )
                                                            )}
                                                            isMulti
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            hideSelectedOptions={
                                                                false
                                                            }
                                                            allowSelectAll={
                                                                true
                                                            }
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.sub_update_type'
                                                            )}{' '}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.sub_update_type'
                                                            ),
                                                            values.subUpdateTypeId,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.sub_update_type_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            name="referenceNo"
                                                            className="form-control"
                                                            value={
                                                                values.referenceNo
                                                            }
                                                            placeholder={__t(
                                                                'form_labels.legal_update.reference_no'
                                                            )}
                                                            maxLength={100}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.reference_no'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.reference_no'
                                                            ),
                                                            values.referenceNo,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.reference_no_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <Col lg={8} className="d-flex p-0">
                                                        <FormGroup className="col-lg-4 edit_datepicker_col">
                                                            <Field
                                                                name="dateOfUpdate"
                                                                component={
                                                                    DatePicker
                                                                }
                                                                className="form-control"
                                                                placeholderText={__t(
                                                                    'form_labels.legal_update.date_of_update'
                                                                )}
                                                                selected={
                                                                    values.dateOfUpdate
                                                                        ? getDateObjectWithValueFromMoment(
                                                                            values.dateOfUpdate
                                                                        )
                                                                        : null
                                                                }
                                                                dateFormat={
                                                                    DATE_FORMAT_DATEPICKER
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    setFieldValue(
                                                                        `dateOfUpdate`,
                                                                        date
                                                                    );
                                                                }}
                                                                showMonthDropdown={
                                                                    true
                                                                }
                                                                showYearDropdown={
                                                                    true
                                                                }
                                                                dropdownMode="select"
                                                                onFocus={(e) =>
                                                                    e.target.blur()
                                                                }
                                                            />

                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.legal_update.date_of_update'
                                                                )}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.legal_update.date_of_update'
                                                                ),
                                                                values.dateOfUpdate,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.legal_update.date_of_update_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup className="col-lg-4 edit_datepicker_col">
                                                            <Field
                                                                name="effectiveDate"
                                                                component={
                                                                    DatePicker
                                                                }
                                                                className="form-control"
                                                                placeholderText={__t(
                                                                    'form_labels.legal_update.effective_date'
                                                                )}
                                                                selected={
                                                                    values.effectiveDate
                                                                        ? getDateObjectWithValueFromMoment(
                                                                            values.effectiveDate
                                                                        )
                                                                        : null
                                                                }
                                                                dateFormat={
                                                                    DATE_FORMAT_DATEPICKER
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    setFieldValue(
                                                                        `effectiveDate`,
                                                                        date
                                                                    );
                                                                }}
                                                                showMonthDropdown={
                                                                    true
                                                                }
                                                                showYearDropdown={
                                                                    true
                                                                }
                                                                dropdownMode="select"
                                                                onFocus={(e) =>
                                                                    e.target.blur()
                                                                }
                                                            />

                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.legal_update.effective_date'
                                                                )}
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup className="col-lg-4 edit_datepicker_col">
                                                            <Field
                                                                name="dateOfPublication"
                                                                component={
                                                                    DatePicker
                                                                }
                                                                className="form-control"
                                                                placeholderText={__t(
                                                                    'form_labels.legal_update.date_of_publication'
                                                                )}
                                                                selected={
                                                                    values.dateOfPublication
                                                                        ? getDateObjectWithValueFromMoment(
                                                                            values.dateOfPublication
                                                                        )
                                                                        : null
                                                                }
                                                                dateFormat={
                                                                    DATE_FORMAT_DATEPICKER
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    setFieldValue(
                                                                        `dateOfPublication`,
                                                                        date
                                                                    );
                                                                }}
                                                                showMonthDropdown={
                                                                    true
                                                                }
                                                                showYearDropdown={
                                                                    true
                                                                }
                                                                dropdownMode="select"
                                                                onFocus={(e) =>
                                                                    e.target.blur()
                                                                }
                                                            />

                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.legal_update.date_of_publication'
                                                                )}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {this.validator.message(
                                                                __t(
                                                                    'form_labels.legal_update.date_of_publication'
                                                                ),
                                                                values.dateOfPublication,
                                                                'required',
                                                                {
                                                                    messages: {
                                                                        required:
                                                                            __t(
                                                                                'validations.legal_update.date_of_publication_required'
                                                                            ),
                                                                    },
                                                                }
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4 edit_card_row">
                                                    <Col lg="12">
                                                        <h4>Advance</h4>
                                                    </Col>



                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="updateSummary"
                                                            className="form-control small_scroll"
                                                            placeholder={__t(
                                                                'form_labels.legal_update.update_summary'
                                                            )}
                                                            value={
                                                                values.updateSummary
                                                            }
                                                            maxLength={1000}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.update_summary'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.update_summary'
                                                            ),
                                                            values.updateSummary,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.update_summary_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="particularsOfUpdate"
                                                            className="form-control small_scroll"
                                                            placeholder={__t(
                                                                'form_labels.legal_update.particulars_of_update'
                                                            )}
                                                            value={
                                                                values.particularsOfUpdate
                                                            }
                                                            maxLength={1000}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.particulars_of_update'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.particulars_of_update'
                                                            ),
                                                            values.particularsOfUpdate,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.particulars_of_update_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="impactOfUpdate"
                                                            className="form-control small_scroll"
                                                            placeholder={__t(
                                                                'form_labels.legal_update.impact_of_update'
                                                            )}
                                                            value={
                                                                (values.impactOfUpdate) ? values.impactOfUpdate : ''
                                                            }
                                                            maxLength={1000}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.impact_of_update'
                                                            )}
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="links"
                                                            className="form-control small_scroll"
                                                            placeholder={__t(
                                                                'form_labels.legal_update.links'
                                                            )}
                                                            value={values.links}
                                                            maxLength={1000}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.links'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.links'
                                                            ),
                                                            values.links,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.links_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="source"
                                                            className="form-control small_scroll"
                                                            placeholder={__t(
                                                                'form_labels.legal_update.source'
                                                            )}
                                                            value={
                                                                values.source
                                                            }
                                                            maxLength={1000}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.source'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.source'
                                                            ),
                                                            values.source,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.source_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component={Select}
                                                            name="isDocumentAttached"
                                                            className="form-control selectbox"
                                                            options={
                                                                documentAttachedValues
                                                            }
                                                            onChange={(opt) => {
                                                                let isDocumentAttached =
                                                                    opt
                                                                        ? opt.value
                                                                        : '';
                                                                setFieldValue(
                                                                    'isDocumentAttached',
                                                                    isDocumentAttached
                                                                );
                                                            }}
                                                            value={documentAttachedValues?.filter(
                                                                (option) =>
                                                                    option.value ===
                                                                    values.isDocumentAttached
                                                            )}
                                                            isClearable={true}
                                                        />
                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.attached_document'
                                                            )}
                                                            <span className="text-danger required">
                                                                *
                                                            </span>
                                                        </Label>
                                                        {this.validator.message(
                                                            __t(
                                                                'form_labels.legal_update.attached_document'
                                                            ),
                                                            values.updatePriority,
                                                            'required',
                                                            {
                                                                messages: {
                                                                    required:
                                                                        __t(
                                                                            'validations.legal_update.attached_document_required'
                                                                        ),
                                                                },
                                                            }
                                                        )}
                                                    </FormGroup>

                                                    <FormGroup className="col-lg-4">
                                                        <Field
                                                            component="textarea"
                                                            name="remarks"
                                                            className="form-control small_scroll"
                                                            value={
                                                                values.remarks
                                                            }
                                                            placeholder={__t(
                                                                'form_labels.legal_update.remarks'
                                                            )}
                                                            maxLength={100}
                                                            rows="3"
                                                        />

                                                        <Label className="form-label">
                                                            {__t(
                                                                'form_labels.legal_update.remarks'
                                                            )}
                                                        </Label>
                                                    </FormGroup>
                                                </Row>
                                                <FormGroup className="mb-0 d-flex justify-content-between mt-3 ">
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => {
                                                            this.props.history.goBack();
                                                        }}
                                                    >
                                                        {BACK}
                                                    </Button>
                                                    <div>
                                                        {this.filterForApproveBtn(
                                                            values
                                                        ) && (
                                                                <>
                                                                    {this.state
                                                                        .isChecker ===
                                                                        false && (
                                                                            <Button
                                                                                title="Reject"
                                                                                className="btn-danger mr-2"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            countryId:
                                                                                                values.countryId,
                                                                                            compliancesId:
                                                                                                values.id,
                                                                                        },
                                                                                        () => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    userApproval: true,
                                                                                                    actionType:
                                                                                                        'Reject',
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className="fa fa-ban "
                                                                                    aria-hidden="true"
                                                                                ></i>
                                                                                Reject
                                                                            </Button>
                                                                        )}
                                                                    <Button
                                                                        title={this.filterForApproveButton(
                                                                            values
                                                                        )}
                                                                        type="submit"
                                                                        color="primary"
                                                                        className="mr-2"
                                                                        onClick={() => {
                                                                            this.setState(
                                                                                {
                                                                                    countryId:
                                                                                        values.countryId,
                                                                                    isFormSubmit: false,
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fa fa-check"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        {this.filterForApproveButton(
                                                                            values
                                                                        )}
                                                                    </Button>
                                                                </>
                                                            )}
                                                        <Button
                                                            type="submit"
                                                            color="info"
                                                            className="mr-2"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isFormSubmit: true,
                                                                });
                                                            }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </React.Fragment>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                    <ApproveModal
                        isModalOpen={this.state.userApproval}
                        onModalDismiss={() => this.closeUserApprovalModal()}
                        modalName={
                            this.state.actionType == 'Approve'
                                ? this.state.legalUpdatesData
                                    .legalUpdateStatusId ==
                                    PENDING_WITH_APPROVER
                                    ? __t('tables.legal_updates.assign_updater')
                                    : this.state.legalUpdatesData
                                        .legalUpdateStatusId === APPROVED
                                        ? __t(
                                            'tables.legal_updates.assign_super_admin'
                                        )
                                        : __t(
                                            'tables.legal_updates.assign_approver'
                                        )
                                : __t('tables.legal_updates.reject_compliance')
                        }
                        countryId={this.state.countryId}
                        compliancesId={this.state.legalUpdatesId}
                        statusType={
                            this.state.legalUpdatesData.legalUpdateStatusId
                        }
                        actionType={this.state.actionType}
                    />
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    sortedIndustryListDropdown: selectSortedIndustryList(),
    sortedSubIndustryListDropdown: selectSortedSubIndustryList(),
    sortedLawListDropDown: selectSortedLawSearchList(),
    sortedEventListDropdown: selectSortedEventList(),
    compliancelegalUpdateDetailsApiResponse: makeLegalUpdateSelectField(
        'compliancelegalUpdateDetailsApiResponse'
    ),
    complianceLegalUpdateApiResponse: makeLegalUpdateSelectField(
        'complianceLegalUpdateApiResponse'
    ),
    lawComplianceListApiResponse: makeLawComplianceSelectField(
        'lawComplianceListApiResponse'
    ),
    lawSectionApiResponse: makeLawComplianceSelectField(
        'lawSectionApiResponse'
    ),
    allCompliancesCategoriesList: makeLawComplianceSelectField(
        'allCompliancesCategoriesList'
    ),
    sortedDocumentTypeListDropDown: selectSortedDocumentTypeList(),
    updateTypeList: selectSortedUpdateTypeList('updateTypeList'),
    sortedSubUpdateTypeListDropdown: selectSortedSubUpdateTypeList('subUpdateTypeList'),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    assignGcmLegalUpdateApiResponse: makeLegalUpdateSelectField(
        'assignGcmLegalUpdateApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getCompanyGroupListByStatusRequest,
    getEntityListByCompanyGroupIdRequest,
    getIndustryListByStatusRequest,
    getSubIndustryListByIndustryRequest,
    getLawCategoryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getLawListRequest,
    getComplianceLegalUpdateDetailsByIdRequest,
    complianceLegalUpdateRequest,
    getLawCompliancebyLowIdRequest,
    getlawSectionByLowIdRequest,
    getComplianceCategoriesRequest,
    getAllDocumentTypeListRequest,
    getAllupdateTypeListRequest,
    getAllSubUpdateTypeListRequest,
    getDepartmentListByStatusRequest,
    updateAssigngcmLegalUpdateRequest,
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withIndustryReducer,
    withIndustrySaga,
    withSubIndustryReducer,
    withSubIndustrySaga,
    withEntityReducer,
    withEntitySaga,
    withLawCategoryReducer,
    withLawCategorySaga,
    withLawReducer,
    withLawSaga,
    withLegalUpdatesReducer,
    withLegalUpdatesSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLawDocumentReducer,
    withLawDocumentSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withRouter
)(LegalUpdateForm);
