import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { BACK, DANGER, NA, NO, YES } from 'constants/commonConstants';
import { complianceAllHeaders } from './CommonFunctions';
import ShowMoreText from 'react-show-more-text';
import __t from 'i18n/translator';
import { toastrMessage } from 'helpers/messageHelper';
import CommonSectionRule from 'components/Application/CommonSectionRule';
class ShowComplianceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    scrollToSection = (className) => {
        const section = document.querySelector('#' + className);
        let position = section.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 180);
        document.getElementById(className).classList.add('animate__bounceIn');
        setTimeout(function () {
            document
                .getElementById(className)
                .classList.remove('animate__bounceIn');
        }, 2000);
    };
    // check if string is url
    checkUrl(str) {
        let isUrl;
        try {
            isUrl = !!new URL(str).hostname;
        } catch (e) {
            isUrl = false;
        }

        return isUrl;
    }
    getShowMoreTextElement = (element) => {
        return (
            <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="show-more-content"
                anchorClass="show-more-link"
                expandByClick={true}
                expanded={false}
                keepNewLines={true}
            >
                {`` + element + '\n'}
            </ShowMoreText>
        );
    };
    render() {
        const formNos =
            this.props?.complianceData?.formNo &&
                this.props?.complianceData?.formNo != ''
                ? this.props?.complianceData?.formNo.split(';')
                : [];
        let linkIndex = 0;
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <div className="compliance_details">
                            <Row className='compliance_badge'>
                                <Col lg="12">
                                    <h4
                                        className="w-100 d-inline-flex align-items-center font-weight-bold"
                                        style={{ background: 'none' }}
                                    >
                                        <Button
                                            type="button"
                                            color="secondary"
                                            outline
                                            className="compliance_back"
                                            onClick={() => {
                                                this.props.onClose();
                                            }}
                                        >
                                            <svg
                                                width="18"
                                                height="14"
                                                viewBox="0 0 18 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2 7L1.29289 6.29289L0.585786 7L1.29289 7.70711L2 7ZM17 8C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6V8ZM7.29289 0.292893L1.29289 6.29289L2.70711 7.70711L8.70711 1.70711L7.29289 0.292893ZM1.29289 7.70711L7.29289 13.7071L8.70711 12.2929L2.70711 6.29289L1.29289 7.70711ZM2 8H17V6H2V8Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            {BACK}
                                        </Button>{' '}
                                        {this.props.complianceData
                                            ?.complianceCode +
                                            ' - ' +
                                            this.props.complianceData
                                                .complianceTitle}
                                    </h4>
                                    {complianceAllHeaders.map((itemData, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <a
                                                    href={() => false}
                                                    className="badge badge-primary mr-2 p-1"
                                                    onClick={() =>
                                                        this.scrollToSection(
                                                            itemData.field
                                                        )
                                                    }
                                                >
                                                    {itemData.label}
                                                </a>
                                            </React.Fragment>
                                        );
                                    })}
                                </Col>
                                <Col lg="12">
                                    <div className='compliance-basic-details details-view'>
                                        <div className='compliance-card'>
                                            <h3>Compliance Details</h3>
                                            <div className='compliance-details'>
                                                <ul className="basic-details">

                                                    <li
                                                        key="sectionReference"
                                                        id="sectionReference"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.section_reference')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData.sectionReference ?
                                                                    <CommonSectionRule
                                                                        type={'section'}
                                                                        sectionReference={this.props.complianceData.sectionReference}
                                                                        sectionInfo={(this.props.complianceData.verbatimSectionReference) ? JSON.parse(this.props.complianceData.verbatimSectionReference) : []}
                                                                    />
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.statutory.details.law_short_name')}
                                                            </b>
                                                            <p>
                                                                <a
                                                                    onClick={() => { this.props.toggle('actDocumentTab'); }}
                                                                    className="text-primary btn-link">{this.props.complianceData.law?.lawShortName}&nbsp;
                                                                    <small>
                                                                        <i className="fas fa-external-link-alt"></i>
                                                                    </small>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="ruleReference"
                                                        id="ruleReference"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.rule_reference')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData.ruleReference ?
                                                                    <CommonSectionRule
                                                                        type={'rule'}
                                                                        ruleReference={this.props.complianceData.ruleReference}
                                                                        ruleInfo={(this.props.complianceData.verbatimRuleReference) ? JSON.parse(this.props.complianceData.verbatimRuleReference) : []}
                                                                    />
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="relevantLaws"
                                                        id="relevantLaws"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.relevant_law_id')}
                                                            </b>
                                                            <p className='has-links'>
                                                                {this.props.complianceData
                                                                    .relevantLaws?.length >
                                                                    0
                                                                    ? this.props.complianceData.relevantLaws.map(
                                                                        (law) => {
                                                                            return (
                                                                                <a
                                                                                    href={() => false}
                                                                                    title="View Details"
                                                                                    className="btn btn-link p-0 text-left law_id_btn"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            '/statutory-compliances/view-law/' +
                                                                                            law.id,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {law.lawCode +
                                                                                        ' - ' +
                                                                                        law.lawShortName}{' '}
                                                                                    <i className="fas fa-external-link-alt"></i>
                                                                                </a>
                                                                            );
                                                                        }
                                                                    )
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li
                                                        key="complianceApplicability"
                                                        id="complianceApplicability"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.compliance_applicability')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        ?.complianceApplicability
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="complianceSpecification"
                                                        id="complianceSpecification"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.specification_of_compliance')}
                                                            </b>
                                                            {this.getShowMoreTextElement(
                                                                this.props.complianceData
                                                                    .complianceSpecification
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="complianceDetails"
                                                        id="complianceDetails"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.more_details_on_compliance')}
                                                            </b>
                                                            {this.getShowMoreTextElement(
                                                                this.props.complianceData
                                                                    .complianceDetails
                                                            )}
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li
                                                        key="dueDateDescription"
                                                        id="dueDateDescription"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.due_date_description')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .dueDateDescription
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="periodicityName"
                                                        id="periodicityName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.frequency')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .periodicity
                                                                        ?.periodicityName ? this.props
                                                                            .complianceData
                                                                            .periodicity
                                                                        ?.periodicityName : NA
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="filingRequired"
                                                        id="filingRequired"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.filing_required')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData
                                                                    .isFilingRequired
                                                                    ? YES
                                                                    : NO}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="filingTypeName"
                                                        id="filingTypeName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.filing_type')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .filingType
                                                                        ?.filingTypeName ? this.props
                                                                            .complianceData
                                                                            .filingType
                                                                        ?.filingTypeName : NA
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li key="formNo" id="formNo">
                                                        {formNos.length > 0 ? (
                                                            <div className="">
                                                                <b>
                                                                    {__t('form_labels.compliance.formno_formats'
                                                                    )}
                                                                </b>
                                                                <ul className="list-styled pl-0 documents-list">
                                                                    {formNos.map(
                                                                        (formNo, key) => {
                                                                            let trimedFormNo = formNo.trim();
                                                                            if ((key === (formNos.length - 1)) && trimedFormNo.startsWith('--')) {
                                                                                return (
                                                                                    <li key={key}>
                                                                                        <span>
                                                                                            {
                                                                                                trimedFormNo.split('--')[1].trim()
                                                                                            }
                                                                                        </span>
                                                                                    </li>
                                                                                );
                                                                            } else if (trimedFormNo === '~') {
                                                                                return (
                                                                                    <li key={key}>
                                                                                        <br />
                                                                                    </li>
                                                                                );
                                                                            }
                                                                            let isHash = trimedFormNo.includes('#');
                                                                            if (trimedFormNo.startsWith('#')) {
                                                                                return (
                                                                                    <li key={key}>
                                                                                        <span>
                                                                                            {
                                                                                                trimedFormNo.slice(1).trim()
                                                                                            }
                                                                                        </span>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                            let tempFormNo = isHash ? trimedFormNo.split("#")[0].trim() : trimedFormNo;
                                                                            let isUrl = this.checkUrl(tempFormNo);
                                                                            let formNodescription = isHash ? (trimedFormNo.split('#')[1].trim() || null) : null;
                                                                            if (isUrl === true) {
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            key
                                                                                        }
                                                                                    >
                                                                                        <a href={tempFormNo.trim()} target="_blank">
                                                                                            {tempFormNo.trim()}
                                                                                        </a>
                                                                                        {
                                                                                            formNodescription &&
                                                                                            (<span>
                                                                                                {
                                                                                                    ' - ' + formNodescription
                                                                                                }
                                                                                            </span>)
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            } else {
                                                                                let documentData =
                                                                                    this.props.complianceData?.documents?.find(
                                                                                        (
                                                                                            opt
                                                                                        ) =>
                                                                                            opt.documentName ==
                                                                                            tempFormNo
                                                                                    );
                                                                                if (
                                                                                    documentData &&
                                                                                    documentData.fileUrl &&
                                                                                    documentData.fileUrl !=
                                                                                    ''
                                                                                ) {
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            <a
                                                                                                // target="_blank"
                                                                                                // rel="noopener noreferrer"
                                                                                                // href={
                                                                                                //     documentData.fileUrl
                                                                                                // }
                                                                                                onClick={() => {
                                                                                                    this.props.saveFile({
                                                                                                        fileName: documentData.fileName,
                                                                                                        fileUrl: documentData.fileUrl
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    '• ' + tempFormNo
                                                                                                }
                                                                                            </a>
                                                                                            {
                                                                                                formNodescription &&
                                                                                                (<span>
                                                                                                    {
                                                                                                        ' - ' + formNodescription
                                                                                                    }
                                                                                                </span>)
                                                                                            }
                                                                                        </li>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            <a
                                                                                                href={() => false}
                                                                                                onClick={() => {
                                                                                                    toastrMessage(
                                                                                                        __t(
                                                                                                            'alerts.no_document_found'
                                                                                                        ),
                                                                                                        DANGER
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    '• ' + tempFormNo
                                                                                                }
                                                                                            </a>
                                                                                            {
                                                                                                formNodescription &&
                                                                                                (<span>
                                                                                                    {
                                                                                                        ' - ' + formNodescription
                                                                                                    }
                                                                                                </span>)
                                                                                            }
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                            }
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <div className="details-info">
                                                                <b>
                                                                    {__t('form_labels.compliance.formno_formats'
                                                                    )}
                                                                </b>
                                                                <p>{NA}</p>
                                                            </div>
                                                        )}
                                                    </li>
                                                    <li
                                                        key="isProofRequired"
                                                        id="isProofRequired"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.need_compliance_evidence')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData
                                                                    .isProofRequired
                                                                    ? YES
                                                                    : NO}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='compliance-card'>
                                            <h3>Consequence of Non-Compliance</h3>
                                            <div className='compliance-details'>
                                                <ul className="basic-details">
                                                    <li
                                                        key="penaltyDescription"
                                                        id="penaltyDescription"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.consequence_of_non_compliance')}
                                                            </b>
                                                            {this.getShowMoreTextElement(
                                                                this.props.complianceData
                                                                    .penaltyDescription
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="penaltyTypeName"
                                                        id="penaltyTypeName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.consequence_category')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData
                                                                    .penaltyType?.length > 0
                                                                    ? this.props.complianceData.penaltyType
                                                                        .map(
                                                                            (
                                                                                penaltyType
                                                                            ) =>
                                                                                penaltyType.penaltyTypeName
                                                                        )
                                                                        .join('; ')
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="monetaryPenaltyValue"
                                                        id="monetaryPenaltyValue"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.monetary_penalty_value')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .monetaryPenaltyValue
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li
                                                        key="impactManagementPersonnel"
                                                        id="impactManagementPersonnel"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.management_personnel_impact')}
                                                            </b>
                                                            <p>
                                                                {this.props.complianceData
                                                                    .impactManagementPersonnels
                                                                    ?.length > 0
                                                                    ? this.props.complianceData.impactManagementPersonnels
                                                                        .map(
                                                                            (
                                                                                impactManagementPersonnel
                                                                            ) =>
                                                                                impactManagementPersonnel.impactManagementPersonnelName
                                                                        )
                                                                        .join('; ')
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="riskRatingName"
                                                        id="riskRatingName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.risk_level')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .riskRating
                                                                        ?.riskRatingName ?
                                                                        this.props
                                                                            .complianceData
                                                                            .riskRating
                                                                            ?.riskRatingName :
                                                                        NA
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='compliance-card'>
                                            <h3>Other Details of Compliance</h3>
                                            <div className='compliance-details'>
                                                <ul className="basic-details">
                                                    <li
                                                        key="complianceTypeName"
                                                        id="complianceTypeName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.compliance_class')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .complianceType
                                                                        ?.complianceTypeName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="complianceCategoryName"
                                                        id="complianceCategoryName"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.compliance_classification')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .complianceCategory
                                                                        ?.complianceCategoryName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="applicabilityCondition"
                                                        id="applicabilityCondition"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.applicability_type')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .applicabilityConditionName
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="enforcementDate"
                                                        id="enforcementDate"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.date_of_enforcement')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .enforcementDate
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="basic-details">
                                                    <li key="eventName" id="eventName">
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.if_event_event_name')}
                                                            </b>
                                                            <p>
                                                                {this.props
                                                                    .complianceData
                                                                    .event
                                                                    ? (
                                                                        <a
                                                                            href={() => false}
                                                                            title={__t('common.compliance_hierarchy')}
                                                                            className="btn btn-link law_id_btn"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    '/event-compliances/' +
                                                                                    this.props
                                                                                        .complianceData
                                                                                        .event?.id,
                                                                                    '_blank'
                                                                                );
                                                                            }}
                                                                        >
                                                                            {this.props
                                                                                .complianceData
                                                                                .event
                                                                                ?.eventName} &nbsp;<i className="fas fa-external-link-alt"></i>
                                                                        </a>
                                                                    )
                                                                    : NA}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li
                                                        key="triggeringCompliance"
                                                        id="triggeringCompliance"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.triggering_compliance')}
                                                            </b>
                                                            <p>
                                                                {this.props
                                                                    .complianceData
                                                                    .triggeringCompliance &&
                                                                    this.props
                                                                        .complianceData
                                                                        .triggeringCompliance !=
                                                                    null &&
                                                                    this.props
                                                                        .complianceData
                                                                        .triggeringCompliance
                                                                        ?.complianceTitle !=
                                                                    '' ? (
                                                                    <>
                                                                        <a
                                                                            href={() => false}
                                                                            title="View Details"
                                                                            className="btn btn-link law_id_btn"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    '/statutory-compliances/view-details/' +
                                                                                    this
                                                                                        .props
                                                                                        .complianceData
                                                                                        .triggeringComplianceId,
                                                                                    '_blank'
                                                                                )
                                                                            }
                                                                        >
                                                                            {this
                                                                                .props
                                                                                .complianceData
                                                                                .triggeringCompliance
                                                                                ?.complianceCode +
                                                                                ' - ' +
                                                                                this
                                                                                    .props
                                                                                    .complianceData
                                                                                    .triggeringCompliance
                                                                                    ?.complianceTitle}{' '}
                                                                            <i className="fas fa-external-link-alt"></i>
                                                                        </a>
                                                                    </>
                                                                ) : (
                                                                    NA
                                                                )}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    {/* <li
                                                        key="compliancePeriod"
                                                        id="compliancePeriod"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.compliance_period')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .compliancePeriod ? this.props
                                                                            .complianceData
                                                                        .compliancePeriod : NA
                                                                }
                                                            </p>
                                                        </div>
                                                    </li> */}
                                                    <li
                                                        key="governingAuthorityWebsite"
                                                        id="governingAuthorityWebsite"
                                                    >
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.governing_authority_website')}
                                                            </b>
                                                            <p>
                                                                {
                                                                    this.props
                                                                        .complianceData
                                                                        .governingAuthorityWebsite ? this.props
                                                                            .complianceData
                                                                        .governingAuthorityWebsite : NA
                                                                }
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li key="briefNotes" id="briefNotes">
                                                        <div className="details-info">
                                                            <b>
                                                                {__t('form_labels.compliance.brief_notes')}
                                                            </b>
                                                            {this.getShowMoreTextElement(
                                                                this.props.complianceData
                                                                    .briefNotes
                                                            )}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
export default ShowComplianceDetails;
