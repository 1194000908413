import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    ADD_ENTITY_LOCATION_REQUEST,
    DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST,
    GET_ALL_ENTITY_LOCATION_LIST_REQUEST,
    GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST,
    GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_REQUEST,
    UPDATE_ENTITY_LOCATION_REQUEST,
    UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_REQUEST,
} from './actionTypes';
import { handleApiCallException } from 'store/Shared/actions';
import { invokeApi } from 'helpers/axiosHelper';
import { DANGER, SUCCESS } from 'constants/commonConstants';
import {
    addEntityLocationResponse,
    deleteEntityLocationByEntityLocationIdResponse,
    getAllEntityLocationListRequest,
    getAllEntityLocationListResponse,
    getEntityLocationByEntityLocationIdResponse,
    getEntityLocationListByEntityIdResponse,
    updateEntityLocationResponse,
    updateEntityLocationStatusByEntityLocationIdResponse,
} from './actions';
import { ENTITY_LOCATION, ENTITY_LOCATION_BY_ENTITY } from 'helpers/apiConstants';
function* addEntityLocation({ payload: { data } }) {
    try {
        const response = yield call(invokeApi, 'post', ENTITY_LOCATION, data);
        if (response.status) {
            yield put(getAllEntityLocationListRequest());
            yield put(addEntityLocationResponse(response.message, SUCCESS));
        } else {
            yield put(addEntityLocationResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getAllEntityLocationList({payload:{isLite}}) {
    try {
        let apiUrl = ENTITY_LOCATION;
        if(isLite?.isLite){
            apiUrl = apiUrl + `?isLite=1`
        }
        const response = yield call(invokeApi, 'get', `${apiUrl}`, '');
        if (response.status) {
            yield put(getAllEntityLocationListResponse(response.data.list));
        } else {
            yield put(getAllEntityLocationListResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityLocationByEntityLocationId({ payload: { entityLocationId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_LOCATION + '/' + entityLocationId); if (response.status) {
            yield put(getEntityLocationByEntityLocationIdResponse(response.message, SUCCESS, response.data));
        } else {
            yield put(getEntityLocationByEntityLocationIdResponse(response.message, DANGER, []));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEntityLocation({ payload: { entityLocationId, data } }) {
    try {
        const response = yield call(invokeApi, 'put', ENTITY_LOCATION + '/' + entityLocationId, data);
        if (response.status) {
            yield put(getAllEntityLocationListRequest());
            yield put(updateEntityLocationResponse(response.message, SUCCESS));
        } else {
            yield put(updateEntityLocationResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* updateEntityLocationStatusByEntityLocationId({
    payload: { entityLocationId, status },
}) {
    try {
        const response = yield call(invokeApi, 'patch', ENTITY_LOCATION + '/' + entityLocationId + '/status/' + status);
        if (response.status) {
            yield put(getAllEntityLocationListRequest());
            yield put(updateEntityLocationStatusByEntityLocationIdResponse(response.message, SUCCESS));
        } else {
            yield put(updateEntityLocationStatusByEntityLocationIdResponse(response.message, DANGER));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* deleteEntityLocationByEntityLocationId({ payload: { entityLocationId } }) {
    try {
        const response = yield call(
            invokeApi,
            'delete',
            ENTITY_LOCATION + '/' + entityLocationId
        );
        if (response.status) {
            yield put(getAllEntityLocationListRequest());
            yield put(
                deleteEntityLocationByEntityLocationIdResponse(
                    response.message,
                    SUCCESS
                )
            );
        } else {
            yield put(
                deleteEntityLocationByEntityLocationIdResponse(
                    response.message,
                    DANGER
                )
            );
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEntityLocationListByEntityId({ payload: { entityId } }) {
    try {
        const response = yield call(invokeApi, 'get', ENTITY_LOCATION_BY_ENTITY + '/' + entityId, '');
        if (response.status) {
            yield put(getEntityLocationListByEntityIdResponse(response.data.list));
        } else {
            yield put(getEntityLocationListByEntityIdResponse([]));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
export function* watchEntityLocation() {
    yield takeEvery(ADD_ENTITY_LOCATION_REQUEST, addEntityLocation);
    yield takeEvery(GET_ALL_ENTITY_LOCATION_LIST_REQUEST, getAllEntityLocationList);
    yield takeEvery(GET_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST, getEntityLocationByEntityLocationId);
    yield takeEvery(UPDATE_ENTITY_LOCATION_REQUEST, updateEntityLocation);
    yield takeEvery(UPDATE_ENTITY_LOCATION_STATUS_BY_ENTITY_LOCATION_ID_REQUEST, updateEntityLocationStatusByEntityLocationId);
    yield takeEvery(DELETE_ENTITY_LOCATION_BY_ENTITY_LOCATION_ID_REQUEST, deleteEntityLocationByEntityLocationId);
    yield takeEvery(GET_ENTITY_LOCATION_LIST_BY_ENTITY_ID_REQUEST, getEntityLocationListByEntityId)
}
function* entityLocationSaga() {
    yield all([fork(watchEntityLocation)]);
}
export default entityLocationSaga;
