import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, CardHeader, FormGroup, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import {
    assignUserAndDepartmentRequest,
    getDepartmentListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    updateAllocationStatusByAllocationIdsRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import __t from 'i18n/translator';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';
import {
    ACTIVE,
    CANCEL,
    DANGER,
    DEFAULT_PAGE_LENGTH,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import { commonConfirmBox, toastrMessage } from 'helpers/messageHelper';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { checkModulePermission } from 'helpers/projectUtils';
import {
    MODULE_USER_ALLOCATION,
    PERMISSION_EDIT,
} from 'constants/databaseConstants';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import ComplianceFilter from './components/ComplianceFilter';
import { Field, Form, Formik } from 'formik';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import CreatableSelect from "react-select/creatable";
import CustomisedModal from 'components/Application/CustomisedModal';
import * as Yup from "yup";
import { getLiteComplianceAllocationRequest, getStartStopSchedulerRequest, postUserMappedRequest } from 'store/Lite/actions';
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import ViewScheduleModal from 'app/Allocation/UserAllocation/ViewScheduleModal';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { makeEntitySelectField, selectSortedLiteEntityTeam } from 'store/Entity/selector';
import ReactTooltip from 'react-tooltip';
import { decryptData } from 'helpers/encryptor';
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
const countriesEntityList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesEntityList,
    reducer: RegisteredAccount,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesEntityList, saga: LiteRegistrationSaga });
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
export const liteListCompliance = [
    {
        Header: 'Compliance Code',
        TransHeader: 'tables.allocation.compliance_code',
        accessor: 'complianceCode',

    },
    {
        Header: 'Compliance Title',
        TransHeader: 'tables.allocation.compliance_title',
        accessor: 'complianceTitle',

    },
    {
        Header: 'Company Group',
        TransHeader: 'tables.allocation.company_group',
        accessor: 'companyGroupName',

    },
    {
        Header: 'Entity',
        TransHeader: 'tables.allocation.entity',
        accessor: 'entityName',

    },
    {
        Header: 'Country',
        TransHeader: 'tables.allocation.country',
        accessor: 'countryName',

    },
    {
        Header: 'State',
        TransHeader: 'tables.allocation.state',
        accessor: 'stateName',

    },
    {
        Header: 'City',
        TransHeader: 'tables.allocation.city',
        accessor: 'cityName',

    },
    {
        Header: 'compliance_classification',
        TransHeader: 'tables.allocation.compliance_classification',
        accessor: 'complianceClassification',

    },
    {
        Header: 'Action',
        TransHeader: 'common.action',
        accessor: 'actions',
        className: 'action_col',
        disableSortBy: true,
    },
];
class LiteComplianceManagement extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            allocationList: [],
            searchFormData: {},
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            userMappedModel:false,
            totalRecords: 0,
            selectedAllocations: [],
            isModalOpen: false,
            formikEditValue:null,
            userAllocationData: {
            },
            isViewScheduleModalOpen: false,
            allocationData: {},
            isAuditModalOpen: false,
            auditId: '',
            fromDashboard: {
                filterType: this.props.match.params.filterType
                    ? this.props.match.params.filterType
                    : '',
                selectedId: this.props.match.params.selectedId
                    ? this.props.match.params.selectedId
                    : ''
            }
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        const splitWithQuestion = this.props.location.search ? this.props.location.search.split("?")[1]:null;
        const splitWithEqual = splitWithQuestion ? splitWithQuestion.split("=")[1]:null;
        const findFilter = splitWithEqual ? JSON.parse(decryptData(splitWithEqual)) : null;
        this.setState({
            searchFormData:{
                ...this.state.searchFormData,
                entitySetUpId:[parseInt(findFilter?.entitySetupId)]
            }
        })
        this.getLiteAllocationSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            findFilter ? { ...this.state.searchFormData,entitySetUpId:[parseInt(findFilter?.entitySetupId)]} : this.state.searchFormData
        );
    }
    openAuditLogModal = (auditId) => {
        this.setState({
            isAuditModalOpen: true,
            auditId: auditId,
        });
    };
    closeAuditLogModal = () => {
        this.setState({
            isAuditModalOpen: false,
            auditId: '',
        });
    };
    getLiteAllocationSearchList = (limit, page, sortBy, values = {}) => {
        let formValues = values;
        if (formValues.type && formValues.type != 1) {
            formValues.isPendingMapping = formValues.type == 2 ? true : false;
        } else {
            delete formValues.isPendingMapping;
        }
        formValues.userAllocation = true;
        let apiData = {
            limit: limit,
            page: page,
            filter: formValues,
            orderBy: sortBy,
        };
        this.props.getLiteComplianceAllocationRequest(apiData);
    };
    formSubmit = (values) => {
        this.setState(
            {
                searchFormData: values,
                currentPage: 1,
                resetTableSection: true
            },
            () => {
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    };
    useMappedForm=async(values)=>{
        let formValue ={ ...values}
        if(formValue.submitterNewValue){
            formValue={
                ...formValue,
                submitterUser:formValue.submitterId
            }
            delete formValue.submitterId;
        }
        if(formValue.firstApproverUserNewValue){
            formValue={
                ...formValue,
                firstApproverUser:formValue.firstApproverUserId
            }
            delete formValue.firstApproverUserId;
        }else{
            if(!formValue.firstApproverUserId){
                delete formValue.firstApproverUserId
            }
        }
        const entityHod = formValue.entityHod && formValue.entityHod.length > 0 && formValue.entityHod.map((ele)=>{
            const obj={
                userId:ele.userId,
                userEmail:ele.userId,
                departmentId:ele.departmentId,
                departmentName:ele.departmentId
            }
            if(ele.departmentNewValue){
                delete obj.departmentId
            }else{
                delete obj.departmentName;
            }
            if(ele.hodNewValue){
                delete obj.userId
            }else{
                delete obj.userEmail;
            }
            return obj
        })
        formValue ={
            ...formValue,
            entityHod,
            compliance_allocation_id:this.state.userAllocationData.id,
            entityId:this.state.userAllocationData.entity?.id,
            liteEntitySetupId:this.state.userAllocationData.entitySetupId,
        }
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            __t('alerts.lite_compliance_user_mapped_confirmation')
        );
        if(response){
            formValue={
                ...formValue,
                mapToAllLocation:1,
            }
            this.props.postUserMappedRequest(formValue);
        }else{
            formValue={
                ...formValue,
                mapToAllLocation:0,
            }
            this.props.postUserMappedRequest(formValue);
        }
    }
    resetForm = () => {
        this.setState(
            {
                searchFormData: {},
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getLiteAllocationSearchList(this.state.pageSize, 1, {
                    key: 'createdAt',
                    value: 'DESC',
                }, {});
                this.props.history.push("/lite/compliance-management")
            }
        );
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    const splitWithQuestion = this.props.location.search ? this.props.location.search.split("?")[1]:null;
                    const splitWithEqual = splitWithQuestion ? splitWithQuestion.split("=")[1]:null;
                    const findFilter = splitWithEqual ? JSON.parse(decryptData(splitWithEqual)) : null;
                    this.getLiteAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        !data?.globalFilter && findFilter ? { ...this.state.searchFormData,entitySetUpId:[parseInt(findFilter?.entitySetupId)]} : this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    const splitWithQuestion = this.props.location.search ? this.props.location.search.split("?")[1]:null;
                    const splitWithEqual = splitWithQuestion ? splitWithQuestion.split("=")[1]:null;
                    const findFilter = splitWithEqual ? JSON.parse(decryptData(splitWithEqual)) : null;
                    this.getLiteAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        !data?.globalFilter && findFilter ? { ...this.state.searchFormData,entitySetUpId:[parseInt(findFilter?.entitySetupId)]} : this.state.searchFormData
                    );
                }
            );
        }
    }
    handleSearch(data) {
        let pageNum =
            data?.globalFilter == this.state.searchFormData?.searchText
                ? data.pageIndex + 1
                : 1;
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                const splitWithQuestion = this.props.location.search ? this.props.location.search.split("?")[1]:null;
                const splitWithEqual = splitWithQuestion ? splitWithQuestion.split("=")[1]:null;
                const findFilter = splitWithEqual ? JSON.parse(decryptData(splitWithEqual)) : null;
    
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    pageNum,
                    this.state.sortBy,
                    !data?.globalFilter && findFilter ?  {...this.state.searchFormData,entitySetUpId:[parseInt(findFilter?.entitySetupId)]}:this.state.searchFormData,
                );
            }
        );
    }
    openUserMappingFormModal = (allocationData) => {
        this.setState({
            isModalOpen: true,
            userMappedModel:true,
            userAllocationData: {
                ...allocationData,
                allocationId: allocationData.id,
                entityId: allocationData.entity?.id,
                entitySetupId:allocationData?.entityLocation?.liteEntitySetupId
            },
            userMappingOperationType: SUBMIT,
        },()=>{
            this.props.getDepartmentListByStatusRequest(ACTIVE);
            this.props.getEntityTeamsByEntityIdRequest(allocationData.entity?.id);
        });
    };
    closeUserMappingFormModal = () => {
        this.setState({
            isModalOpen: false,
            userAllocationData: {},
            userMappingOperationType: SUBMIT,
        });
    };

    openViewScheduleModal = (allocationData) => {
        this.setState({
            isViewScheduleModalOpen: true,
            allocationData: {
                ...allocationData,
                allocationId: allocationData.id,
                entityId: allocationData.entity?.id,
            },
        });
    };
    closeViewScheduleModal = () => {
        this.setState({
            isViewScheduleModalOpen: false,
            allocationData: {},
        });
    };
    scheduleUpdateAllocation = async (allocationId, status) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            status === true
                ? __t('common.lite_compliance_go_live')
                : __t('common.lite_compliance_go_live_disabled')
        );
        if (response) {
            this.props.getStartStopSchedulerRequest({
                allocationId: [allocationId],
                isSchedulerActive: status,
            });
        }
    };

    scheduleUpdateMultiAllocation = async (selectedAllocations, status) => {
        const response = await commonConfirmBox(
            __t('common.are_you_sure'),
            status === true
                ? __t('common.lite_compliance_go_live')
                : __t('common.lite_compliance_go_live_disabled')
        );
        if (response) {
            if (selectedAllocations.length > 0) {
                this.props.getStartStopSchedulerRequest({
                    allocationId: selectedAllocations.map((opt) => opt.id),
                    isSchedulerActive: status,
                });
            }
        }
    };
    toggleScheduler = async(id, isSchedulerActive) => {
        this.scheduleUpdateAllocation(id, !isSchedulerActive);
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && (
                        <a
                            href={() => false}
                            className="btn btn-link text-primary tbl-action"
                            title={__t(
                                'form_labels.allocation.map_departments_users'
                            )}
                            onClick={() => {
                                this.openUserMappingFormModal(allocationData);
                            }}
                        >
                            {allocationData.isPendingMapping === true ? (
                                <i className="fas fa-user-plus"></i>
                            ) : (
                                <i className="fas fa-user-check"></i>
                            )}
                        </a>
                    )}
                    {checkModulePermission(
                        MODULE_USER_ALLOCATION,
                        PERMISSION_EDIT
                    ) === true && (
                            <a
                                href={() => false}
                                title={__t('form_labels.allocation.view_schedule')}
                                className="btn btn-link text-info tbl-action"
                                onClick={() => {
                                    this.openViewScheduleModal(allocationData);
                                }}
                            >
                                <i className="fas fa-calendar-alt"></i>
                            </a>
                        )}
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && allocationData.isSchedulerActive === false &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                title={__t(
                                    'form_labels.allocation.start_schedule'
                                )}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    this.toggleScheduler(allocationData.id, allocationData.isSchedulerActive)
                                }}
                            >
                                <i className="fas fa-play-circle"></i>
                            </a>
                        )}
                    {allocationData.isSchedulerActive === true &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                title={__t(
                                    'form_labels.allocation.stop_schedule'
                                )}
                                className="btn btn-link text-danger tbl-action"
                                onClick={() => {

                                    this.toggleScheduler(allocationData.id, allocationData.isSchedulerActive)
                                }}
                            >
                                <i className="fas fa-stop-circle"></i>
                            </a>
                        )}

                </div>
            );

            return {
                checkbox: !allocationData.isPendingMapping,
                id: allocationData.id,
                isPendingMapping: allocationData.isPendingMapping,
                isSchedulerActive: allocationData.isSchedulerActive,
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.liteComplianceAllocationListApiResponse &&
            this.props.liteComplianceAllocationListApiResponse !== nextprops.liteComplianceAllocationListApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                sortBy: nextprops.liteComplianceAllocationListApiResponse.data.orderBy,
                pageCount: nextprops.liteComplianceAllocationListApiResponse.data.totalPages,
                currentPage: nextprops.liteComplianceAllocationListApiResponse.data.currentPage,
                totalRecords: nextprops.liteComplianceAllocationListApiResponse.data.totalRecords,
                allocationList: this.generateTable(
                    nextprops.liteComplianceAllocationListApiResponse.data.complianceList
                ),
            });
        }
        if (
            nextprops.allocationStatusApiResponse &&
            this.props.allocationStatusApiResponse !==
            nextprops.allocationStatusApiResponse
        ) {
            toastrMessage(
                nextprops.allocationStatusApiResponse.message,
                nextprops.allocationStatusApiResponse.responseType
            );
            if (
                nextprops.allocationStatusApiResponse.responseType === SUCCESS
            ) {
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }
        if (
            nextprops.userAllocationApiResponse &&
            this.props.userAllocationApiResponse !==
            nextprops.userAllocationApiResponse
        ) {
            toastrMessage(
                nextprops.userAllocationApiResponse.message,
                nextprops.userAllocationApiResponse.responseType
            );
            if (nextprops.userAllocationApiResponse.responseType === SUCCESS) {
                this.closeUserMappingFormModal();
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData,
                );
            }
        }
        if(nextprops.addLiteUserMappedApiResponse && nextprops.addLiteUserMappedApiResponse !== this.props.addLiteUserMappedApiResponse){
            if(nextprops.addLiteUserMappedApiResponse.responseType == SUCCESS){
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData
                );     
                this.setState({
                    userMappedModel:false,
                })
            }
            toastrMessage(nextprops.addLiteUserMappedApiResponse.message,nextprops.addLiteUserMappedApiResponse.responseType)
        }
        if(nextprops.entityTeams){
            this.setState({
                formikEditValue: {
                    submitterId:this.state.userAllocationData.submitterId,
                    firstApproverUserId:this.state.userAllocationData.firstApproverUserId,
                    submitterNewValue:false,
                    firstApproverUserNewValue:false,
                    entityHod: nextprops.entityTeams?.liteEntityHods && nextprops.entityTeams?.liteEntityHods?.length > 0 ? nextprops.entityTeams?.liteEntityHods?.filter((ele)=> {
                        return this.state.userAllocationData?.departmentId?.includes(ele.departmentId) && this.state.userAllocationData?.entityHodId?.includes(ele.id)
                    }).map((element)=>{
                        return {
                            departmentId:element.departmentId,
                            userId:element.userId,
                            hodNewValue:false,
                            departmentNewValue:false,
                            id: Math.random()
                        }
                    }):[]
                }
            })
        }
        if(nextprops.liteComplianceStartStopScheduler && nextprops.liteComplianceStartStopScheduler !== this.props.liteComplianceStartStopScheduler){
            if(nextprops.liteComplianceStartStopScheduler.responseType == SUCCESS){
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData
                );
                
            }
            toastrMessage(nextprops.liteComplianceStartStopScheduler.message,nextprops.liteComplianceStartStopScheduler.responseType)
        }
    }

    onModalDismiss(){
        this.setState((prevState)=>{
            return {
                ...prevState,
                userMappedModel:!prevState
            }
        })
    }
    render() {
        const data = {
            columns: liteListCompliance,
            rows: this.state.allocationList,
        };
        const submitterFormLabel = this.state.submitterLabel
        ? this.state.submitterLabel
        : __t('form_labels.allocation.submitter');
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum type={'lite-users-allocation'} />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={true}
                            dialogName={__t('lite.compliance_management.heading')}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <ComplianceFilter
                                    allocationFilterData={
                                        this.state.searchFormData
                                    }
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values);
                                    }}
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>
                    <Col lg="12">
                        {!this.state.resetTableSection && (
                            <Card>
                                <CardHeader className='p-0 d-flex  justify-content-between'>
                                    <div>
                                        <p
                                            className="page-header accordion_header px-4 py-3 m-0"
                                        >
                                            <h4 className="m-0">{__t("lite.compliance_management.headingSecondary")}</h4>
                                        </p>
                                    </div>
                                <div className='d-flex py-3 px-4 m-0'>
                                    {
                                        (this.state.selectedAllocations.length > 0 &&
                                            this.state.selectedAllocations.filter(
                                                (opt) => opt.isSchedulerActive === true
                                            ).length ===
                                            this.state.selectedAllocations
                                                .length) || <Button color='success' className='d-flex align-items-center mr-2'   onClick={async() => {
                                                    if (
                                                        this.state.selectedAllocations
                                                            .length === 0
                                                    ) {
                                                        toastrMessage(
                                                            __t(
                                                                'alerts.please_select_record'
                                                            ),
                                                            DANGER
                                                        );
                                                    } else {
                                                            this.scheduleUpdateMultiAllocation(
                                                                this.state
                                                                    .selectedAllocations,
                                                                true
                                                            );
                                                    }
                                                }}>
                                            <i className="fas fa-play"></i>
                                            {__t("lite.compliance.go_live")}
                                        </Button>
                                    }
                                     {(this.state.selectedAllocations.length > 0 &&
                                    this.state.selectedAllocations.filter(
                                        (opt) => opt.isSchedulerActive === false
                                    ).length ===
                                    this.state.selectedAllocations
                                        .length) || (
                                        <Button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                if (
                                                    this.state.selectedAllocations
                                                        .length === 0
                                                ) {
                                                    toastrMessage(
                                                        __t(
                                                            'alerts.please_select_record'
                                                        ),
                                                        DANGER
                                                    );
                                                } else {
                                                    this.scheduleUpdateMultiAllocation(
                                                        this.state
                                                            .selectedAllocations,
                                                        false
                                                    );
                                                }
                                            }}
                                        >
                                            <i className="fas fa-stop"></i>
                                            {__t(
                                                'lite.compliance.stop_live'
                                            )}
                                        </Button>
                                    )}
                               
                                    <Button
                                        color='link'
                                        type='button'
                                        className='text-primary p-0 ml-2'
                                        data-tip
                                        data-for='goLive'
                                    >
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.89999 16.5H12.1V9.89999H9.89999V16.5ZM11 7.7C11.3117 7.7 11.5731 7.5944 11.7843 7.3832C11.9955 7.172 12.1007 6.91093 12.1 6.6C12.0993 6.28906 11.9937 6.028 11.7832 5.8168C11.5727 5.6056 11.3117 5.5 11 5.5C10.6883 5.5 10.4273 5.6056 10.2168 5.8168C10.0063 6.028 9.90073 6.28906 9.89999 6.6C9.89926 6.91093 10.0049 7.17236 10.2168 7.3843C10.4287 7.59623 10.6898 7.70146 11 7.7ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5553 4.2075 19.7718 3.2175 18.7825C2.2275 17.7932 1.44393 16.6291 0.866801 15.29C0.289668 13.9509 0.000734725 12.5209 1.3924e-06 11C-0.00073194 9.47906 0.288201 8.04906 0.866801 6.71C1.4454 5.37093 2.22897 4.20676 3.2175 3.2175C4.20603 2.22823 5.3702 1.44467 6.71 0.8668C8.0498 0.288933 9.4798 0 11 0C12.5202 0 13.9502 0.288933 15.29 0.8668C16.6298 1.44467 17.794 2.22823 18.7825 3.2175C19.771 4.20676 20.555 5.37093 21.1343 6.71C21.7136 8.04906 22.0022 9.47906 22 11C21.9978 12.5209 21.7089 13.9509 21.1332 15.29C20.5575 16.6291 19.774 17.7932 18.7825 18.7825C17.791 19.7718 16.6269 20.5557 15.29 21.1343C13.9531 21.7129 12.5231 22.0015 11 22Z" fill="currentColor" /></svg>
                                    </Button>
                                    <ReactTooltip id='goLive' type='dark' effect="solid" place="top" className='text-center'>
                                        <p className='mb-0'>{__t("common.tooltip_compliance_go_live_1")} <br/>{__t("common.tooltip_compliance_go_live_2")}</p>
                                    </ReactTooltip>
                                </div>
                                </CardHeader>
                                <CardBody className="custom_table table-responsive">
                                                <CustomisedReactTableWithPaginationAndRowSelection
                                                    onchangeCheckbox={(row) => {
                                                        this.setState({
                                                            selectedAllocations: row
                                                                .map((itemRow) => ({
                                                                    id: itemRow.id,
                                                                    isSchedulerActive:
                                                                        itemRow.isSchedulerActive,
                                                                    isPendingMapping:
                                                                        itemRow.isPendingMapping,
                                                                }))
                                                                .filter((opt) => {
                                                                    if (
                                                                        !opt.isPendingMapping
                                                                    )
                                                                        return opt;
                                                                }),
                                                        });
                                                    }}
                                                    paging={true}
                                                    className="table table-bordered table-striped"
                                                    displayEntries={true}
                                                    searching={true}
                                                    currentPage={this.state.currentPage}
                                                    pageCount={this.state.pageCount}
                                                    handleFetchData={
                                                        this.handleFetchData
                                                    }
                                                    handleSort={this.handleSort}
                                                    handleSearch={this.handleSearch}
                                                    preFilledSearchText={
                                                        this.state.searchFormData
                                                            ?.searchText
                                                    }
                                                    tableData={data}
                                                    totalRecords={
                                                        this.state.totalRecords
                                                    }
                                                />
                                </CardBody>
                            </Card>
                        )}  
                    </Col>
                    <CustomisedModal
                    modalSize={'modal-lg'}
                    modalName={"Map Departments And Users"}
                    isModalOpen={this.state.userMappedModel}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <Row>
                        <Col lg="12">
                            <Formik
                                initialValues={this.state.userAllocationData.isPendingMapping == true ? {
                                    submitterId:"",
                                    firstApproverUserId:"",
                                    submitterNewValue:false,
                                    firstApproverUserNewValue:false,
                                    entityHod:[{
                                        departmentId:"",
                                        userId:"",
                                        hodNewValue:false,
                                        departmentNewValue:false,
                                        id:1,
                                    }]
                                }:this.state.formikEditValue}
                                enableReinitialize={true}
                                validationSchema={Yup.object({
                                    // Conditional Validation for Submitter
                                    submitterId: Yup.string()
                                        .when("submitterNewValue", {
                                            is: true,
                                            then: (schema) => schema.email("Submitter must be a valid email address.")
                                                                 .required("Submitter Email is required."),
                                            otherwise: (schema) => schema.notRequired()
                                        })
                                        .test(
                                            "unique-submit",
                                            "Submitter email must be different from First Approver and HOD Email.",
                                            function (value) {
                                                const { firstApproverUserId, entityHod } = this.parent;
                                                if (value && firstApproverUserId && value === firstApproverUserId) {
                                                    return false;
                                                }
                                                if (value && entityHod?.some((data) => data.hodId === value)) {
                                                    return false;
                                                }
                                                return true;
                                            }
                                        ),
                                    // Conditional Validation for First Approver
                                    firstApproverUserId: Yup.string()
                                        .when("firstApproverUserNewValue", {
                                            is: true,
                                            then: (schema) => schema.email("First Approver must be a valid email address."),
                                            otherwise: (schema) => schema.notRequired(),
                                        })
                                        .test(
                                            "unique-approver",
                                            "First Approver email must be different from Submitter and HOD Email.",
                                            function (value) {
                                                const { submitterId, entityHod } = this.parent;
                                                if (value && submitterId && value === submitterId) {
                                                    return false;
                                                }
                                                if (value && entityHod?.some((data) => data.hodId === value)) {
                                                    return false;
                                                }
                                                return true;
                                            }
                                        )
                                        .optional(),
                                    // Entity HOD Validations
                                    entityHod: Yup.array()
                                        .of(
                                            Yup.object({
                                                departmentId: Yup.string().required("Department Name is required."),
                                                userId:  Yup.string().required("HOD Email is required.")
                                                // .test(
                                                //     "unique-hod",
                                                //     "HOD Email must be different from Submitter and First Approver.",
                                                //     function (value) {
                                                //         const { submitterId, firstApproverUserId } = this.options.context;
                                                //         if (value && submitterId && value === submitterId) {
                                                //             return false;
                                                //         }
                                                //         if (value && firstApproverUserId && value === firstApproverUserId) {
                                                //             return false;
                                                //         }
                                                //         return true;
                                                //     }
                                                // ),
                                            })
                                        )
                                        // .test(
                                        //     "unique-entityHod",
                                        //     "Each entityHod entry must have a unique combination of departmentId, departmentName, userId, and userEmail.",
                                        //     function (value) {
                                        //         if (!value) return true;
                                        //         const seen = new Set();
                                        //         return value.every((entry) => {
                                        //             const key = `${entry.departmentId}-${entry.departmentName}-${entry.userId}-${entry.userEmail}`;
                                        //             if (seen.has(key)) {
                                        //                 return false;
                                        //             }
                                        //             seen.add(key);
                                        //             return true;
                                        //         });
                                        //     }
                                        // ),
                                })}
                                validateOnBlur={true}
                                onSubmit={this.useMappedForm}
                            >
                                {({ values, errors,touched,setFieldValue,setFieldTouched,handleBlur }) => {
                                    return  <Form>
                                        <Row className="d-flex flex-column mr-0">
                                            {
                                                values?.entityHod && values?.entityHod?.length > 0 && values?.entityHod.map((element,index)=>{
                                                    return <Col className='d-flex' key={element.id}>
                                                         <FormGroup className="col-5">
                                                         <Field
                                                                component={CreatableSelect}
                                                                name={`entityHod.${index}.departmentId`}
                                                                className="form-control selectbox"
                                                                options={this.props.sortedDepartmentListDropdown}
                                                                onChange={(opt) => {
                                                                    if (opt?.__isNew__) {
                                                                        setFieldValue(`entityHod.${index}.departmentNewValue`, true)
                                                                    } else {
                                                                        setFieldValue(`entityHod.${index}.departmentNewValue`, false)
                                                                    }
                                                                    setFieldValue(`entityHod.${index}.departmentId`, opt?.value)
                                                                }}
                                                                value={values?.entityHod[index]?.departmentNewValue ? {label: values?.entityHod[index]?.departmentId,value:values?.entityHod[index]?.departmentId} :values?.entityHod[index]?.departmentId ? this.props.sortedDepartmentListDropdown.filter((ele)=> ele.value == values?.entityHod[index]?.departmentId) :null}
                                                                onBlur={handleBlur}
                                                                placeholder="Enter/Select Department"
                                                                isClearable={true}
                                                                closeMenuOnSelect={true}
                                                            />
                                                            <Label className="form-label">
                                                                {__t(
                                                                    'form_labels.allocation.department'
                                                                )}{' '}
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {
                                                                touched?.entityHod && touched?.entityHod?.length >0 && touched?.entityHod[index]?.departmentId && errors?.entityHod && errors?.entityHod?.length > 0 && errors?.entityHod[index]?.departmentId && <small className='error'>{errors?.entityHod[index]?.departmentId}</small>
                                                            }
                                                        </FormGroup>

                                                        <FormGroup className="col-5">
                                                            <Field
                                                                component={CreatableSelect}
                                                                name={`entityHod.${index}.userId`}
                                                                className="form-control selectbox"
                                                                options={this.props
                                                                    .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=>(ele.value !== values.submitterId) &&  (ele.value !== values.firstApproverUserId))}
                                                                onChange={(opt) => {
                                                                        if (opt?.__isNew__) {
                                                                            setFieldValue(`entityHod.${index}.hodNewValue`, true)
                                                                        } else {
                                                                            setFieldValue(`entityHod.${index}.hodNewValue`, false)
                                                                        }
                                                                        setFieldValue(`entityHod.${index}.userId`, opt?.value)
                                                                
                                                                }}
                                                                placeholder="Enter/Select Hod"
                                                                value={element.hodNewValue ? {label: element?.userId,value:element?.userId}: this.props
                                                                .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=> ele?.value == element?.userId)}
                                                                onBlur={handleBlur}
                                                                isClearable={true}
                                                                closeMenuOnSelect={true}
                                                            />
                                                            <Label className="form-label">
                                                                HOD
                                                                <span className="text-danger required">
                                                                    *
                                                                </span>
                                                            </Label>
                                                            {
                                                               errors?.entityHod && errors?.entityHod?.length > 0 && errors?.entityHod[index]?.userId && <small className='error'>{errors?.entityHod[index]?.userId}</small>
                                                            }
                                                        </FormGroup>
                                                        {
                                                            values.entityHod && values.entityHod.length > 1 &&  <FormGroup className='col-1'>
                                                            
                                                            <Button type='button' color='danger' onClick={()=>{
                                                                const AddHOdToMainArray =[...values.entityHod];
                                                                const filterHod = AddHOdToMainArray && AddHOdToMainArray.length > 0 && AddHOdToMainArray.filter((ele)=>ele.id !== element.id);
                                                                setFieldValue("entityHod",filterHod)
                                                            }} className='mr-2'><i className='fas fa-minus m-0'></i></Button>
                                                          </FormGroup>
                                                        }
                                                        {
                                                            index == (values.entityHod.length - 1) ? <FormGroup className='col-1'>
                                                            <Button type='button' color='primary' className='mr-2' onClick={()=>{
                                                                const AddHOdToMainArray =[...values.entityHod];
                                                                const AddHod ={
                                                                    departmentId:"",
                                                                    userId:"",
                                                                    hodNewValue:false,
                                                                    departmentNewValue:false,
                                                                    id:(new Date().getTime()).toString(36) 
                                                                }
                                                                AddHOdToMainArray.push(AddHod);
                                                                setFieldValue("entityHod",AddHOdToMainArray)
                                                            }}><i className='fas fa-plus m-0'></i></Button>
                                                        </FormGroup>:null
                                                        }
                                                       
                                                        
                                                    </Col>
                                                })
                                            }
                                           

                                            <FormGroup className="col-lg-12">
                                                <Field
                                                    component={CreatableSelect}
                                                    name={`submitterId`}
                                                    options={this.props
                                                        .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=> (ele.value ==  values.firstApproverUserId) || values.entityHod.find((element)=>element.userId !== ele.value))}
                                                    className="form-control selectbox"
                                                    onChange={(opt) => {
                                                        if (opt?.__isNew__) {
                                                            setFieldValue(`submitterNewValue`, true)
                                                            setFieldTouched("submitterId",true,true)
                                                        } else {
                                                            setFieldValue(`submitterNewValue`, false)
                                                        }
                                                        setFieldValue(`submitterId`, opt?.value)
                                                    }}
                                                    placeholder="Enter/Select Submitter"
                                                    value={values.submitterNewValue ? {label: values?.submitterId,value:values.submitterId}:this.props
                                                    .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=>ele.value == values.submitterId)}
                                                    onBlur={handleBlur}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {submitterFormLabel}
                                                    <span className="text-danger required">
                                                        *
                                                    </span>
                                                </Label>
                                                {
                                                  touched.submitterId && errors.submitterId && <small className='error'>{errors.submitterId}</small>
                                                }
                                            </FormGroup>
                                            <React.Fragment>
                                                <FormGroup className="col-lg-12">
                                                    <Field
                                                        component={CreatableSelect}
                                                        name="firstApproverUserId"
                                                        options={
                                                            this.props
                                                            .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=> (ele.value !==  values.submitterId) && values.entityHod.find((element)=>element.userId !== ele.value))
                                                        }
                                                        className="form-control selectbox"
                                                        onChange={(opt) => {
                                                            if (opt?.__isNew__) {
                                                                setFieldValue(`firstApproverUserNewValue`, true)
                                                                setFieldTouched("firstApproverUserNewValue",true,true)
                                                            } else {
                                                                setFieldValue(`firstApproverUserNewValue`, false)
                                                            }
                                                            setFieldValue(`firstApproverUserId`, opt?.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder="Enter/Select Reviewer"
                                                        value={values?.firstApproverUserNewValue? {label: values?.firstApproverUserId,value:values.firstApproverUserId}:this.props
                                                        .sortedEntityTeamAndConsultantTeamUserDropdown.filter((ele)=>ele.value == values.firstApproverUserId)}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.allocation.first_approver'
                                                        )}{' '}
                                                    </Label>
                                                    {
                                                         touched.firstApproverUserId && errors.firstApproverUserId && <small className='error'>{errors.firstApproverUserId}</small>
                                                    }
                                                </FormGroup>
                                            </React.Fragment>
                                            <Col lg="12">
                                                <FormGroup className="mb-0 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mr-2"
                                                    >
                                                       Map User
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        onClick={()=>this.onModalDismiss()}

                                                    >
                                                        {CANCEL}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                }}
                            </Formik>
                        </Col>
                    </Row>
                </CustomisedModal>
                                <ViewScheduleModal
                                    modalName={__t('form_labels.allocation.view_schedule')}
                                    isModalOpen={this.state.isViewScheduleModalOpen}
                                    allocationData={this.state.allocationData}
                                    onModalDismiss={() => this.closeViewScheduleModal()}
                                />
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    liteComplianceAllocationListApiResponse: makeLiteClientRegistrationSelectField('liteComplianceAllocationListApiResponse'),
    userAllocationApiResponse: makeAllocationSelectField(
        'userAllocationApiResponse'
    ),
    allocationStatusApiResponse: makeAllocationSelectField(
        'allocationStatusApiResponse'
    ),
    addLiteUserMappedApiResponse:makeLiteClientRegistrationSelectField("addLiteUserMappedApiResponse"),
    sortedEntityTeamAndConsultantTeamUserDropdown:selectSortedLiteEntityTeam(),
    entityTeams:makeEntitySelectField('entityTeams'),
    liteComplianceStartStopScheduler:makeLiteClientRegistrationSelectField("liteComplianceStartStopScheduler")
});
const withConnect = connect(mapStatetoProps, {
    getDepartmentListByStatusRequest,
    getEntityTeamsByEntityIdRequest,
    assignUserAndDepartmentRequest,
    updateAllocationStatusByAllocationIdsRequest,
    postUserMappedRequest,
    getLiteComplianceAllocationRequest,
    getStartStopSchedulerRequest
}); 
export default compose(
    withConnect,
    withAllocationReducer,
    withAllocationSaga,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withRouter
)(LiteComplianceManagement);
