import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import { DEFAULT_PAGE_LENGTH } from 'constants/commonConstants';
import React, { Component } from 'react'
import { Badge, Button, Card, CardBody, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import __t from 'i18n/translator';
import { Link } from 'react-router-dom';

const AccountDetailsHeader = [
    {
        Header: 'Account Name',
        accessor: 'account_name',
    },
    {
        Header: 'Company Name',
        accessor: 'company_full_name',
    },
    {
        Header: 'Country',
        accessor: 'country',
    },
    {
        Header: 'Client Account Status',
        accessor: 'client_account_status'
    },

    {
        Header: 'Plan Details',
        accessor: 'plan_details',
    },
    {
        Header: 'Plan Status',
        accessor: 'plan_status',
    },
    {
        Header: 'Payment Status',
        accessor: 'payment_status',
    },
    {
        Header: 'Status of EL',
        accessor: 'status_of_el',
    },
    {
        Header: 'Actions',
        // TransHeader: 'common.action',
        accessor: 'actions',
        disableSortBy: true,
    },
];

const ClientStatusSelection = [
    {
        value: "1",
        label: "In Progress",
    },
    {
        value: "2",
        label: "Active",
    },
    {
        value: "3",
        label: "Dormant"
    },
    {
        value: "4",
        label: "Extension",
    }, ,
    {
        value: "5",
        label: "Suspension",
    },
    {
        value: "6",
        label: "Terminated",
    },
]
class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountDetails: [],
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            totalRecords: 0,
            searchFormData: {}
        }
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        if (this.props.activeTab === "account_details") {
            this.getAccountDetailsList(this.state.pageSize, this.state.currentPage, { ...this.state.searchFormData });
        }
    }
    getAccountDetailsList = (limit, page, sortBy, values = {}) => {
        let apiData = {
            limit: limit,
            page: page,
            filters: values,
            orderBy: sortBy,
        };
        this.props.getAccountDetailsRequest(apiData);
    }
    generateAccountDetailsTable(records) {
        let accountDetailsArray = [];
        accountDetailsArray = records.data.map((accountData, i) => {
            const client_status = this.ClientStatus(accountData.dataDeletionOnSuspension ==1 ? 6 :accountData.isAccountSuspend == 1 ? 5 : accountData.isTrailPlanExtended == 1 ? 4 : accountData.accountSetupStatus);
            const payment_status = this.PaymentStatus(accountData.paymentStatus);
            const plan_status = this.PlanStatus(accountData.planName);
            const document_status = this.DocumentStatus(accountData.paperworkStatus);
            const planStatus = this.planStatus(accountData.planStatus);
            const parseAllowedCountries = accountData?.allowedCountries ? JSON.parse(accountData?.allowedCountries) : null;
            const getCountryName = parseAllowedCountries && parseAllowedCountries.length > 0 && parseAllowedCountries.map((ele) => ele.countryName).join(",");
            return {
                company_full_name: accountData.companyFullName,
                account_name: accountData.accountName,
                country: getCountryName ?? "-",
                client_account_status: client_status,
                plan_details: plan_status,
                payment_status: payment_status,
                status_of_el: document_status,
                plan_status:planStatus,
                actions: <div className='d-flex'>
                    <Link className='btn btn-link text-primary p-1' title='View' to={`/lite/account-details/${accountData.id}`}>
                        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.4997" cy="8.50261" r="3.84314" stroke="currentColor" strokeWidth="1.64706" /><path d="M21.341 7.61396C21.657 8.01392 21.8151 8.2139 21.8151 8.5C21.8151 8.7861 21.657 8.98608 21.341 9.38604C19.8529 11.2694 16.0082 15.5 11.5 15.5C6.99178 15.5 3.14715 11.2694 1.65899 9.38604C1.34295 8.98608 1.18493 8.7861 1.18493 8.5C1.18493 8.2139 1.34295 8.01392 1.65899 7.61396C3.14715 5.73062 6.99178 1.5 11.5 1.5C16.0082 1.5 19.8529 5.73062 21.341 7.61396Z" stroke="currentColor" strokeWidth="1.64706" /></svg>
                    </Link>
                    {
                        accountData.planName ?  <Link className='btn btn-link text-success p-1' title='Edit' to={`/lite/account-details/edit/${accountData.id}`}>
                        <i className='fas fa-pencil-alt'></i>
                    </Link>:null
                    }
                </div>
            };
        });
        return accountDetailsArray;
    }

    DocumentStatus(documentStatus) {
        switch (documentStatus) {
            case 1:
                return <Badge color="warning">{__t("lite.account_details_module.pending")}</Badge>
            case 2:
                return <Badge color="secondary">{__t("lite.account_details_module.todo")}</Badge>
            case 3:
                return <Badge color="primary">{__t("lite.account_details_module.executed")}</Badge>
            default:
                return "-"
        }
    }
    PlanStatus(planStatus) {
        switch (planStatus) {
            case "Basic Plan":
                return <Badge color="info">{__t("lite.plan.basic")}</Badge>
            case "Premium Plan":
                return <Badge color="danger">{__t("lite.plan.premium")}</Badge>
            default:
                return "-"
        }
    }
    ClientStatus(clientStatus) {
        switch (clientStatus) {
            case 1:
                return <Badge color="success">{__t("lite.clientStatus.inProgress")}</Badge>
            case 2:
                return <Badge color="info">{__t("lite.clientStatus.active")}</Badge>
            case 3:
                return <Badge color="secondary">{__t("lite.clientStatus.dormant")}</Badge>
            case 4:
                return <Badge color="primary">{__t("lite.clientStatus.extension")}</Badge>
            case 5:
                return <Badge className="bg-orange">{__t("lite.clientStatus.suspension")}</Badge>
            case 6:
                return <Badge color="danger">{__t("lite.clientStatus.deletion")}</Badge>
            default:
                return "-"
        }
    }
    planStatus(plan_status){
        switch (plan_status) {
            case 1:
                return <Badge color="info">{__t("lite.plan_status.trail")}</Badge>
            case 2:
                return <Badge color="success">{__t("lite.plan_status.active")}</Badge>
            case 3:
                return <Badge color="danger">{__t("lite.plan_status.expired")}</Badge>
            default:
                return "-"
        }
    }
    PaymentStatus(paymentStatus) {
        switch (paymentStatus) {
            case 1:
                return <Badge color="success">{__t("lite.paymentStatus.completed")}</Badge>
            case 0:
                return <Badge color="warning">{__t("lite.paymentStatus.pending")}</Badge>
            default:
                return "-"
        }
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.activeTab && this.props.activeTab !== nextprops.activeTab && nextprops.activeTab == "account_details"
        ) {
            this.getAccountDetailsList(this.state.pageSize, this.state.currentPage, { ...this.state.sortBy });
        }
        if (nextprops.liteAccountDetails && this.props.liteAccountDetails !== nextprops.liteAccountDetails && nextprops.activeTab == "account_details") {
            this.setState({
                accountDetails: this.generateAccountDetailsTable(nextprops.liteAccountDetails),
                pageCount: nextprops.liteAccountDetails.totalPages,
                currentPage:
                    nextprops.liteAccountDetails.currentPage,
                totalRecords:
                    nextprops.liteAccountDetails.totalRecords,
            })
        }
    }

    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getAccountDetailsList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                if (this.props.activeTab == "account_details") {
                    this.getAccountDetailsList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            }
        );
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getAccountDetailsList(data.pageSize, data.pageIndex + 1, { key: data.sortBy[0]?.id, value: sortOrder }, this.state.searchFormData)
                }
            );
        }
    }

    getStatusChangeFilter(data) {
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                accountSetupStatus: data.filters.clientStatus ?? null
            }
        }, () => {

            this.getAccountDetailsList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy,
                {
                    ...this.state.searchFormData
                }
            );
        })
    }
    render() {
        const data = {
            columns: AccountDetailsHeader,
            rows: this.state.accountDetails,
        };
        return <div>
            <Card>
                <CardBody className="custom_table table-responsive">
                    <div className="legal-update-status">
                        <FormGroup className='mb-0 form-group'>
                            <Select
                                name="clientStatus"
                                className="form-control selectbox"
                                options={ClientStatusSelection}
                                onChange={(opt) => {
                                    let statusVal =
                                        opt && opt?.value
                                            ? opt.value
                                            : '';
                                    this.setState({
                                        clientStatus: statusVal
                                    })
                                    this.getStatusChangeFilter(
                                        {
                                            filters: {
                                                clientStatus: parseInt(statusVal)
                                            }
                                        }
                                    );
                                }}

                                value={ClientStatusSelection.filter(
                                    (option) =>
                                        this.state.clientStatus == option.value
                                )}
                                closeMenuOnSelect={true}
                                isClearable={true}
                            />
                            <Label className="form-label">
                                {__t("lite.status")}
                            </Label>
                        </FormGroup>
                    </div>
                    <CustomisedReactTableWithPagination
                        className="table table-bordered table-striped"
                        displayEntries={
                            true
                        }
                        searching={true}
                        currentPage={
                            this.state.currentPage
                        }

                        pageCount={
                            this.state.pageCount
                        }
                        totalRecords={this.state.totalRecords}
                        handleFetchData={this.handleFetchData}
                        handleSort={this.handleSort}
                        handleSearch={this.handleSearch}
                        preFilledSearchText={
                            this.state.searchFormData
                                ?.searchText
                        }
                        tableData={data}
                    />
                </CardBody>
            </Card>
        </div>
    }
}

export default AccountDetails