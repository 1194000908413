import React, { Component } from 'react';
import {
    Row,
    FormGroup,
    Button,
    Label,
    Card,
    Col,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ACTIVE,
    DANGER,
    DATE_FORMAT_DATEPICKER,
    DATE_FORMAT_PLACEHOLDER,
    RESET,
    SUBMIT,
    SUCCESS,
} from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getEntityListByCountryRequest,
    getEventListBySearchRequest,
    getAssignedEventCompliancesListRequest,
    planEventRequest,
} from 'store/actions';
import { compose } from 'redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import eventReducer from 'store/Masters/Event/reducer';
import eventSaga from 'store/Masters/Event/saga';
import eventAllocationReducer from 'store/EventAllocation/reducer';
import eventAllocationSaga from 'store/EventAllocation/saga';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedEntityList } from 'store/Entity/selector';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import {
    initialPlanEventFilterDataState,
    initialUserAllocationFormDataState,
    listComplianceColumns,
} from './CommonFunctions';
import __t from 'i18n/translator';
import { selectSortedEventList } from 'store/Masters/Event/selector';
import Select from 'react-select';
import {
    customDateValidator,
    formatDateForApiSystem,
    getDateFrom,
    getDateObjectWithValueFromMoment,
} from 'helpers/dateTimeHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeAllocationSelectField } from 'store/Allocation/selector';
import { makeEventAllocationSelectField } from 'store/EventAllocation/selector';
import MapLocationAndUserForm from './MapLocationAndUserForm';
import { toastrMessage } from 'helpers/messageHelper';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import Switch from 'react-switch';
import BulkMapLocationAndUserForm from './BulkMapLocationAndUserForm';
import { userIsLite } from 'helpers/litehelper';
import NoAccess from 'components/Application/NoAccess';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const eventKey = 'event';
const withEventReducer = injectReducer({
    key: eventKey,
    reducer: eventReducer,
});
const withEventSaga = injectSaga({ key: eventKey, saga: eventSaga });
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
const eventAllocationKey = 'eventAllocation';
const withEventAllocationReducer = injectReducer({
    key: eventAllocationKey,
    reducer: eventAllocationReducer,
});
const withEventAllocationSaga = injectSaga({
    key: eventAllocationKey,
    saga: eventAllocationSaga,
});
class PlanEvent extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            validators: Object.assign(customDateValidator()),
        });
        this.state = {
            allComplianceRecords: [],
            eventData: initialPlanEventFilterDataState(),
            operationType: SUBMIT,
            lawComplianceList: [],
            mapLocationAndUserModal: false,
            complianceCode: '',
            complianceId: '',
            userAllocationData: {},
            entityLocations: [],
            selectedAllocations: [],
            bulkMapLocationAndUserModal: false,
        };
    }
    componentDidMount() {
        if (
            this.props.match.params.countryId &&
            this.props.match.params.eventId
        ) {
            this.getStateListByCountry([this.props.match.params.countryId]);
            this.props.getEntityListByCountryRequest([
                this.props.match.params.countryId,
            ]);
            this.getEventsBySearch({
                ...this.state.eventData,
                countryId: this.props.match.params.countryId,
            });
            this.setState({
                eventData: {
                    ...this.state.eventData,
                    eventId: this.props.match.params.eventId,
                    countryId: this.props.match.params.countryId,
                },
            });
        }
        this.props.getCountryListByStatusRequest(ACTIVE);
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? stateIds : []);
    }
    getEventsBySearch(values) {
        let formValues = values;
        this.props.getEventListBySearchRequest({
            ...formValues,
            countryId: [formValues.countryId],
            entityId: [formValues.entityId],
        });
    }
    getAssignedEventCompliancesSearchList = (values = {}) => {
        let apiData = {
            limit: -1,
            page: -1,
            filter: {
                countryId: [values.countryId],
                stateId: values.stateId,
                cityId: values.cityId,
                entityId: [values.entityId],
                eventId: [values.eventId],
                eventOccurrenceDate: formatDateForApiSystem(
                    values.eventOccurrenceDate
                ),
            },
            orderBy: {},
        };
        this.props.getAssignedEventCompliancesListRequest(apiData, true);
    };
    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.setState({
                eventData: values,
            });
            this.getAssignedEventCompliancesSearchList(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                eventData: initialPlanEventFilterDataState(),
                lawComplianceList: [],
                allComplianceRecords: [],
            },
            () => {
                this.validator.hideMessages();
            }
        );
    };
    resetAllForm = (values) => {
        this.setState({
            eventData: {
                ...this.state.eventData,
                compliances: [],
            },
            lawComplianceList: [],
        });
    };
    formSubmitForAssign = () => {
        let selectedCheckboxComplianceAllocationIds = [];
        this.state.selectedAllocations.forEach((selectedAllocation) => {
            selectedAllocation.id.forEach((opt) => {
                selectedCheckboxComplianceAllocationIds.push(opt);
            });
        });
        let selectedEventCompliances = [];
        selectedEventCompliances = this.state.eventData.compliances.filter(
            (opt) => {
                if (
                    selectedCheckboxComplianceAllocationIds.some(
                        (complianceAllocationId) =>
                            opt.complianceAllocationId?.includes(
                                complianceAllocationId
                            )
                    )
                ) {
                    return opt;
                }
            }
        );
        if (selectedEventCompliances.length === 0) {
            toastrMessage(__t('alerts.please_select_record'), DANGER);
        } else {
            let formEventData = {
                ...this.state.eventData,
                eventOccurrenceDate: formatDateForApiSystem(
                    this.state.eventData.eventOccurrenceDate
                ),
                compliances: selectedEventCompliances.map((opt) => {
                    return {
                        ...opt,
                        dueDate: formatDateForApiSystem(opt.dueDate),
                    };
                }),
            };
            this.props.planEventRequest(formEventData);
        }
    };
    formSubmitForMapLocationAndUsers = (values) => {
        let formValues = { ...values, complianceId: this.state.complianceId };
        let compliances = this.state.eventData.compliances;
        let complianceExist = this.state.eventData.compliances.find(
            (opt) => opt.complianceId === this.state.complianceId
        );
        if (complianceExist) {
            compliances = compliances.map((opt) => {
                if (opt.complianceId === this.state.complianceId) {
                    return formValues;
                } else {
                    return opt;
                }
            });
        } else {
            compliances.push(formValues);
        }
        this.setState(
            {
                eventData: {
                    ...this.state.eventData,
                    compliances: compliances,
                },
            },
            () => {
                this.setState({
                    lawComplianceList: this.generateTable(
                        this.state.allComplianceRecords
                    ),
                });
                this.closeMapLocationAndUserModal();
            }
        );
    };
    formSubmitForBulkMapLocationAndUsers = (values) => {
        const compliances = [];
        for (const item of this.state.allComplianceRecords) {
            const foundData = item.entityLocations?.find((data) =>
                values?.entityLocationId.includes(data.id)
            );
            if (foundData) {
                let enableDueDate = false;
                if (
                    item.eventDueDateFormula === '' &&
                    item.eventDueDateFormula != '0' &&
                    (item.triggeringComplianceId == '' ||
                        item.triggeringComplianceId == 'null' ||
                        item.triggeringComplianceId == null)
                ) {
                    enableDueDate = true;
                }
                compliances.push({
                    complianceAllocationId: item?.entityLocations?.map(
                        (x) => x?.complianceAllocationId
                    ),
                    entityId: this.state.eventData.entityId,
                    entityLocationId: values?.entityLocationId,
                    cityName: values?.cityName,
                    eventDueDateFormula: item?.eventDueDateFormula,
                    dueDate: item?.dueDate,
                    departmentId: values?.departmentId,
                    submitterId: values?.submitterId,
                    firstApproverUserId: values?.firstApproverUserId,
                    secondApproverUserId: values?.secondApproverUserId,
                    enableDueDate: enableDueDate,
                    triggeringComplianceId: item?.triggeringComplianceId,
                    complianceId: item?.complianceId,
                });
            }
        }
        this.setState(
            {
                eventData: {
                    ...this.state.eventData,
                    compliances: compliances,
                },
            },
            () => {
                this.setState({
                    lawComplianceList: this.generateTable(
                        this.state.allComplianceRecords
                    ),
                });
                this.closeBulkMapLocationAndUserModal();
            }
        );
    };
    openMapLocationAndUserModal = (allocationData) => {
        let alreadySelectedData = this.state.eventData.compliances.find(
            (opt) => opt.complianceId == allocationData.lawCompliance.id
        );
        let userAllocationData = alreadySelectedData
            ? {
                ...alreadySelectedData,
                dueDate: alreadySelectedData.dueDate,
            }
            : initialUserAllocationFormDataState();

        let enableDueDate = false;
        if (
            allocationData.eventDueDateFormula === '' &&
            allocationData.eventDueDateFormula != '0' &&
            (allocationData.triggeringComplianceId == '' ||
                allocationData.triggeringComplianceId == 'null' ||
                allocationData.triggeringComplianceId == null)
        ) {
            enableDueDate = true;
        }
        this.setState(
            {
                mapLocationAndUserModal: true,
                complianceCode: allocationData.lawCompliance.complianceCode,
                complianceId: allocationData.lawCompliance.id,
                userAllocationData: {
                    ...userAllocationData,
                    dueDate: !enableDueDate
                        ? allocationData?.dueDate
                        : alreadySelectedData?.dueDate,
                    eventDueDateFormula: allocationData.eventDueDateFormula,
                    enableDueDate: enableDueDate,
                    triggeringComplianceId:
                        allocationData.lawCompliance?.triggeringComplianceId,
                    complianceAllocationId: !alreadySelectedData
                        ? allocationData.entityLocations.map(
                            (opt) => opt.complianceAllocationId
                        )
                        : alreadySelectedData.complianceAllocationId,
                    cityName: !alreadySelectedData
                        ? allocationData.entityLocations
                            .map((opt) => opt.city.cityName)
                            .join(',')
                        : alreadySelectedData.cityName,
                    entityLocationId: !alreadySelectedData
                        ? allocationData.entityLocations.map((opt) => opt.id)
                        : alreadySelectedData.entityLocationId,
                },
                entityLocations: allocationData.entityLocations,
            }
        );
    };
    bulkOpenMapLocationAndUserModal = () => {

        const allCities = this.state.allComplianceRecords.flatMap((item) =>
            item.entityLocations.map((location) => ({
                cityName: location.city.cityName,
                id: location.id,
            }))
        );

        const uniqueIds = {};
        const uniqueCountryData = [];
        for (const item of allCities) {
            if (!uniqueIds[item.id]) {
                uniqueIds[item.id] = true;
                uniqueCountryData.push(item);
            }
        }

        this.setState({
            bulkMapLocationAndUserModal: true,
            entityLocations: uniqueCountryData,
        });
    };
    closeMapLocationAndUserModal = () => {
        this.setState({
            mapLocationAndUserModal: false,
            complianceCode: '',
            complianceId: '',
            userAllocationData: {},
            entityLocations: [],
        });
    };
    closeBulkMapLocationAndUserModal = () => {
        this.setState({
            bulkMapLocationAndUserModal: false,
            complianceCode: '',
            complianceId: '',
            userAllocationData: {},
            entityLocations: [],
        });
    };
    generateTable = (records) => {
        let complianceRecords = [];
        complianceRecords = records.map((allocationData, i) => {
            let action = (
                <a
                    href={() => false}
                    onClick={() => {
                        window.open(
                            '/statutory-compliances/view-details/' +
                            allocationData.lawCompliance.id,
                            '_blank'
                        );
                    }}
                    className="text-nowrap"
                >
                    {allocationData.lawCompliance?.complianceCode}
                </a>
            );
            let alreadySelected = this.state.eventData.compliances.find(
                (opt) => opt.complianceId === allocationData.complianceId
            );
            let enableDueDate = false;
            if (
                allocationData.eventDueDateFormula == '' &&
                allocationData.eventDueDateFormula != '0' &&
                (allocationData.triggeringComplianceId == '' ||
                    allocationData.triggeringComplianceId == 'null' ||
                    allocationData.triggeringComplianceId == null)
            ) {
                enableDueDate = true;
            }
            let actions = (
                <React.Fragment key={allocationData.complianceId}>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.openMapLocationAndUserModal(allocationData);
                        }}
                        className="btn btn-link tbl-action text-primary"
                        data-tip="hello world"
                        title={__t('common.click_to_map_users')}
                    >
                        {alreadySelected ? (
                            <i className="fas fa-user-check"></i>
                        ) : (
                            <i className="fas fa-user-plus"></i>
                        )}
                    </a>
                </React.Fragment>
            );
            return {
                checkbox: alreadySelected ? true : false,
                id: allocationData.entityLocations.map(
                    (opt) => opt.complianceAllocationId
                ),
                complianceCode: action,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                cityName: alreadySelected ? alreadySelected.cityName : '-',
                dueDate:
                    enableDueDate && alreadySelected
                        ? getDateFrom(alreadySelected.dueDate)
                        : allocationData.dueDate,
                actions: actions,
            };
        });
        return complianceRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allocationApiResponse &&
            this.props.allocationApiResponse !== nextprops.allocationApiResponse
        ) {
            if (nextprops.allocationApiResponse.responseType === SUCCESS) {
                if (
                    nextprops.allocationApiResponse.data.complianceList
                        ?.length == 0
                ) {
                    toastrMessage(__t('alerts.no_compliances_found'), DANGER);
                }
                this.setState(
                    {
                        allComplianceRecords:
                            nextprops.allocationApiResponse.data.complianceList,
                        lawComplianceList: this.generateTable(
                            nextprops.allocationApiResponse.data.complianceList
                        ),
                    },
                    () => {
                        this.setState({
                            resetTableSection: false,
                        });
                    }
                );
            }
        }
        if (
            nextprops.planEventApiResponse &&
            nextprops.planEventApiResponse !== this.props.planEventApiResponse
        ) {
            toastrMessage(
                nextprops.planEventApiResponse.message,
                nextprops.planEventApiResponse.responseType
            );
            if (nextprops.planEventApiResponse.responseType === SUCCESS) {
                this.resetForm();
            }
        }
    }
    render() {
        const data = {
            columns: listComplianceColumns,
            rows: this.state.lawComplianceList,
        };
        return (
            <React.Fragment>
              <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader>
                                <div className="page-header">
                                    <h4 className="with-back-btn">
                                        <a
                                            href={() => false}
                                            className="dripicons-arrow-thin-left"
                                            onClick={() => {
                                                this.props.history.push(
                                                    '/plan-events'
                                                );
                                            }}
                                        >
                                            { }
                                        </a>
                                        {__t('page_headers.plan_event')}
                                    </h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={this.state.eventData}
                                    enableReinitialize={true}
                                    onSubmit={this.formSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>

                                            <Row>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="countryId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCountryListDropdown
                                                        }
                                                        styles={{
                                                            menuPortal: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                            menu: (
                                                                provided
                                                            ) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        onChange={(opt) => {
                                                            let selectedValues =
                                                                opt
                                                                    ? opt.value
                                                                    : '';
                                                            setFieldValue(
                                                                'countryId',
                                                                selectedValues
                                                            );
                                                            setFieldValue(
                                                                'stateId',
                                                                []
                                                            );
                                                            setFieldValue(
                                                                'entityId',
                                                                ''
                                                            );
                                                            this.getStateListByCountry(
                                                                [selectedValues]
                                                            );
                                                            this.props.getEntityListByCountryRequest(
                                                                [selectedValues]
                                                            );
                                                            this.getEventsBySearch(
                                                                {
                                                                    ...values,
                                                                    countryId:
                                                                        selectedValues,
                                                                }
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        value={this.props.sortedCountryListDropdown?.filter(
                                                            (option) =>
                                                                values.countryId ==
                                                                option.value
                                                        )}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.country'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.country'
                                                        ),
                                                        values.countryId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.country_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="stateId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedStateListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            let selectedValues =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'stateId',
                                                                selectedValues
                                                            );
                                                            setFieldValue(
                                                                'cityId',
                                                                []
                                                            );
                                                            this.getCityListByState(
                                                                selectedValues
                                                            );
                                                        }}
                                                        value={this.props.sortedStateListDropdown?.filter(
                                                            (option) =>
                                                                values.stateId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                        key="stateId"
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.allocation.state'
                                                        )}

                                                    </Label>

                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={
                                                            CustomMultiSelect
                                                        }
                                                        name="cityId[]"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedCityListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            let selectedValues =
                                                                opt
                                                                    ? opt.map(
                                                                        (x) =>
                                                                            x.value
                                                                    )
                                                                    : [];
                                                            setFieldValue(
                                                                'cityId',
                                                                selectedValues
                                                            );
                                                        }}
                                                        value={this.props.sortedCityListDropdown?.filter(
                                                            (option) =>
                                                                values.cityId?.includes(
                                                                    option.value
                                                                )
                                                        )}
                                                        isMulti
                                                        closeMenuOnSelect={
                                                            false
                                                        }
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        allowSelectAll={true}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            animatedComponents,
                                                        }}
                                                        key="cityId"
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.allocation.city'
                                                        )}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="col-lg-3">
                                                    <Field
                                                        component={Select}
                                                        name="entityId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedEntityListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            let entityId = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'entityId',
                                                                entityId
                                                            );
                                                            setFieldValue(
                                                                'eventId',
                                                                ''
                                                            );
                                                            this.getEventsBySearch(
                                                                {
                                                                    ...values,
                                                                    entityId:
                                                                        entityId,
                                                                }
                                                            );
                                                        }}
                                                        value={this.props.sortedEntityListDropdown?.filter(
                                                            (option) =>
                                                                values.entityId ==
                                                                option.value
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.entity'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.entity'
                                                        ),
                                                        values.entityId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.entity_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-xl-5 col-xxl-6 ">
                                                    <Field
                                                        component={Select}
                                                        name="eventId"
                                                        className="form-control selectbox"
                                                        options={
                                                            this.props
                                                                .sortedEventListDropdown
                                                        }
                                                        onChange={(opt) => {
                                                            let eventIds = opt
                                                                ? opt.value
                                                                : '';
                                                            setFieldValue(
                                                                'eventId',
                                                                eventIds
                                                            );
                                                        }}
                                                        value={this.props.sortedEventListDropdown?.filter(
                                                            (option) =>
                                                                values.eventId ==
                                                                option.value
                                                        )}
                                                        isClearable={true}
                                                    />
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.event'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.event'
                                                        ),
                                                        values.eventId,
                                                        'required',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.event_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-xl-4 col-xxl-4 sticky_label event_date">
                                                    <div className="event_switch">
                                                        <Switch
                                                            className={`switch-sm plan_switch ${values.eventType ===
                                                                'past'
                                                                ? 'past'
                                                                : 'future'
                                                                }`}
                                                            onColor="#9BD732"
                                                            onChange={(
                                                                checked
                                                            ) => {
                                                                setFieldValue('eventOccurrenceDate', '');
                                                                if (
                                                                    checked ===
                                                                    true
                                                                ) {
                                                                    setFieldValue(
                                                                        'eventType',
                                                                        'future'
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        'eventType',
                                                                        'past'
                                                                    );
                                                                }
                                                            }}
                                                            id="event-type"
                                                            uncheckedIcon={
                                                                <div className="past_switch">
                                                                    Past
                                                                </div>
                                                            }
                                                            checkedIcon={
                                                                <div className="future_switch">
                                                                    Future
                                                                </div>
                                                            }
                                                            checkedHandleIcon={
                                                                <div className="future_switch">
                                                                    Future
                                                                </div>
                                                            }
                                                            uncheckedHandleIcon={
                                                                <div className="past_switch">
                                                                    Past
                                                                </div>
                                                            }
                                                            checked={
                                                                values.eventType ===
                                                                    'past'
                                                                    ? false
                                                                    : true
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        component={DatePicker}
                                                        className="form-control"
                                                        name="eventOccurrenceDate"
                                                        selected={
                                                            values.eventOccurrenceDate
                                                                ? getDateObjectWithValueFromMoment(
                                                                    values.eventOccurrenceDate
                                                                )
                                                                : null
                                                        }
                                                        dateFormat={
                                                            DATE_FORMAT_DATEPICKER
                                                        }
                                                        onChange={(date) => {
                                                            setFieldValue(
                                                                'eventOccurrenceDate',
                                                                date
                                                            );
                                                        }}
                                                        showMonthDropdown={true}
                                                        showYearDropdown={true}
                                                        dropdownMode="select"
                                                        placeholderText={
                                                            DATE_FORMAT_PLACEHOLDER
                                                        }
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                        isClearable={true}
                                                        minDate={
                                                            values.eventType ==
                                                                'future'
                                                                ? new Date()
                                                                : ''
                                                        }
                                                        maxDate={
                                                            values.eventType ==
                                                                'past'
                                                                ? new Date()
                                                                : ''
                                                        }
                                                    ></Field>
                                                    <Label className="form-label">
                                                        {__t(
                                                            'form_labels.events.plan.occurrence_date'
                                                        )}
                                                        <span className="text-danger required">
                                                            *
                                                        </span>
                                                    </Label>
                                                    {this.validator.message(
                                                        __t(
                                                            'form_labels.events.plan.occurrence_date'
                                                        ),
                                                        values.eventOccurrenceDate
                                                            ? getDateObjectWithValueFromMoment(
                                                                values.eventOccurrenceDate
                                                            )
                                                            : '',
                                                        'required|customDate',
                                                        {
                                                            messages: {
                                                                required: __t(
                                                                    'validations.events.plan.occurrence_date_required'
                                                                ),
                                                            },
                                                        }
                                                    )}
                                                </FormGroup>
                                                <FormGroup className="col-xl-3 col-xxl-2 d-flex justify-content-end pl-0">
                                                    <div className="d-inline-flex">
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                        >
                                                            {__t(
                                                                'form_labels.events.plan.generate_events'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            type="reset"
                                                            color="secondary"
                                                            onClick={
                                                                this.resetForm
                                                            }
                                                        >
                                                            {RESET}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                    {this.state.lawComplianceList?.length > 0 && (
                        <React.Fragment>
                            <Col lg="12">
                                <Card>
                                    <CardBody className="custom_table">
                                        <Button
                                            className="btn btn-info"
                                            onClick={() =>
                                                this.bulkOpenMapLocationAndUserModal()
                                            }
                                        >
                                            Bulk User Allocation{' '}
                                        </Button>
                                        <CustomisedReactTableWithPaginationAndRowSelection
                                            onchangeCheckbox={(row) => {
                                                this.setState({
                                                    selectedAllocations:
                                                        row.map((itemRow) => ({
                                                            id: itemRow.id,
                                                        })),
                                                });
                                            }}
                                            searching={false}
                                            paging={false}
                                            displayEntries={false}
                                            className="table table-bordered table-striped"
                                            tableData={data}
                                        />
                                    </CardBody>
                                    <CardFooter>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="mr-2"
                                                onClick={
                                                    this.formSubmitForAssign
                                                }
                                            >
                                                {__t(
                                                    'form_labels.events.plan.schedule_tasks'
                                                )}
                                            </Button>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                className="mr-2"
                                                onClick={() => {
                                                    this.resetAllForm(
                                                        this.state
                                                            .allocationFilterData
                                                    );
                                                }}
                                            >
                                                {RESET}
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <MapLocationAndUserForm
                                isModalOpen={this.state.mapLocationAndUserModal}
                                onModalDismiss={() =>
                                    this.closeMapLocationAndUserModal()
                                }
                                modalName={
                                    __t(
                                        'form_labels.events.plan.map_entity_location_and_user_for_compliance'
                                    ) +
                                    ' | ' +
                                    this.state.complianceCode
                                }
                                complianceId={this.state.complianceId}
                                eventData={this.state.eventData}
                                userAllocationData={
                                    this.state.userAllocationData
                                }
                                entityLocations={this.state.entityLocations}
                                onFormValid={(values) => {
                                    this.formSubmitForMapLocationAndUsers(
                                        values
                                    );
                                }}
                            />
                            <BulkMapLocationAndUserForm
                                isModalOpen={
                                    this.state.bulkMapLocationAndUserModal
                                }
                                onModalDismiss={() =>
                                    this.closeBulkMapLocationAndUserModal()
                                }
                                modalName={__t(
                                    'form_labels.events.plan.bulk_map_entity_location_and_user_for_compliance'
                                )}
                                eventData={this.state.eventData}
                                userAllocationData={
                                    this.state.userAllocationData
                                }
                                entityLocations={this.state.entityLocations}
                                onFormValid={(values) => {
                                    this.formSubmitForBulkMapLocationAndUsers(
                                        values
                                    );
                                }}
                            />
                        </React.Fragment>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedEventListDropdown: selectSortedEventList(),
    allocationApiResponse: makeAllocationSelectField('allocationApiResponse'),
    planEventApiResponse: makeEventAllocationSelectField(
        'planEventApiResponse'
    ),
});
const withConnect = connect(mapStatetoProps, {
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getEntityListByCountryRequest,
    getEventListBySearchRequest,
    getAssignedEventCompliancesListRequest,
    planEventRequest,
});
export default compose(
    withConnect,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withEntityReducer,
    withEntitySaga,
    withEventReducer,
    withEventSaga,
    withAllocationReducer,
    withAllocationSaga,
    withEventAllocationReducer,
    withEventAllocationSaga,
    withRouter
)(PlanEvent);
