import React, { Component } from 'react';
import { Row, FormGroup, Button, Label, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CLEAR, DATE_FORMAT_DATEPICKER, DATE_FORMAT_PLACEHOLDER, GENERATE, NONE, SUBMIT } from 'constants/commonConstants';
import SimpleReactValidator from 'simple-react-validator';
import { Formik, Form, Field } from 'formik';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Select from 'react-select';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { Option, MultiValue, animatedComponents } from 'helpers/projectUtils';
import { getDateObjectWithValueFromMoment } from 'helpers/dateTimeHelper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { initialCertificateFilterDataState } from './CommonFunctions';
import { certificateReportTypeList, roleList } from 'constants/dropdowns';
import { LITE_COMPLIANCE_STATUS, LITE_EMAIL_TO_CC_ROLE_EXCLUDED, LITE_TYPE_OF_REPORT_EXCLUDED } from 'constants/databaseConstants';
import { userIsLite } from 'helpers/litehelper';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
class CertificateFilterForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            filterData: initialCertificateFilterDataState(),
            operationType: SUBMIT
        }
    }

    formSubmit = (values) => {
        if (this.validator.allValid()) {
            this.props.onFormSubmit(values);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    resetForm = () => {
        this.setState(
            {
                filterData: initialCertificateFilterDataState(),
            },
            () => {
                this.props.onFormReset();
            }
        );
    };

    render() {
        let roleListDropdown = roleList;
        let reportTypeListDropdown = certificateReportTypeList;
        const isLite = JSON.parse(decryptData(getValueByKey(authConstants.IS_LITE)));
        const getComplianceCategory = isLite.litePlan?.complianceCategories ? JSON.parse(isLite.litePlan.complianceCategories):null;
        const getComplianceCategoryId = getComplianceCategory && getComplianceCategory.length > 0 ? getComplianceCategory.map((ele)=>ele.id):null;
        return (
            <React.Fragment>
                <Formik
                    initialValues={this.props.filterData}
                    enableReinitialize={true}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Row>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="typeOfReport[]"
                                        className="form-control selectbox"
                                        options={reportTypeListDropdown.filter((element)=>{
                                            if(userIsLite()){
                                                return !LITE_TYPE_OF_REPORT_EXCLUDED.includes(element.value)
                                            }else{
                                                return element
                                            }
                                        })}
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let typeOfReport = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'typeOfReport',
                                                typeOfReport
                                            );
                                        }}
                                        value={reportTypeListDropdown.filter(
                                            (option) =>
                                                values.typeOfReport.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.reports.type_of_report'
                                        )}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="roleId[]"
                                        className="form-control selectbox"
                                        options={roleListDropdown.filter((element)=>{
                                            if(userIsLite()){
                                               return !LITE_EMAIL_TO_CC_ROLE_EXCLUDED.includes(element.value)
                                            }else{
                                                return element
                                            }
                                        })}
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let roleId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'roleId',
                                                roleId
                                            );
                                            setFieldValue('userId', []);
                                            setFieldValue('departmentId', []);
                                            this.props.getUserListByEntityAndRole(values.entityId, roleId);
                                        }}
                                        value={roleListDropdown.filter(
                                            (option) =>
                                                values.roleId.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.reports.type_of_role'
                                        )}
                                    </Label>
                                </FormGroup>
                                {
                                    !userIsLite() ? <FormGroup className='col-lg-3'>
                                    <Field
                                        component={Select}
                                        className="form-control selectbox"
                                        options={this.props.sortedCompanyGroupListDropdown.filter(
                                            (opt) => opt.value != NONE
                                        )}
                                        onChange={(opt) => {
                                            setFieldValue(
                                                'companyGroupId',
                                                opt.value
                                            );
                                            setFieldValue(
                                                'entityId',
                                                []
                                            );
                                        }}
                                        value={this.props.sortedCompanyGroupListDropdown.filter((e) => e.value == values.companyGroupId)}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.company_group')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.reports.company_group'
                                        ),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.reports.company_group_required'
                                                )
                                            }
                                        })}
                                </FormGroup>:null
                                }
                                
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="entityId"
                                        className="form-control selectbox"
                                        options={this.props.sortedEntityListDropdown}
                                        onChange={(opt) => {
                                            let entityId = opt
                                                ? opt?.map((x) => x.value)
                                                : [];

                                            setFieldValue('entityId', entityId);
                                            this.props.getUserListByEntityAndRole(entityId, values.roleId);
                                        }}
                                        value={this.props.sortedEntityListDropdown?.filter(
                                            (option) =>
                                                values?.entityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.entity')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.reports.entity'
                                        ),
                                        values.entityId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.reports.entity_required'
                                                )
                                            }
                                        })}
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCountryListDropdown
                                        }
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let countryId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('countryId', countryId);
                                            setFieldValue('stateId', []);
                                            this.props.getStateListByCountry(
                                                countryId
                                            );
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            (option) =>
                                                values.countryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.reports.country'
                                        )}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.reports.country'
                                        ),
                                        values.countryId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.reports.country_required'
                                                )
                                            }
                                        })}
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedStateListDropdown.filter((opt) => opt.value != -1)
                                        }
                                        onChange={(opt) => {
                                            let stateId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('stateId', stateId);
                                            setFieldValue('cityId', []);
                                            this.props.getCityListByState(
                                                opt && opt.map((x) => x.value)
                                            );
                                        }}
                                        value={this.props.sortedStateListDropdown?.filter(
                                            (option) =>
                                                values.stateId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.state')}
                                        <span className="text-danger required">
                                            *
                                        </span>
                                    </Label>
                                    {this.validator.message(
                                        __t(
                                            'form_labels.reports.state'
                                        ),
                                        values.stateId,
                                        'required',
                                        {
                                            messages: {
                                                required: __t(
                                                    'validations.reports.state_required'
                                                )
                                            }
                                        })}
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedCityListDropdown.filter((opt) => opt.value != -1)
                                        }
                                        onChange={(opt) => {
                                            let cityId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('cityId', cityId);
                                        }}
                                        value={this.props.sortedCityListDropdown?.filter(
                                            (option) =>
                                                values.cityId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.city')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="departmentId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedDepartmentListDropdown
                                        }
                                        onChange={(opt) => {
                                            let departmentId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('departmentId', departmentId);
                                        }}
                                        value={this.props.sortedDepartmentListDropdown?.filter(
                                            (option) =>
                                                values.departmentId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.department')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={Select}
                                        name="userId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedUserListByEntityAndRoleDropdown}
                                        styles={{
                                            menuPortal: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        onChange={(opt) => {
                                            let userId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue(
                                                'userId',
                                                userId
                                            );
                                        }}
                                        value={this.props.sortedUserListByEntityAndRoleDropdown.filter(
                                            (option) =>
                                                values.userId.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t(
                                            'form_labels.reports.users'
                                        )}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="lawId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.props.sortedLawListOfLoginUserDropdown
                                        }
                                        onChange={(opt) => {
                                            let lawId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('lawId', lawId);
                                        }}
                                        value={this.props.sortedLawListOfLoginUserDropdown?.filter(
                                            (option) =>
                                                values.lawId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.law')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="taskStatusId[]"
                                        className="form-control selectbox select-optgroup-field"
                                        options={this.props.sortedTaskStatusListDropdown.filter((element)=>{
                                            if(userIsLite()){
                                                return LITE_COMPLIANCE_STATUS.includes(element.value)
                                            }else{
                                                return element
                                          }
                                        })}
                                        onChange={(opt) => {
                                            let taskStatusId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('taskStatusId', taskStatusId);
                                        }}
                                        value={this.props.sortedTaskStatusListDropdown?.filter(
                                            (option) =>
                                                values.taskStatusId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.compliance_status')}
                                    </Label>
                                </FormGroup>

                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="complianceTypeId[]"
                                        className="form-control selectbox"
                                        options={this.props.allCompliancesTypesList}
                                        onChange={(opt) => {
                                            let complianceTypeId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('complianceTypeId', complianceTypeId);
                                        }}
                                        value={this.props.allCompliancesTypesList?.filter(
                                            (option) =>
                                                values.complianceTypeId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.compliance_class')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="complianceCategoryId[]"
                                        className="form-control selectbox"
                                        options={this.props.allCompliancesCategoriesList.filter((element)=>{
                                            if(userIsLite()){
                                                return getComplianceCategoryId?.includes(element.value)
                                            }else{
                                                return element
                                            }
                                        })}
                                        onChange={(opt) => {
                                            let complianceCategoryId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('complianceCategoryId', complianceCategoryId);
                                        }}
                                        value={this.props.allCompliancesCategoriesList?.filter(
                                            (option) =>
                                                values.complianceCategoryId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.compliance_classification')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className="col-lg-3">
                                    <Field
                                        component={CustomMultiSelect}
                                        name="complianceRiskRatingId[]"
                                        className="form-control selectbox"
                                        options={this.props.riskRatingsList}
                                        onChange={(opt) => {
                                            let complianceRiskRatingId = opt
                                                ? opt.map((x) => x.value)
                                                : [];
                                            setFieldValue('complianceRiskRatingId', complianceRiskRatingId);
                                        }}
                                        value={this.props.riskRatingsList?.filter(
                                            (option) =>
                                                values.complianceRiskRatingId?.includes(
                                                    option.value
                                                )
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{
                                            Option,
                                            MultiValue,
                                            animatedComponents,
                                        }}
                                        isClearable={true}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.reports.risk_level')}
                                    </Label>
                                </FormGroup>
                                <Col lg={3} className="d-flex">
                                    <FormGroup className='w-100 sticky_label mr-2 position-relative'>
                                        <Field
                                            component={
                                                DatePicker
                                            }
                                            isClearable={true}
                                            className="form-control"
                                            name="fromDate"
                                            selected={(values.fromDate) ? getDateObjectWithValueFromMoment(values.fromDate) : null}
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            placeholderText={DATE_FORMAT_PLACEHOLDER}
                                            onChange={date => {
                                                setFieldValue(
                                                    'fromDate',
                                                    date
                                                )
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            onFocus={e => e.target.blur()}
                                            maxDate={values.toDate}
                                        ></Field>
                                        <Label className="form-label pl-0">
                                            {__t('form_labels.task_filters.from_date')}

                                        </Label>

                                    </FormGroup>
                                    <FormGroup className='w-100 sticky_label ml-2 position-relative'>
                                        <Field
                                            component={
                                                DatePicker
                                            }
                                            isClearable={true}
                                            className="form-control"
                                            name="toDate"
                                            selected={(values.toDate) ? getDateObjectWithValueFromMoment(values.toDate) : null}
                                            dateFormat={DATE_FORMAT_DATEPICKER}
                                            onChange={date => {
                                                setFieldValue(
                                                    'toDate',
                                                    date
                                                )
                                            }}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            dropdownMode="select"
                                            placeholderText={DATE_FORMAT_PLACEHOLDER}
                                            onFocus={e => e.target.blur()}
                                            minDate={values.fromDate}
                                        ></Field>
                                        <Label className="form-label pl-0">
                                            {__t('form_labels.reports.to_date')}

                                        </Label>

                                    </FormGroup>
                                </Col>
                                <FormGroup className="col-lg-3 d-flex justify-content-end mb-2">
                                    <div className="d-inline-block pt-2">
                                        <Button
                                            type="reset"
                                            color="secondary"
                                            onClick={this.resetForm}
                                        >
                                            {CLEAR}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="ml-2"
                                        >
                                            {GENERATE}
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
});
const withConnect = connect(mapStatetoProps, {
});
export default compose(
    withConnect,
    withRouter
)(CertificateFilterForm);
