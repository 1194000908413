import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getCityListByStateRequest, getCompanyGroupListByStatusRequest, getCountryListByStatusRequest, getDepartmentListByStatusRequest, getEntityTeamsByEntityIdRequest, getLawCategoryListByStatusRequest, getStateListByCountryRequest } from 'store/actions';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import { selectSortedLawCategoryList } from 'store/Masters/LawCategory/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import lawCategoryReducer from 'store/Masters/LawCategory/reducer';
import lawCategorySaga from 'store/Masters/LawCategory/saga';
import injectReducer from 'helpers/injectReducer';
import RegisteredAccount from 'store/Lite/reducer';
import LiteRegistrationSaga from 'store/Lite/saga';
import injectSaga from 'helpers/injectSaga';
import { withRouter } from "react-router-dom"
import { ACTIVE, CLEAR, DEFAULT_PAGE_LENGTH, NONE, SEARCH } from 'constants/commonConstants';
import { Field, Formik } from 'formik';
import __t from 'i18n/translator';
import CustomMultiSelect from 'components/Application/CustomMultiSelect';
import { animatedComponents, checkModulePermission, MultiValue, Option } from 'helpers/projectUtils';
import Select from "react-select"
import CustomisedReactTableWithPagination from 'components/Application/CustomisedReactTableWithPagination';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { getEntitySetupListRequest, getLiteComplianceAllocationRequest } from 'store/Lite/actions';
import { makeLiteClientRegistrationSelectField } from 'store/Lite/selector';
import { liteListCompliance } from './LiteComplianceManagement';
import CustomisedModal from 'components/Application/CustomisedModal';
import { makeEntitySelectField, selectSortedLiteEntityTeam } from 'store/Entity/selector';
import allocationReducer from 'store/Allocation/reducer';
import allocationSaga from 'store/Allocation/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import { selectSortedCityList } from 'store/Masters/City/selector';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import { decryptData } from 'helpers/encryptor';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from "constants/authConstants";
import { ROLE_SUPER_ADMIN } from 'constants/databaseConstants';
const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });

const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const lawCategoryKey = 'lawCategory';
const withLawCategoryReducer = injectReducer({
    key: lawCategoryKey,
    reducer: lawCategoryReducer,
});
const withLawCategorySaga = injectSaga({
    key: lawCategoryKey,
    saga: lawCategorySaga,
});
const allocationKey = 'allocation';
const withAllocationReducer = injectReducer({
    key: allocationKey,
    reducer: allocationReducer,
});
const withAllocationSaga = injectSaga({
    key: allocationKey,
    saga: allocationSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const countriesEntityList = 'RegisteredAccount';
const withLiteCountriesListReducer = injectReducer({
    key: countriesEntityList,
    reducer: RegisteredAccount,
});
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const withLiteCountriesListSaga = injectSaga({ key: countriesEntityList, saga: LiteRegistrationSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
class ComplianceDiscoveryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            complianceList: [],
            searchFormData: {},
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            allocationList:[],
            singleSelectedCompliance:null,
            currentPage: 1,
            showUserModel:false,
            sortedStateListDropdown: [],
            sortedCityListDropdown: [],
            sortedEntitySetupListDropdown:[],
            resetTableSection: false,
            userMappedModel:false,
            totalRecords: 0,
            isRmUser:JSON.parse(decryptData(getValueByKey(authConstants.IS_RM_USER))),
            roleId: JSON.parse(decryptData(getValueByKey(authConstants.USER_ROLES)))
            
        }
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        if (this.props.activeTab == "compliance_discovery_details") {
                this.props.getCountryListByStatusRequest(ACTIVE);
                this.props.getLawCategoryListByStatusRequest(ACTIVE);
                this.props.getCompanyGroupListByStatusRequest(ACTIVE,"",1);
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    this.state.currentPage,
                    this.state.sortBy,
                    this.state.searchFormData
                );
        }
    }
    getStateListByCountry(countryIds) {
            this.props.getStateListByCountryRequest((countryIds) ? countryIds : []);
        }
    getCityListByState(stateIds) {
            this.props.getCityListByStateRequest((stateIds) ? stateIds : []);
    }

    getLiteAllocationSearchList = (limit, page, sortBy, values = {}) => {
            let formValues = values;
            if (formValues.type && formValues.type != 1) {
                formValues.isPendingMapping = formValues.type == 2 ? true : false;
            } else {
                delete formValues.isPendingMapping;
            }
            formValues.userAllocation = true;
            let apiData = {
                limit: limit,
                page: page,
                filter: formValues,
                orderBy: sortBy,
            };
            if(this.state.isRmUser && this.state.roleId[0].roleId !== ROLE_SUPER_ADMIN){
                apiData.filter= {
                    ...apiData.filter,
                    isRmUser:true
                }
            }
            this.props.getLiteComplianceAllocationRequest(apiData);
    };
    generateTable = (records) => {
        let allocationRecords = [];
        allocationRecords = records.map((allocationData, i) => {
            let actions = (
                <div className="tbl_action">
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && (
                        <a
                            href={() => false}
                            className="btn btn-link text-primary tbl-action"
                            onClick={()=>{
                                if(!(allocationData.isPendingMapping === true)){
                                    this.openUserModel(allocationData)
                                }
                            }}
                        >
                            {allocationData.isPendingMapping === true ? (
                                <i className="fas fa-user-plus"></i>
                            ) : (
                                <i className="fas fa-user-check"></i>
                            )}
                        </a>
                    )}
                    {allocationData?.entity?.companyGroup?.tenant?.isDbCreated === true && allocationData.isSchedulerActive === false &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                className="btn btn-link text-success tbl-action"
                            >
                                <i className="fas fa-play-circle"></i>
                            </a>
                        )}
                    {allocationData.isSchedulerActive === true &&
                        allocationData.isPendingMapping === false && (
                            <a
                                href={() => false}
                                className="btn btn-link text-danger tbl-action"
                            >
                                <i className="fas fa-stop-circle"></i>
                            </a>
                        )}

                </div>
            );

            return {
                checkbox: !allocationData.isPendingMapping,
                id: allocationData.id,
                isPendingMapping: allocationData.isPendingMapping,
                isSchedulerActive: allocationData.isSchedulerActive,
                companyGroupName:
                    allocationData.entity?.companyGroup?.companyGroupName,
                entityName: allocationData.entity?.entityName,
                complianceCode: allocationData.lawCompliance?.complianceCode,
                countryName:
                    allocationData.entityLocation?.country?.countryName,
                stateName: allocationData.entityLocation?.state?.stateName,
                cityName: allocationData.entityLocation?.city?.cityName,
                lawShortName: allocationData.lawCompliance?.law?.lawShortName,
                complianceTitle: allocationData.lawCompliance?.complianceTitle,
                complianceClassification:
                    allocationData.lawCompliance?.complianceCategory
                        ?.complianceCategoryName,
                actions: actions,
            };
        });
        return allocationRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.activeTab && this.props.activeTab !== nextprops.activeTab && nextprops.activeTab == "compliance_discovery_details"
        ) {
            this.props.getCountryListByStatusRequest(ACTIVE);
            this.props.getLawCategoryListByStatusRequest(ACTIVE);
            this.props.getCompanyGroupListByStatusRequest(ACTIVE,"",1);
            this.getLiteAllocationSearchList(
                this.state.pageSize,
                this.state.currentPage,
                this.state.sortBy,
                this.state.searchFormData
            );
        }
        if (
            nextprops.liteComplianceAllocationListApiResponse &&
            this.props.liteComplianceAllocationListApiResponse !== nextprops.liteComplianceAllocationListApiResponse
        ) {
            this.setState({
                resetTableSection: false,
                sortBy: nextprops.liteComplianceAllocationListApiResponse.data.orderBy,
                pageCount: nextprops.liteComplianceAllocationListApiResponse.data.totalPages,
                currentPage: nextprops.liteComplianceAllocationListApiResponse.data.currentPage,
                totalRecords: nextprops.liteComplianceAllocationListApiResponse.data.totalRecords,
                allocationList: this.generateTable(
                    nextprops.liteComplianceAllocationListApiResponse.data.complianceList
                ),
            });
        }
        if (
            nextprops.sortedStateListDropdown &&
            this.props.sortedStateListDropdown !==
            nextprops.sortedStateListDropdown
        ) {
            this.setState({
                sortedStateListDropdown: nextprops.sortedStateListDropdown,
            });
        }
        if (
            nextprops.sortedCityListDropdown &&
            this.props.sortedCityListDropdown !==
            nextprops.sortedCityListDropdown
        ) {
            this.setState({
                sortedCityListDropdown: nextprops.sortedCityListDropdown,
            });
        }
        if(nextprops.liteEntitySetupLocationListApiResponse && nextprops.liteEntitySetupLocationListApiResponse !== this.props.liteEntitySetupLocationListApiResponse){
            const list = nextprops.liteEntitySetupLocationListApiResponse?.data && nextprops.liteEntitySetupLocationListApiResponse?.data?.list.length > 0 && nextprops.liteEntitySetupLocationListApiResponse?.data?.list.map((element)=>{
                return  element.liteEntitySetupList && element.liteEntitySetupList.length > 0 && element.liteEntitySetupList.map((ele,index)=>{
                    return {
                        label:`${element.entityName} - Location${index + 1}`,
                        value: ele.id
                    }
                })
            });
            this.setState({
                sortedEntitySetupListDropdown: list &&list.length > 0 ? list?.flat(1):[]
            })  
        }
    }
    openUserModel(allocationData){
        this.setState({
            showUserModel:true,
            singleSelectedCompliance:allocationData
        },()=>{
            this.props.getDepartmentListByStatusRequest(ACTIVE,1);
            this.props.getEntityTeamsByEntityIdRequest(allocationData.entity?.id);  
        })
    }

    onModalDismiss(){
        this.setState({
            showUserModel:false,
            singleSelectedCompliance:null
        })
    }
    formSubmit(values){
        this.setState(
            {
                searchFormData: values,
                currentPage: 1,
                resetTableSection: true
            },
            () => {
                this.getLiteAllocationSearchList(
                    this.state.pageSize,
                    1,
                    this.state.sortBy,
                    this.state.searchFormData
                );
            }
        );
    }

    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getLiteAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                if (this.props.activeTab == "account_details") {
                    this.getLiteAllocationSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData
                    );
                }
            }
        );
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc != undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getLiteAllocationSearchList(data.pageSize, data.pageIndex + 1, { key: data.sortBy[0]?.id, value: sortOrder }, this.state.searchFormData)
                }
            );
        }
    }
    render() {
        const data = {
            columns: liteListCompliance,
            rows: this.state.allocationList,
        };
        const submitterName = this.props.entityTeams?.entityTeam && this.props.entityTeams?.entityTeam.length > 0 && this.props.entityTeams?.entityTeam.find((ele)=>ele.userId == this.state.singleSelectedCompliance?.submitterId);
        const firstApproverName = this.props.entityTeams?.entityTeam && this.props.entityTeams?.entityTeam.length > 0 && this.props.entityTeams?.entityTeam.find((ele)=>ele.userId == this.state.singleSelectedCompliance?.firstApproverUserId);
        const entityHod = this.props.entityTeams?.liteEntityHods && this.props.entityTeams?.liteEntityHods.length > 0 &&  this.props.entityTeams?.liteEntityHods.filter((ele)=> this.state.singleSelectedCompliance?.departmentId.includes(ele.departmentId) && this.state.singleSelectedCompliance?.entityHodId.includes(ele.id)).map((hodId)=>{
            const hodUser = this.props.entityTeams?.entityHod && this.props.entityTeams?.entityHod.length > 0  ? [...this.props.entityTeams.entityHod]:[];
            const managementUser = this.props.entityTeams?.managementUser && this.props.entityTeams?.managementUser.length > 0 ? [...this.props.entityTeams?.managementUser]:[];
            const entityTeam = this.props.entityTeams?.entityTeam && this.props.entityTeams?.entityTeam.length > 0 ? [...this.props.entityTeams?.entityTeam]:[];
            const combineUser = [...hodUser,...managementUser,...entityTeam];
            const findHodUserName = combineUser && combineUser.length > 0 && combineUser.find((element)=>element.userId == hodId.userId);
            const findDepartmentName = this.props.sortedDepartmentListDropdown && this.props.sortedDepartmentListDropdown.length > 0 && this.props.sortedDepartmentListDropdown.find((ele)=>ele.value == hodId?.departmentId);
            return {
                email:findHodUserName?.user?.email,
                departmentName:findDepartmentName?.label
            }
        })
        return <div>
            <CustomisedCollapseForOne
                className="accordion"
                viewDialog={false}
                dialogName={__t('page_headers.user_filter')}
            >
                <Formik
                    initialValues={{
                        countryId: [],
                        lawCategoryId: [],
                        companyGroupId:[],
                        entityId:[],
                        stateId:[],
                        cityId:[],
                        lawCategoryId:[],
                    }}
                    onSubmit={this.formSubmit}
                >
                    {({ values, setFieldValue,resetForm }) => (
                        <Form className="px-4 pt-4 pb-2">
                            <Row className="filter-form">
                                <React.Fragment>
                                <FormGroup className="col-lg-3">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="companyGroupId[]"
                                            className="form-control selectbox"
                                            options={this.props.sortedCompanyGroupListDropdown.filter(
                                                (opt) => opt.value != NONE
                                            )}
                                            onChange={(opt) => {
                                                let companyGroup = (opt) ? opt.map(x => x.value) : [];
                                                setFieldValue(
                                                    'companyGroupId',
                                                    companyGroup
                                                );
                                                this.props.getEntitySetupListRequest({
                                                    filter: {
                                                    companyGroupId :companyGroup
                                                  }
                                                })
                                            }}
                                            value={this.props.sortedCompanyGroupListDropdown.filter(
                                                (option) => values.companyGroupId.includes(option.value)
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{ Option, MultiValue, animatedComponents }}
                                        />
                                        <Label className="form-label">
                                            {__t(
                                                'form_labels.allocation.company_group'
                                            )}{' '}
                                            <span className="text-danger required">
                                                *
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="entitySetUpId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedEntitySetupListDropdown
                                        }
                                        onChange={opt => {
                                            setFieldValue(
                                                'entitySetUpId',
                                                (opt) ? opt.map(x => x.value) : []
                                            );
                                        }}
                                        value={this.state.sortedEntitySetupListDropdown?.filter(
                                            option =>
                                                values.entitySetUpId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.entity')}
                                    </Label>
                                </FormGroup>
                                    <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="countryId[]"
                                        className="form-control selectbox"
                                        options={this.props.sortedCountryListDropdown}
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}
                                        onChange={opt => {
                                            let countryIds = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'countryId',
                                                countryIds
                                            );
                                            setFieldValue(
                                                'stateId',
                                                []
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            if (countryIds.length > 0) {
                                                this.getStateListByCountry(countryIds);
                                                this.getCityListByState([]);
                                            } else {
                                                this.setState({
                                                    sortedStateListDropdown: [],
                                                    sortedCityListDropdown: []
                                                })
                                            }
                                        }}
                                        value={this.props.sortedCountryListDropdown?.filter(
                                            option =>
                                                values.countryId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.country')}
                                    </Label>
                                </FormGroup>
                                <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="stateId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedStateListDropdown
                                        }
                                        onChange={opt => {
                                            let stateId = (opt) ? opt.map(x => x.value) : []
                                            setFieldValue(
                                                'stateId',
                                                stateId
                                            );
                                            setFieldValue(
                                                'cityId',
                                                []
                                            );
                                            if (stateId.length > 0) {
                                                this.getCityListByState(stateId);
                                            } else {
                                                this.setState({
                                                    sortedCityListDropdown: []
                                                })
                                            }
                                        }}
                                        value={this.state.sortedStateListDropdown?.filter(
                                            option =>
                                                values.stateId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.state')}
                                    </Label>
                                </FormGroup>
                                    <FormGroup className='col-lg-3'>
                                    <Field
                                        component={CustomMultiSelect}
                                        name="cityId[]"
                                        className="form-control selectbox"
                                        options={
                                            this.state.sortedCityListDropdown
                                        }
                                        onChange={opt => {
                                            let cityId = (opt) ? opt.map(x => x.value) : [];
                                            setFieldValue(
                                                'cityId',
                                                cityId
                                            );
                                        }}
                                        value={this.state.sortedCityListDropdown?.filter(
                                            option => values.cityId?.includes(option.value)
                                        )}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        components={{ Option, MultiValue, animatedComponents }}
                                    />
                                    <Label className="form-label">
                                        {__t('form_labels.allocation.city')}
                                    </Label>
                                </FormGroup>
                                    <FormGroup className="col-lg-3">
                                        <Field
                                            component={CustomMultiSelect}
                                            name="lawCategoryId[]"
                                            className="form-control selectbox"
                                            options={
                                                this.props
                                                    .sortedLawCategoryListDropdown
                                            }
                                            onChange={(opt) => {
                                                let selectedValues = opt
                                                    ? opt.map((x) => x.value)
                                                    : [];
                                                setFieldValue(
                                                    'lawCategoryId',
                                                    selectedValues
                                                );
                                                this.getLawsByFilters({
                                                    ...values,
                                                    lawCategoryId:
                                                        selectedValues,
                                                });
                                            }}
                                            value={this.props.sortedLawCategoryListDropdown?.filter(
                                                (option) =>
                                                    values.lawCategoryId?.includes(
                                                        option.value
                                                    )
                                            )}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                MultiValue,
                                                animatedComponents,
                                            }}
                                            key="lawCategoryId"
                                        />
                                        <Label className="form-label">
                                            Practice Area
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="col-lg-6 d-flex justify-content-end align-items-end">
                                        <div className='d-flex'>
                                            <Button className='mx-1' type='reset' onClick={resetForm}>{CLEAR}</Button>
                                            <Button className='mx-1' type="button" color="primary" onClick={() => {
                                                this.formSubmit(values, true)
                                            }}>{SEARCH}</Button>
                                        </div>
                                    </FormGroup>
                                </React.Fragment>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </CustomisedCollapseForOne>

            <Row>
                <Col lg="12">
                    <Card className="mt-3">
                        <CardBody className="custom_table table-responsive">
                            <CustomisedReactTableWithPagination
                                className="table table-bordered table-striped"
                                displayEntries={
                                    true
                                }
                                searching={true}
                                currentPage={
                                    this.state.currentPage
                                }

                                pageCount={
                                    this.state.pageCount
                                }
                                totalRecords={this.state.totalRecords}
                                handleFetchData={this.handleFetchData}
                                handleSort={this.handleSort}
                                handleSearch={this.handleSearch}
                                preFilledSearchText={
                                    this.state.searchFormData
                                        ?.searchText
                                }
                                tableData={data}
                            />
                        </CardBody>
                    </Card>

                </Col>
                <CustomisedModal
                    modalName={"User Mapped and Department"}
                    isModalOpen={this.state.showUserModel}
                    onModalDismiss={() => this.onModalDismiss()}
                >
                    <div className='compliance-basic-details'>
                        <div className='compliance-card border-0 p-0 m-0'>     
                            <div className='compliance-details'>
                                {
                                    entityHod && entityHod.length > 0 && entityHod.map((element)=>{
                                        return  <ul className="basic-details">
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                            {__t("form_labels.entity.department")}
                                                        </b>
                                                        <p>
                                                        {element?.departmentName ?? "-"}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="details-info">
                                                        <b>
                                                          HOD
                                                        </b>
                                                        <p>
                                                        {element?.email ?? "-"}
                                                        </p>
                                                    </div>
                                                </li>
                                    </ul>
                                    })
                                }
                                <ul className="basic-details">
                                   <li>
                                        <div className="details-info">
                                            <b>
                                                {__t("form_labels.allocation.submitter")}
                                            </b>
                                            <p>
                                            {submitterName?.user?.email ?? "-"}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="details-info">
                                            <b>
                                                {__t("form_labels.allocation.first_approver")}
                                            </b>
                                            <p>
                                            {firstApproverName?.user?.email ?? "-"}
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </CustomisedModal>
            </Row>
        </div>
    }
}


const mapStatetoProps = createStructuredSelector({
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedLawCategoryListDropdown: selectSortedLawCategoryList(),
    liteComplianceAllocationListApiResponse:makeLiteClientRegistrationSelectField("liteComplianceAllocationListApiResponse"),
    sortedEntityTeamAndConsultantTeamUserDropdown:selectSortedLiteEntityTeam(),
    entityTeams:makeEntitySelectField('entityTeams'),
    liteEntitySetupLocationListApiResponse:makeLiteClientRegistrationSelectField("liteEntitySetupLocationListApiResponse")
});

const withConnect = connect(mapStatetoProps, {
    getStateListByCountryRequest,
    getCountryListByStatusRequest,
    getLawCategoryListByStatusRequest,
    getLiteComplianceAllocationRequest,
    getEntityTeamsByEntityIdRequest,
    getCityListByStateRequest,
    getDepartmentListByStatusRequest,
    getEntitySetupListRequest,
    getCompanyGroupListByStatusRequest
});

export default compose(
    withConnect,
    withStateReducer,
    withStateSaga,
    withLawCategorySaga,
    withLawCategoryReducer,
    withCountryReducer,
    withCountrySaga,
    withCityReducer,
    withCitySaga,
    withLiteCountriesListReducer,
    withLiteCountriesListSaga,
    withAllocationReducer,
    withAllocationSaga,
    withEntitySaga,
    withEntityReducer,
    withDepartmentReducer,
    withDepartmentSaga,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withRouter
)(ComplianceDiscoveryDetails);