import React, {Component} from "react";
import NoDataImage from "../../../images/empty-data.png"

class   NoDataFound extends Component{
   render(){
    return <figure className="empty-data">
    <img src={NoDataImage} alt="empty" />
</figure> 
   }
}

export default NoDataFound;