export const LITE_ACCOUNT_DETAILS_REQUEST = "lite_account_details_request";
export const LITE_ACCOUNT_DETAILS_RESPONSE = "lite_account_details_response";

export const LITE_ACCOUNT_DETAILS_ENTITY_REQUEST = "lite-account-details-entity-request";
export const LITE_ACCOUNT_DETAILS_ENTITY_RESPONSE = "lite-account-details-entity-response";

export const LITE_COUNTRY_LIST_WISE_ENTITY_REQUEST = "lite-country-list-wise-entity-request";
export const LITE_COUNTRY_LIST_WISE_ENTITY_RESPONSE = "lite-country-list-wise-entity-response";

export const LITE_ACCOUNT_ENTITY_NAME_UPDATE_REQUEST = "lite-account-entity-name-update-request";

export const LITE_ACCOUNT_ENTITY_NAME_UPDATE_RESPONSE = "lite-account-entity-name-update-response";

export const LITE_ENTITY_STATE_LIST_REQUEST = "lite-entity-state-list-request";

export const LITE_ENTITY_STATE_LIST_RESPONSE = "lite-entity-state-list-response";


export const LITE_ACCOUNT_UPDATE_REQUEST = "lite-account-update-request";

export const LITE_ACCOUNT_UPDATE_RESPONSE = "lite-account-update-response";

export const LITE_ENTITY_DETAILS_BY_ENTITY_ID_REQUEST = "lite_entity_details_by_entity_id_request";

export const LITE_ENTITY_DETAILS_BY_ENTITY_ID_RESPONSE = "lite_entity_details_by_entity_id_response";

export const LITE_ENTITY_UPDATE_BY_ID_REQUEST = "lite_entity_update_by_id_request";

export const LITE_ENTITY_UPDATE_BY_ID_RESPONSE = "lite_entity_update_by_id_response";

export const LITE_ENTITY_WISE_QUESTION_LIST_REQUEST = "lite_entity_wise_question_list_request";

export const LITE_ENTITY_WISE_QUESTION_LIST_RESPONSE = "lite_entity_wise_question_list_response";

export const LITE_SUSPEND_ACCOUNT_REQUEST = "lite-suspend-account-request";

export const LITE_SUSPEND_ACCOUNT_RESPONSE = "lite-suspend-account-response";

export const LITE_EDIT_LITE_ACCOUNT_REQUESTS = "lite_edit_lite_account_requests";

export const LITE_EDIT_LITE_ACCOUNT_RESPONSE = "lite_edit_lite_account_response";